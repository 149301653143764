import * as Types from '../../constants/actionconstants';
import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getLedgers = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_LEDGERS_REQUEST,
      });

      API.request('get', EndPoints.Ledgers)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.GET_LEDGERS_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.GET_LEDGERS_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_LEDGERS_FAILURE,
          });
        });
    });
};
