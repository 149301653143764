import React, { Component } from 'react';
import { connect } from 'react-redux';

import Radium from 'radium';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import { Tooltip as MuiToolTip } from '@mui/material';
import { Lock } from '@mui/icons-material';

import { DialogueBoxes } from './DialogueBoxes';

import { style } from '../style';
import hoursaddentryicon from '../../../assets/images/hours-add-btn-grey.svg';
import hoursbookingcyanicon from '../../../assets/images/hours-booking-tin-cyan.svg';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { hasPermission } from '../../../constants/ability';
import { TIME_ENTRY_REGEX } from '../../../constants/utility';
import { HoverMenu } from '../HoverMenu';
class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entry_add_hover: false,
      entry_add_hover: -1,
      is_dialoguebox_open: false,
      selected_day: null,
      new_booking: {},
      booking_minutes: 480, // initializing with 8h
      booking_minutes_error: '',
      booking_rate_error: '',
      is_edit_booking: false,
      selected_booking: {},
      is_loading: false,
      is_form_valid: false,
      createdBy: null,
      edittedBy: null,
      entry_hover_date: null,
      holiday_entry: false,
      showHolidayTooltip: true,
    };
  }

  componentDidUpdate() {}

  fetchDataOnload = () => {
    const { booking_entries, loggedin_user_id } = this.props;
    if (booking_entries[loggedin_user_id]) return;
    loggedin_user_id && this.getBookings(loggedin_user_id);
  };

  updateStateAndHandleChanges = () => {
    const { booking_minutes } = this.state;
    const { getMinutesInHMFormat } = this.props;

    this.checkFormValidity();
    this.handleRateChange();
    this.handleChangeMinutes({
      target: {
        value: booking_minutes
          ? getMinutesInHMFormat(booking_minutes)
          : this.state.booking_minutes_error
          ? ''
          : '8h',
      },
    });
  };

  handleBookingChange = (event) => {
    const { new_booking, booking_minutes } = this.state;
    this.setState(
      {
        new_booking: { ...new_booking, [event.target.name]: event.target.value },
      },
      this.updateStateAndHandleChanges,
    );
  };

  handleBookingStartDateChange = (event) => {
    const { new_booking, is_edit_booking } = this.state;
    const { isWeekendDay, isHoliday } = this.props;
    const convertDate = { month: event.month - 1, day: event.day, year: event.year };
    const updatedBooking = {
      ...new_booking,
      ['start_date']: Moment(convertDate).format('YYYY-MM-DD'),
    };
    this.setState(
      {
        new_booking: {
          ...new_booking,
          ['start_date']: Moment(convertDate).format('YYYY-MM-DD'),
        },
      },

      this.updateStateAndHandleChanges,
    );
    if (
      isWeekendDay(Moment(convertDate)) ||
      isHoliday(Moment(convertDate)) ||
      !new_booking.end_date
    ) {
      this.setState({
        new_booking: {
          ...updatedBooking,
          ['end_date']: Moment(convertDate).format('YYYY-MM-DD'),
        },
      });
    } else {
      let new_start_date = Moment(convertDate);

      if (
        Moment(new_booking.end_date).diff(new_start_date, 'days') < 0 ||
        isWeekendDay(Moment(new_booking.end_date)) ||
        isHoliday(Moment(new_booking.end_date))
      ) {
        this.setState({
          new_booking: {
            ...updatedBooking,
            ['end_date']: Moment(convertDate).format('YYYY-MM-DD'),
          },
        });
      }
    }

    // if edit case, end date should be same as start date
    if (is_edit_booking) {
      this.setState({
        new_booking: {
          ...updatedBooking,
          ['end_date']: Moment(convertDate).format('YYYY-MM-DD'),
        },
      });
    }
  };

  handleBookingEndDateChange = (event) => {
    const { new_booking, booking_minutes } = this.state;
    const { isWeekendDay, isHoliday } = this.props;
    const convertDate = { month: event.month - 1, day: event.day, year: event.year };
    const updatedBooking = {
      ...new_booking,
      ['end_date']: Moment(convertDate).format('YYYY-MM-DD'),
    };
    this.setState(
      {
        new_booking: { ...new_booking, ['end_date']: Moment(convertDate).format('YYYY-MM-DD') },
      },
      () => {
        this.updateStateAndHandleChanges;
      },
    );
    if (isWeekendDay(Moment(convertDate)) || isWeekendDay(Moment(new_booking.start_date))) {
      this.setState({
        new_booking: {
          ...updatedBooking,
          ['start_date']: Moment(convertDate).format('YYYY-MM-DD'),
        },
      });
    } else if (isHoliday(Moment(convertDate)) || isHoliday(Moment(new_booking.start_date))) {
      this.setState({
        new_booking: {
          ...updatedBooking,
          ['start_date']: Moment(convertDate).format('YYYY-MM-DD'),
        },
      });
    }
  };

  handleRateChange = () => {
    if (
      !this.state.new_booking.hourly_rate &&
      (this.state.new_booking.hourly_rate !== 0 || this.state.new_booking.hourly_rate < 0)
    ) {
      this.setState({
        booking_rate_error: 'Invalid rate',
      });
    } else {
      this.setState({
        booking_rate_error: '',
      });
    }
  };

  checkFormValidity = () => {
    if (this.state.booking_minutes && this.state.new_booking.projectId) {
      this.setState({
        is_form_valid: true,
      });
    } else if (this.state.is_form_valid) {
      // for returning invalid state if wrong parameters are entered again.
      this.setState({
        is_form_valid: false,
      });
    }
  };

  getBookings = async (resourceId) => {
    const { getWeekBookings, current_raw_date } = this.props;
    try {
      await getWeekBookings(resourceId, -1, current_raw_date.format('YYYY-MM-DD'));
    } catch {
      console.log('Error');
    }
  };

  createBooking = async () => {
    const { addCustomBooking, enqueueSnackbar } = this.props;

    const data = this.generateBookingsArray();

    try {
      this.setState({ is_loading: true, is_form_valid: false });
      await addCustomBooking(data);
      this.props.changeWeekStart(data[0].start_date);
      this.onSuccessfulOperation();
      enqueueSnackbar('Booking added successfully', { variant: 'success' });
    } catch (error) {
      this.setState({ is_loading: false });
      enqueueSnackbar('Error adding booking', { variant: 'error' });
    }
  };

  deleteBooking = async (docId) => {
    const { deleteCustomBooking, enqueueSnackbar } = this.props;

    var answer = window.confirm('Are you sure you want to delete this booking?');
    if (answer) {
      try {
        this.setState({ is_loading: true, is_form_valid: false });
        await deleteCustomBooking(docId);
        this.onSuccessfulOperation();
        enqueueSnackbar('Booking deleted successfully', { variant: 'success' });
      } catch (error) {
        this.setState({ is_loading: false, is_form_valid: false });
        enqueueSnackbar('Error deleting booking', { variant: 'error' });
      }
    }
  };

  editBooking = async (docId) => {
    const { editCustomBooking, enqueueSnackbar } = this.props;
    const { new_booking, booking_minutes } = this.state;
    const data = {
      ...new_booking,
      minutes: parseInt(booking_minutes ? booking_minutes : new_booking.minutes),
      resourceId: parseInt(new_booking.resourceId),
      projectId: parseInt(new_booking.projectId),
      hourly_rate: parseFloat(new_booking.hourly_rate),
    };

    try {
      this.setState({ is_loading: true });
      await editCustomBooking(docId, data);
      this.onSuccessfulOperation();
      enqueueSnackbar('Booking updated successfully', { variant: 'success' });
    } catch (error) {
      this.setState({ is_loading: false });
      enqueueSnackbar('Error updating booking', { variant: 'error' });
    }
  };

  onSuccessfulOperation = () => {
    const resourceId = this.setResourceId();
    this.setState({ is_loading: false, is_form_valid: false, booking_minutes: '' });
    this.handleCloseDialogueBox();
    this.getBookings(resourceId);
  };

  generateBookingsArray = () => {
    const { new_booking, selected_day, booking_minutes } = this.state;
    const { week_start, isHoliday, isWeekendDay } = this.props;

    let bookings = [];

    const resourceId = this.setResourceId();
    const startDate = new_booking.start_date ? new_booking.start_date : selected_day;
    const endDate = new_booking.end_date ? new_booking.end_date : selected_day;

    // ask user if they want to add entry on weekend if they selected start date of weekend
    let weekendStartStatus = isWeekendDay(Moment(startDate));
    let weekendConfirm = undefined;
    if (weekendStartStatus) {
      weekendConfirm = window.confirm(
        'Start date is a weekend, are you sure you want to add weekend entries?\n\nPress Ok to include weekend entries, press Cancel to ignore them.',
      );
    }

    let entriesLength = Moment(endDate).diff(Moment(startDate), 'days');
    for (let day = Moment(startDate); day.isSameOrBefore(endDate); day.add(1, 'days')) {
      // 1. User adds multiple entries from one weekday to another (Friday - Monday)
      // 1. Ignore weekends in that case automatically
      // 2. User selects start date of a weekend, in that case take decision based on the above prompt
      // 3. Skip if the day is a holiday, only can add one entry for holiday

      // 1.
      if (!weekendStartStatus && isWeekendDay(day)) {
        continue;
      }
      // 2.
      else if (weekendConfirm !== undefined && !weekendConfirm && isWeekendDay(day)) {
        continue;
      }
      // 3.
      else if (isHoliday(day) && entriesLength > 0) {
        continue;
      }

      bookings.push({
        resourceId: parseInt(resourceId),
        projectId: parseInt(new_booking.projectId),
        start_date: day.format('YYYY-MM-DD'),
        end_date: day.format('YYYY-MM-DD'),
        week_start: week_start.format('YYYY-MM-DD'),
        minutes: parseInt(booking_minutes),
        description: new_booking.description ? new_booking.description : '',
        hourly_rate: parseFloat(new_booking.hourly_rate),
      });
    }

    return bookings;
  };

  setResourceId = () => {
    const {
      resources,
      selected_resource,
      loggedin_user_id,
      selected_view,
      selected_project_logger,
    } = this.props;
    const resourceId =
      selected_view === 'resources'
        ? selected_resource.id
        : selected_view === 'private'
        ? loggedin_user_id
        : resources &&
          resources
            .filter((resource) => resource.id === selected_project_logger)
            .map((resource) => {
              return resource.id;
            });
    return resourceId;
  };

  getLoggedMinutesForDate = (dates) => {
    const { booking_entries, current_user } = this.props;
    const filtered_entries = [];
    let total_minutes_logged = 0;
    let entry_minutes = {};
    booking_entries[current_user] &&
      booking_entries[current_user].forEach((timeEntry) => {
        if (dates.includes(timeEntry.start_date)) {
          total_minutes_logged += timeEntry.minutes;
          filtered_entries.push(timeEntry);

          // adding to dict, later we will get max minutes
          const date = timeEntry.start_date;
          if (entry_minutes[date]) {
            // adding minutes to existing entry
            entry_minutes[date] = entry_minutes[date] + timeEntry.minutes;
          } else {
            // making a new entry
            entry_minutes[date] = timeEntry.minutes;
          }
        }
      });
    // finding day with max minutes
    const max_minutes_logged_key = total_minutes_logged
      ? Object.entries(entry_minutes).reduce((a, b) => (a[1] > b[1] ? a : b))[0]
      : 0;
    return { total_minutes_logged, max_minutes_logged: entry_minutes[max_minutes_logged_key] || 0 };
  };

  handleChangeMinutes = (event) => {
    const { new_booking, is_edit_booking, selected_booking } = this.state;
    const { start_date, end_date } = new_booking;
    const dates = this.props.enumerateDaysBetweenDates(start_date, end_date);
    const max_minutes = 1440; // a day has 1440 minutes
    const { total_minutes_logged, max_minutes_logged } = this.getLoggedMinutesForDate(dates);
    // max allowed minutes to enter
    let editEntryMinutes =
      is_edit_booking && new_booking && new_booking.minutes ? new_booking.minutes : 0;
    if (selected_booking && selected_booking.start_date !== end_date) {
      editEntryMinutes = 0;
    }
    const minutes_left = max_minutes - (max_minutes_logged - editEntryMinutes);

    let booking_minutes = '';
    let minutesInput = event.target.value;
    minutesInput = minutesInput.replace(/\s+/g, '');
    // only allow input of format 4h7m, 4h, or 7m
    if (minutesInput.search(TIME_ENTRY_REGEX) < 0) {
      this.setState({
        booking_minutes_error: 'Invalid time',
        booking_minutes,
      });
      return;
    }

    if (minutesInput.indexOf('h') > -1 && minutesInput.indexOf('m') > -1) {
      let hIndex = minutesInput.indexOf('h');
      let mIndex = minutesInput.indexOf('m');
      let hours = minutesInput.substring(0, hIndex);
      let minutes = minutesInput.substring(hIndex + 1, mIndex);

      booking_minutes = parseInt(hours) * 60 + parseInt(minutes);
    } else if (minutesInput.indexOf('h') > -1) {
      let hours = minutesInput.substring(0, minutesInput.length - 1);
      booking_minutes = parseInt(hours) * 60;
    } else if (minutesInput.indexOf('m') > -1) {
      let minutes = minutesInput.substring(0, minutesInput.length - 1);
      booking_minutes = parseInt(minutes);
    }

    // changing state and handling error message
    if (booking_minutes + (max_minutes_logged - editEntryMinutes) > max_minutes) {
      // dont allow to enter time exceeding 24h
      this.setState({
        booking_minutes_error: `Max ${this.props.getMinutesInHMFormat(minutes_left)} left`,
        booking_minutes,
      });
    } else {
      // valid entry
      this.setState(
        {
          booking_minutes_error: '',
          booking_minutes,
        },
        () => {
          this.checkFormValidity();
        },
      );
    }
  };

  changeMinutesFormat = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes}m`;
  };

  checkTimeEntryExists = (cdate) => {
    const { booking_entries, current_user } = this.props;
    let found = [];
    if (current_user && booking_entries) {
      const current_booking_entries = booking_entries[current_user];

      for (let tindex in current_booking_entries) {
        let te = current_booking_entries[tindex].start_date;
        if (te === cdate.format('YYYY-MM-DD')) {
          found.push(current_booking_entries[tindex]);
        }
      }
    }
    if (found.length > 0) {
      found.sort((booking1, booking2) => booking1.projectname.localeCompare(booking2.projectname));
    }
    return found;
  };

  toggleAddEntryButton = (value, day) => {
    const { isHoliday } = this.props;
    this.setState({ entry_add_hover: value, entry_hover_date: day, holiday_entry: isHoliday(day) });
  };

  handleCloseDialogueBox = () => {
    this.setState({
      is_dialoguebox_open: false,
      is_edit_booking: false,
      new_booking: {},
      booking_minutes: 480,
      booking_minutes_error: '',
      booking_rate_error: '',
    });
  };

  handleOpenCreateModal = (day, value) => {
    const start_date = day.format('YYYY-MM-DD');
    const end_date = day.format('YYYY-MM-DD');
    this.setState(
      {
        is_dialoguebox_open: value,
        selected_day: day,
        new_booking: { start_date, end_date },
      },
      () => {
        this.handleChangeMinutes({ target: { value: '8h' } }); // to show limit message if any
      },
    );
  };

  toggleHolidayTooltip = (val) => {
    this.setState({
      showHolidayTooltip: val,
    });
  };

  handleOpenEditModal = (booking) => {
    const {
      description,
      minutes,
      hourly_rate,
      resourceId,
      projectId,
      start_date,
      end_date,
      week_start,
    } = booking;
    let created_event,
      editted_event = null;
    if (booking && 'events' in booking && booking.events !== null) {
      let created_events = booking.events.filter((event) => event.type === 'Created');
      created_event = created_events[0];
      let editted_events = booking.events.filter((event) => event.type === 'Modified');
      editted_event = editted_events[editted_events.length - 1];
    }
    this.setState(
      {
        is_dialoguebox_open: true,
        selected_booking: booking,
        is_edit_booking: true,
        new_booking: {
          description,
          minutes,
          hourly_rate,
          resourceId,
          projectId,
          start_date,
          end_date,
          week_start,
        },
        booking_minutes: minutes,
        booking_minutes_error:
          this.props.getMinutesInHMFormat(minutes).search(TIME_ENTRY_REGEX) < 0
            ? 'Invalid time'
            : '',
        createdBy: created_event,
        edittedBy: editted_event,
      },
      () => {
        this.checkFormValidity();
        this.handleRateChange();
      },
    );
  };

  render() {
    const {
      days,
      isHoliday,
      getHoliday,
      isWeekendDay,
      getProjectColor,
      resources,
      projects,
      selected_view,
      loggedin_user_id,
      name,
      selected_resource,
      selected_project_logger,
      getMinutesInHMFormat,
      roles,
      last_locked,
      enqueueSnackbar,
      can_create_entries,
      formattedMinimumDate,
    } = this.props;

    const {
      entry_add_hover,
      is_dialoguebox_open,
      selected_day,
      new_booking,
      is_edit_booking,
      selected_booking,
      is_loading,
      is_form_valid,
      booking_minutes_error,
      booking_rate_error,
      createdBy,
      edittedBy,
      entry_hover_date,
      holiday_entry,
      showHolidayTooltip,
    } = this.state;
    return (
      <>
        <div style={style.BookingsHeader}>
          <div style={style.BookingsHeaderLabel}>
            <img src={hoursbookingcyanicon} alt="" />
            <div>Bookings</div>
          </div>
        </div>
        <div style={style.BookingsCurrentWeek}>
          {days.map((day, index) => {
            const isWeekendDayCheck = isWeekendDay(day);
            const isHolidayCheck = isHoliday(day);
            const isInFuture = Moment().format('YYYY-MM-DD') < day.format('YYYY-MM-DD');
            const isLocked = Moment(day).isSameOrBefore(last_locked);
            const bookings = this.checkTimeEntryExists(day);
            const can_edit = hasPermission(roles, 'hour_logging', 'can_update_bookings');
            return (
              <>
                {holiday_entry &&
                showHolidayTooltip &&
                day.format('YYYY-MM-DD') === entry_hover_date.format('YYYY-MM-DD') ? (
                  <MuiToolTip title={getHoliday(day).title} placement="top">
                    <div
                      key={index}
                      id="bookings-div"
                      style={
                        isWeekendDayCheck
                          ? style.EntryDayBoxWeekend
                          : isHolidayCheck
                          ? style.EntryDayBoxHoliday
                          : style.EntryDayBox
                      }
                      onMouseEnter={() => this.toggleAddEntryButton(index, day)}
                      onMouseLeave={() => this.toggleAddEntryButton(-1, day)}>
                      {bookings && bookings.length > 0 ? (
                        selected_view === 'projects' ||
                        selected_view === 'resources' ||
                        selected_view === 'teams' ? (
                          selected_project_logger || selected_resource ? (
                            bookings.map((booking, index) => {
                              return (
                                <div
                                  className="tooltip"
                                  key={index}
                                  style={{
                                    ...style.BookingsItem,
                                    backgroundColor: getProjectColor(booking.projectId),
                                    cursor: hasPermission(
                                      roles,
                                      'hour_logging',
                                      'can_update_bookings',
                                    )
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                  onClick={() => {
                                    if (
                                      !isLocked &&
                                      hasPermission(roles, 'hour_logging', 'can_update_bookings')
                                    ) {
                                      this.handleOpenEditModal(booking);
                                    } else if (isLocked) {
                                      enqueueSnackbar('Booking is locked', { variant: 'warning' });
                                    } else {
                                      // enqueueSnackbar('No permission', { variant: 'warning' })
                                    }
                                  }}
                                  onMouseEnter={() => this.toggleHolidayTooltip(false)}
                                  onMouseLeave={() => this.toggleHolidayTooltip(true)}>
                                  <HoverMenu
                                    entry={booking}
                                    getProjectColor={getProjectColor}
                                    getNameInitials={this.props.getNameInitials}
                                    handleDeleteEntryFromPopup={this.deleteBooking}
                                    handleEditEntryFromPopup={this.handleOpenEditModal}
                                    getHoursfromMinutes={this.props.getHoursfromMinutes}
                                    formatTimeAgo={this.props.formatTimeAgo}
                                    toDelete={booking.docId}
                                    isLocked={isLocked}
                                    can_edit={can_edit}></HoverMenu>
                                  <div>
                                    <b>{booking.projectname}</b>
                                    <br />
                                    {this.props.getMinutesInHMFormat(booking.minutes)}
                                  </div>
                                  {isLocked ? (
                                    <div>
                                      <Lock fontSize="small" />
                                    </div>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : isLocked ? (
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}>
                              <Lock fontSize="small" />
                            </div>
                          ) : null
                        ) : (
                          bookings.map((booking, index) => {
                            //for private tab
                            return (
                              <div
                                className="tooltip"
                                key={index}
                                style={{
                                  ...style.BookingsItem,
                                  backgroundColor: getProjectColor(booking.projectId),
                                  cursor: hasPermission(
                                    roles,
                                    'hour_logging',
                                    'can_update_bookings',
                                  )
                                    ? 'pointer'
                                    : 'default',
                                }}
                                onClick={() => {
                                  if (
                                    !isLocked &&
                                    hasPermission(roles, 'hour_logging', 'can_update_bookings')
                                  ) {
                                    this.handleOpenEditModal(booking);
                                  } else if (isLocked) {
                                    enqueueSnackbar('Booking is locked', { variant: 'warning' });
                                  } else {
                                    // enqueueSnackbar('No permission', { variant: 'warning' })
                                  }
                                }}
                                onMouseEnter={() => this.toggleHolidayTooltip(false)}
                                onMouseLeave={() => this.toggleHolidayTooltip(true)}>
                                <HoverMenu
                                  entry={booking}
                                  getProjectColor={getProjectColor}
                                  getNameInitials={this.props.getNameInitials}
                                  handleDeleteEntryFromPopup={this.deleteBooking}
                                  handleEditEntryFromPopup={this.handleOpenEditModal}
                                  getHoursfromMinutes={this.props.getHoursfromMinutes}
                                  formatTimeAgo={this.props.formatTimeAgo}
                                  toDelete={booking.docId}
                                  isLocked={isLocked}
                                  can_edit={can_edit}></HoverMenu>
                                <div>
                                  <b>{booking.projectname}</b>
                                  <br />
                                  {this.props.getMinutesInHMFormat(booking.minutes)}
                                </div>
                                {isLocked ? (
                                  <div>
                                    <Lock fontSize="small" />
                                  </div>
                                ) : null}
                              </div>
                            );
                          })
                        )
                      ) : isLocked ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}>
                          <Lock fontSize="small" />
                        </div>
                      ) : null}
                      {!isLocked &&
                      can_create_entries === true &&
                      entry_add_hover === index &&
                      hasPermission(roles, 'hour_logging', 'can_create_bookings') ? (
                        <MuiToolTip title="Add Entry" placement="bottom">
                          <div
                            style={style.EntryItemAdd}
                            onClick={() => this.handleOpenCreateModal(day, true, {})}>
                            <img src={hoursaddentryicon} alt="" />
                          </div>
                        </MuiToolTip>
                      ) : null}
                    </div>
                  </MuiToolTip>
                ) : (
                  <div
                    key={index}
                    id="bookings-div"
                    style={
                      isWeekendDayCheck
                        ? style.EntryDayBoxWeekend
                        : isHolidayCheck
                        ? style.EntryDayBoxHoliday
                        : style.EntryDayBox
                    }
                    onMouseEnter={() => this.toggleAddEntryButton(index, day)}
                    onMouseLeave={() => this.toggleAddEntryButton(-1, day)}>
                    {bookings && bookings.length > 0 ? (
                      selected_view === 'projects' ||
                      selected_view === 'resources' ||
                      selected_view === 'teams' ? (
                        selected_project_logger || selected_resource ? (
                          bookings.map((booking, index) => {
                            return (
                              <div
                                className="tooltip"
                                key={index}
                                style={{
                                  ...style.BookingsItem,
                                  backgroundColor: getProjectColor(booking.projectId),
                                  cursor: hasPermission(
                                    roles,
                                    'hour_logging',
                                    'can_update_bookings',
                                  )
                                    ? 'pointer'
                                    : 'default',
                                }}
                                onClick={() => {
                                  if (
                                    !isLocked &&
                                    hasPermission(roles, 'hour_logging', 'can_update_bookings')
                                  ) {
                                    this.handleOpenEditModal(booking);
                                  } else if (isLocked) {
                                    enqueueSnackbar('Booking is locked', { variant: 'warning' });
                                  } else {
                                    // enqueueSnackbar('No permission', { variant: 'warning' })
                                  }
                                }}
                                onMouseEnter={() => this.toggleHolidayTooltip(false)}
                                onMouseLeave={() => this.toggleHolidayTooltip(true)}>
                                <HoverMenu
                                  entry={booking}
                                  getProjectColor={getProjectColor}
                                  getNameInitials={this.props.getNameInitials}
                                  handleDeleteEntryFromPopup={this.deleteBooking}
                                  handleEditEntryFromPopup={this.handleOpenEditModal}
                                  getHoursfromMinutes={this.props.getHoursfromMinutes}
                                  formatTimeAgo={this.props.formatTimeAgo}
                                  toDelete={booking.docId}
                                  isLocked={isLocked}
                                  can_edit={can_edit}></HoverMenu>
                                <div>
                                  <b>{booking.projectname}</b>
                                  <br />
                                  {this.props.getMinutesInHMFormat(booking.minutes)}
                                </div>
                                {isLocked ? (
                                  <div>
                                    <Lock fontSize="small" />
                                  </div>
                                ) : null}
                              </div>
                            );
                          })
                        ) : isLocked ? (
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}>
                            <Lock fontSize="small" />
                          </div>
                        ) : null
                      ) : (
                        bookings.map((booking, index) => {
                          //for private tab
                          return (
                            <div
                              className="tooltip"
                              key={index}
                              style={{
                                ...style.BookingsItem,
                                backgroundColor: getProjectColor(booking.projectId),
                                cursor: hasPermission(roles, 'hour_logging', 'can_update_bookings')
                                  ? 'pointer'
                                  : 'default',
                              }}
                              onClick={() => {
                                if (
                                  !isLocked &&
                                  hasPermission(roles, 'hour_logging', 'can_update_bookings')
                                ) {
                                  this.handleOpenEditModal(booking);
                                } else if (isLocked) {
                                  enqueueSnackbar('Booking is locked', { variant: 'warning' });
                                } else {
                                  // enqueueSnackbar('No permission', { variant: 'warning' })
                                }
                              }}
                              onMouseEnter={() => this.toggleHolidayTooltip(false)}
                              onMouseLeave={() => this.toggleHolidayTooltip(true)}>
                              <HoverMenu
                                entry={booking}
                                getProjectColor={getProjectColor}
                                getNameInitials={this.props.getNameInitials}
                                handleDeleteEntryFromPopup={this.deleteBooking}
                                handleEditEntryFromPopup={this.handleOpenEditModal}
                                getHoursfromMinutes={this.props.getHoursfromMinutes}
                                formatTimeAgo={this.props.formatTimeAgo}
                                toDelete={booking.docId}
                                isLocked={isLocked}
                                can_edit={can_edit}></HoverMenu>
                              <div>
                                <b>{booking.projectname}</b>
                                <br />
                                {this.props.getMinutesInHMFormat(booking.minutes)}
                              </div>
                              {isLocked ? (
                                <div>
                                  <Lock fontSize="small" />
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      )
                    ) : isLocked ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}>
                        <Lock fontSize="small" />
                      </div>
                    ) : null}
                    {!isLocked &&
                    can_create_entries === true &&
                    entry_add_hover === index &&
                    hasPermission(roles, 'hour_logging', 'can_create_bookings') ? (
                      <MuiToolTip title="Add Entry" placement="bottom">
                        <div
                          style={style.EntryItemAdd}
                          onClick={() => this.handleOpenCreateModal(day, true, {})}>
                          <img src={hoursaddentryicon} alt="" />
                        </div>
                      </MuiToolTip>
                    ) : null}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <DialogueBoxes
          is_dialoguebox_open={is_dialoguebox_open}
          handleCloseDialogueBox={this.handleCloseDialogueBox}
          resources={resources}
          projects={projects}
          selected_day={selected_day}
          new_booking={new_booking}
          selected_booking={selected_booking}
          handleBookingChange={this.handleBookingChange}
          createBooking={this.createBooking}
          selected_view={selected_view}
          loggedin_user_id={loggedin_user_id}
          loggedin_user_name={name}
          selected_resource={selected_resource}
          selected_project_logger={selected_project_logger}
          handleChangeMinutes={this.handleChangeMinutes}
          is_edit_booking={is_edit_booking}
          editBooking={this.editBooking}
          changeMinutesFormat={this.changeMinutesFormat}
          deleteBooking={this.deleteBooking}
          is_loading={is_loading}
          is_form_valid={is_form_valid}
          isWeekendDay={isWeekendDay}
          getMinutesInHMFormat={getMinutesInHMFormat}
          last_locked={this.props.last_locked}
          isHoliday={isHoliday}
          roles={roles}
          booking_minutes_error={booking_minutes_error}
          booking_rate_error={booking_rate_error}
          createdBy={createdBy}
          edittedBy={edittedBy}
          formattedMinimumDate={formattedMinimumDate}
          handleBookingStartDateChange={this.handleBookingStartDateChange}
          handleBookingEndDateChange={this.handleBookingEndDateChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking_entries: state.BookingReducer.booking_entries,
    name: state.LoginReducer.name,
    email: state.LoginReducer.email,
    resources: state.ResourceReducer.resources,
    projects: state.ProjectReducer.projects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWeekBookings: (resid, projid, date) =>
    dispatch(ActionsCreator.getWeekBookings(resid, projid, date)),
  addCustomBooking: (data) => dispatch(ActionsCreator.addCustomBooking(data)),
  editCustomBooking: (docId, data) => dispatch(ActionsCreator.editCustomBooking(docId, data)),
  deleteCustomBooking: (docId) => dispatch(ActionsCreator.deleteCustomBooking(docId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Radium(Bookings)));
