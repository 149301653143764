import React, { Component } from 'react';

import Role from '../../components/RolesManagement';
import './RolesManagementPage.css';

class RoleManagementPage extends Component {
  render() {
    return (
      <div className="role-management-page-main">
        <Role />
      </div>
    );
  }
}

export default RoleManagementPage;
