import React from 'react';

import { List, ListItem } from '@mui/material';

import { style } from './style';

export const ClientListView = (props) => (
  <List style={style.ListView}>
    {props.clients.map((client, index) => {
      return (
        <ListItem
          button
          key={index}
          onClick={() => props.handleClientClick(client.docId)}
          style={
            props.client_selected.docId === client.docId
              ? style.SelectedClientItem
              : style.UnselectedClientItem
          }>
          <span
            style={
              props.client_selected.docId === client.docId
                ? style.SelectedClientText
                : style.UnselectedClientText
            }>
            {client.project_name}
          </span>
        </ListItem>
      );
    })}
  </List>
);
