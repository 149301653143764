export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-dark-green)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  FlexCol: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  BodyWithFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 204px)',
  },
  BodyWithoutFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 154px)',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 184px)',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  FilledButton: {
    backgroundColor: 'var(--tin-dark-green)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  TableCellP: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0px',
  },
  edit_button: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 'none',
  },
  delete_button: {
    color: 'red',
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 'none',
  },
  TableCell1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingLeft: '40px',
    paddingRight: '20px',
    justifyContent: 'center',
  },
  TextWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  TableCell2: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '20px',
    paddingLeft: '35px',
  },
  TableCell3: {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0px',
    paddingLeft: '35px',
  },
  TableCell4: {
    justifyContent: 'center',
    display: 'flex',
    flex: 0.2,
    alignItems: 'center',
    padding: '0px',
  },
  TableCell5: {
    justifyContent: 'center',
    display: 'flex',
    flex: 0.2,
    alignItems: 'center',
    padding: '0px',
    paddingRight: '40px',
  },
  TableCellH1: {
    display: 'flex',
    flex: 3,
    alignItems: 'center',
    padding: '0px',
    paddingLeft: '5px',
  },
  TableContainer: {
    flex: 1,
    width: '100%',
    padding: '0px 20px',
  },
  SearchFieldsContainer: {
    height: '50px',
  },
  ShowFiltersButtonContainer: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  ShowFiltersButton: {
    padding: '5px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-grey)',
    textDecoration: 'underline',
  },
  DetailPageContainer: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  RowItems1: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-dark-green)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  DeleteHeadingRow: {
    fontSize: '12px',
    flex: 1,
    display: 'flex',
    padding: '20px 100px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  DeleteBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  FilterSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-dark-green)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
};
