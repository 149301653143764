import React from 'react';

import Moment from 'moment';
import { Table, TableBody, TableHead, TableCell, TableRow, Checkbox } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

import { style } from './style';
import { InvoiceState } from '../../constants/invoicestate';
import { InvoicesSearchFields } from './InvoicesSearchFields';
import { getTotalFromInvoiceDataUpdated } from '../../Selectors/index';

export const AllInvoices = (props) => (
  <div style={style.TableContainer}>
    {props.show_filters ? (
      <>
        {props.show_checkboxes ? (
          <div style={style.ShowCheckboxesContainer}>
            <div
              style={
                props.checkedValues.length === 0
                  ? style.ClickableText
                  : style.ShowFiltersButtonActive
              }
              onClick={props.checkedValues.length !== 0 ? () => props.handleDownloadCSV() : null}>
              Download CSV
            </div>
            <div
              style={
                props.checkedValues.length === 0
                  ? style.ClickableText
                  : style.ShowFiltersButtonActive
              }
              onClick={() => props.handleChangeShowCheckboxes(false)}>
              Cancel
            </div>
          </div>
        ) : (
          <div style={style.ShowCheckboxesContainer}>
            <div
              style={style.ClickableText}
              onClick={() => props.handleChangeShowFilterStatus(false)}>
              Select invoices to export to CSV
            </div>
          </div>
        )}
        <div style={style.ShowFiltersButtonContainer}>
          <div
            style={style.ShowFiltersButton}
            onClick={() => props.handleChangeShowFilterStatus(false)}>
            Hide Filters
          </div>
        </div>
        <InvoicesSearchFields
          projects={props.projects}
          selected_status={props.selected_status}
          selected_project={props.selected_project}
          selected_to_date={props.selected_to_date}
          selected_from_date={props.selected_from_date}
          handleClearFilters={props.handleClearFilters}
          handleChangeToDate={props.handleChangeToDate}
          selected_invoice_id={props.selected_invoice_id}
          handleChangeFromDate={props.handleChangeFromDate}
          selected_issued_to_date={props.selected_issued_to_date}
          handleChangeIssuedToDate={props.handleChangeIssuedToDate}
          selected_issued_from_date={props.selected_issued_from_date}
          handleChangeSelectedStatus={props.handleChangeSelectedStatus}
          handleChangeIssuedFromDate={props.handleChangeIssuedFromDate}
          handleChangeSelectedProject={props.handleChangeSelectedProject}
          handleChangeSearchInvoiceID={props.handleChangeSearchInvoiceID}
        />
      </>
    ) : (
      <>
        {props.show_checkboxes ? (
          <div style={style.ShowCheckboxesContainer}>
            <div
              style={
                props.checkedValues.length === 0
                  ? style.ClickableText
                  : style.ShowFiltersButtonActive
              }
              onClick={props.checkedValues.length !== 0 ? () => props.handleDownloadCSV() : null}>
              Download CSV
            </div>
            <div
              style={
                props.checkedValues.length === 0
                  ? style.ClickableText
                  : style.ShowFiltersButtonActive
              }
              onClick={() => props.handleChangeShowCheckboxes(false)}>
              Cancel
            </div>
          </div>
        ) : (
          <div style={style.ShowCheckboxesContainer}>
            <div style={style.ClickableText} onClick={() => props.handleChangeShowCheckboxes(true)}>
              Select invoices to export to CSV
            </div>
          </div>
        )}
        <div style={style.ShowFiltersButtonContainer}>
          <div
            style={style.ShowFiltersButton}
            onClick={() => props.handleChangeShowFilterStatus(true)}>
            Show Filters
          </div>
        </div>
      </>
    )}

    <Table style={style.Table}>
      <TableHead style={style.Head}>
        <TableRow style={style.TableRow}>
          {props.show_checkboxes ? (
            <TableCell className="hours-management-button">
              <Checkbox
                disabled
                value="disabled"
                inputProps={{ 'aria-label': 'disabled checkbox' }}
              />
            </TableCell>
          ) : null}
          {Object.keys(props.header).map((item, index) => {
            return (
              <TableCell
                key={index}
                style={index !== 4 ? style.TableCell3 : style.TableCellH1}
                className="hours-management-button"
                onClick={() => props.handleInvoiceSort(item)}>
                {item}&nbsp;
                <SortIcon />
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={props.show_filters ? style.BodyWithFilters : style.BodyWithoutFilters}>
        {props.invoices.map((invoice, index) => {
          if (!invoice.is_affiliate) {
            return (
              <>
                <TableRow key={index} style={style.TableRow} className="hours-management-button">
                  {props.show_checkboxes ? (
                    <TableCell
                      style={
                        !invoice.affiliate_receiver || !invoice.affiliate_exists
                          ? style.TableCell3
                          : style.TableCell333
                      }
                      className="hours-management-button">
                      {invoice.status === 'READY' ? (
                        <Checkbox
                          checked={props.checkedValues.includes(invoice.docId)}
                          onChange={(e) => props.handleCheckbox(e, invoice)}
                          key={invoice.docId}
                          value="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      ) : (
                        <Checkbox
                          disabled
                          value="disabled"
                          inputProps={{ 'aria-label': 'disabled checkbox' }}
                        />
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell3
                        : style.TableCell333
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    <div
                      style={{
                        display: 'flex',
                        color: 'white',
                        backgroundColor: InvoiceState[invoice.status].color,
                        padding: '5px 10px',
                        borderRadius: '20px',
                        fontSize: '11px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {invoice.status}
                    </div>
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell
                        : style.TableCellNoBorder
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    {Moment(invoice.start_date).format('DD/MM/YYYY') === '01/01/0001' ? (
                      <span style={{ paddingLeft: '2.5em' }}>-</span>
                    ) : (
                      Moment(invoice.start_date).format('DD/MM/YYYY')
                    )}
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell
                        : style.TableCellNoBorder
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    {Moment(invoice.end_date).format('DD/MM/YYYY') === '01/01/0001' ? (
                      <span style={{ paddingLeft: '2.5em' }}>-</span>
                    ) : (
                      Moment(invoice.end_date).format('DD/MM/YYYY')
                    )}
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell
                        : style.TableCellNoBorder
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    {invoice.invoice_id === '' ? 'NOT ASSIGNED' : invoice.invoice_id}
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCellH1
                        : style.TableCellH12
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    {invoice.project_name}
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell
                        : style.TableCellNoBorder
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    {invoice.receiver}
                  </TableCell>
                  <TableCell
                    style={
                      !invoice.affiliate_receiver || !invoice.affiliate_exists
                        ? style.TableCell
                        : style.TableCellNoBorder
                    }
                    onClick={() => props.showInvoice(invoice.docId)}>
                    ${getTotalFromInvoiceDataUpdated(invoice)}
                  </TableCell>
                </TableRow>
                {invoice.affiliate_receiver
                  ? //let affiliate_invoices = invoices.filter(inv => inv.invoice_data_for_affliliate.base_docID === invoice.docId);
                    props.invoices
                      .filter((inv) => inv.invoice_data_for_affliliate.base_docID === invoice.docId)
                      .map((affiliate, index3) => {
                        return (
                          <TableRow
                            key={'affiliate' + index3}
                            style={style.TableRow}
                            className="hours-management-button">
                            {props.show_checkboxes ? (
                              <TableCell className="hours-management-button">
                                {affiliate.status === 'READY' ? (
                                  <Checkbox
                                    checked={props.checkedValues.includes(affiliate.docId)}
                                    onChange={(e) => props.handleCheckbox(e, affiliate)}
                                    key={affiliate.docId}
                                    value="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                ) : (
                                  <Checkbox
                                    disabled
                                    value="disabled"
                                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                                  />
                                )}
                              </TableCell>
                            ) : null}
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCell333
                                  : style.TableCell3
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              <div
                                style={{
                                  display: 'flex',
                                  color: 'white',
                                  backgroundColor: InvoiceState[affiliate.status].color,
                                  padding: '5px 10px',
                                  borderRadius: '20px',
                                  fontSize: '11px',
                                  fontWeight: 'bold',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                {affiliate.status}
                              </div>
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellNoBorder
                                  : style.TableCell
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              {Moment(affiliate.start_date).format('DD/MM/YYYY') ===
                              '01/01/0001' ? (
                                <span style={{ paddingLeft: '2.5em' }}>-</span>
                              ) : (
                                Moment(affiliate.start_date).format('DD/MM/YYYY')
                              )}
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellNoBorder
                                  : style.TableCell
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              {Moment(affiliate.end_date).format('DD/MM/YYYY') === '01/01/0001' ? (
                                <span style={{ paddingLeft: '2.5em' }}>-</span>
                              ) : (
                                Moment(affiliate.end_date).format('DD/MM/YYYY')
                              )}
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellNoBorder
                                  : style.TableCell
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              {affiliate.invoice_id === '' ? 'NOT ASSIGNED' : invoice.invoice_id}
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellH12
                                  : style.TableCellH1
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              {affiliate.project_name}
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellNoBorder
                                  : style.TableCell
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              {affiliate.receiver}
                            </TableCell>
                            <TableCell
                              style={
                                index3 + 1 !== invoice.affiliate_receiver.length
                                  ? style.TableCellNoBorder
                                  : style.TableCell
                              }
                              onClick={() => props.showInvoice(affiliate.docId)}>
                              ${getTotalFromInvoiceDataUpdated(affiliate)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </>
            );
          } else {
            return null;
          }
        })}
      </TableBody>
    </Table>
  </div>
);
