import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_RECEIVERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_RECEIVERS_SUCCESS:
      return {
        ...state,
        receivers: action.payload,
        loading: false,
        no_receivers_data: false,
      };
    case Types.GET_RECEIVERS_FAILURE:
      return {
        ...state,
        loading: false,
        no_receivers_data: true,
      };
    case Types.SAVE_RECEIVER_REQUEST:
      return {
        ...state,
      };
    case Types.SAVE_RECEIVER_SUCCESS:
      return {
        ...state,
      };
    case Types.SAVE_RECEIVER_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_RECEIVER_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_RECEIVER_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_RECEIVER_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
