import * as Types from '../../constants/actionconstants';
const initialState = {
  forcestop_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.START_LOADER:
      return {
        ...state,
        loading: action.loading,
        loader_background_color: action.loaderBackgroundColor,
      };
    case Types.STOP_LOADER:
      return {
        ...state,
        loading: action.loading,
      };
    case Types.FORCE_STOP_LOADER:
      return {
        ...state,
        forcestop_loading: action.forcestop_loading,
      };
    default:
      return state;
  }
};
