import React, { Component } from 'react';

import Payment from '../../components/Payments';
import './PaymentsPage.css';

class RoleManagementPage extends Component {
  render() {
    return (
      <div className="payments-page-main">
        <Payment />
      </div>
    );
  }
}

export default RoleManagementPage;
