import React, { Component } from 'react';

import './ReceiverPage.css';
import Receiver from '../../components/Receiver';

class ReceiverPage extends Component {
  render() {
    return (
      <div className="receiverpage-main">
        <Receiver />
      </div>
    );
  }
}

export default ReceiverPage;
