import React from 'react';

import { style } from './style';
import { formatFloat } from '../../../Selectors';

export const DashboardCards = (props) => (
  <div style={style.CardsRow}>
    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard1 }}
      onClick={props.handleShowBillableInvoices}>
      <p style={style.TotalInvoiceValue}>${formatFloat(props.total_invoice)}</p>
      <label style={style.TotalInvoiceLabel}>Approx. Total Invoice</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard2 }}
      onClick={props.handleShowInvoicedProjects}>
      <p style={style.TotalInvoiceValue}>{props.invoiced_projects}</p>
      <label style={style.TotalInvoiceLabel}>Projects Invoiced</label>
    </div>
    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard2 }}
      onClick={props.handleShowBillableProjects}>
      <p style={style.TotalInvoiceValue}>{props.billable_projects}</p>
      <label style={style.TotalInvoiceLabel}>Billable Projects</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard3 }}
      onClick={props.handleShowBillableResources}>
      <p style={style.TotalInvoiceValue}>{props.billable_resources}</p>
      <label style={style.TotalInvoiceLabel}>Billable Resources</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard4 }}
      onClick={props.handleShowUnBillableResources}>
      <p style={style.TotalInvoiceValue}>{props.unbillable_resources}</p>
      <label style={style.TotalInvoiceLabel}>UnBillable Resources</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard5 }}
      onClick={props.handleShowUnBillableProjects}>
      <p style={style.TotalInvoiceValue}>{props.unbillable_projects}</p>
      <label style={style.TotalInvoiceLabel}>UnBillable Projects</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard6 }}
      onClick={props.handleShowMissingHoursDefaulters}>
      <p style={style.TotalInvoiceValue}>{Math.round(props.missed_hours)}</p>
      <label style={style.TotalInvoiceLabel}>Hours Missing</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.AdminCard7 }}
      onClick={props.handleShowMissingRatesDefaulters}>
      <p style={style.TotalInvoiceValue}>{props.total_rate_defaulters}</p>
      <label style={style.TotalInvoiceLabel}>Rates Missing</label>
    </div>
  </div>
);
