import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_LEDGERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_LEDGERS_SUCCESS:
      return {
        ...state,
        ledgers: action.payload,
        loading: false,
        no_ledgers_data: false,
      };
    case Types.GET_LEDGERS_FAILURE:
      return {
        ...state,
        loading: false,
        no_ledgers_data: true,
      };
    default:
      return state;
  }
};
