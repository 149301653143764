import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_HOLIDAYS_REQUEST:
      return {
        ...state,
      };
    case Types.GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays: action.payload,
      };
    case Types.GET_HOLIDAYS_FAILURE:
      return {
        ...state,
        holidays: null,
      };
    case Types.ADD_HOLIDAYS_REQUEST:
      return {
        ...state,
      };
    case Types.ADD_HOLIDAYS_SUCCESS:
      return {
        ...state,
      };
    case Types.ADD_HOLIDAYS_FAILURE:
      return {
        ...state,
      };
    case Types.DELETE_HOLIDAY_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
      };
    case Types.DELETE_HOLIDAY_FAILURE:
      return {
        ...state,
      };

    case Types.UPDATE_HOLIDAY_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_HOLIDAY_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_HOLIDAY_FAILURE:
      return {
        ...state,
      };

    case Types.UPDATE_PMS_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_PMS_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_PMS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
