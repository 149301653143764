export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
    boxShadow: 'var(--tin-grey) 4px 1px 20px -5px',
  },
  ListViewContainer: {
    flex: 7,
  },
  SelectedProjectItem: {
    backgroundColor: 'var(--tin-orange)',
  },
  UnselectedProjectItem: {
    color: 'var(--tin-grey)',
  },
  SelectedProjectText: {
    color: 'white',
  },
  UnselectedProjectText: {
    color: 'var(--tin-grey)',
  },
  FilledButton: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  Container: {
    width: '100%',
    height: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  LabelValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    flex: 2,
    padding: '5px',
    fontSize: '16px',
    color: 'var(--tin-grey)',
  },
  Value: {
    flex: 3,
    fontSize: '14px',
    color: 'var(--tin-orange)',
    padding: '5px',
    margin: '0px',
    fontWeight: 'bold',
  },
  ProjectSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-orange)',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ButtonContainer: {
    padding: '20px',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 234px)',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCell: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '35px',
    paddingLeft: '35px',
  },
  TotalLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flex: 3,
    fontSize: '15px',
  },
  TotalContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '30px',
    paddingRight: '65px',
    alignItems: 'flex-end',
  },
};
