import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toolbar } from '@mui/material';
import Radium from 'radium';

import InvoiceReport from './InvoiceReport/index';
import ProjectReport from './HourLogging/index';
import ReportsList from './ReportsList';
import { style } from './style';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_view: 'hour_logging',
    };
  }

  setSelectedView = (event) => {
    this.setState({
      selected_view: event.target.value,
    });
  };

  renderReportType = () => {
    switch (this.state.selected_view) {
      case 'hour_logging':
        return <ProjectReport />;
      case 'invoice':
        return <InvoiceReport />;
    }
  };
  render() {
    const { range, no_active_period } = this.props;
    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div style={style.ToolBarRow}>
            <div className="breadcrumb-link">Reports</div>

            <div>
              {' '}
              {!no_active_period && range ? (
                <span style={style.ActivePeriodText}>
                  Active Period: {range.start_date} to {range.end_date}
                </span>
              ) : null}
            </div>
          </div>
        </Toolbar>
        <ReportsList />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    no_active_period: state.HoursManagementReducer.no_active_period,
    range: state.HoursManagementReducer.range,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Report));
