export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
    boxShadow: 'var(--tin-grey) 4px 1px 20px -5px',
  },
  ListViewContainer: {
    flex: 7,
  },
  SelectedProjectItem: {
    backgroundColor: 'var(--tin-orange)',
  },
  UnselectedProjectItem: {
    color: 'var(--tin-grey)',
    fontSize: '0.825rem',
  },
  SelectedProjectText: {
    color: 'white',
    fontSize: '0.825rem',
  },
  Select2: {
    backgroundColor: 'white',
    padding: '10px',
    paddingBotton: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    marginLeft: '20px',
    marginBottom: '-10px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-orange)',
    color: 'black',
    width: '90%',
    marginLeft: '-10px',
    outline: 'none',
    ':focus': {
      outline: 'none',
    },
  },
  UnselectedProjectText: {
    color: 'var(--tin-grey)',
  },
  FilledButton: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  Container: {
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    paddingBottom: '5px',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',
  },
  LabelValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    flex: 2,
    padding: '5px',
    fontSize: '16px',
    color: 'var(--tin-grey)',
  },
  Value: {
    flex: 3,
    fontSize: '14px',
    color: 'var(--tin-orange)',
    padding: '5px',
    margin: '0px',
    fontWeight: 'bold',
  },
  BorderedButtonClear: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  ButtonContainer2: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BorderedButton2: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  ProjectSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-orange)',
    color: 'var(--tin-grey)',
    width: '90%',
    ':focus': {
      outline: 'none',
    },
  },
  ProjectSelect2: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    color: 'var(--tin-orange)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ButtonContainer: {
    padding: '20px 0px 20px 0px',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  edit_button: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
};
