import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getProjectLoggers = (projid, date) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_RESOURCES_REQUEST,
      });

      API.request(
        'get',
        EndPoints.GetProjectLoggers +
          `?date=${date}&entry_type=bookings&entry_type=time_entry&projid=${projid}`,
      )
        .then((response) => {
          resolve(response.data);
          dispatch({
            payload: response.data,
            type: Types.GET_RESOURCES_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_RESOURCES_FAILURE,
          });
        });
    });
};

export const getResources = (query) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_RESOURCES_REQUEST,
      });
      const method = query ? 'get' : 'post';
      API.request(method, EndPoints.GetResources + (query ? query : ''), {
        teamdeck: false,
      })
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data,
            type: Types.GET_RESOURCES_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_RESOURCES_FAILURE,
          });
        });
    });
};

export const getRates = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_RATES_REQUEST,
      });

      API.request('get', EndPoints.GetRates)
        .then((response) => {
          resolve(response.data);
          dispatch({
            payload: response.data,
            type: Types.GET_RATES_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_RATES_FAILURE,
          });
        });
    });
};

export const getNewRates = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_NEW_RATES_REQUEST,
      });

      API.request('get', EndPoints.Rates)
        .then((response) => {
          resolve(response.data);
          dispatch({
            payload: response.data,
            type: Types.GET_NEW_RATES_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_NEW_RATES_FAILURE,
          });
        });
    });
};

export const setRate = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.SET_RESOURCE_RATE_REQUEST,
      });

      API.request('post', EndPoints.Rates, data)
        .then((response) => {
          resolve(true);
          dispatch({
            type: Types.SET_RESOURCE_RATE_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SET_RESOURCE_RATE_FAILURE,
          });
        });
    });
};

export const editRate = (data, docId) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.SET_RESOURCE_RATE_REQUEST,
      });

      API.request('put', EndPoints.Rates + '/' + `${docId}`, data)
        .then((response) => {
          resolve(true);
          dispatch({
            type: Types.SET_RESOURCE_RATE_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SET_RESOURCE_RATE_FAILURE,
          });
        });
    });
};

export const deleteRate = (docId) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.DELETE_RATE_REQUEST,
      });
      API.request('delete', EndPoints.Rates + '/' + `${docId}`)
        .then((response) => {
          resolve(response.data);
          if (response.data) {
            dispatch({
              type: Types.DELETE_RATE_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.DELETE_RATE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_RATE_FAILURE,
          });
        });
    });
};

export const updateResourceConfig = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.UPDATE_RESOURCE_CONFIG_REQUEST,
      });

      API.request('post', EndPoints.UpdateResourceConfig, data)
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data,
            type: Types.UPDATE_RESOURCE_CONFIG_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_RESOURCE_CONFIG_FAILURE,
          });
        });
    });
};

export const addResource = (resource) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_RESOURCE_REQUEST,
      });

      API.request('post', EndPoints.AddResource, resource)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.ADD_RESOURCE_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.ADD_RESOURCE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.ADD_RESOURCE_FAILURE,
          });
        });
    });
};
