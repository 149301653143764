import React from 'react';

import Radium from 'radium';

import { style } from './style';

export const MyComponent = (props) => (
  <div style={style.SearchFieldsContainer}>
    <div style={style.Row}>
      <div style={style.RowItems1}>
        <input
          key={1}
          type="text"
          value={props.selected_email}
          style={style.FilterSelect}
          placeholder="Email"
          onChange={props.handleEmailSelection}
        />
      </div>
      <div style={style.RowItems1}>
        <select
          key={2}
          name="Role"
          value={props.selected_role}
          style={style.FilterSelect}
          onChange={props.handleRoleSelection}>
          <option value="" disabled>
            All Roles
          </option>
          {props.roles.map((item, index) => {
            return <option key={index}>{item}</option>;
          })}
        </select>
      </div>
      <div style={style.RowItems1}>
        <button style={style.BorderedButton} onClick={props.handleClearFilters}>
          Clear
        </button>
      </div>
    </div>
  </div>
);

export const RolesSearchFields = Radium(MyComponent);
