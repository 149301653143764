import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const saveReceiver = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_RECEIVER_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.Receivers, data)
        .then((res) => {
          dispatch({
            type: Types.SAVE_RECEIVER_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SAVE_RECEIVER_FAILURE,
          });
        });
    });
  };
};

export const updateReceiver = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_RECEIVER_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('put', EndPoints.Receivers + id, data)
        .then((res) => {
          dispatch({
            type: Types.UPDATE_RECEIVER_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_RECEIVER_FAILURE,
          });
        });
    });
  };
};

export const getAllReceivers = () => {
  return (dispatch) => {
    dispatch({
      type: Types.GET_RECEIVERS_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('get', EndPoints.Receivers)
        .then((res) => {
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.GET_RECEIVERS_SUCCESS,
            });
            resolve(true);
          } else {
            dispatch({
              payload: null,
              type: Types.GET_RECEIVERS_FAILURE,
            });
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_RECEIVERS_FAILURE,
          });
        });
    });
  };
};

export const deleteReceiver = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_RECEIVER_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('delete', EndPoints.Receivers + id)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.DELETE_RECEIVER_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.DELETE_RECEIVER_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_RECEIVER_FAILURE,
          });
        });
    });
  };
};
