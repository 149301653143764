import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { Ability } from '../../constants/ability';
import { style } from './style';
import { withSnackbar } from 'notistack';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import Moment from 'moment';

import { DialogBox } from './DialogBox';
import { RolesSearchFields } from '../RolesManagement/RolesSearchFields';
import * as ReactGA from 'react-ga';

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_email: '',
      selected_role: '',
      show_create_popup: false,
      show_edit_popup: false,
      show_delete_popup: false,
      selected_role_docId: '',
      show_filters: false,
      filtered_role: '',
      filtered_email: '',
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadRoleData();
  }

  toggleCreatePopup() {
    this.setState({
      show_create_popup: !this.state.show_create_popup,
    });
  }

  toggleEditPopup(id, email, role) {
    this.setState({
      show_edit_popup: !this.state.show_edit_popup,
      selected_role_docId: id,
      selected_email: email,
      selected_role: role,
    });
  }

  toggleDeletePopup(id, email, role) {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
      selected_role_docId: id,
      selected_email: email,
      selected_role: role,
    });
  }

  handleEmailSelection = (e) => {
    this.setState({
      selected_email: e.target.value,
    });
  };

  handleRoleSelection = (e) => {
    this.setState({
      selected_role: e.target.value,
    });
  };

  handleEmailFilteredSelection = (e) => {
    this.setState({
      filtered_email: e.target.value,
    });
  };

  handleRoleFilteredSelection = (e) => {
    this.setState({
      filtered_role: e.target.value,
    });
  };

  loadRoleData = async () => {
    let { stopLoader, fetchRoleData, getResources } = this.props;

    let rolesListRequest = fetchRoleData();

    let resourcesRequest = getResources();

    await rolesListRequest;

    await resourcesRequest;

    await stopLoader();
  };

  removeRole = async () => {
    let { stopLoader, startLoader, deleteRole, fetchRoleData } = this.props;
    let { selected_role_docId, selected_email, selected_role } = this.state;

    await this.toggleDeletePopup(selected_role_docId, selected_email, selected_role);

    startLoader('#029E5F');

    ReactGA.event({
      category: 'Role Management',
      action: 'Deleted a Role',
    });

    await deleteRole(this.state.selected_role_docId);

    await fetchRoleData();

    await this.setState({
      selected_role: '',
      selected_email: '',
    });

    await stopLoader();
  };

  generateRole = async () => {
    let { stopLoader, startLoader, createRole, fetchRoleData } = this.props;
    let { selected_email, selected_role } = this.state;

    await this.toggleCreatePopup();

    startLoader('#029E5F');

    if (selected_email === '') {
      let emails = this.props.resources.map((resource) => resource.email);
      selected_email = emails[0];
    }

    if (selected_role === '') {
      let roles = Object.keys(Ability);
      selected_role = roles[0];
    }

    this.setState({
      selected_role: '',
      selected_email: '',
    });

    let data = {
      email: selected_email,
      role: selected_role,
    };

    ReactGA.event({
      category: 'Role Management',
      action: 'Added a Role',
    });

    await createRole(data);

    await fetchRoleData();

    await stopLoader();
  };

  changeRole = async () => {
    let { stopLoader, startLoader, editRole, fetchRoleData } = this.props;
    let { selected_role } = this.state;

    await this.toggleEditPopup(this.state.selected_role_docId, selected_role);

    startLoader('#029E5F');

    if (selected_role === '') {
      let roles = Object.keys(Ability);
      selected_role = roles[0];
    }

    let data = {
      role: selected_role,
    };

    ReactGA.event({
      category: 'Role Management',
      action: 'Edited a Role',
    });

    await editRole(this.state.selected_role_docId, data);

    await fetchRoleData();

    await this.setState({
      selected_role: '',
      selected_email: '',
    });

    await stopLoader();
  };

  handleClearFilters = () => {
    this.setState({
      filtered_role: '',
      filtered_email: '',
    });
  };

  handleChangeShowFilterStatus = (status) => {
    this.setState({
      show_filters: status,
    });
  };

  render() {
    const { loading, no_role_data, role_data, resources } = this.props;
    const {
      show_filters,
      selected_role,
      selected_email,
      filtered_email,
      filtered_role,
      show_create_popup,
      show_edit_popup,
      show_delete_popup,
    } = this.state;

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div className="breadcrumb-link" style={style.HeaderText}>
            Manage Roles
          </div>
          <button style={style.FilledButton} onClick={this.toggleCreatePopup.bind(this)}>
            + Create Role
          </button>
          {resources ? (
            <DialogBox
              handleEmailSelection={this.handleEmailSelection}
              handleRoleSelection={this.handleRoleSelection}
              toggleCreatePopup={this.toggleCreatePopup.bind(this)}
              toggleEditPopup={this.toggleEditPopup.bind(this)}
              toggleDeletePopup={this.toggleDeletePopup.bind(this)}
              roles={Object.keys(Ability)}
              emails={resources.map((resource) => resource.email).sort()}
              selected_role={selected_role}
              selected_email={selected_email}
              show_create_popup={show_create_popup}
              show_edit_popup={show_edit_popup}
              show_delete_popup={show_delete_popup}
              createRole={this.generateRole}
              editRole={this.changeRole}
              deleteRole={this.removeRole}
            />
          ) : null}
        </Toolbar>

        <div style={loading || no_role_data ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#C992B8" height={50} width={50} />
          ) : no_role_data ? (
            <EmptyContainer />
          ) : (
            <div style={style.TableContainer}>
              {show_filters ? (
                <>
                  <div style={style.ShowFiltersButtonContainer}>
                    <div
                      style={style.ShowFiltersButton}
                      onClick={() => this.handleChangeShowFilterStatus(false)}>
                      Hide Filters
                    </div>
                  </div>
                  <RolesSearchFields
                    roles={Object.keys(Ability)}
                    selected_role={filtered_role}
                    selected_email={filtered_email}
                    handleClearFilters={this.handleClearFilters}
                    handleEmailSelection={this.handleEmailFilteredSelection}
                    handleRoleSelection={this.handleRoleFilteredSelection}
                  />
                </>
              ) : (
                <div style={style.ShowFiltersButtonContainer}>
                  <div
                    style={style.ShowFiltersButton}
                    onClick={() => this.handleChangeShowFilterStatus(true)}>
                    Show Filters
                  </div>
                </div>
              )}
              <Table style={style.Table}>
                <TableHead style={style.Head}>
                  <TableRow style={style.TableRow}>
                    <TableCell style={style.TableCell1}>Email</TableCell>
                    <TableCell style={style.TableCell2}>Role</TableCell>
                    <TableCell style={style.TableCell3}>Updated At</TableCell>
                    <TableCell style={style.TableCell4}>Edit</TableCell>
                    <TableCell style={style.TableCell5}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={show_filters ? style.BodyWithFilters : style.BodyWithoutFilters}>
                  {role_data.map((user, index) => {
                    return ((filtered_email !== '' && user.email.includes(filtered_email)) ||
                      filtered_email === '') &&
                      ((filtered_role !== '' && filtered_role === user.role) ||
                        filtered_role === '') ? (
                      <TableRow style={style.TableRow} key={index}>
                        <TableCell style={style.TableCell1}>
                          <div
                            style={{
                              color: 'black',
                            }}>
                            {user.email}
                          </div>
                        </TableCell>

                        <TableCell style={style.TableCell2}>
                          <div style={{ color: 'black' }}>{user.role}</div>
                        </TableCell>

                        <TableCell style={style.TableCell3}>
                          {Moment(user.fsts).format('DD/MM/YYYY') === '01/01/0001' ? (
                            <span style={{ paddingLeft: '2.5em' }}>-</span>
                          ) : (
                            Moment(user.fsts).format('DD/MM/YYYY')
                          )}
                        </TableCell>

                        <TableCell style={style.TableCell4}>
                          <button
                            style={style.edit_button}
                            onClick={() => this.toggleEditPopup(user.docId, user.email, user.role)}>
                            <Edit />
                          </button>
                        </TableCell>

                        <TableCell style={style.TableCell5}>
                          <button
                            style={style.delete_button}
                            onClick={() =>
                              this.toggleDeletePopup(user.docId, user.email, user.role)
                            }>
                            <Delete />
                          </button>
                        </TableCell>
                      </TableRow>
                    ) : null;
                  })}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,
    no_role_data: state.RoleReducer.no_role_data,
    role_data: state.RoleReducer.role_data,
    resources: state.ResourceReducer.resources,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  fetchRoleData: () => dispatch(ActionsCreator.fetchRoleData()),
  getResources: () => dispatch(ActionsCreator.getResources()),
  createRole: (data, data1) => dispatch(ActionsCreator.createRole(data, data1)),
  deleteRole: (id) => dispatch(ActionsCreator.deleteRole(id)),
  editRole: (id, data) => dispatch(ActionsCreator.editRole(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Role));
