export const style = {
  edit_button: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCellMedium: {
    display: 'flex',
    flex: 1.5,
    alignItems: 'center',
  },
  TableCellLong: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  RatesContainer: {
    width: '94%',
    marginLeft: '30px',
    marginTop: '20px',
  },
  Head: {
    display: 'block',
    width: '100%',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: '40vh',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  BadgeRed: {
    color: 'white',
    backgroundColor: 'red',
    marginLeft: '10px',
    height: '15px',
    width: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7.5px',
  },
  EditModeActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px',
    marginLeft: '-30px',
  },
  ResourceEmail: { fontSize: '13px' },
  SetRateButton: {
    backgroundColor: 'white',
    height: 'auto',
    width: '100%',
    padding: '5px',
    fontSize: '30px',
  },
  SetRateButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
};
