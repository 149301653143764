import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getHolidays = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_HOLIDAYS_REQUEST,
      });

      API.request('get', EndPoints.GetHolidays)
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data ? response.data : [],
            type: Types.GET_HOLIDAYS_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_HOLIDAYS_FAILURE,
          });
        });
    });
};

export const addHolidays = (holidays) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_HOLIDAYS_REQUEST,
      });

      API.request('post', EndPoints.AddHolidays, holidays)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.ADD_HOLIDAYS_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.ADD_HOLIDAYS_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.ADD_HOLIDAYS_FAILURE,
          });
        });
    });
};

export const deleteHoliday = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_HOLIDAY_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('delete', EndPoints.DeleteHoliday + '/' + id)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.DELETE_HOLIDAY_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.DELETE_HOLIDAY_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_HOLIDAY_FAILURE,
          });
        });
    });
  };
};

export const editHoliday = (id, holidays) => {
  console.log(`id: ${id} holiday ${holidays}`);
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_HOLIDAY_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('put', EndPoints.UpdateHoliday + '/' + id, holidays)
        .then((res) => {
          if (res.data) {
            console.log('res: ', res.data);
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.UPDATE_HOLIDAY_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.UPDATE_HOLIDAY_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_HOLIDAY_FAILURE,
          });
        });
    });
  };
};
export const updatePMs = () => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_PMS_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('put', EndPoints.updatePMs)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.UPDATE_PMS_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.UPDATE_PMS_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_PMS_FAILURE,
          });
        });
    });
  };
};
