import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const fetchRoleData = () => {
  return (dispatch) => {
    dispatch({
      type: Types.FETCH_ROLE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('get', EndPoints.FetchRoleData)
        .then((res) => {
          dispatch({
            payload: res.data,
            type: Types.FETCH_ROLE_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.FETCH_ROLE_FAILURE,
          });
        });
    });
  };
};

export const createRole = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_ROLE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.Role, data)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.CREATE_ROLE_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.CREATE_ROLE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.CREATE_ROLE_FAILURE,
          });
        });
    });
  };
};

export const deleteRole = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_ROLE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('delete', EndPoints.Role + id)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.DELETE_ROLE_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.DELETE_ROLE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_ROLE_FAILURE,
          });
        });
    });
  };
};

export const editRole = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.EDIT_ROLE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('put', EndPoints.Role + id, data)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.EDIT_ROLE_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.EDIT_ROLE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.EDIT_ROLE_FAILURE,
          });
        });
    });
  };
};
