import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ActionsCreator } from '../../../Redux/Actions/index';

class HourLoggerDashboard extends Component {
  componentDidMount() {
    const { stopLoader } = this.props;

    stopLoader();
  }

  render() {
    const { name } = this.props;

    return (
      <div>
        Welcome {name}, please log your hours <a href="/main/hours-logging">here</a> and have a good
        day :)
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.LoginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HourLoggerDashboard);
