import React from 'react';

import { Input, Table, TableRow, TableBody, TableCell, TableHead, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { style } from '../StreamlineView/style';
import { getEmployeeData, formatFloat } from '../../../Selectors/index';

export const InvoiceDetailsTableView = (props) => {
  return (
    <>
      {!props.showInvoiceData.is_affiliate ? (
        <div style={{ display: 'flex', marginTop: '5px' }}>
          <Table style={style.Table}>
            <TableHead style={style.Head}>
              <TableRow style={style.TableRow}>
                <TableCell style={{ ...style.TableCell1, ...style.TextWrap }}>
                  <Typography style={{ flex: 3 }}></Typography>
                </TableCell>

                <TableCell
                  style={style.TableCell}
                  align="center"
                  colSpan={props.resource_details[0] ? props.resource_details[0].weeks.length : 1}>
                  Weeks
                </TableCell>
                <TableCell style={style.TableCell} />
                <TableCell style={style.TableCell} />
                {props.edit && props.show_reconciliation_data === true ? (
                  <>
                    <TableCell style={style.TableCell} align="center" colSpan={2}>
                      Reconciliation
                      <Delete style={{ color: 'red' }} onClick={props.handleDeleteReconciliation} />
                    </TableCell>
                  </>
                ) : null}
                <TableCell style={style.TableCell} />
                {props.edit && props.showInvoiceData ? (
                  <TableCell style={style.TableCell2} />
                ) : null}
              </TableRow>

              <TableRow style={style.TableRow}>
                <TableCell
                  style={{
                    ...style.TableCellInvoiceDetailLong,
                    ...style.TextWrap,
                  }}>
                  <p style={{ marginLeft: '10px' }}>Resource</p>
                </TableCell>
                {props.resource_details[0] &&
                  props.resource_details[0].weeks.map((week, index) => {
                    return (
                      <TableCell key={index} style={style.TableCellInvoiceDetailDefault}>
                        {week.week_duration.replace(' - ', '\n-\n')}
                      </TableCell>
                    );
                  })}
                <TableCell style={style.TableCellInvoiceDetailDefault}>{`Total\nHours`}</TableCell>
                <TableCell style={style.TableCellInvoiceDetailDefault}>{`Rate\nUSD`}</TableCell>
                {props.edit && props.show_reconciliation_data === true ? (
                  <>
                    <TableCell style={style.TableCellInvoiceDetailDefault}>
                      {`Previous\nCompleted\nHours`}
                    </TableCell>
                    <TableCell
                      style={style.TableCellInvoiceDetailDefault}>{`Reconciled\nHours`}</TableCell>
                  </>
                ) : null}
                <TableCell
                  style={style.TableCellInvoiceDetailDefault}>{`Invoice\nValue\n$`}</TableCell>
                {props.edit && props.showInvoiceData ? (
                  <TableCell style={style.TableCellInvoiceDetailDefault}>Actions</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody style={style.Body}>
              {props.resource_details[0].resource !== 'dummy_resource'
                ? props.resource_details.map((resource_detail, index) => {
                    const rateDisplayValue =
                      'rate_display_string' in resource_detail
                        ? resource_detail.rate_display_string
                        : resource_detail.rate;
                    let emplyeeData =
                      props.parsed_csv_data !== undefined &&
                      props.parsed_csv_data !== null &&
                      !props.editable
                        ? getEmployeeData(
                            resource_detail.resource,
                            props.parsed_csv_data,
                            parseInt(props.parsed_csv_data.upload.total_hours),
                          )
                        : null;
                    return (
                      <TableRow key={index} style={style.TableRow}>
                        <TableCell
                          style={{
                            ...style.TableCellInvoiceDetailLong,
                            ...style.TextWrap,
                            ...style.TableCellMargin,
                          }}>
                          {emplyeeData !== null ? (
                            <div style={{ flex: 1, minWidth: '20px' }}>
                              {emplyeeData.missed_hours !== 0 ? (
                                <span
                                  className={`hours-management-badge${
                                    emplyeeData.missed_hours > 0 ? '-red' : '-blue'
                                  }`}>
                                  !
                                </span>
                              ) : (
                                <span className="hours-management-badge" />
                              )}
                            </div>
                          ) : null}
                          {props.editable ? (
                            resource_detail.resource
                          ) : (
                            <Typography
                              style={style.TextWrap}
                              className="hours-management-button"
                              onClick={() =>
                                props.showDetailHourDistribution(resource_detail.resource)
                              }>
                              {resource_detail.resource}
                            </Typography>
                          )}
                        </TableCell>
                        {resource_detail.weeks.map((week, index2) => {
                          const totalWeekHoursDisplayValue =
                            'total_week_hours_display_string' in week
                              ? week.total_week_hours_display_string
                              : week.total_week_hours;
                          return (
                            <TableCell
                              key={index2}
                              style={{
                                ...style.TableCellInvoiceDetailDefault,
                                ...style.TableCellMargin,
                              }}>
                              {props.edit && props.showInvoiceData ? (
                                <Input
                                  style={style.Input}
                                  onChange={(event) =>
                                    props.handleChange(
                                      event,
                                      'hours',
                                      resource_detail.resource,
                                      index2,
                                    )
                                  }
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  value={totalWeekHoursDisplayValue}
                                  onWheel={(e) => e.target.blur()}
                                  onKeyDown={(e) =>
                                    (e.key === 'ArrowUp' || e.key === 'ArrowDown') &&
                                    e.preventDefault()
                                  }
                                />
                              ) : (
                                formatFloat(week.total_week_hours)
                              )}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          style={{
                            ...style.TableCellInvoiceDetailDefault,
                            ...style.TableCellMargin,
                          }}>
                          {props.edit === true &&
                          props.showInvoiceData &&
                          props.newInvoiceData !== undefined
                            ? formatFloat(props.newInvoiceData[index].total_hours)
                            : formatFloat(resource_detail.total_hours)}
                        </TableCell>
                        <TableCell
                          style={{
                            ...style.TableCellInvoiceDetailDefault,
                            ...style.TableCellMargin,
                          }}>
                          {props.edit && props.showInvoiceData ? (
                            <Input
                              style={style.Input}
                              onChange={(event) =>
                                props.handleChange(event, 'rate', resource_detail.resource, index)
                              }
                              type="number"
                              inputProps={{ min: 0 }}
                              value={rateDisplayValue}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.key === 'ArrowUp' || e.key === 'ArrowDown') && e.preventDefault()
                              }
                            />
                          ) : (
                            resource_detail.rate
                          )}
                        </TableCell>
                        {props.show_reconciliation_data === true ? (
                          <>
                            <TableCell
                              style={{
                                ...style.TableCellInvoiceDetailDefault,
                                ...style.TableCellMargin,
                              }}>
                              <Input
                                style={style.Input}
                                onChange={(event) =>
                                  props.handleActualHoursChange(event, resource_detail.resource)
                                }
                                type="number"
                                inputProps={{ min: 0 }}
                                value={props.getActualHour(
                                  resource_detail,
                                  props.newInvoiceData[index].total_hours,
                                )}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.key === 'ArrowUp' || e.key === 'ArrowDown') &&
                                  e.preventDefault()
                                }
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                ...style.TableCellInvoiceDetailDefault,
                                ...style.TableCellMargin,
                              }}>
                              <Input
                                style={style.Input}
                                onChange={(event) =>
                                  props.handleMissedHoursChange(event, resource_detail.resource)
                                }
                                type="number"
                                inputProps={{ min: -999999999 }}
                                value={props.getMissedHour(resource_detail)}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.key === 'ArrowUp' || e.key === 'ArrowDown') &&
                                  e.preventDefault()
                                }
                              />
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell
                          style={{
                            ...style.TableCellInvoiceDetailDefault,
                            ...style.TableCellMargin,
                          }}>
                          {props.edit === true &&
                          props.showInvoiceData &&
                          props.newInvoiceData !== undefined
                            ? formatFloat(
                                props.newInvoiceData[index].rate *
                                  props.newInvoiceData[index].total_hours,
                              )
                            : formatFloat(resource_detail.rate * resource_detail.total_hours)}
                        </TableCell>
                        {props.edit && props.showInvoiceData ? (
                          <TableCell
                            style={{
                              ...style.TableCellInvoiceDetailDefault,
                              ...style.TableCellMargin,
                            }}>
                            <Delete
                              style={{ color: 'red' }}
                              onClick={() => props.handleDeleteRow(index)}
                            />
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })
                : null}

              {/*props.resource_details[0].resource !== "dummy_resource" && props.auto_fill ? (

        props.auto_fill_resource_details.map((resource_detail, index) => {
          let emplyeeData =
            props.parsed_csv_data !== undefined && props.parsed_csv_data !== null && !props.editable
              ? getEmployeeData(
                  resource_detail.resource,
                  props.parsed_csv_data,
                  parseInt(props.parsed_csv_data.upload.total_hours)
                )
              : null;
          return (
            <TableRow key={index} style={style.TableRow}>
              <TableCell style={{ ...style.TableCellInvoiceDetailLong, ...style.TextWrap }}>
                {emplyeeData !== null ? (
                  <div style={{ flex: 1, minWidth: '20px' }}>
                    {emplyeeData.missed_hours !== 0 ? (
                      <span
                        className={`hours-management-badge${
                          emplyeeData.missed_hours > 0 ? '-red' : '-blue'
                        }`}
                      >
                        !
                      </span>
                    ) : (
                      <span className="hours-management-badge" />
                    )}
                  </div>
                ) : null}
                {props.editable ? (
                  <Typography style={{  }}>{resource_detail.resource}</Typography>
                ) : (
                  <Typography
                    style={{  }}
                    className="hours-management-button"
                    onClick={() => props.showDetailHourDistribution(resource_detail.resource)}
                  >
                    {resource_detail.resource}
                  </Typography>
                )}
              </TableCell>
              {resource_detail.weeks.map((week, index) => {
                return (
                  <TableCell key={index} style={style.TableCell}>
                    {props.edit && props.showInvoiceData ? (
                      <Input
                        style={style.Input}
                        onChange={event =>
                          props.handleChange(event, 'hours', resource_detail.resource, index)
                        }
                        type="number"
                        value={week.auto_fill_hours}
                      />
                    ) : (
                      formatFloat(week.auto_fill_hours)
                    )}
                  </TableCell>
                );
              })}
              <TableCell style={style.TableCell}>
                {props.edit === true && props.showInvoiceData && props.newInvoiceData !== undefined
                  ? formatFloat(props.newInvoiceData[index].total_hours)
                  : formatFloat(resource_detail.total_hours)}
              </TableCell>
              <TableCell style={style.TableCell}>
                {props.edit && props.showInvoiceData ? (
                  <Input
                  style={style.Input}
                    onChange={event =>
                      props.handleChange(event, 'rate', resource_detail.resource, index)
                    }
                    type="number"
                    value={resource_detail.rate}
                  />
                ) : (
                  resource_detail.rate
                )}
              </TableCell>
              {props.show_reconciliation_data === true ? (
                <>
                <TableCell style={style.TableCell}>
                  <Input
                    onChange={event =>
                      props.handleActualHoursChange(event, resource_detail.resource)
                    }
                    type="number"
                    value={props.getActualHour(resource_detail, props.newInvoiceData[index].total_hours)}
                  />                   
                </TableCell>
                <TableCell style={style.TableCell}>
                <Input
                    onChange={event =>
                      props.handleMissedHoursChange(event, resource_detail.resource)
                    }
                    type="number"
                    value= {props.getMissedHour(resource_detail)}  
                  />    
                                
                </TableCell>
                </>
              ) : null }
              <TableCell style={style.TableCell}>
                {props.edit === true && props.showInvoiceData && props.newInvoiceData !== undefined
                  ? formatFloat(
                      props.newInvoiceData[index].rate * props.newInvoiceData[index].total_hours
                    )
                  : formatFloat(resource_detail.rate * resource_detail.total_hours)}
              </TableCell>
              {props.edit && props.showInvoiceData ? (
                <TableCell style={style.TableCell2}>
                  <Delete style={{ color: 'red' }} onClick={() => props.handleDeleteRow(index)} />
                </TableCell>
              ) : null}
            </TableRow>
          );
          
        })
        ) : null */}
            </TableBody>
          </Table>
        </div>
      ) : null}
      {props.showInvoiceData.is_affiliate ? (
        <div style={{ display: 'flex', marginTop: '5px' }}>
          <Table style={style.Table}>
            <TableHead style={style.Head}>
              <TableRow style={style.TableRow}>
                <TableCell style={{ ...style.TableCell1, ...style.TextWrap }}>
                  <p>Resource</p>
                </TableCell>
                <TableCell style={style.TableCell}>Total Invoice Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={style.TableRow}>
                <TableCell style={{ ...style.TableCell1, ...style.TextWrap }}>
                  <p>Services</p>
                </TableCell>
                <TableCell style={style.TableCell}>
                  {props.showInvoiceData.invoice_data_for_affliliate.total_base_amount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : null}
      {!props.showInvoiceData.is_affiliate ? (
        <Table style={style.Table}>
          <TableHead style={style.Head}>
            <TableRow style={style.TableRow}>
              <TableCell style={{ ...style.TableCell1 }}>
                {!props.edit && props.showInvoiceData && props.showInvoiceData.events != null ? (
                  <p style={{ flex: 3 }}>
                    <button
                      style={styleButton.ActivityLogButton}
                      onClick={props.handleToggleActivityLogView}>
                      Activity Log{' '}
                      {props.show_activity_log ? (
                        <ExpandLessIcon></ExpandLessIcon>
                      ) : (
                        <ExpandMoreIcon></ExpandMoreIcon>
                      )}
                    </button>
                  </p>
                ) : (
                  <p style={{ flex: 3 }}></p>
                )}
              </TableCell>
              {props.totalWeeks &&
                props.totalWeeks.map((week, index) => {
                  return (
                    <TableCell
                      key={index}
                      style={{ display: 'flex', flex: 1, paddingRight: '5vh' }}
                    />
                  );
                })}
              <TableCell style={{ display: 'flex', flex: 1, paddingTop: '2.5vh' }} align="left">
                {props.total_hours ? (
                  <>
                    {' '}
                    Total hours <br />
                    {props.total_hours}{' '}
                  </>
                ) : null}
              </TableCell>
              <TableCell style={style.TableCell}></TableCell>
              {props.edit && props.show_reconciliation_data === true ? (
                <>
                  <TableCell style={style.TableCell}></TableCell>
                  <TableCell style={style.TableCell}></TableCell>
                </>
              ) : null}
              <TableCell
                style={{
                  display: 'flex',
                  flex: 1,
                  paddingTop: '2.5vh',
                  marginRight: 'auto',
                  paddingRight: '7vh',
                }}>
                {' '}
                Total Cost <br /> ${props.total_invoice}
              </TableCell>
              {props.edit && props.showInvoiceData ? (
                <TableCell style={style.TableCell2}></TableCell>
              ) : null}
            </TableRow>
          </TableHead>
        </Table>
      ) : null}
    </>
  );
};

const styleButton = {
  ActivityLogButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: 'var(--tin-blue)',
    border: 'white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
};
