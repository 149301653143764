export const style = {
  ProjectDetailInvoiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '20px',
  },
  TotalLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flex: 3,
    fontSize: '12px',
  },
  TotalContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
    alignItems: 'flex-end',
  },
  PlusContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
    alignItems: 'flex-start',
  },
  FooterRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '30px',
    height: '50px',
    alignItems: 'center',
  },
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-green)',
    color: 'white',
    height: '64px',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
  },
  FilledButton: {
    backgroundColor: 'var(--tin-green)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: '25vh',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
    whiteSpace: 'nowrap',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  DeleteBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  CancelBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ButtonContainer2: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Add_button: {
    color: '--tin-green',
    cursor: 'pointer',
    border: 'none',
  },
  Column: {
    display: 'flex',
    flexDirection: 'row',
  },
  ColumnItems1: {
    marginRight: '50px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ColumnItems2: {
    marginTop: '10px',
    marginRight: '50px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  RowItemsNote: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    borderTop: 'solid 1.5px #fff700',
    borderLeft: 'solid 1.5px #fff700',
    borderRight: 'solid 1.5px #fff700',
    borderBottom: 'solid 1.5px #fff700',
    backgroundColor: '#ffe476',
  },
  Warning: {
    fontSize: 30,
    padding: '10px',
    color: 'var(--tin-grey)',
  },
  BorderedButton2: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  RowItems1: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
  },
  ProjectSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-green)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ProjectSelect2: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ProjectSelect3: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    margin: '20px',
    color: 'var(--tin-orange)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  ProjectSelectNote: {
    padding: '10px',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  DateInput: {
    display: 'flex',
    width: '100%',
    margin: '20px',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-green)',
    color: 'var(--tin-grey)',
    ':focus': {
      outline: 'none',
    },
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  InvoicesCount: {
    display: 'flex',
    flex: 3,
    flexDirection: 'row',
    paddingLeft: '30px',
  },
  LabelValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    margin: '0px',
    fontSize: '12px',
  },
  RadioLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '18px',
    marginLeft: '20px',
  },
  RadioButton: {
    marginBottom: '5.5px',
    alignItems: 'center',
    marginRight: '8px',
    width: '20px',
    height: '20px',
    outline: 'none',
    boxShadow: 'none',
  },
  Value: {
    fontSize: '14px',
    margin: '0px',
    fontWeight: 'bold',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  BodyWithFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 254px)',
  },
  BodyWithoutFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 154px)',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 184px)',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCellNoBorder: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    borderBottom: 'none',
  },
  TableCell3: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCell333: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    borderBottom: 'none',
  },
  TableCell33: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    padding: '10px 20px 10pxpx 10px',
    minWidth: '20px',
  },
  TableCellH1: {
    display: 'flex',
    flex: 3,
    alignItems: 'center',
  },
  TableCellH12: {
    display: 'flex',
    flex: 3,
    alignItems: 'center',
    borderBottom: 'none',
  },
  TableContainer: {
    flex: 1,
    width: '100%',
    padding: '0px 40px',
  },
  SearchFieldsContainer: {
    height: '100px',
  },
  MainHeaderContainer: {
    height: '20px',
    display: 'flex',
    paddingTop: '10px',
    flexDirection: 'column',
  },
  ShowFiltersButtonContainer: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ShowCheckboxesContainer: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  ClickableText: {
    padding: '5px',
    marginTop: '10px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-grey)',
    textDecoration: 'underline',
  },

  ShowFiltersButton: {
    padding: '0px 5px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-grey)',
    textDecoration: 'underline',
  },

  ShowFiltersButtonActive: {
    padding: '0px 5px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-blue)',
    textDecoration: 'underline',
  },
  DetailPageContainer: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
  },
};
