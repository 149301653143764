import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { style } from '../StreamlineView/style';

export const ProjectResourceHoursDistribution = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCell}>Resource</TableCell>
        <TableCell style={style.TableCell}>Total Hours</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.resource_details.map((item, index) => {
        return (
          <TableRow
            key={index}
            style={style.TableRow}
            className="hours-management-clickable-tablerow"
            onClick={() => props.showDetailHourDistribution(item[0])}>
            <TableCell style={style.TableCell}>{item[0]}</TableCell>
            <TableCell style={style.TableCell}>{item[1]}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
