import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Bars } from 'react-loader-spinner';

import { DialogBoxes } from './DialogBoxes';
import { style } from '../StreamlineView/style';
import { Ability, hasPermission } from '../../../constants/ability';
import { DashboardCards } from './DashboardCards';
import { ProjectInvoices } from './ProjectInvoices';
import { InvoiceState } from '../../../constants/invoicestate';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { EmptyContainer } from '../../Utility/EmptyContainer';
import { InvoicesHistoryChart } from './InvoicesHistoryChart';
import { HoursDistributionChart } from './HoursDistributionChart';
import {
  getEmployeeData,
  getEmployeeRecords,
  getProjectOfResource,
  getTotalFromInvoiceData,
  getProjectInvoiceDetails,
  getResourceHoursDistribution,
  generateProjectManagerInvoicesData,
  generateDataforPMInvoiceHistoryChart,
} from '../../../Selectors/index';

class ProjectManagerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours_chart_data: [],
      loading: true,
      show_tentative_invoices: false,
      show_single_invoice: false,
      show_hours_distribution: false,
      show_resource_hours_distribution: false,
    };
  }

  componentDidMount() {
    this.loadDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_month !== this.props.selected_month) this.loadDashboardData();
  }

  async loadDashboardData() {
    const {
      new_rates,
      getNewRates,
      projects,
      unbillable,
      stopLoader,
      getProjects,
      getInvoices,
      getParsedData,
      getUnbillableProjects,
      selected_month,
    } = this.props;
    !new_rates && (await getNewRates());
    !projects && (await getProjects());
    !unbillable && (await getUnbillableProjects());
    await getInvoices();
    await getParsedData(selected_month);

    this.setState(
      {
        loading: false,
      },
      () => {
        stopLoader();
      },
    );
  }

  handleCreateProjectInvoice = () => {
    const { history } = this.props;
    let { project } = this.props;
    history.push({
      pathname: '/main/invoice',
      state: { props_project: project, props_show_create_invoice: true, invoice_type: 'Postpaid' },
    });
  };

  handleShowTentativeInvoices = () => {
    this.setState({
      show_tentative_invoices: true,
    });
  };

  handleHideTentativeInvoices = () => {
    this.setState({
      show_tentative_invoices: false,
    });
  };

  handleShowResourceHoursDistribution = (resource) => {
    this.setState({
      show_resource: resource,
      show_resource_hours_distribution: true,
    });
  };

  handleHideResourceHoursDistribution = () => {
    this.setState({
      show_resource: '',
      show_resource_hours_distribution: false,
    });
  };

  handleShowHoursDistribution = () => {
    this.setState({
      show_hours_distribution: true,
    });
  };

  handleHideHoursDistribution = () => {
    this.setState({
      show_hours_distribution: false,
    });
  };

  handleShowProjectInvoice = (project) => {
    let { new_rates, projects, parsed_csv_data, setInvoiceDetailData } = this.props;
    let details = getProjectInvoiceDetails(new_rates, parsed_csv_data, project, projects);
    console.log(details);

    if (details.resource_details[0] === undefined) return;

    setInvoiceDetailData({
      project: details.show_project,
      project_manager: details.project_manager,
      status: InvoiceState.DRAFT,
      resource_details: details.resource_details,
      total_invoice: details.total_invoice,
      parsed_csv_data: parsed_csv_data,
      total_hours: parseInt(parsed_csv_data.upload.total_hours),
      editable: false,
    }).then((data) => {
      if (data) {
        this.setState({
          show_single_invoice: true,
        });
      }
    });
  };

  handleShowHistoricalInvoice = (id) => {
    let { invoices, setInvoiceDetailData } = this.props;

    let invoice = invoices.filter((inv) => inv.docId === id)[0];

    setInvoiceDetailData({
      project: invoice.project_name,
      project_manager: invoice.project_manager,
      status: InvoiceState[invoice.status],
      resource_details: JSON.parse(invoice.project_invoice_data),
      total_invoice: getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data)),
      editable: false,
    }).then((data) => {
      if (data) {
        this.setState({
          show_single_invoice: true,
        });
      }
    });
  };

  handleHideSingleInvoice = () => {
    this.setState({
      show_single_invoice: false,
    });
  };

  render() {
    let { invoices, roles, project, parsed_csv_data, projects, new_rates, email, unbillable } =
      this.props;
    let {
      loading,
      show_hours_distribution,
      show_resource_hours_distribution,
      show_single_invoice,
      show_tentative_invoices,
      show_resource,
    } = this.state;
    let invoices_chart_data = {};
    let hours_chart_data = [];
    let filtered_projects = [];
    let resource_data = [];
    let resource_records = [];
    let invoices_data = null;
    let current_project_details = null;
    let total_invoiced = 0;

    //Ability[roles[0]].invoices.type === 'project' &&
    if (
      invoices &&
      roles.filter((role) => Ability[role].invoices.type === 'project').length &&
      hasPermission(roles, 'invoices', 'can_edit')
    ) {
      invoices = invoices.filter((invoice) => invoice.project_name === project);
      invoices_chart_data = generateDataforPMInvoiceHistoryChart(invoices);
    }

    if (project !== '' && parsed_csv_data) {
      hours_chart_data = getResourceHoursDistribution(project, parsed_csv_data);
    }

    if (projects && new_rates && parsed_csv_data) {
      filtered_projects = projects.filter((projectf) => !unbillable.includes(projectf.name));
      let resource_projects = getProjectOfResource(email, projects);
      filtered_projects = filtered_projects.filter((projectf) =>
        resource_projects.includes(projectf.name),
      );
      let filtered_projects_names = filtered_projects.map((item) => item.name);
      invoices_data = generateProjectManagerInvoicesData(
        filtered_projects_names,
        parsed_csv_data,
        new_rates,
        projects,
      );
      current_project_details = getProjectInvoiceDetails(
        new_rates,
        parsed_csv_data,
        project,
        projects,
      );
    }

    if (show_resource_hours_distribution) {
      resource_data = getEmployeeData(
        show_resource,
        parsed_csv_data,
        parseInt(parsed_csv_data.upload.total_hours),
      );
      resource_records = getEmployeeRecords(show_resource, parsed_csv_data.records);
    }

    if (invoices) {
      total_invoiced = invoices.reduce(
        (a, b) => a + getTotalFromInvoiceData(JSON.parse(b.project_invoice_data)),
        0,
      );
    }

    return (
      <div
        style={
          loading || (hours_chart_data.length <= 0 && invoices.length === 0)
            ? style.LoadingContainer
            : style.MainContainer
        }>
        {loading ? (
          <Bars color="#01b9fe" height={50} width={50} />
        ) : hours_chart_data.length <= 0 && invoices.length === 0 ? (
          <EmptyContainer />
        ) : (
          <div style={style.FlexCol}>
            <DashboardCards
              handleCreateProjectInvoice={this.handleCreateProjectInvoice}
              handleShowTentativeInvoices={this.handleShowTentativeInvoices}
              tentative_invoices_total={filtered_projects.length}
              handleShowDetailProjectInvoice={this.handleShowDetailProjectInvoice}
              total_project_invoice={
                current_project_details ? current_project_details.total_invoice : 0
              }
              total_invoiced={total_invoiced}
              handleShowProjectInvoice={this.handleShowProjectInvoice}
              project={project}
            />
            <div style={style.ChartRow}>
              <HoursDistributionChart
                hours_chart_data={hours_chart_data}
                handleShowHoursDistribution={this.handleShowHoursDistribution}
              />
              <ProjectInvoices
                invoices={invoices}
                handleShowHistoricalInvoice={this.handleShowHistoricalInvoice}
              />
            </div>
            <div style={style.ChartRow}>
              <InvoicesHistoryChart invoices_chart_data={invoices_chart_data} />
            </div>
            <DialogBoxes
              show_resource={show_resource}
              resource_data={resource_data}
              invoices_data={invoices_data}
              resource_records={resource_records}
              resource_details={hours_chart_data}
              show_single_invoice={show_single_invoice}
              show_tentative_invoices={show_tentative_invoices}
              show_hours_distribution={show_hours_distribution}
              handleHideSingleInvoice={this.handleHideSingleInvoice}
              handleShowProjectInvoice={this.handleShowProjectInvoice}
              handleHideHoursDistribution={this.handleHideHoursDistribution}
              handleHideTentativeInvoices={this.handleHideTentativeInvoices}
              show_resource_hours_distribution={show_resource_hours_distribution}
              handleShowResourceHoursDistribution={this.handleShowResourceHoursDistribution}
              handleHideResourceHoursDistribution={this.handleHideResourceHoursDistribution}
              total_hours={parsed_csv_data ? parseInt(parsed_csv_data.upload.total_hours) : 0}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.LoginReducer.name,
    email: state.LoginReducer.email,
    roles: state.LoginReducer.roles,
    new_rates: state.ResourceReducer.new_rates,
    invoices: state.InvoiceReducer.invoices,
    projects: state.ProjectReducer.projects,
    unbillable: state.ProjectReducer.unbillable,
    no_projects_data: state.ProjectReducer.no_data,
    no_rates_data: state.ResourceReducer.no_rates_data,
    no_parsed_data: state.HoursManagementReducer.no_parsed_data,
    parsed_csv_data: state.HoursManagementReducer.parsed_csv_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewRates: () => dispatch(ActionsCreator.getNewRates()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getInvoices: () => dispatch(ActionsCreator.getInvoices()),
  getParsedData: (data) => dispatch(ActionsCreator.getParsedData(data)),
  getUnbillableProjects: () => dispatch(ActionsCreator.getUnbillableProjects()),
  setInvoiceDetailData: (data) => dispatch(ActionsCreator.setInvoiceDetailData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectManagerDashboard));
