import React from 'react';

import Radium from 'radium';

import { style } from './style';

export const MyComponent = (props) => (
  <div style={style.SearchFieldsContainer}>
    <div style={style.Row}>
      <div style={style.RowItems1}>
        <select
          key={2}
          name="Project"
          value={props.selected_project}
          style={style.FilterSelect}
          onChange={props.handleProjectSelection}>
          <option value="" disabled>
            All Projects
          </option>
          {props.projects.map((project, index) => {
            if (project.active === true && project.billable === true)
              return (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              );
            else return null;
          })}
        </select>
      </div>
      <div style={style.RowItems1}>
        <button style={style.BorderedButton} onClick={props.handleClearFilters}>
          Clear
        </button>
      </div>
    </div>
  </div>
);

export const PaymentsSearchFields = Radium(MyComponent);
