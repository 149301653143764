import React, { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowUpwardRounded, ArrowDownwardRounded } from '@mui/icons-material';

import { style } from '../style';
import styles from './AllResourceView.module.css';

export const AllResourceView = (props) => {
  const [state, setState] = useState(null);
  const [sortingKey, setSortingKey] = useState({ name: 'name', isAscending: true });

  useEffect(() => {
    const { resources, hours_data, total_hours } = props;
    let data = [];

    resources &&
      resources.forEach((resource, index) => {
        if (resource.name in hours_data) {
          const { completed_hours = 0, Leave = 0 } = hours_data[resource.name]; // destructuring and initializing them to zero if not present
          const total_hours_worked = parseFloat(completed_hours);
          const overtime_hours =
            total_hours_worked > total_hours
              ? parseFloat((total_hours_worked - total_hours).toFixed(2))
              : 0;
          const missed_hours =
            total_hours_worked + Leave < total_hours
              ? parseFloat((total_hours - (total_hours_worked + Leave)).toFixed(2))
              : 0;
          data.push({
            name: resource.name,
            completed_hours: parseFloat(completed_hours.toFixed(2)),
            leave_hours: Leave,
            overtime_hours,
            missed_hours,
            active: resource.active,
          });
        } else {
          data.push({
            name: resource.name,
            completed_hours: 0,
            leave_hours: 0,
            overtime_hours: 0,
            missed_hours: total_hours,
            active: resource.active,
          });
        }
      });

    setState(data);
  }, [props.resources, props.total_hours]);

  useEffect(() => {
    if (state) {
      const data = [...state];
      if (sortingKey.isAscending) {
        // names of employees are string type, so they are sorted in different way
        sortingKey.name === 'name'
          ? data.sort((a, b) => ('' + a[sortingKey.name]).localeCompare(b[sortingKey.name])) // sorting of strings
          : data.sort((a, b) => a[sortingKey.name] - b[sortingKey.name]); // sorting of numbers

        setState(data);
      } else {
        // names of employees are string type, so they are sorted in different way
        sortingKey.name === 'name'
          ? data.sort((a, b) => ('' + b[sortingKey.name]).localeCompare(a[sortingKey.name])) // sorting of strings
          : data.sort((a, b) => b[sortingKey.name] - a[sortingKey.name]); // sorting of numbers

        setState(data);
      }
    }
  }, [sortingKey]);

  const toggleSort = (key) => {
    // replacing key "employees" with "name", our data supports key "name"
    key = key === 'employees' ? 'name' : key;
    // checking if key already exists or not
    if (sortingKey.name === key) {
      // toggling between ascending and descending order for the same key
      setSortingKey({ name: key, isAscending: !sortingKey.isAscending });
    } else {
      // different key than previous, sort the list in ascending order first
      setSortingKey({ name: key, isAscending: true });
    }
  };

  // get formated key for the column
  const getItem = (item) => {
    if (item === 'Employees') return 'name';
    return item.split(' ').join('_').toLowerCase();
  };

  const areIncompleteHours = ({ completed_hours, leave_hours }) => {
    if (completed_hours + leave_hours < props.total_hours) return true;

    return false;
  };

  const sortFunction = (list) => {
    // Sort projects based on activity status (active projects first)
    const result = list.sort((a, b) => {
      if (a.active && !b.active) {
        return -1; // a is active, b is inactive, a comes before b
      } else if (!a.active && b.active) {
        return 1; // a is inactive, b is active, b comes before a
      }
      return 0; // both projects have the same activity status, maintain the order
    });
    return result;
  };

  return (
    <div style={style.TableContainer}>
      <div style={style.TableContainer}>
        <Table style={style.Table}>
          <TableHead style={style.Head}>
            <TableRow style={style.TableRow}>
              {Object.keys(props.header).map((item, index) => {
                return (
                  <TableCell
                    key={index}
                    className="hours-management-button"
                    onClick={() => toggleSort(item.split(' ').join('_').toLowerCase())}
                    style={index === 0 ? style.TableCell1 : style.TableCell}>
                    {item}
                    {sortingKey.isAscending ? (
                      <ArrowUpwardRounded
                        className={
                          getItem(item) === sortingKey.name
                            ? styles.SortingArrowEnabled
                            : styles.SortingArrow
                        }
                      />
                    ) : (
                      <ArrowDownwardRounded
                        className={
                          getItem(item) === sortingKey.name
                            ? styles.SortingArrowEnabled
                            : styles.SortingArrow
                        }
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody style={style.Body}>
            {state &&
              sortFunction(state).map((resource, index) => {
                const itemColor = resource.active ? '#3E3630' : 'red';
                return (
                  <TableRow
                    key={index}
                    style={style.TableRow}
                    className="hours-management-button"
                    onClick={() => props.handleShowHoursDistribution(resource.name)}>
                    <TableCell style={{ ...style.EmployeeNameContainer, ...style.TableCell1 }}>
                      <span
                        className={
                          areIncompleteHours(resource)
                            ? `hours-management-badge-red`
                            : 'hide-content'
                        }>
                        !
                      </span>
                      <div style={{ flex: 3, color: itemColor }}>{resource.name}</div>
                    </TableCell>
                    <TableCell style={style.TableCell}>{resource.completed_hours}</TableCell>
                    <TableCell style={style.TableCell}>{resource.leave_hours}</TableCell>
                    <TableCell style={style.TableCell} className="">
                      {resource.overtime_hours}
                    </TableCell>
                    <TableCell
                      style={style.TableCell}
                      className={resource.missed_hours >= 40 ? 'hours-management-red' : ''}>
                      {resource.missed_hours}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
