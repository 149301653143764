import React, { Component } from 'react';
import { connect } from 'react-redux';

import Moment from 'moment';
import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';
import { Dialog } from '@mui/material';

import { style } from './style';
import HoursDetailListView from './HoursDetailListview';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { HourManagementHeader } from './HourManagementHeader';

class HoursManagementCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_confirm_lock_data: false,
      total_hours: undefined,
      new_last_locked: Moment().format('YYYY-MM-DD'),
      fetched_entries: false,
    };
  }

  static defaultProps = {
    loading: true,
  };

  async componentDidMount() {
    this.loadHoursManagementData();
    await this.props.getHolidays();
  }

  componentDidUpdate() {
    const { holidays, last_locked } = this.props;
    const { total_hours, new_last_locked } = this.state;
    if (holidays && holidays.length && last_locked && isNaN(total_hours)) {
      let next_day_last_locked = Moment(last_locked).clone().add(1, 'days').format('YYYY-MM-DD');
      const workingHours = this.getTotalWorkingHours(
        next_day_last_locked,
        new_last_locked,
        holidays,
      );
      this.setState({ total_hours: workingHours });
    }
    this.fetchEntries();
  }

  getTotalWorkingHours = (start_date, end_date, holidays) => {
    var startDate = Moment(start_date);
    var endDate = Moment(end_date);
    let weekendDates = []; // storing weekend dates
    let daysCount = 0; // storing number of week days
    const holidaysInRange = holidays.filter(
      (holiday) =>
        Moment(holiday.start_date).isBetween(startDate, endDate) ||
        Moment(holiday.start_date).isSame(startDate) ||
        Moment(holiday.start_date).isSame(endDate),
    ); //filter out holidays in the given range
    // const checkHolidayOnSelectedDate = holidays.filter(holiday=> Moment(holiday.start_date).isSame(endDate)); //checking if any holiday is on the same date as the selected one so we skip the calculations
    for (var date = Moment(startDate); date.diff(endDate, 'days') <= 0; date.add(1, 'days')) {
      const day = date.day();
      if (day === 6 || day === 0) {
        weekendDates.push(date.format('YYYY-MM-DD'));
        continue;
      }
      daysCount++;
    }
    // filtering holidays that came on week days
    const holidaysNotOnWeekend = holidaysInRange.filter(
      (holiday) => !weekendDates.includes(holiday.start_date),
    );
    // getting number of hours of those holidays
    const holidaysHours = holidaysNotOnWeekend.length * 8;
    if (daysCount > 0) {
      // subtracting those holidays from total week day hours
      const workingHours = daysCount * 8 - holidaysHours;
      return workingHours;
    }
    return 0;
  };

  fetchEntries = () => {
    const { fetched_entries, new_last_locked } = this.state;
    const { getEntriesHours, last_locked, stopLoader, enqueueSnackbar } = this.props;

    if (last_locked && !fetched_entries) {
      let next_day_last_locked = Moment(last_locked).clone().add(1, 'days').format('YYYY-MM-DD');
      return getEntriesHours(next_day_last_locked, new_last_locked).then((res) => {
        if (res) {
          this.setState({
            fetched_entries: true,
          });
          stopLoader();
        } else {
          enqueueSnackbar('Unable to fetch entries!', { variant: 'error' });
          stopLoader();
        }
      });
    }
  };

  loadHoursManagementData = async () => {
    const { stopLoader, enqueueSnackbar, getLastLocked, getResources, resources, last_locked } =
      this.props;
    await getResources();
    !last_locked &&
      (await getLastLocked().then((res) => {
        if (!res) {
          enqueueSnackbar('Unable to fetch last locked date!', { variant: 'error' });
          stopLoader();
        }
      }));
    if (last_locked) {
      stopLoader();
    }
  };

  handleLockData = () => {
    const { new_last_locked } = this.state;
    const { email, startLoader, stopLoader, enqueueSnackbar, setLastLocked, resources } =
      this.props;

    if (!new_last_locked) {
      enqueueSnackbar('No last locked date set!', { variant: 'error' });
      return;
    }

    let user = null;
    if (resources && resources.length > 0) {
      user = resources.filter((resource) => {
        if (resource.email === email) {
          return resource;
        }
        return null;
      })[0];
    }

    startLoader('#189477')
      .then((response) => {
        if (response) {
          this.handleHideLockDataConfirmPrompt();
          return setLastLocked({
            locked_at: new_last_locked,
            locked_by_id: user.id,
          });
        }
      })
      .then((res) => {
        if (res) {
          enqueueSnackbar('Data Locked!', { variant: 'success' });
          this.loadNewData();
        } else {
          stopLoader();
          enqueueSnackbar('Unable to Lock Data, contact Admin!', { variant: 'error' });
        }
      });
  };

  loadNewData = () => {
    const { new_last_locked } = this.state;
    const { startLoader, getEntriesHours, getLastLocked, stopLoader } = this.props;

    let enddate = Moment(new_last_locked).clone().add(1, 'days').format('YYYY-MM-DD');

    startLoader('#189477').then((response) => {
      getLastLocked().then((response) => {
        getEntriesHours(new_last_locked, enddate);
        stopLoader();
      });
    });
  };

  handleShowLockDataConfirmPrompt = () => {
    this.setState({
      show_confirm_lock_data: true,
    });
  };

  handleHideLockDataConfirmPrompt = () => {
    this.setState({
      show_confirm_lock_data: false,
    });
  };

  handleChangeTotalHours = (e) => {
    const val = parseInt(e.target.value);
    if (isNaN(val) || val < 0) {
      this.props.enqueueSnackbar('Invalid value for hours', { variant: 'error' });
      this.setState({
        total_hours: '',
      });
    } else {
      this.setState({
        total_hours: val,
      });
    }
  };

  handleChangeLastLocked = (e) => {
    const { enqueueSnackbar, last_locked, getEntriesHours, startLoader, stopLoader } = this.props;

    const convertDate = { month: e.month - 1, day: e.day, year: e.year };
    (async () => {
      await this.setState({
        new_last_locked: Moment(convertDate).format('YYYY-MM-DD'),
        total_hours: undefined,
      });

      if (convertDate) {
        startLoader('#189477').then((response) => {
          let next_day_last_locked = Moment(last_locked)
            .clone()
            .add(1, 'days')
            .format('YYYY-MM-DD');
          if (response) {
            return getEntriesHours(
              next_day_last_locked,
              Moment(convertDate).format('YYYY-MM-DD'),
            ).then((res) => {
              if (res) {
                enqueueSnackbar('Fetched Entries!', { variant: 'success' });
                stopLoader();
              } else {
                enqueueSnackbar('Unable to fetch Entries', { variant: 'error' });
                stopLoader();
              }
            });
          }
        });
      } else {
        enqueueSnackbar('Cannot fetch entries as filter date is empty', { variant: 'error' });
      }
    })();
  };

  render() {
    const { loading, time_entries_hours, resources, last_locked } = this.props;
    const { new_last_locked, show_confirm_lock_data, total_hours } = this.state;
    const minimumDate = Moment(last_locked).clone().add(1, 'days');
    const formattedMinimumDate = {
      year: minimumDate.year(),
      month: minimumDate.month() + 1,
      day: minimumDate.date(),
    };
    let active_period_dates;
    let total_worked_hours = 0,
      total_completed_hours = 0,
      total_leave_hours = 0,
      total_missed_hours = 0,
      total_overtime_hours = 0;

    if (time_entries_hours) {
      for (var entry in time_entries_hours) {
        // get completed hours for resource and calculate total hours
        let completed_hours = time_entries_hours[entry].completed_hours
          ? time_entries_hours[entry].completed_hours
          : 0;
        total_completed_hours += completed_hours;
        total_overtime_hours += completed_hours > total_hours ? completed_hours - total_hours : 0;
        total_missed_hours += completed_hours < total_hours ? total_hours - completed_hours : 0;

        // get leave hours as it's added by a Project name
        let leave_hours = time_entries_hours[entry].Leave ? time_entries_hours[entry].Leave : 0;
        if (leave_hours) {
          total_leave_hours += leave_hours;
        }
        total_worked_hours = total_completed_hours + total_leave_hours;
      }
    }

    // add missed hours if resource added no hours
    if (resources && time_entries_hours) {
      for (let index in resources) {
        let resource = resources[index];
        if (!(resource.name in time_entries_hours)) {
          total_missed_hours += total_hours;
        }
      }
    }

    return (
      <div style={style.ComponentMain}>
        <HourManagementHeader
          total_hours={total_hours}
          total_worked_hours={total_worked_hours}
          total_leave_hours={total_leave_hours}
          total_missed_hours={total_missed_hours}
          active_period_dates={active_period_dates}
          total_overtime_hours={total_overtime_hours}
          total_completed_hours={total_completed_hours}
          start_date={last_locked}
          end_date={new_last_locked}
          handleShowLockDataConfirmPrompt={this.handleShowLockDataConfirmPrompt}
          handleChangeTotalHours={this.handleChangeTotalHours}
          handleChangeLastLocked={this.handleChangeLastLocked}
          formattedMinimumDate={formattedMinimumDate}
        />
        <div style={loading || !time_entries_hours ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#189477" height={50} width={50} />
          ) : !time_entries_hours ? (
            <EmptyContainer />
          ) : (
            <HoursDetailListView
              total_hours={total_hours}
              total_worked_hours={total_worked_hours}
              resources={resources}
            />
          )}
        </div>
        <Dialog
          open={show_confirm_lock_data}
          onClose={this.handleHideLockDataConfirmPrompt}
          fullWidth={true}
          maxWidth="sm">
          <div style={style.HeadingRow}>
            <h2 style={style.Heading}>Confirmation</h2>
            <p style={style.Cross} onClick={this.handleHideLockDataConfirmPrompt}>
              X
            </p>
          </div>
          <div style={style.PromptText}>
            This action is irreversible. Are you sure you want to lock data?
          </div>
          <div style={style.ButtonContainer}>
            <button style={style.BorderedButton} onClick={this.handleLockData}>
              Confirm
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,

    roles: state.LoginReducer.roles,
    email: state.LoginReducer.email,

    error_message: state.HoursManagementReducer.error_message,
    time_entries_hours: state.HoursManagementReducer.time_entries_hours,
    resources: state.ResourceReducer.resources,
    last_locked: state.HoursManagementReducer.last_locked,

    holidays: state.SettingReducer.holidays,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),

  getEntriesHours: (start, end) => dispatch(ActionsCreator.getEntriesHours(start, end)),
  getResources: () => dispatch(ActionsCreator.getResources()),

  getLastLocked: () => dispatch(ActionsCreator.getLastLocked()),
  setLastLocked: (data) => dispatch(ActionsCreator.setLastLocked(data)),

  getHolidays: () => dispatch(ActionsCreator.getHolidays()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(HoursManagementCustom));
