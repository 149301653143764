import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getTeams = (query) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_TEAMS_REQUEST,
      });
      const queryString = query ? query : '';
      API.request('get', EndPoints.GetTeams + queryString)
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data ? response.data : [],
            type: Types.GET_TEAMS_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_TEAMS_FAILURE,
          });
        });
    });
};

export const addTeam = (team) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_TEAM_REQUEST,
      });

      API.request('post', EndPoints.AddTeams, team)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.ADD_TEAM_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.ADD_TEAM_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.ADD_TEAM_FAILURE,
          });
        });
    });
};

export const updateTeam = (team, id) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.UPDATE_TEAM_REQUEST,
      });

      API.request('put', EndPoints.UpdateTeams + '/' + id, team)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.UPDATE_TEAM_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.UPDATE_TEAM_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_TEAM_FAILURE,
          });
        });
    });
};
