import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const fetchReportTimeEntries = (start, end, format) => {
  return (dispatch) => {
    dispatch({
      type: Types.FETCH_REPORTENTRIES_REQUEST,
    });
    return new Promise((resolve) => {
      let params = 'start=' + start + '&end=' + end + '&format=' + format;
      API.request('get', EndPoints.FetchReportTimeEntries + '?' + params)
        .then((res) => {
          dispatch({
            payload: res.data,
            type: Types.FETCH_REPORTENTRIES_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.FETCH_REPORTENTRIES_FAILURE,
          });
        });
    });
  };
};
