export const style = {
  ReportsListContainer: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
  },
  ReportsListBox: {
    width: '95%',
    height: '95%',
    backgroundColor: 'white',
    boxShadow: '1px 1px 20px grey',
    borderRadius: '10px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '20px',
    paddingLeft: '20px',
  },
  ReportsListItem: {
    backgroundColor: 'var(--tin-pink)',
    color: 'white',
    margin: 30,
    width: '350px',
    height: '100px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#df9fb7',
      transform: 'scale(1.02)',
    },
  },
  ItemIcon: {
    marginRight: '10px',
    width: '30%',
  },
  HeaderTextWithBackIcon: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    alignItems: 'center',
    paddingLeft: 20,
    cursor: 'pointer',
  },
  FilterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '3%',
    marginTop: 15,
  },
  DialogDateInput: {
    padding: '10px 10px 10px 0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    color: 'var(--tin-grey)',
  },
  TimeContainerText: {
    margin: '5px 15px',
    padding: '5px 0px',
    fontSize: '16px',
    fontWeight: 'bold',
    height: '100%',
    verticalAlign: '-webkit-baseline-middle',
  },
  TimeContainer: {
    position: 'relative',
    display: 'flex',
    margin: '13.4px',
    marginLeft: 'auto',
    marginRight: '50px',
    padding: 20,
    verticalAlign: 'bottom',
    width: 'auto',
  },
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-pink)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
    cursor: 'pointer',
    margin: '0px 20px',
  },
  TableContainer: {
    flex: 1,
    margin: '20px',
    boxShadow: '-1px 2px 15px -5px black',
    backgroundColor: 'white',
    borderRadius: '10px',
    minHeight: '45vh',
  },
  FlexCol: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'var(--tin-lightgrey)',
  },
  Headings: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
  },
  CardHeadingText: {
    flex: 3,
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    cursor: 'pointer',
  },
  ProjectsSelection: {
    flex: 1,
    display: 'flex',
    padding: '5px',
  },
  ProjectSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-pink)',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  CentralizedRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColumnChart: {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      fullWidth: false,
      labels: {
        boxWidth: 20,
        fontSize: 11,
        padding: 10,
        usePointStyle: false,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          maxBarThickness: 40,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          stacked: false,
        },
      ],
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
  },
  PieChart: {
    legend: {
      display: true,
      position: 'right',
      fullWidth: false,
      labels: {
        boxWidth: 20,
        fontSize: 11,
        padding: 5,
        usePointStyle: true,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
  },
  ChartContainer: {
    padding: '20px',
  },
  TimelineChart: {
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
  },
  HourContainer: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
  },
  HourLoggingTable: {
    width: '95%',
    height: '95%',
    backgroundColor: 'white',
    boxShadow: '1px 1px 20px grey',
    borderRadius: '10px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  TableRow: {
    width: '100%',
    padding: '0px',
    display: 'flex',
    border: '.05px solid rgb(0,0,0,.1)',
  },
  RowHeader: {
    backgroundColor: 'rgb(0,0,0,.1)',
    fontWeight: 'bold',
  },
  RowBlock: {
    width: '30%',
    padding: '20px',
    borderLeft: '0.5px solid rgb(0,0,0,.1',
    display: 'flex',
  },
  TableHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    padding: '20px',
    verticalAlign: 'top',
  },
  RowIcon: {
    marginBottom: '-5px',
    paddingRight: '10px',
  },
  nameRowIcon: {
    margin: '0px 10px 0px 32px',
    padding: '3px',
    color: 'var(--tin-pink)',
    border: '1px solid var(--tin-pink)',
    borderRadius: '50px',
    fontSize: '10px',
  },
  ToolBarRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  Indicator: {
    padding: '0px',
    marginTop: '-10px',
    border: '2px solid white',
    width: '20px',
    position: 'absolute',
  },
  DateStyle: {
    textAlign: 'center',
    borderColor: 'rgba(0,0,0,0.1)',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    marginTop: '5px',
    outline: 'none',
    width: '210px',
  },
};
