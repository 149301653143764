import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { style } from './style';

export const ResourcesList = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCellP}>Resources</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.resources.map((item, index) => {
        return (
          <TableRow key={index} style={style.TableRow}>
            <TableCell style={style.TableCellP}>{item}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
