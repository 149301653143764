import React from 'react';

import { style } from '../StreamlineView/style';
import { formatFloat } from '../../../Selectors';

export const DashboardCards = (props) => (
  <div style={style.CardsRow}>
    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.DashboardCard2 }}
      onClick={props.handleCreateProjectInvoice}>
      <p style={style.TotalInvoiceValue}>+</p>
      <label style={style.TotalInvoiceLabel}>Create Invoice</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.DashboardCard3 }}
      onClick={props.handleShowTentativeInvoices}>
      <p style={style.TotalInvoiceValue}>{props.tentative_invoices_total}</p>
      <label style={style.TotalInvoiceLabel}>Tentative Invoices</label>
    </div>

    <div
      className="hours-management-button"
      style={{ ...style.Cards, ...style.DashboardCard1 }}
      onClick={() => props.handleShowProjectInvoice(props.project)}>
      <p style={style.TotalInvoiceValue}>${formatFloat(props.total_project_invoice)}</p>
      <label style={style.TotalInvoiceLabel}>Approx. Total Invoice</label>
    </div>
    <div style={{ ...style.Cards, ...style.DashboardCard4 }}>
      <p style={style.TotalInvoiceValue}>${formatFloat(props.total_invoiced)}</p>
      <label style={style.TotalInvoiceLabel}>Total Invoiced</label>
    </div>
    <div style={{ flex: 1 }} />
  </div>
);
