import React, { Component } from 'react';

import './DashboardPage.css';
import Dashboard from '../../components/Dashboard';

class DashboardPage extends Component {
  render() {
    return (
      <main className="dashboard-main">
        <Dashboard />
      </main>
    );
  }
}

export default DashboardPage;
