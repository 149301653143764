import React from 'react';

import { Table, Button, TableRow, TableBody, TableCell, TableHead } from '@mui/material';

import { style } from '../style';

export default class ResourceView extends React.PureComponent {
  render() {
    let { employee, total_hours, hours_data, sendEmail } = this.props;

    let completed_hours = employee in hours_data ? hours_data[employee].completed_hours : 0;
    let leave_hours = employee in hours_data ? hours_data[employee].Leave : 0;
    let overtime_hours =
      employee in hours_data
        ? completed_hours > total_hours
          ? completed_hours - total_hours
          : 0
        : 0;
    let missed_hours =
      employee in hours_data
        ? completed_hours + leave_hours < total_hours
          ? total_hours - (completed_hours + leave_hours)
          : 0
        : total_hours;

    return (
      <div style={style.HoursDistributionContainer}>
        <div style={style.Rows}>
          <div style={style.LabelRows1}>
            <label className="hours-management-employee-detail-label">Name:</label>
            <p className="hours-management-employee-detail-value">{employee}</p>
          </div>
          <div style={style.LabelRows1}>
            <label className="hours-management-employee-detail-label">Status:</label>
            <p className="hours-management-employee-detail-value">Active</p>
          </div>
          {missed_hours > 0 ? (
            <div style={style.GenerateLabel}>
              <label className="hours-management-employee-detail-label">Warning Email:</label>
              <div className="hours-management-generate-email">
                <Button
                  className="hours-management-upload-button"
                  onClick={() => {
                    if (missed_hours > 0) {
                      let data = JSON.stringify([
                        {
                          name: employee,
                          thours: completed_hours,
                          vhours: leave_hours,
                          mhours: parseInt(total_hours),
                        },
                      ]);
                      sendEmail(data);
                    }
                  }}>
                  Generate
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div style={style.Rows}>
          <div style={style.LabelRows1}>
            <label className="hours-management-employee-detail-label">Total Hours:</label>
            <p className="hours-management-employee-detail-value">{total_hours}</p>
          </div>
          <div style={style.LabelRows1}>
            <label
              className={`hours-management-employee-detail-label
              ${completed_hours > 0 ? ' hours-management-green' : ''}`}>
              Completed Hours:
            </label>
            <p className="hours-management-employee-detail-value">{completed_hours}</p>
          </div>
          <div style={style.LabelRows1}>
            <label
              className={`hours-management-employee-detail-label 
              ${missed_hours > 0 ? ' hours-management-red' : ''} 
              ${missed_hours < 0 ? ' hours-management-blue' : ''}`}>
              Missed Hours:
            </label>
            <p className="hours-management-employee-detail-value">{missed_hours}</p>
          </div>
          <div style={style.LabelRows1}>
            <label className="hours-management-employee-detail-label">Overtime Hours:</label>
            <p className="hours-management-employee-detail-value">{overtime_hours}</p>
          </div>
          <div style={style.LabelRows1}>
            <label className="hours-management-employee-detail-label">Leave Hours:</label>
            <p className="hours-management-employee-detail-value">{leave_hours}</p>
          </div>
        </div>

        <Table style={style.Table}>
          <TableHead style={style.Head}>
            <TableRow style={style.TableRow}>
              <TableCell style={style.TableCell}>Project Invoiced on</TableCell>
              <TableCell style={style.TableCell}>Timesheet Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={style.Body1}>
            {employee in hours_data &&
              Object.entries(hours_data[employee]).map(([pname, hours]) => {
                if (pname !== 'completed_hours' && pname !== 'Leave') {
                  return (
                    <TableRow key={pname} style={style.TableRow}>
                      <TableCell style={style.TableCell}>{pname}</TableCell>
                      <TableCell style={style.TableCell}>{hours}</TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </div>
    );
  }
}
