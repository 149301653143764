import * as Types from '../../constants/actionconstants';
import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getWeekBookings = (resid, projid, date) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_BOOKING_REQUEST,
      });

      let bookingUrl = EndPoints.BookingsBase + '?';
      if (resid !== -1 && projid !== -1) {
        bookingUrl = bookingUrl + 'resid=' + resid + '&projid=' + projid;
      } else if (resid !== -1) {
        bookingUrl = bookingUrl + 'resid=' + resid;
      } else if (projid !== -1) {
        bookingUrl = bookingUrl + '&projid=' + projid;
      }
      bookingUrl += '&date=' + date;

      API.request('get', bookingUrl)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: { id: resid, data: res.data },
              type: Types.GET_BOOKING_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.GET_BOOKING_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_BOOKING_FAILURE,
          });
        });
    });
};

export const addCustomBooking = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_BOOKING_REQUEST,
      });

      API.request('post', EndPoints.BookingsBase, data)
        .then((res) => {
          resolve(res.data, EndPoints.BookingsBase);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.ADD_BOOKING_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.ADD_BOOKING_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.ADD_BOOKING_FAILURE,
          });
        });
    });
};

export const editCustomBooking = (id, data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.UPDATE_BOOKING_REQUEST,
      });

      const updateUrl = EndPoints.BookingsBase + '/' + id;

      API.request('put', updateUrl, data)
        .then((res) => {
          resolve(res.data, updateUrl);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.UPDATE_BOOKING_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.UPDATE_BOOKING_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.UPDATE_BOOKING_FAILURE,
          });
        });
    });
};

export const deleteCustomBooking = (id) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.DELETE_BOOKING_REQUEST,
      });

      const deleteUrl = EndPoints.BookingsBase + '/' + id;

      API.request('delete', deleteUrl)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.DELETE_BOOKING_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.DELETE_BOOKING_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.DELETE_BOOKING_FAILURE,
          });
        });
    });
};
