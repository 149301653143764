import ClientPage from '../ClientPage';
import BillingPage from '../BillingPage';
import ReportsPage from '../ReportsPage';
import InvoicesPage from '../InvoicesPage';
import ProjectsPage from '../ProjectsPage';
import TeamsPage from '../TeamsPage';
import RececiverPage from '../ReceiverPage';
import ResourcesPage from '../ResourcesPage';
import DashboardPage from '../DashboardPage';
import HoursManagementPage from '../HoursManagementPage';
import RoleManagementPage from '../RolesManagementPage';
import PaymentsPage from '../PaymentsPage';
import LedgersPage from '../LedgersPage';
import HoursLoggingPage from '../HoursLoggingPage';
import SettingsPage from '../SettingsPage';

export const Pages = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '/invoice',
    name: 'Invoices',
    component: InvoicesPage,
  },
  {
    path: '/payment',
    name: 'Payments',
    component: PaymentsPage,
  },
  {
    path: '/ledger',
    name: 'Ledgers',
    component: LedgersPage,
  },
  {
    path: '/project',
    name: 'Projects',
    component: ProjectsPage,
  },
  {
    path: '/team',
    name: 'Teams',
    component: TeamsPage,
  },
  {
    path: '/resource',
    name: 'Resources',
    component: ResourcesPage,
  },
  {
    path: '/hoursmanagement',
    name: 'Hours Management',
    component: HoursManagementPage,
  },
  {
    path: '/report',
    name: 'Reports',
    component: ReportsPage,
  },
  {
    path: '/client',
    name: 'Clients',
    component: ClientPage,
  },
  {
    path: '/receiver',
    name: 'Receivers',
    component: RececiverPage,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: BillingPage,
  },
  {
    path: '/role-management',
    name: 'Roles Management',
    component: RoleManagementPage,
  },
  {
    path: '/hours-logging',
    name: 'Hour Logging',
    component: HoursLoggingPage,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
  },
];
