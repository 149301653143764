import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const savePayment = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_PAYMENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.Payments, data)
        .then((res) => {
          dispatch({
            type: Types.SAVE_PAYMENT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SAVE_PAYMENT_FAILURE,
          });
        });
    });
  };
};

export const updatePayment = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_PAYMENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.Payments + id, data)
        .then((res) => {
          dispatch({
            type: Types.UPDATE_PAYMENT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_PAYMENT_FAILURE,
          });
        });
    });
  };
};

export const getAllPayments = () => {
  return (dispatch) => {
    dispatch({
      type: Types.GET_PAYMENTS_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('get', EndPoints.Payments)
        .then((res) => {
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.GET_PAYMENTS_SUCCESS,
            });
            resolve(true);
          } else {
            dispatch({
              payload: null,
              type: Types.GET_PAYMENTS_FAILURE,
            });
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_PAYMENTS_FAILURE,
          });
        });
    });
  };
};

export const deletePayment = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_PAYMENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('delete', EndPoints.Payments + id)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.DELETE_PAYMENT_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.DELETE_PAYMENT_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_PAYMENT_FAILURE,
          });
        });
    });
  };
};
