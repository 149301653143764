import * as Types from '../../constants/actionconstants';
const initialState = {
  parsed_csv_data: null,
  parsed_csv_data_between_dates: null,
  previous_resource_details: [],
  discount: 0,
  expenses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.PARSED_CSV_DATA_REQUEST:
      return {
        ...state,
        loadingDashboard: true,
      };
    case Types.PARSED_CSV_DATA_SUCCESS:
      return {
        ...state,
        parsed_csv_data: action.payload,
        loadingDashboard: false,
        no_parsed_data: false,
      };
    case Types.PARSED_CSV_DATA_FAILURE:
      return {
        ...state,
        parsed_csv_data: undefined,
        loadingDashboard: false,
        no_parsed_data: true,
      };
    case Types.PARSED_CSV_DATA_BETWEEN_DATES_REQUEST:
      return {
        ...state,
        loadingDashboard: true,
      };
    case Types.SET_AUTO_FILL_DATA:
      return {
        ...state,
        resource_details: action.payload,
      };
    case Types.SET_RESOURCE_DETAILS:
      console.log('payload ', action.payload);
      return {
        ...state,
        previous_resource_details: action.payload,
      };
    case Types.PARSED_CSV_DATA_BETWEEN_DATES_SUCCESS:
      return {
        ...state,
        parsed_csv_data_between_dates: action.payload,
        loadingDashboard: false,
        no_parsed_data: false,
      };
    case Types.PARSED_CSV_DATA_BETWEEN_DATES_FAILURE:
      return {
        ...state,
        parsed_csv_data_between_dates: undefined,
        loadingDashboard: false,
        no_parsed_data: true,
      };
    case Types.HOURS_BETWEEN_DATES_REQUEST:
      return {
        ...state,
        loadingDashboard: true,
      };
    case Types.HOURS_BETWEEN_DATES_SUCCESS:
      return {
        ...state,
        loadingDashboard: false,
      };
    case Types.HOURS_BETWEEN_DATES_FAILURE:
      return {
        ...state,
        loadingDashboard: false,
      };
    case Types.SET_DASHBOARD_PROJECT_REQUEST:
      return {
        ...state,
      };
    case Types.SET_DASHBOARD_PROJECT_SUCCESS:
      return {
        ...state,
        dashboard_project: action.dashboard_project,
      };
    case Types.SET_DASHBOARD_PROJECT_FAILURE:
      return {
        ...state,
      };
    case Types.SAVE_INVOICE_REQUEST:
      return {
        ...state,
      };
    case Types.SAVE_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_saved: action.payload.saved,
        invoice_save_message: action.payload.message,
      };
    case Types.SAVE_INVOICE_FAILURE:
      return {
        ...state,
        invoice_saved: action.payload.saved,
        invoice_save_message: action.payload.message,
      };
    case Types.DELETE_INVOICE_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
      };
    case Types.DELETE_INVOICE_FAILURE:
      return {
        ...state,
      };
    case Types.GET_INVOICES_REQUEST:
      return {
        ...state,
        loadingInvoices: true,
      };
    case Types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loadingInvoices: false,
      };
    case Types.GET_INVOICES_FAILURE:
      return {
        ...state,
        invoices: [],
        loadingInvoices: false,
        showInvoice: 'all',
      };
    case Types.PREPARE_INVOICE_REQUEST:
      return {
        ...state,
      };
    case Types.PREPARE_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_error_message: undefined,
        prepared_invoice: action.payload,
      };
    case Types.PREPARE_INVOICE_ERROR:
      return {
        ...state,
        invoice_error_message: action.error,
      };
    case Types.PREPARE_INVOICE_FAILURE:
      return {
        invoice_error_message: undefined,
        ...state,
      };
    case Types.RESET_PREPARE_INVOICE:
      return {
        ...state,
        prepared_invoice: null,
      };
    case Types.GET_SINGLE_INVOICE_REQUEST:
      return {
        ...state,
      };
    case Types.GET_SINGLE_INVOICE_SUCCESS:
      return {
        ...state,
      };
    case Types.GET_SINGLE_INVOICE_FAILURE:
      return {
        ...state,
      };
    case Types.SET_BILLABLE_PROJECT_INVOICES_REQUEST:
      return {
        ...state,
      };
    case Types.SET_BILLABLE_PROJECT_INVOICES_SUCCESS:
      return {
        ...state,
        invoice_data: action.payload,
      };
    case Types.SET_BILLABLE_PROJECT_INVOICES_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        loadingInvoices: true,
      };
    case Types.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loadingInvoices: true,
      };
    case Types.UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        loadingInvoices: false,
      };
    case Types.SET_SHOW_INVOICE:
      return {
        ...state,
        showInvoice: action.payload.project,
        loadingInvoices: false,
        showInvoiceData: action.payload.invoice,
      };
    case Types.RESET_SHOW_INVOICE:
      return {
        ...state,
        showInvoice: 'all',
        loadingInvoices: false,
      };
    case Types.CHANGE_INVOICE_DATA:
      return {
        ...state,
        discount: action.payload.discount,
        expenses: action.payload.expenses,
        showInvoiceData: action.payload,
      };
    case Types.RESET_SHOW_INVOICE_DATA:
      return {
        ...state,
        showInvoiceData: action.payload,
      };
    case Types.SET_INVOICE_DETAIL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case Types.UPDATE_INVOICE_DATES_REQUEST:
      return {
        ...state,
        loadingInvoices: true,
      };
    case Types.UPDATE_INVOICE_DATES_SUCCESS:
      return {
        ...state,
        loadingInvoices: true,
      };
    case Types.UPDATE_INVOICE_DATES_FAILURE:
      return {
        ...state,
        loadingInvoices: false,
      };
    case Types.DOWNLOAD_INVOICE_REQUEST:
      return {
        ...state,
      };
    case Types.DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
      };
    case Types.DOWNLOAD_INVOICE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
