import React from 'react';

import Radium from 'radium';
import { Dialog } from '@mui/material';

const MyComponent = (props) => (
  <>
    <Dialog open={props.show_confirmation_popup} fullWidth={true} maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Do you want to continue to edit this entry?</h2>
      </div>

      <div style={style.ButtonContainer}>
        <button style={style.BorderedButton2} onClick={() => props.onContinue()}>
          {'Yes'}
        </button>
        <button style={style.PinkBorderedButton} onClick={() => props.onCancel()}>
          {'No'}
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_delete_popup}
      onClose={() => props.toggleDeletePopupResources()}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h3 style={style.Heading}>Are you sure you want to delete this entry?</h3>
        <p style={style.Cross} onClick={() => props.toggleDeletePopupResources()}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={() => props.handleDelete()}>
          {'Yes'}
        </button>
        <button
          style={style.CancelBorderedButton}
          onClick={() => props.toggleDeletePopupResources()}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
    <Dialog
      open={props.show_add_project}
      onClose={props.handleHideAddProject}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Project</h2>
        <p style={style.Cross} onClick={props.handleHideAddProject}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={3}
            name="project_name"
            type="text"
            value={props.project_name}
            style={style.Select}
            placeholder="Project Name"
            onChange={props.handleProjectName}
          />
        </div>
        <div style={style.RowItems}>
          <input
            key={4}
            name="project_color"
            type="text"
            value={props.project_color}
            style={style.Select}
            placeholder="Project Color"
            onChange={props.handleProjectColor}
          />
        </div>
        {/* <div style={style.RowItems}>
          <select
            key={0}
            name="project_manager"
            value={props.project_manager}
            style={style.Select}
            onChange={props.handleProjectManager}
          >
            <option value={''} disabled>
              Select Project Manager
            </option>
            {props.resources.map((resource, index) => {
              return (
                <option key={index} value={resource.name}>
                  {resource.name}
                </option>
              );
            })}
          </select>
        </div> */}
      </div>
      {/* <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={1}
            name="project_status"
            value={props.project_status}
            style={style.Select}
            onChange={props.handleProjectStatus}
          >
            <option value={''} disabled>
              Select Project Status
            </option>
            <option value={true}>Active</option>
            <option value={false}>InActive</option>
          </select>
        </div>
        <div style={style.RowItems}>
          <select
            key={2}
            name="project_association"
            value={props.project_association}
            style={style.Select}
            onChange={props.handleProjectAssociation}
          >
            <option value={''} disabled>
              Select Project Association
            </option>
            <option value={true}>Billable</option>
            <option value={false}>UnBillable</option>
          </select>
        </div>
      </div> */}
      {props.project_errors.length > 0 ? (
        <div style={style.ErrorContainer}>
          {props.project_errors.length > 1 ? 'Errors:' : 'Error:'}
          <ul style={style.ErrorList}>
            {props.project_errors.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        </div>
      ) : null}
      <div style={style.ButtonContainer}>
        <button
          style={
            props.project_errors.length > 0 || props.project_name == '' || props.project_color == ''
              ? style.GreyBorderedButton
              : style.BorderedButton
          }
          onClick={props.handleAddProject}
          disabled={
            props.project_errors.length > 0 || props.project_name == '' || props.project_color == ''
          }>
          Create
        </button>
      </div>
    </Dialog>

    {/*<Dialog
      open={props.show_add_associate_receiver}
      onClose={props.handleHideAddAssociateReceiver}
      fullWidth={true}
      maxWidth="sm"
    >
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Sub-Receiver</h2>
        <p style={style.Cross} onClick={props.handleHideAddAssociateReceiver}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <p key={4} style={style.Select2}>Receiver</p>
        </div>
        <div style={style.RowItems2}>
          <p key={5} style={style.Select2}>Sub-receiver</p>
        </div>
        <div style={style.RowItems}>
          <p key={6} style={style.Select2}>Percentage</p>
        </div>
        <div style={style.RowItems}>
        </div>
      </div>
      { props.AssociatedReceivers.length !== 0 ? 
        props.AssociatedReceivers.map((associated_receiver, index) => {
          return (
          <div style={style.Row}>
            <div style={style.RowItems}>
              <p key={associated_receiver.name+10} style={style.Select2}>{props.selected_project_receiver}</p>
            </div>
            <div style={style.RowItems2}>
              <select
                key={associated_receiver.name+7}
                name="associated_receiver"
                value={associated_receiver.name !== "dummy_value" ? associated_receiver.name : '' }
                style={style.Select}
                onChange={event => props.handleAssociateReceiver(event, index, 'name')}
              >
                <option value={''} disabled>
                  Select Sub-Receiver
                </option>
                {props.receivers.map((receiver, index2) => {
                  return ( 
                    <option key={associated_receiver.name+index2} disabled={receiver.receiver_name === props.selected_project_receiver} value={receiver.receiver_name}>
                      {receiver.receiver_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div style={style.RowItems}>
              <input
                key={associated_receiver.name+8}
                name="receiver_percentage"
                type="text"
                value={associated_receiver.percentage !== -1 ? associated_receiver.percentage : '' }
                style={style.Select}
                placeholder="%"
                onChange={event => props.handleAssociateReceiver(event, index, 'percentage')}
              />  
            </div> 
            <div style={style.RowItems}>
              <button
                style={style.BorderedButtonClear}
                onClick={event => props.handleRemoveAssociateReceiver(index)}
              >
                Remove
              </button>
            </div>      
          </div>
          ) ;
        })   
      : null}
      <div style={style.ButtonContainer2}>
        <button
          style={style.BorderedButton2}
          onClick={props.handleAddSubReceiverRow}
        >
          +Add Sub-Receiver
        </button>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.AssociatedReceivers.length === 0 ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleAddSubReceivers}
          disabled={props.AssociatedReceivers.length === 0}
        >
          Create
        </button>
      </div>
    </Dialog>*/}
  </>
);

export const DialogBoxes = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  RowItems2: {
    flex: 1.5,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-orange)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select2: {
    backgroundColor: 'white',
    padding: '10px',
    paddingBotton: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    marginLeft: '20px',
    marginBottom: '-10px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select3: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  FilledButton: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  BorderedButtonClear: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    marginLeft: '0px 10px',
    cursor: 'pointer',
  },
  BorderedButton2: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ErrorContainer: {
    fontSize: '14px',
    color: '#cc0000',
    padding: '0 20px',
  },
  ErrorList: {
    margin: '1px',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ButtonContainer2: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  DeleteBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  CancelBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
};
