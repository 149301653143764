import React, { Component } from 'react';

import Resource from '../../components/Resource';
import './ResourcesPage.css';

class ResourcesPage extends Component {
  render() {
    return (
      <div className="resourcepage-main">
        <Resource />
      </div>
    );
  }
}

export default ResourcesPage;
