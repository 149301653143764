import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Edit } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';

import { Tooltip as MuiToolTip } from '@mui/material';

import { style } from './style';
import { formatFloat } from '../../Selectors/index';
import { useState, useEffect } from 'react';
import { ROLE_TYPES, default_role_type } from '../../constants/utility';

export const RatesTableView = (props) => {
  const [editedData, setEditedData] = useState({});
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [isAddRateActive, setIsAddRateActive] = useState(false);

  const themeColor = !props.entity_parent_active
    ? 'var(--tin-grey)'
    : props.entity_type === 'Project'
    ? 'var(--tin-yellow)'
    : 'var(--tin-orange)';

  useEffect(() => {
    resetState();
  }, [props.entity_parent_id, props.resources_rate_data.length, props.edit_mode_active]);

  const toggleRowEditable = (index) => {
    if (editableRowIndex === index) {
      setEditableRowIndex(-1);
    } else {
      setEditableRowIndex(index);
    }
    setEditedData({});
    setIsAddRateActive(false);
  };

  const activateSetRate = () => {
    setIsAddRateActive(true);
    setEditableRowIndex(-1);
    setEditedData({});
    handleRowEdit({ target: { value: default_role_type } }, 'role_type');
  };

  const handleRowEdit = (event, field) => {
    const { value } = event.target;

    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const resetState = () => {
    setEditableRowIndex(-1);
    setEditedData({});
    setIsAddRateActive(false);
  };

  const handleSave = (role, rate, role_type) => {
    const { docId, project_id, resource_id } = props.resources_rate_data[editableRowIndex];
    props.handleEdit(role, rate, project_id, resource_id, role_type, docId);
    // Clear the editable rows and edited data
    resetState();
  };

  const handleAddRate = () => {
    props.handleApplyButton(
      props.entity_parent_id,
      parseInt(editedData.entity),
      editedData.role,
      editedData.role_type,
      parseInt(editedData.rate),
    );
    // Clear the editable rows and edited data
    resetState();
  };

  const renderSetRateButton = () => (
    <TableCell style={style.SetRateButtonContainer}>
      <button
        style={{
          ...style.SetRateButton,
          color: themeColor,
          border: `dashed 1px ${themeColor}`,
          cursor: !props.entity_parent_active ? 'not-allowed' : 'pointer',
        }}
        onClick={activateSetRate}
        disabled={!isAddRateActive && !props.entity_parent_active}>
        +
      </button>
    </TableCell>
  );

  const renderEntityFieldNewRate = () => (
    <TableCell style={style.TableCellLong}>
      <select
        key={0}
        name={props.entity_type.toLowerCase()}
        value={editedData.entity ?? ''}
        style={props.select_style}
        onChange={(event) => handleRowEdit(event, 'entity')}>
        <option value={''} disabled>
          Select {props.entity_type}
        </option>
        {props.active_entities.map((active_entity, index) => {
          return (
            <option key={index} value={active_entity.id}>
              {active_entity.name}
            </option>
          );
        })}
      </select>
    </TableCell>
  );

  const renderEntityField = (resource_rate_data) => (
    <TableCell style={style.TableCellLong}>
      {props.entity_type == 'Project' ? (
        <>
          {resource_rate_data.project_name}
          <MuiToolTip title="Inactive Project" placement="top">
            <p>{!resource_rate_data.project_active && <span style={style.BadgeRed}>!</span>}</p>
          </MuiToolTip>
        </>
      ) : (
        <div style={style.Table}>
          <div style={{ ...style.TableCell, marginBottom: '5px' }}>
            {resource_rate_data.resource_name}
            {!resource_rate_data.resource_active && (
              <MuiToolTip title="Inactive Resource" placement="top">
                <span style={style.BadgeRed}>!</span>
              </MuiToolTip>
            )}
          </div>
          <i style={style.ResourceEmail}>{resource_rate_data.resource_email}</i>
        </div>
      )}
    </TableCell>
  );

  const renderRoleField = (resource_rate_data, isEditable) => {
    const isNewRateField = resource_rate_data === null;
    return (
      <TableCell style={style.TableCellMedium}>
        {isEditable || isNewRateField ? (
          <input
            key={1}
            name="role"
            type="text"
            style={props.select_style}
            value={editedData.role ?? resource_rate_data?.role ?? ''}
            placeholder="Enter Role"
            onChange={(event) => handleRowEdit(event, 'role')}
          />
        ) : resource_rate_data.role !== '' ? (
          resource_rate_data.role
        ) : (
          'NOT ASSIGNED'
        )}
      </TableCell>
    );
  };

  const renderRoleTypeField = (resource_rate_data, isEditable) => {
    const isNewRateField = resource_rate_data === null;
    return (
      <TableCell style={style.TableCellMedium}>
        {isEditable || isNewRateField ? (
          <select
            key={0}
            name="role_type"
            value={editedData.role_type ?? resource_rate_data?.role_type ?? ''}
            style={props.select_style}
            onChange={(event) => handleRowEdit(event, 'role_type')}>
            <option value={''} disabled>
              Role Type
            </option>
            {ROLE_TYPES.map((role_type, index) => {
              const isDisabled =
                !props.roles.includes('Super Admin') &&
                !props.roles.includes('Operations Controller') &&
                props.roles.includes('Project Manager') &&
                (role_type == 'regular' || role_type == 'non-regular');
              return (
                <option key={index} value={role_type} disabled={isDisabled}>
                  {role_type}
                </option>
              );
            })}
          </select>
        ) : (
          resource_rate_data.role_type
        )}
      </TableCell>
    );
  };

  const renderRateField = (resource_rate_data, isEditable) => {
    const isNewRateField = resource_rate_data === null;
    return (
      <TableCell style={style.TableCell}>
        {isEditable || isNewRateField ? (
          <input
            key={2}
            name="rate"
            style={props.select_style}
            type="number"
            min="0"
            value={editedData.rate ?? resource_rate_data?.rate ?? ''}
            placeholder="Enter Rate"
            onChange={(event) => handleRowEdit(event, 'rate')}
          />
        ) : (
          `$${formatFloat(resource_rate_data.rate)}`
        )}
      </TableCell>
    );
  };

  const renderEditButton = (resource_rate_data, index, isEditable) => {
    const isNewRateField = resource_rate_data === null;
    let disabled_apply_button = false;
    if (isNewRateField) {
      disabled_apply_button =
        !editedData.rate ||
        parseInt(editedData.rate) <= 0 ||
        editedData.entity === '' ||
        editedData.role === '';
    }
    return (
      <TableCell style={style.TableCell}>
        {isEditable || isNewRateField ? (
          <div style={style.EditModeActions}>
            <button
              style={disabled_apply_button ? style.GreyBorderedButton : props.button_style}
              onClick={() =>
                isNewRateField
                  ? handleAddRate()
                  : handleSave(
                      editedData.role ?? resource_rate_data.role,
                      editedData.rate ?? resource_rate_data.rate,
                      editedData.role_type ?? resource_rate_data.role_type,
                    )
              }
              disabled={disabled_apply_button}>
              Save
            </button>
            <button style={style.PinkBorderedButton} onClick={resetState}>
              Cancel
            </button>
          </div>
        ) : (
          <button
            disabled={!props.can_set_rate}
            style={style.edit_button}
            onClick={() => toggleRowEditable(index)}>
            <Edit />
          </button>
        )}
      </TableCell>
    );
  };

  const renderDeleteButton = (resource_rate_data, isEditable) =>
    props.can_delete ? (
      <TableCell style={style.TableCell}>
        <Delete
          style={{ color: 'red', marginLeft: '15px' }}
          onClick={() => {
            if (!isEditable) {
              resetState();
            }
            props.toggleDeletePopup(resource_rate_data.docId);
          }}
        />
      </TableCell>
    ) : null;

  const renderRowNewRate = () => {
    return props.show_set_rate_button ? (
      <TableRow key={0} style={style.TableRow}>
        {isAddRateActive ? (
          <>
            {renderEntityFieldNewRate()}
            {renderRoleField(null, null)}
            {renderRoleTypeField(null, null)}
            {renderRateField(null, null)}
            {renderEditButton(null, null, null)}
            {props.can_delete ? <TableCell style={style.TableCell} /> : null}
          </>
        ) : (
          renderSetRateButton()
        )}
      </TableRow>
    ) : null;
  };

  const renderRow = (resource_rate_data, index) => {
    const isEditable = editableRowIndex === index;
    return (
      <TableRow key={index} style={style.TableRow}>
        {renderEntityField(resource_rate_data)}
        {renderRoleField(resource_rate_data, isEditable)}
        {renderRoleTypeField(resource_rate_data, isEditable)}
        {renderRateField(resource_rate_data, isEditable)}
        {renderEditButton(resource_rate_data, index, isEditable)}
        {renderDeleteButton(resource_rate_data, isEditable)}
      </TableRow>
    );
  };

  const columns = [props.entity_type, 'Role', 'Role Type', 'Rate', 'Edit'];
  if (props.can_delete) {
    columns.push('Delete');
  }
  return (
    <div style={style.RatesContainer}>
      <h4 style={{ margin: '0px' }}> {props.entity_type} Rates</h4>
      <Table style={style.Table}>
        <TableHead style={style.Head}>
          <TableRow style={style.TableRow}>
            {columns.map((item, index) => {
              return (
                <TableCell
                  key={index + 1}
                  style={
                    index === 0
                      ? style.TableCellLong
                      : index <= 2
                      ? style.TableCellMedium
                      : style.TableCell
                  }>
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
          {renderRowNewRate()}
        </TableHead>
        <TableBody style={style.Body}>
          {props.resources_rate_data.map((item, index) => {
            return renderRow(item, index);
          })}
        </TableBody>
      </Table>
    </div>
  );
};
