import axios from 'axios';
var qs = require('qs');

axios.defaults.withCredentials = true;

const Headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-env': process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev',
};

const FileHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-env': process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev',
};

export const requestFile = (method, url, data) => {
  return axios({
    method: method,
    url: url,
    data: data,
    responseType: 'blob',
    headers: Headers,
  });
};

export const request = (method, url, data) => {
  return axios({
    method: method,
    url: url,
    data: data,
    headers: Headers,
  });
};

export const uploadFileRequest = (method, url, data) => {
  let senddata = method === 'post' ? qs.stringify(data) : data;
  return axios({
    method: method,
    url: url,
    data: senddata,
    headers: FileHeaders,
  });
};
