import { combineReducers } from 'redux';

import HoursManagementReducer from './HoursManagementReducer';
import GenerateEmailReducer from './GenerateEmailReducer';
import ResourceReducer from './ResourceReducer';
import ReceiverReducer from './ReceiverReducer';
import ProjectReducer from './ProjectReducer';
import InvoiceReducer from './InvoiceReducer';
import BillingReducer from './BillingReducer';
import LoaderReducer from './LoaderReducer';
import ClientReducer from './ClientReducer';
import LoginReducer from './LoginReducer';
import TeamReducer from './TeamReducer';
import RoleReducer from './RoleReducer';
import PaymentReducer from './PaymentReducer';
import LedgerReducer from './LedgerReducer';
import HoursReducer from './HoursReducer';
import SettingReducer from './SettingReducer';
import BookingReducer from './BookingReducer';
import ReportReducer from './ReportReducer';

export const rootReducer = combineReducers({
  HoursManagementReducer,
  GenerateEmailReducer,
  ResourceReducer,
  ReceiverReducer,
  ProjectReducer,
  InvoiceReducer,
  BillingReducer,
  LoaderReducer,
  ClientReducer,
  LoginReducer,
  TeamReducer,
  RoleReducer,
  PaymentReducer,
  LedgerReducer,
  HoursReducer,
  SettingReducer,
  BookingReducer,
  ReportReducer,
});
