import React from 'react';

import Radium from 'radium';
import { style } from './style';
import { Dialog, Input } from '@mui/material';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_edit_popup || props.show_create_popup}
      onClose={
        props.show_edit_popup ? () => props.toggleEditPopup('', 0, '') : props.toggleCreatePopup
      }
      fullWidth={true}
      maxWidth="sm">
      <div style={style.DeleteHeadingRow}>
        <h2 style={style.Heading}>
          {props.show_edit_popup ? 'Change Payment Parameters' : 'New Payment'}
        </h2>
        <p
          style={style.Cross}
          onClick={
            props.show_edit_popup ? () => props.toggleEditPopup('', 0, '') : props.toggleCreatePopup
          }>
          X
        </p>
      </div>
      <div style={style.Row}>
        {props.show_create_popup ? (
          <div style={style.RowItems}>
            <select
              key={1}
              name="Projects"
              value={props.selected_project}
              style={style.Select1}
              onChange={props.handleProjectSelection}>
              <option value="" disabled>
                Project
              </option>
              {props.projects.map((project, index) => {
                if (project.active === true && project.billable === true)
                  return (
                    <option key={index} value={project.id}>
                      {project.name}
                    </option>
                  );
                else return null;
              })}
            </select>
          </div>
        ) : null}
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <Input
            key={3}
            type="date"
            style={style.DateInput}
            placeholder="Date"
            value={props.selected_date}
            onChange={props.handleDateSelection}
          />
        </div>
        <div style={style.RowItems}>
          <Input
            onChange={props.handleAmountSelection}
            type="number"
            style={style.Select}
            placeholder="Amount"
            value={props.selected_amount}
          />
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={
            (props.show_edit_popup && props.disable_edit_button) ||
            (props.show_create_popup && props.disable_create_button)
              ? style.GreyBorderedButton
              : style.BorderedButton
          }
          onClick={props.show_edit_popup ? props.updatePayment : props.savePayment}
          disabled={
            props.show_edit_popup ? props.disable_edit_button : props.disable_create_button
          }>
          {props.show_edit_popup ? 'Save' : 'Create'}
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_delete_popup}
      onClose={() => props.toggleDeletePopup('')}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.DeleteHeadingRow}>
        <h2 style={style.Heading}>Are you sure you want to delete this payment</h2>
        <p style={style.Cross} onClick={() => props.toggleDeletePopup('')}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={props.deletePayment}>
          {'Yes'}
        </button>
        <button style={style.DeleteBorderedButton} onClick={() => props.toggleDeletePopup('')}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
  </>
);

export const DialogBox = Radium(MyComponent);
