import React from 'react';

import { Input, TableRow, TableCell } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { style } from '../StreamlineView/style';
import { formatFloat } from '../../../Selectors/index';

export const DiscountTable = (props) => (
  <>
    {!props.delete_discount ? (
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCell3}>
          {props.edit && props.showInvoiceData ? (
            <div>
              Discount {props.discount_type === 'Percentage' ? '%' : 'Abs'}
              <Input
                style={style.Input}
                onChange={(event) => props.handleChange(event, 'discount', '', '')}
                type="number"
                value={props.discount_display_string}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          ) : (
            <p>
              Discount{' '}
              {props.discount_type === 'Percentage'
                ? `(${props.discount})%`
                : `Absolute (${props.discount})`}
            </p>
          )}
        </TableCell>
        <TableCell style={style.TableCell}>
          {props.discount_type === 'Percentage' ? (
            <p style={style.Discount}>
              (${formatFloat(props.total_invoice * (props.discount / 100))})
            </p>
          ) : (
            <p style={style.Discount}>(${props.discount})</p>
          )}
        </TableCell>
        {props.edit && props.showInvoiceData ? (
          <TableCell style={style.TableCell}>
            <Delete style={{ color: 'red' }} onClick={() => props.handleDeleteDiscount()} />
          </TableCell>
        ) : null}
      </TableRow>
    ) : null}
  </>
);
