export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
  },
  SelectedHeaderItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 25px',
    fontSize: '14px',
    lineHeight: '17px',
    cursor: 'pointer',
    color: 'var(--tin-cyan)',
  },
  UnselectedHeaderItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 25px',
    fontSize: '14px',
    lineHeight: '17px',
    cursor: 'pointer',
    color: 'var(--tin-grey-3)',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    width: '20%',
    overflowY: 'scroll',
    borderTop: '1px solid var(--tin-grey-hours-border)',
    borderRight: '1px solid var(--tin-grey-hours-border)',
  },
  ChipContainer: {
    display: 'inline-block',
    paddingRight: 10,
  },
  SelectedChip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 26,
    border: '1px solid var(--tin-cyan)',
    color: 'var(--tin-cyan)',
    backgroundColor: 'white',
    borderRadius: '50%',
    width: 26,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: '12px',
  },
  UnselectedChip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 26,
    border: '1px solid var(--tin-grey-4)',
    color: 'var(--tin-grey-4)',
    borderRadius: '50%',
    width: 26,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: '12px',
  },
  SelectedResourceItem: {
    color: 'white',
    backgroundColor: 'var(--tin-cyan)',
    height: 53,
    fontSize: 11,
  },
  UnselectedResourceItem: {
    color: 'var(--tin-grey-2)',
    height: 53,
    fontSize: 11,
  },
  UnselectedInactiveResourceItem: {
    color: 'red',
    height: 53,
    fontSize: 11,
  },
  SelectedProjectItem: {
    color: 'white',
    backgroundColor: 'var(--tin-cyan)',
    height: 53,
    fontSize: 11,
    lineHeight: '100%',
    paddingLeft: 50,
  },
  UnselectedProjectItem: {
    fontFamily: 'Lato',
    fontSyle: 'Regular',
    color: 'var(--tin-grey-2)',
    fontSize: 11,
    lineHeight: '100%',
    paddingLeft: 50,
    height: 53,
  },
  UnselectedInactiveProjectItem: {
    fontFamily: 'Lato',
    fontSyle: 'Regular',
    color: 'red',
    fontSize: 11,
    lineHeight: '100%',
    paddingLeft: 50,
    height: 53,
  },
  HoursContainer: {
    width: '80%',
    borderTop: '1px solid var(--tin-grey-hours-border)',
  },
  EmptyViewContainer: {
    height: '100%',
    borderTop: '1px solid var(--tin-grey-hours-border)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  HoursHeader: {
    display: 'flex',
    flexDirection: 'row',
    background: 'var(--tin-light-cyan)',
    justifyContent: 'space-between',
    padding: '20px 30px 20px 30px',
    fontWeight: 'bold',
    fontSize: '11px',
    marginBottom: '10px',
  },
  HoursWeekChangeIcon: {
    cursor: 'pointer',
    margin: '0 10px',
    height: '9px',
  },
  HoursHolidayIcon: {
    marginLeft: '10px',
    height: '20px',
  },
  HoursInnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--tin-cyan)',
  },
  HoursSummary: {
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    border: '0.5px solid var(--tin-grey-hours-border)',
    padding: '18px',
    marginTop: '7px',
    fontSize: '14px',
  },
  HoursSummaryText: {
    marginRight: '20px',
  },
  HoursCurrentWeek: {
    borderBottom: '0.5px solid var(--tin-grey-hours-border)',
    borderTop: '0.5px solid var(--tin-grey-hours-border)',
    display: 'flex',
    flexDirection: 'row',
  },
  HoursStartMonth: {
    position: 'absolute',
    left: '3px',
    top: '2px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.3)',
    letterSpacing: '0.3px',
    textShadow: '0px 0.7px rgb(1 0 0 / 20%)',
  },
  HoursEndMonth: {
    position: 'absolute',
    right: '3px',
    top: '2px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.3)',
    letterSpacing: '0.3px',
    textShadow: '0px 0.7px rgb(1 0 0 / 20%)',
  },
  HoursDayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    minHeight: '69px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    position: 'relative',
    color: 'var(--tin-grey-2)',
  },
  HoursDayBoxHoliday: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    minHeight: '69px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    position: 'relative',
    color: 'var(--tin-grey-2)',
    background: 'rgb(255 255 153 / 52%)',
  },
  HoursDayBoxFuture: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    minHeight: '69px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    position: 'relative',
    color: 'var(--tin-grey-4)',
  },
  HoursDayBoxActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    height: '69px',
    width: '147px',
    textAlign: 'center',
    color: 'var(--tin-cyan)',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    borderBottom: 'solid 5px var(--tin-cyan)',
    position: 'relative',
  },
  HoursDayBoxWeekend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    minHeight: '69px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    position: 'relative',
    color: 'var(--tin-grey-2)',
    background: '#F8F8F8',
  },
  HoursDayBoxFutureWeekend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    minHeight: '69px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    position: 'relative',
    color: 'var(--tin-grey-4)',
    background: '#F8F8F8',
  },
  HoursDayBoxActiveWeekend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    height: '69px',
    width: '147px',
    textAlign: 'center',
    color: 'var(--tin-cyan)',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    borderBottom: 'solid 5px var(--tin-cyan)',
    position: 'relative',
    background: '#F8F8F8',
  },
  BookingsHeader: {
    background: '#FFFFFF',
    borderTop: '0.5px solid var(--tin-grey-hours-border)',
    borderBottom: '0.5px solid var(--tin-grey-hours-border)',
    padding: '20px 30px 20px 30px',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BookingsHeaderLabel: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'var(--tin-cyan)',
    display: 'flex',
    gap: '15px',
    justifyContent: 'left',
    alignItems: 'center',
  },
  NewEntry: {
    fontSize: '13px',
    lineHeight: '17px',
    color: 'var(--tin-cyan)',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  BookingsCurrentWeek: {
    borderTop: '0.5px solid var(--tin-grey-hours-border)',
    borderBottom: '0.5px solid var(--tin-grey-hours-border)',
    marginTop: '7px',
    display: 'flex',
    flexDirection: 'row',
  },
  BookingsDayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    minHeight: '140px',
    width: '140px',
    border: 'solid 0.5px var(--tin-lightgrey)',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  BookingsItem: {
    borderRadius: '3px',
    backgroundColor: 'var(--tin-spaceblue3)',
    textAlign: 'left',
    padding: '5px',
    fontSize: '11px',
    color: '#fff',
    margin: '1px',
    width: '95%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },

  EntryHeader: {
    background: '#FFFFFF',
    borderTop: '0.5px solid var(--tin-grey-hours-border)',
    borderBottom: '0.5px solid var(--tin-grey-hours-border)',
    padding: '20px 30px 20px 30px',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  EntryHeaderLabel: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'var(--tin-cyan)',
    display: 'flex',
    gap: '15px',
    justifyContent: 'left',
    alignItems: 'center',
  },
  EntryCurrentWeek: {
    borderTop: '0.5px solid var(--tin-grey-hours-border)',
    borderBottom: '0.5px solid var(--tin-grey-hours-border)',
    marginTop: '7px',
    display: 'flex',
    flexDirection: 'row',
  },
  EntryDayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '12px',
    minHeight: '140px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  EntryDayBoxWeekend: {
    fontSize: '12px',
    minHeight: '140px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    background: '#F8F8F8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    flexDirection: 'column',
  },
  EntryDayBoxHoliday: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '12px',
    minHeight: '140px',
    width: '147px',
    borderRight: 'solid 0.5px var(--tin-grey-hours-border)',
    textAlign: 'center',
    flexDirection: 'column',
    background: 'rgb(255 255 153 / 52%)',
    position: 'relative',
  },
  EntryItem: {
    textAlign: 'left',
    fontSize: '11px',
    color: '#fff',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '1px',
  },
  EntryItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  EntryItemHoliday: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    gap: '5px',
    fontWeight: 'bold',
    color: 'var(--tin-grey-2)',
  },
  EntryItemHolidayFuture: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '5px',
    fontWeight: 'bold',
    color: 'var(--tin-grey-4)',
  },
  EntryItemAdd: {
    borderRadius: '3px',
    fontSize: '11px',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
    marginTop: 'auto',
  },
  EntryItem2: {
    width: '100%',
    borderRadius: '3px',
    backgroundColor: 'var(--tin-yellow)',
    textAlign: 'left',
    padding: '5px',
    fontSize: '11px',
    color: '#fff',
  },
  EntryItemWeekendContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  EntryItemWeekendLabel: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'var(--tin-grey-2)',
  },
  EntryItemWeekendLabelFuture: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'var(--tin-grey-4)',
  },
  EntryItemAddBtn: {
    borderRadius: '3px',
    fontSize: '11px',
    color: 'rgb(255, 255, 255)',
    cursor: 'pointer',
  },
  DialogContainer: {
    padding: '20px',
  },
  DialogCloseButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
  },
  DialogContainerHeading: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#242424',
    lineHeight: '24px',
    margin: '0 0 10px 0',
  },
  DialogInputContainer: {
    marginBottom: '20px',
    marginTop: 30,
  },
  DialogTimeInputContainer: {
    marginTop: 30,
    marginBottom: 5,
  },
  DialogTextAreaContainer: {
    marginBottom: '20px',
    marginTop: '30px',
  },
  DialogueEdittedByContainer: {
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  DialogInputLabel: {
    fontSize: '11px',
    color: 'var(--tin-grey)',
    paddingBottom: '10px',
  },
  DialogInput: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    marginTop: '5px',
    color: 'black',
    padding: '7px 7px 7px 3px',
  },
  MinutesInput: { maxWidth: 66 },
  DialogInputTextArea: {
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    fontSize: '13px',
    marginTop: '5px',
    color: 'black',
    padding: '7px 7px 7px 3px',
    width: '100%',
  },
  DialogInputDisabled: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    fontSize: '13px',
    marginTop: '5px',
    color: 'black',
    padding: '7px 7px 7px 3px',
  },
  DialogInputChipContainer: {
    backgroundColor: '#F2F2F2',
  },
  DialogInputChip: {
    height: 24,
    width: 24,
    marginLeft: 5,
    color: 'var( --tin-cyan)',
    backgroundColor: 'rgba(24,148,119,0.21)',
    fontSize: 11,
  },
  DialogInputSelect: {
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
  },
  DialogInputUnSelect: {
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    color: '#BBBABA',
  },
  DialogDateInputContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '15px',
    gap: '25px',
  },
  DialogDateInput: {
    padding: '10px 10px 10px 0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    color: 'var(--tin-grey)',
    ':focus': {
      outline: 'none',
    },
  },
  DialogInputDateField: {
    padding: '7px 7px 7px 3px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    color: 'black',
    ':focus': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 'auto',
    fontSize: '14px',
    width: '141px',
  },
  DialogTimeRateInputContainer: {
    marginTop: 30,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'start',
    gap: '25px',
  },
  DialogTimeRateInputField: {
    display: 'flex',
    width: '141px',
    marginTop: 5,
    paddingBottom: 2,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
  },
  DialogTimeRateInput: {
    marginTop: 0,
    border: 'none',
    width: '100%',
  },
  DateWeekendNotice: {
    fontSize: '13px',
    color: 'rgba(128, 128, 128, 0.8)',
    marginBottom: '15px',
  },
  DialogSubmission: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
  },
  DialogSubmissionEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  DialogSubmissionMessage: {
    flex: '1',
    fontSize: '11px',
    marginBottom: '5px',
  },
  DialogSubmissionMessageBooking: {
    fontSize: '11px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    padding: '5px 20px',
  },
  DialogSubmissionText: {
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  DialogSubmissionBtn: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'var(--tin-spacegreen4)',
    padding: '7px',
    fontSize: '14px',
    letterSpacing: '0.2px',
    fontWeight: '900',
    ':hover': {
      transition: 'all .5s',
      backgroundColor: 'var(--tin-lightgrey)',
    },
  },
  DialogDisabledBtn: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: '#BBBABA',
    padding: '7px',
    fontSize: '14px',
    letterSpacing: 0,
    fontWeight: '400',
  },
  DialogDiscardBtn: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: '#242424',
    fontSize: '14px',
    letterSpacing: 0,
    fontWeight: '400',
    marginRight: 25,
  },
  DialogDeleteBtn: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'var(--tin-red)',
    fontSize: '11px',
    letterSpacing: '0.2px',
    fontWeight: '900',
    boxShadow: 'none',
    ':hover': {
      transform: 'scale(1.2)',
      // color: 'var(--tin-red)',
      // backgroundColor: 'var(--tin-lightgrey)',
    },
  },
  ProjectLoggers: {
    margin: '10px',
  },
  BackProjectsButton: {
    marginTop: 10,
    marginBottom: 20,
    marginLeft: '50px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '13px',
    cursor: 'pointer',
  },
  ProjectsSelectField: {
    border: 'none',
    width: '100%',
    textDecoration: 'none',
    paddingLeft: 10,
    fontSize: '11px',
    color: 'var(--tin-grey-2)',
  },
  ProjectsSelectFieldOptions: {
    color: 'black',
    padding: '15px !important',
  },
  LoaderContainer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DateStyle: {
    textAlign: 'center',
    borderColor: 'rgba(0,0,0,0.1)',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    marginTop: '5px',
    outline: 'none',
  },
};
