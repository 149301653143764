import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import Moment from 'moment';
import Radium from 'radium';
import calendarGrey from '../../../assets/images/calendar-grey.svg';
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { getValueType } from './utils';
import { TYPE_MUTLI_DATE, TYPE_RANGE, TYPE_SINGLE_DATE } from './constants';
import './style.css';

const MyComponent = (props) => {
  const calendarContainerElement = useRef(null);
  const inputElement = useRef(null);
  const shouldPreventToggle = useRef(false);
  const [isCalendarOpen, setCalendarVisiblity] = useState(false);

  let displayedDate;
  if (props.datePickerValue) {
    const valueType = getValueType(props.datePickerValue);
    if (valueType === TYPE_RANGE) {
      const startDate = {
        month: props.datePickerValue.from.month - 1,
        day: props.datePickerValue.from.day,
        year: props.datePickerValue.from.year,
      };
      displayedDate = `${Moment(startDate).format('DD/MM/YYYY')}  to  `;
      if (props.datePickerValue.to) {
        const endDate = {
          month: props.datePickerValue.to.month - 1,
          day: props.datePickerValue.to.day,
          year: props.datePickerValue.to.year,
        };
        displayedDate += `${Moment(endDate).format('DD/MM/YYYY')}`;
      } else {
        displayedDate += `-`;
      }
    } else if (valueType === TYPE_SINGLE_DATE) {
      const convertDate = {
        month: props.datePickerValue.month - 1,
        day: props.datePickerValue.day,
        year: props.datePickerValue.year,
      };
      displayedDate = Moment(convertDate).format('DD/MM/YYYY'); //to handle date value for input field
    } else {
      //TODO: add date display code for valueType === TYPE_MUTLI_DATE
      // if we introduce such a date picker usecase in the app in future
    }
  }

  useEffect(() => {
    const valueType = getValueType(props.datePickerValue);
    if (valueType === TYPE_MUTLI_DATE) return; // no need to close the calendar
    const shouldCloseCalendar =
      valueType === TYPE_SINGLE_DATE
        ? !isCalendarOpen
        : !isCalendarOpen && props.datePickerValue.from && props.datePickerValue.to;
    if (shouldCloseCalendar) inputElement.current.blur();
  }, [props.datePickerValue, isCalendarOpen]);

  const handleBlur = (e) => {
    e.persist();
    if (!isCalendarOpen) return;
    const isInnerElementFocused = calendarContainerElement.current.contains(e.relatedTarget);
    if (shouldPreventToggle.current) {
      shouldPreventToggle.current = false;
      inputElement.current.focus();
    } else if (isInnerElementFocused && e.relatedTarget) {
      e.relatedTarget.focus();
    } else {
      setCalendarVisiblity(false);
    }
  };

  const openCalendar = () => {
    if (!shouldPreventToggle.current) setCalendarVisiblity(true);
  };

  const handleKeyUp = ({ key }) => {
    switch (key) {
      case 'Enter':
        setCalendarVisiblity(true);
        break;
      case 'Escape':
        setCalendarVisiblity(false);
        break;
    }
  };

  const handleCalendarChange = (newValue) => {
    const valueType = getValueType(newValue);
    props.handleChangeDate(newValue);
    if (valueType === TYPE_SINGLE_DATE) setCalendarVisiblity(false);
    else if (valueType === TYPE_RANGE && newValue.from && newValue.to && props.has_apply_button) {
      //this condition is for reports page
      setCalendarVisiblity(true);
    } else if (valueType === TYPE_RANGE && newValue.from && newValue.to) {
      setCalendarVisiblity(false);
    }
  };

  const handleApplyButton = () => {
    props.onDateSelect();
    setCalendarVisiblity(false);
  };
  return (
    <>
      <div
        // style={{ display: 'flex' }}
        onFocus={openCalendar}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}>
        <label>
          <img
            src={calendarGrey}
            alt=""
            style={{
              position: 'absolute',
              zIndex: '1',
              marginTop: '7px',
              cursor: 'pointer',
              marginLeft: '3px',
            }}
          />
          <input readOnly ref={inputElement} value={displayedDate} style={props.style} />
        </label>
        {isCalendarOpen && (
          <>
            <div ref={calendarContainerElement} className="DatePicker__customCalendarContainer">
              {props.has_apply_button ? (
                <Calendar
                  value={props.datePickerValue}
                  onChange={handleCalendarChange}
                  shouldHighlightWeekends
                  calendarClassName={`DefaultCustomCalendar ${props.calendarClassName}`}
                  minimumDate={props.formattedMinimumDate}
                  maximumDate={props.maximumDate}
                  renderFooter={() => (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', padding: '1rem 2rem' }}>
                      <button
                        type="button"
                        onClick={() => {
                          handleApplyButton();
                        }}
                        className="buttonStyle">
                        {props.button_name}
                      </button>
                    </div>
                  )}
                />
              ) : (
                <Calendar
                  value={props.datePickerValue}
                  onChange={handleCalendarChange}
                  shouldHighlightWeekends
                  calendarClassName={`DefaultCustomCalendar ${props.calendarClassName}`}
                  minimumDate={props.formattedMinimumDate}
                />
              )}
            </div>
            <div className="DatePicker__customCalendarArrow" />
          </>
        )}
      </div>
    </>
  );
};

export const CustomDatePicker = Radium(MyComponent);
