import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';

import { DialogBoxes } from './DialogBoxes';
import { AllResourceView } from './AllResourceView';
import { ActionsCreator } from '../../../Redux/Actions';
import * as ReactGA from 'react-ga';
import { style } from '../style';
class HoursDetailListview extends Component {
  constructor() {
    super();
    this.state = {
      header: {
        Employees: 'ascending',
        'Completed Hours': 'ascending',
        'Leave Hours': 'ascending',
        'Overtime Hours': 'ascending',
        'Missed Hours': 'ascending',
      },
      show_hours_distribution: false,
      filtered_resource: '',
    };
  }

  handleShowHoursDistribution = (employee) => {
    this.setState({
      show_hours_distribution: true,
      view: employee,
    });
  };

  handleHideHoursDistribution = () => {
    this.setState({
      show_hours_distribution: false,
    });
  };

  handleGenerateEmailReminder = (data) => {
    let { generateEmailForResources, startLoader, stopLoader, enqueueSnackbar } = this.props;

    startLoader('#189477')
      .then((response) => {
        if (response) {
          return generateEmailForResources(data);
        }
      })
      .then((response) => {
        if (response.sent) {
          ReactGA.event({
            category: 'Hour Management',
            action: 'Sent an email reminder for Resource',
          });
          enqueueSnackbar(response.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        return stopLoader();
      })
      .then((response) => {
        if (response && data.length === 1) {
          this.setState({
            view: data[0].name,
          });
        }
      });
  };

  handleGenerateInvoicesEmailReminder = () => {
    let { generateEmailForInvoices, startLoader, stopLoader, enqueueSnackbar } = this.props;

    startLoader('#189477')
      .then((response) => {
        if (response) {
          return generateEmailForInvoices();
        }
      })
      .then((response) => {
        if (response.sent) {
          ReactGA.event({
            category: 'Hour Management',
            action: 'Sent an email reminder for Invoice',
          });
          enqueueSnackbar(response.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        return stopLoader();
      });
  };

  handleSort = (item, myFunction, value, objectName) => {
    let sort_statuses = this.state.header;
    let sort_type = sort_statuses[item];
    let new_sort_statuses = sort_statuses;

    switch (sort_type) {
      case 'ascending':
        new_sort_statuses[item] = 'descending';
        this.setState({
          // filtered_hours_data: myFunction('ascending', value, objectName),
          header: new_sort_statuses,
        });
        break;
      case 'descending':
        new_sort_statuses[item] = 'ascending';
        this.setState({
          // filtered_hours_data: myFunction('descending', value, objectName),
          header: new_sort_statuses,
        });
        break;
      default:
        break;
    }
  };

  handleHoursSort = (item) => {
    // const { filtered_hours_data } = this.state;
    // if (item === 'Employees') {
    //   this.handleSort(item, stringSort, filtered_hours_data, 'employee');
    // } else if (item === 'Completed Hours') {
    //   this.handleSort(item, integerHoursSort, filtered_hours_data, 'completed_hours');
    // } else if (item === 'Leave Hours') {
    //   this.handleSort(item, integerHoursSort, filtered_hours_data, 'leave_hours');
    // } else if (item === 'Overtime Hours') {
    //   this.handleSort(item, integerHoursSort, filtered_hours_data, 'overtime_hours');
    // } else if (item === 'Missed Hours') {
    //   this.handleSort(item, integerHoursSort, filtered_hours_data, 'missed_hours');
    // }
  };

  handleResourceFilteredSelection = (e) => {
    this.setState({
      filtered_resource: e.target.value,
    });
  };

  render() {
    let { time_entries_hours, resources, total_worked_hours, total_hours } = this.props;
    let { view, header, show_hours_distribution, filtered_resource } = this.state;
    const show_resources =
      resources &&
      resources.filter(
        (resource) =>
          (filtered_resource !== '' &&
            resource.name.toLowerCase().includes(filtered_resource.toLowerCase())) ||
          filtered_resource == '',
      );
    return (
      <div
        style={
          !time_entries_hours
            ? { display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }
            : { display: 'flex', flex: 1 }
        }>
        {!time_entries_hours ? (
          <Bars color="#00A9FD" height={50} width={50} />
        ) : (
          <>
            <div className="hours-management-details-container">
              <div style={style.RowItems1}>
                <input
                  key={1}
                  type="text"
                  value={filtered_resource}
                  style={style.FilterSelect}
                  placeholder="Resource"
                  onChange={this.handleResourceFilteredSelection}
                />
              </div>

              <AllResourceView
                header={header}
                resources={show_resources}
                total_worked_hours={total_worked_hours}
                total_hours={total_hours}
                hours_data={time_entries_hours}
                handleHoursSort={this.handleHoursSort}
                sendEmail={this.handleGenerateEmailReminder}
                sendInvoicingEmail={this.handleGenerateInvoicesEmailReminder}
                handleShowHoursDistribution={this.handleShowHoursDistribution}
              />
            </div>
            <DialogBoxes
              employee={view}
              total_worked_hours={total_worked_hours}
              total_hours={total_hours}
              hours_data={time_entries_hours}
              sendEmail={this.handleGenerateEmailReminder}
              show_hours_distribution={show_hours_distribution}
              handleHideHoursDistribution={this.handleHideHoursDistribution}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    time_entries_hours: state.HoursManagementReducer.time_entries_hours,
    email_sent: state.GenerateEmailReducer.email_sent,
    email_message: state.GenerateEmailReducer.email_message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  generateEmailForInvoices: () => dispatch(ActionsCreator.generateEmailForInvoices()),
  generateEmailForResources: (data) => dispatch(ActionsCreator.generateEmailForResources(data)),
  resetParsedCSV: () => dispatch(ActionsCreator.resetParsedCSV()),
  resetRates: () => dispatch(ActionsCreator.resetRates()),
  resetShowDetailInvoice: () => dispatch(ActionsCreator.resetShowDetailInvoice()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(HoursDetailListview));
