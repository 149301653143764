import React from 'react';

import { Ability } from '../../constants/ability';
import { EmptyContainer } from '../Utility/EmptyContainer';
import HourLoggerDashboard from './HourLoggerDashboard';
import ProjectManagerDashboard from './ProjectManagerDashboard';

export const AuthenticatedDashboard = (props) => (
  <>
    {Ability[props.role].dashboard.present ? (
      // only display HourLoggerDashboard to all dashboard viewers
      <HourLoggerDashboard />
    ) : (
      // React.createElement(Ability[props.role].dashboard.name, {
      //   ...(Ability[props.role].dashboard.name === ProjectManagerDashboard
      //     ? { project: props.project, selected_month: props.selected_month }
      //     : { selected_month: props.selected_month })
      // })
      <EmptyContainer />
    )}
  </>
);
