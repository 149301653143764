import React from 'react';

import RobotIcon from '../../assets/images/robot_icon.png';

export const EmptyContainer = () => (
  <div style={style.Container}>
    <img style={style.RobotIcon} src={RobotIcon} alt="" />
    <div style={style.InfoText}>Such a lonely, empty space.</div>
  </div>
);

const style = {
  Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  RobotIcon: {
    height: '70px',
    width: 'auto',
    margin: '0px 20px 20px',
  },
  InfoText: {
    color: 'var(--tin-grey)',
  },
};
