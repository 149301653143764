import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN_CODE_EXCHANGE_REQUEST:
      return {
        ...state,
      };
    case Types.LOGIN_CODE_EXCHANGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        login_success: true,
      };
    case Types.LOGIN_CODE_EXCHANGE_FAILURE:
      return {
        ...state,
        login_success: false,
      };
    case Types.LOGGEDIN_CHECK_REQUEST:
      return {
        ...state,
      };
    case Types.LOGGEDIN_CHECK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        logged_in: true,
      };
    case Types.LOGGEDIN_CHECK_FAILURE:
      return {
        ...state,
        logged_in: false,
      };
    case Types.LOGOUT_REQUEST:
      return {
        ...state,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        logged_in: false,
      };
    case Types.LOGOUT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
