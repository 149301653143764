import billingwhiteicon from '../../assets/images/Billing white.png';
import billingpinkicon from '../../assets/images/Billing colored.png';
import homeblueicon from '../../assets/images/Home icon colored.png';
import homewhiteicon from '../../assets/images/Home icon white 2x.png';
import clientwhiteicon from '../../assets/images/Client icon white.png';
import reportswhiteicon from '../../assets/images/Reports WHITE ICON.png';
import reportspinkicon from '../../assets/images/Reports ICON Colored.png';
import clientcoloredicon from '../../assets/images/Client icon colored.png';
import receiverwhiteicon from '../../assets/images/Receiver icon white.png';
import invoicegreenicon from '../../assets/images/Invoices icon colored.png';
import invoicewhiteicon from '../../assets/images/Invoices icon white 2x.png';
import projectorangeicon from '../../assets/images/Projects icon colored.png';
import projectwhiteicon from '../../assets/images/Projects icon white 2x.png';
import resourceyellowicon from '../../assets/images/Resources icon colored.png';
import resourcewhiteicon from '../../assets/images/Resources icon white 2x.png';
import receivercoloredicon from '../../assets/images/Receiver icon colored.png';
import hoursmanagementwhiteicon from '../../assets/images/Hours Management WHITE ICON.png';
import hoursmanagementcyanicon from '../../assets/images/Hours Management ICON Colored.png';
import rolemanagementcolored from '../../assets/images/Role Management Colored.png';
import rolemanagementuncolored from '../../assets/images/Role Management White icon.png';
import hourlogging from '../../assets/images/hour-logging-icon.svg';
import settingsicon from '../../assets/images/Settings icon.svg';

export const DrawerList = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-blue)',
    inactive_background_color: 'white',
    inactive_icon: homeblueicon,
    active_icon: homewhiteicon,
  },
  {
    path: '/invoice',
    name: 'Invoices',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-green)',
    inactive_background_color: 'white',
    inactive_icon: invoicegreenicon,
    active_icon: invoicewhiteicon,
  },
  /* {
    path: '/payment',
    name: 'Payments',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-green)',
    inactive_background_color: 'white',
    inactive_icon: invoicegreenicon,
    active_icon: invoicewhiteicon,
  },*/
  {
    path: '/ledger',
    name: 'Ledgers',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-green)',
    inactive_background_color: 'white',
    inactive_icon: invoicegreenicon,
    active_icon: invoicewhiteicon,
  },
  {
    path: '/project',
    name: 'Projects',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-orange)',
    inactive_background_color: 'white',
    inactive_icon: projectorangeicon,
    active_icon: projectwhiteicon,
  },
  {
    path: '/team',
    name: 'Teams',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-orange)',
    inactive_background_color: 'white',
    inactive_icon: projectorangeicon,
    active_icon: projectwhiteicon,
  },
  {
    path: '/resource',
    name: 'Resources',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-yellow)',
    inactive_background_color: 'white',
    inactive_icon: resourceyellowicon,
    active_icon: resourcewhiteicon,
  },
  {
    path: '/client',
    name: 'Clients',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-darkblue)',
    inactive_background_color: 'white',
    inactive_icon: clientcoloredicon,
    active_icon: clientwhiteicon,
  },
  {
    path: '/receiver',
    name: 'Receivers',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-hazelnut)',
    inactive_background_color: 'white',
    inactive_icon: receivercoloredicon,
    active_icon: receiverwhiteicon,
  },
  {
    path: '/hoursmanagement',
    name: 'Hours Management',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-cyan)',
    inactive_background_color: 'white',
    inactive_icon: hoursmanagementcyanicon,
    active_icon: hoursmanagementwhiteicon,
  },
  {
    path: '/report',
    name: 'Reports',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-pink)',
    inactive_background_color: 'white',
    inactive_icon: reportspinkicon,
    active_icon: reportswhiteicon,
  },
  {
    path: '/billing',
    name: 'Billing',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-light-pink)',
    inactive_background_color: 'white',
    inactive_icon: billingpinkicon,
    active_icon: billingwhiteicon,
  },
  {
    path: '/role-management',
    name: 'Roles Management',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-dark-green)',
    inactive_background_color: 'white',
    inactive_icon: rolemanagementcolored,
    active_icon: rolemanagementuncolored,
  },
  {
    path: '/hours-logging',
    name: 'Hour Logging',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-orange)',
    inactive_background_color: 'white',
    inactive_icon: hourlogging,
    active_icon: hourlogging,
  },
  {
    path: '/settings',
    name: 'Settings',
    active_label_color: 'white',
    inactive_label_color: 'var(--tin-grey)',
    active_background_color: 'var(--tin-dark-green)',
    inactive_background_color: 'white',
    inactive_icon: settingsicon,
    active_icon: settingsicon,
  },
];
