import React from 'react';

import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { formatFloat } from '../../../Selectors';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

export const ResourceHoursDistribution = (props) => (
  <div style={style.Container}>
    <div style={style.Rows}>
      <div style={style.LabelRows1}>
        <label style={style.Label}>Name:</label>
        <p style={style.Value1}>{props.resource}</p>
      </div>
      <div style={style.LabelRows}>
        <label style={style.Label}>Status:</label>
        <p style={style.Value}>Active</p>
      </div>
      {props.sendEmail ? (
        <div style={style.LabelRows}>
          <label style={style.Label}>Warning Email:</label>
          <div className="hours-management-generate-email">
            <Button
              className="hours-management-upload-button"
              onClick={() => {
                if (props.missed_hours > 0) {
                  let data = JSON.stringify([
                    {
                      name: props.resource,
                      thours: props.completed_hours,
                      vhours: props.leave_hours,
                      mhours: parseInt(props.total_hours),
                    },
                  ]);
                  props.sendEmail(data);
                }
              }}>
              Generate
            </Button>
          </div>
        </div>
      ) : null}
    </div>
    <div style={style.Rows}>
      <div style={style.LabelRows}>
        <label style={style.Label}>Total Hours:</label>
        <p style={style.Value}>{props.total_hours}</p>
      </div>
      <div style={style.LabelRows}>
        <label
          style={style.Label}
          className={`hours-management-employee-detail-label
          ${props.completed_hours > 0 ? ' hours-management-green' : ''}`}>
          Complete Hours:
        </label>
        <p style={style.Value}>{props.completed_hours}</p>
      </div>
      <div style={style.LabelRows}>
        <label
          style={style.Label}
          className={`hours-management-employee-detail-label 
          ${props.missed_hours > 0 ? ' hours-management-red' : ''} 
          ${props.missed_hours < 0 ? ' hours-management-blue' : ''}`}>
          Missed Hours:
        </label>
        <p style={style.Value}>{props.missed_hours}</p>
      </div>
      <div style={style.LabelRows}>
        <label style={style.Label}>Leave Hours:</label>
        <p style={style.Value}>{props.leave_hours}</p>
      </div>
    </div>

    <Table style={style.Table}>
      <TableHead style={style.TableHead}>
        <TableRow style={style.TableRow}>
          <TableCell style={style.TableCell}>Project Invoiced on</TableCell>
          <TableCell style={style.TableCell}>Timesheet Hours</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="hours-management-details-view-table">
        {props.resource_records.map((resource, index) => {
          return (
            <TableRow key={index} style={style.TableRow}>
              <TableCell style={style.TableCell}>{resource.project}</TableCell>
              <TableCell style={style.TableCell}>{formatFloat(resource.time)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
);

const style = {
  Container: {
    paddingTop: '20px',
  },
  LabelRows: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  LabelRows1: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Label: {
    flex: 1,
    fontSize: '14px',
    alignItems: 'center',
  },
  Value: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  Value1: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
  },
  GenerateLabel: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
  },
  Rows: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '25vh',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableHead: {
    display: 'block',
    width: '100%',
  },
};
