import React from 'react';

import Radium from 'radium';
import Chart from 'chart.js';
import { style } from '../style';
import ReactChartkick, { PieChart } from 'react-chartkick';

ReactChartkick.addAdapter(Chart);

const MyComponent = (props) => (
  <div style={style.TableContainer}>
    <div style={style.Headings}>
      <div style={style.CardHeadingText}>
        Invoiced: ${props.invoices_pie_chart_data.reduce((a, b) => a + b[1], 0)}
      </div>
      <div style={style.ProjectsSelection}>
        <input
          key={0}
          type="date"
          style={style.ProjectSelect}
          value={props.selected_start_date}
          placeholder="From"
          onChange={props.handleChangeStartDate}
        />
      </div>
      <div style={style.ProjectsSelection}>
        <input
          key={1}
          type="date"
          value={props.selected_end_date}
          style={style.ProjectSelect}
          placeholder="To"
          onChange={props.handleChangeEndDate}
        />
      </div>
    </div>
    <div style={style.ChartContainer}>
      <PieChart
        style={style.CentralizedRow}
        data={props.invoices_pie_chart_data}
        library={style.PieChart}
        curve={true}
        messages={{ empty: 'No data to make Chart!' }}
      />
    </div>
  </div>
);

export const InvoicesPieChart = Radium(MyComponent);
