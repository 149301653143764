import React from 'react';

import { Link } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { style } from './style';
import { InvoiceState } from '../../constants/invoicestate';
import { calculateTotalOfInvoices } from '../../Selectors/index';

export const InvoiceSectionHeader = (props) => (
  <Toolbar style={style.MainHeading}>
    <div style={style.HeaderText}>
      <Breadcrumbs style={{ flex: 1 }}>
        <Link className="breadcrumb-link" to="/main/invoice" onClick={props.handleShowAllInvoices}>
          Invoices
        </Link>
        {props.showInvoice !== 'all' ? (
          <Link
            className="breadcrumb-link"
            to="/main/invoice"
            onClick={() => props.handleShowProjectInvoice(props.showInvoice)}>
            {props.showInvoice}
          </Link>
        ) : null}
      </Breadcrumbs>
      {!props.no_active_period && props.range ? (
        <span style={style.ActivePeriodText}>
          Active Period: {props.range.start_date} to {props.range.end_date}
        </span>
      ) : null}
    </div>
    {props.invoices && props.showInvoice === 'all' ? (
      <div style={style.InvoicesCount}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Total Invoices</label>
          <p style={style.Value}>${calculateTotalOfInvoices(props.invoices)}</p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Drafted</label>
          <p style={style.Value}>
            $
            {calculateTotalOfInvoices(
              props.invoices.filter(
                (invoice) =>
                  invoice.status === InvoiceState.DRAFT.name ||
                  invoice.status === InvoiceState.READY.name,
              ),
            )}
          </p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Due</label>
          <p style={style.Value}>
            $
            {calculateTotalOfInvoices(
              props.invoices.filter(
                (invoice) =>
                  invoice.status === InvoiceState.SENT.name ||
                  invoice.status === InvoiceState.DUE.name,
              ),
            )}
          </p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Received</label>
          <p style={style.Value}>
            $
            {calculateTotalOfInvoices(
              props.invoices.filter((invoice) => invoice.status === InvoiceState.CLEARED.name),
            )}
          </p>
        </div>
      </div>
    ) : null}
    {props.can_create_invocie ? (
      <button style={style.FilledButton} onClick={props.handleShowInvoiceOptions}>
        + Create Invoice
      </button>
    ) : null}
  </Toolbar>
);
