import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';

import { withSnackbar } from 'notistack';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Pages } from './Pages';
import { DrawerList } from './DrawerList';
import UnknownPage from '../UnknownPage';
import { Ability } from '../../constants/ability';
import logouticon from '../../assets/images/Logout.png';
import { ActionsCreator } from '../../Redux/Actions/index';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import tinvoicelogo from '../../assets/images/Tinvoice logo.png';

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history, location, roles } = this.props;
    this.props.unknownPageStartLoader();
    let path = location.pathname;
    let page;

    if (path !== '/main') {
      path = path.replace('/main', '');
      page = Pages.filter((p) => p.path === path)[0];

      if (
        !Pages.map((p) => p.path).includes(path) ||
        !this.getAccessList(roles).includes(page.name)
      ) {
        history.replace('/main/not-found');
      }
    }
  }

  handleCardClick = (path, color) => {
    const { history, startLoader, resetShowInvoice } = this.props;

    if (path === '/invoice' || path === '/main/invoice') {
      resetShowInvoice();
    }

    startLoader(color).then((response) => {
      if (response) {
        history.push(path);
      }
    });
  };

  handleLogoutClick = () => {
    const { history, startLoader, stopLoader, checkLoggedIn, logout, enqueueSnackbar } = this.props;

    startLoader('#01b9fe')
      .then((response) => {
        if (response) {
          return logout();
        }
      })
      .then((response) => {
        if (response) {
          history.push('/');
          return checkLoggedIn();
        } else {
          stopLoader();
          enqueueSnackbar('Unable to Logout!', { variant: 'error' });
        }
      })
      .then((response) => {
        if (response) {
          stopLoader();
        }
      });
  };

  getAccessList = (roles) => {
    let access = [];
    roles.forEach((role) => {
      access.push(...Ability[role].can_access);
    });
    access = [...new Set(access)];
    return access;
  };

  render() {
    const { match, location, roles } = this.props;
    const accessList = this.getAccessList(roles);
    return (
      <div style={style.MainPage}>
        <div style={style.MainDrawer}>
          <Toolbar>
            <img style={style.AppLogo} src={tinvoicelogo} alt="" />
          </Toolbar>
          <List style={style.List}>
            {DrawerList.map((item, index) => {
              let active_status = location.pathname.includes(item.path);
              let can_access = accessList.includes(item.name);

              if (can_access) {
                return (
                  <ListItem
                    button
                    key={index}
                    style={
                      active_status
                        ? { backgroundColor: item.active_background_color, ...style.ListItemDetail }
                        : {
                            backgroundColor: item.inactive_background_color,
                            ...style.ListItemDetail,
                          }
                    }
                    disabled={location.pathname.includes(item.path)}
                    onClick={() =>
                      this.handleCardClick('/main' + item.path, item.active_background_color)
                    }>
                    <ListItemIcon style={style.ListItemIcon}>
                      <img
                        style={style.MainIcon}
                        src={active_status ? item.active_icon : item.inactive_icon}
                        alt=""
                      />
                    </ListItemIcon>
                    <Typography
                      style={
                        active_status
                          ? {
                              fontWeight: 'bold !important',
                              color: item.active_label_color,
                              ...style.ListItemText,
                            }
                          : {
                              fontWeight: 'bold !important',
                              color: item.inactive_label_color,
                              ...style.ListItemText,
                            }
                      }>
                      {item.name}
                    </Typography>
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
          </List>
          <div
            style={style.LogoutContainer}
            className="hours-management-button"
            onClick={this.handleLogoutClick}>
            <div style={style.LogoutItemIcon}>
              <img style={style.MainIcon} src={logouticon} alt="" />
            </div>
            <div style={style.LogoutText}>Logout</div>
          </div>
        </div>
        <div
          style={
            location.pathname === '/main/not-found' ? style.MainContainer404 : style.MainContainer
          }>
          <Route
            exact
            path={`${match.url}`}
            component={() => <Redirect to={`${match.url}/hours-logging`} />}
          />
          <AuthenticatedRoutes accessList={accessList} baseURL={match.url} />
          <Route exact path={`${match.url}/not-found`} component={UnknownPage} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(ActionsCreator.logout()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  resetShowInvoice: () => dispatch(ActionsCreator.resetShowInvoice()),
  unknownPageStartLoader: () => dispatch(ActionsCreator.unknownPageStartLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(MainPage)));

const style = {
  List: {
    width: '100%',
  },
  ListItem: {
    padding: '0px',
  },
  ListItemDetail: {
    padding: '10px',
    paddingLeft: '10px',
    width: '100%',
    display: 'flex',
    opacity: 1,
  },
  ListItemIcon: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ListItemText: {
    flex: 3,
    display: 'flex',
    fontSize: '0.825rem',
  },
  MainPage: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  MainDrawer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 2,
    boxShadow: 'var(--tin-grey) 4px 1px 20px -5px',
  },
  MainContainer: {
    flex: 11,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: '100vh',
  },
  MainContainer404: {
    flex: 11,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#b5a8a3',
  },
  AppLogo: {
    height: '40px',
  },
  MainIcon: {
    height: '25px',
  },
  LogoutContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 'auto',
    padding: '10px',
  },
  LogoutItemIcon: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '56px',
  },
  LogoutText: {
    flex: 3,
    display: 'flex',
    padding: '30px 0px',
    color: 'var(--tin-grey)',
    fontSize: '0.825rem;',
  },
};
