import React from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material';

import { style } from './style';
import { BillingSearchFields } from './BillingSearchFields';

export const BillingTableView = (props) => (
  <div style={style.TableContainer}>
    {props.show_filters ? (
      <>
        <div style={style.ShowFiltersButtonContainer}>
          <div
            style={style.ShowFiltersButton}
            onClick={() => props.handleChangeShowFilterStatus(false)}>
            Hide Filters
          </div>
        </div>
        <BillingSearchFields handleClearFilters={props.handleClearFilters} />
      </>
    ) : (
      <div style={style.ShowFiltersButtonContainer}>
        <div
          style={style.ShowFiltersButton}
          onClick={() => props.handleChangeShowFilterStatus(true)}>
          Show Filters
        </div>
      </div>
    )}

    <Table style={style.Table}>
      <TableHead style={style.Head}>
        <TableRow style={style.TableRow}>
          <TableCell style={style.TableCell1}>Resource</TableCell>
          {props.months.map((item, index) => {
            return (
              <TableCell key={index} style={style.TableCell}>
                {item}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={props.show_filters ? style.BodyWithFilters : style.BodyWithoutFilters}>
        {Object.keys(props.billing_data).map((resource_name, index) => {
          let resource_total_hours = 0;
          let resource_billing = 0;
          props.months.forEach((item) => {
            let exists = props.billing_data[resource_name].hasOwnProperty(item);
            let value;

            if (exists) {
              value = props.billing_data[resource_name][item];

              let projects_total_hours = 0;
              Object.keys(value).forEach((project_name) => {
                projects_total_hours += value[project_name][0];
              });

              let total_billed_amount = 0;
              Object.keys(value).forEach((project_name) => {
                total_billed_amount += value[project_name][1];
              });
              resource_total_hours += projects_total_hours;
              resource_billing += total_billed_amount;
            }
          });
          return (
            <TableRow key={index} style={style.TableRow} className="hours-management-button">
              <TableCell style={style.TableCell1}>
                {resource_name}
                <br />
                {'Total Hours: ' + resource_total_hours}
                <br />
                {'Total Billing: $' + resource_billing}
              </TableCell>
              {props.months.map((item, index) => {
                let exists = props.billing_data[resource_name].hasOwnProperty(item);
                let value;

                if (exists) {
                  value = props.billing_data[resource_name][item];

                  let projects_total_hours = 0;
                  Object.keys(value).forEach((project_name) => {
                    projects_total_hours += value[project_name][0];
                  });

                  let total_billed_amount = 0;
                  Object.keys(value).forEach((project_name) => {
                    total_billed_amount += value[project_name][1];
                  });

                  return (
                    <TableCell key={index} style={style.TableCell}>
                      {'Monthly Hours: ' + projects_total_hours}
                      <br />
                      {'Billed Amount: $' + total_billed_amount}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={index} style={style.TableCell}>
                      {'Nil'}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
);
