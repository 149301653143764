import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { Toolbar } from '@mui/material';
import { withSnackbar } from 'notistack';
import * as ReactGA from 'react-ga';

import { hasPermission } from '../../constants/ability';
import { ClientListView } from './ClientListView';
import { ClientDetailView } from './ClientDetailView';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { CreateDialogBox } from './CreateDialogBox';
import { style } from './style';

const LOADER_COLOR = '#3b3a50';

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      project_id: '',
      show_client: '',
      client_name: '',
      client_address: '',
      client_selected: null,
      show_add_client: false,
      client_project_pdf_name: '',
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadClientsData();
  }

  loadClientsData = async () => {
    const { projects, stopLoader, getProjects, getAllClients } = this.props;

    !projects && (await getProjects());
    getAllClients().then((res) => {
      stopLoader();
    });
  };

  handleClientClick = (client) => {
    this.setState({
      edit: false,
      show_client: client,
    });
  };

  handleEditClient = (id) => {
    const {
      clients,
      stopLoader,
      startLoader,
      updateClient,
      getAllClients,
      enqueueSnackbar,
      no_clients_data,
    } = this.props;
    let {
      project_id,
      show_client,
      client_name,
      client_address,
      client_selected,
      client_project_pdf_name,
    } = this.state;

    if (show_client === '' && !no_clients_data && clients && clients.length > 0) {
      client_selected = clients[0];
    } else if (show_client !== '' && !no_clients_data && clients) {
      client_selected = clients.filter((client) => client.docId === show_client)[0];
    }

    if (clients && client_selected && project_id === '') {
      project_id = client_selected.project_id;
    }

    if (clients && client_selected && client_name === '') {
      client_name = client_selected.client_name;
    }

    if (clients && client_selected && client_address === '') {
      client_address = client_selected.client_address;
    }

    if (clients && client_selected && client_project_pdf_name === '') {
      client_project_pdf_name = client_selected.client_project_pdf_name;
    }

    startLoader(LOADER_COLOR)
      .then((res) => {
        if (res) {
          return updateClient({
            docId: id,
            project_id: project_id,
            client_name: client_name,
            client_address: client_address,
            client_project_pdf_name: client_project_pdf_name,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Clients',
            action: 'Edited a Client',
          });
          enqueueSnackbar('Client Updated!', { variant: 'success' });
          this.handleHideEditClientView();
          return getAllClients();
        } else {
          enqueueSnackbar("Client wasn't updated!", { variant: 'error' });
          stopLoader();
        }
      })
      .then((res) => {
        stopLoader();
      });
  };

  handleShowEditClientView = () => {
    this.setState({
      edit: true,
    });
  };

  handleHideEditClientView = () => {
    this.setState({
      edit: false,
      project_id: '',
      client_name: '',
      client_address: '',
      client_project_pdf_name: '',
    });
  };

  handleAddClient = () => {
    const { saveClient, stopLoader, startLoader, getAllClients, enqueueSnackbar } = this.props;
    let { project_id, client_name, client_address, client_project_pdf_name } = this.state;

    startLoader(LOADER_COLOR)
      .then((res) => {
        if (res) {
          return saveClient({
            project_id: project_id,
            client_name: client_name,
            client_address: client_address,
            client_project_pdf_name: client_project_pdf_name,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Clients',
            action: 'Created a Client',
          });
          enqueueSnackbar('Client Saved!', { variant: 'success' });
          this.handleHideAddClient();
          return getAllClients();
        } else {
          enqueueSnackbar("Client wasn't saved!", { variant: 'error' });
          stopLoader();
        }
      })
      .then((res) => {
        stopLoader();
      });
  };

  handleShowAddClient = () => {
    this.setState({
      show_add_client: true,
    });
  };

  handleHideAddClient = () => {
    this.setState({
      show_add_client: false,
    });
  };

  handleAddClientChanges = (e, type) => {
    if (type === 'name') {
      this.setState({
        client_name: e.target.value,
      });
    } else if (type === 'address') {
      this.setState({
        client_address: JSON.stringify(e.target.value.split('\n')),
      });
    } else if (type === 'project_id') {
      this.setState({
        project_id: e.target.value,
      });
    } else if (type === 'project_name') {
      this.setState({
        client_project_pdf_name: e.target.value,
      });
    }
  };

  render() {
    const { roles, clients, projects, loading, no_clients_data } = this.props;
    let {
      edit,
      project_id,
      show_client,
      client_name,
      client_address,
      client_selected,
      show_add_client,
      client_project_pdf_name,
    } = this.state;
    let show_edit_button = hasPermission(roles, 'client', 'can_edit');
    let disabled_create_button =
      project_id === '' ||
      client_name === '' ||
      client_address === '' ||
      client_project_pdf_name === '';

    if (show_client === '' && !no_clients_data && clients && clients.length > 0) {
      client_selected = clients[0];
    } else if (show_client !== '' && !no_clients_data && clients) {
      client_selected = clients.filter((client) => client.docId === show_client)[0];
    }

    if (clients && client_selected && project_id === '' && edit) {
      project_id = client_selected.project_id;
    }

    if (clients && client_selected && client_name === '' && edit) {
      client_name = client_selected.client_name;
    }

    if (clients && client_selected && client_address === '' && edit) {
      client_address = client_selected.client_address;
    }

    if (clients && client_selected && client_project_pdf_name === '' && edit) {
      client_project_pdf_name = client_selected.client_project_pdf_name;
    }

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div className="breadcrumb-link" style={style.HeaderText}>
            Clients
          </div>
          {hasPermission(roles, 'client', 'can_create') ? (
            <button style={style.FilledButton} onClick={this.handleShowAddClient}>
              + Add Client
            </button>
          ) : null}
        </Toolbar>
        <div style={loading || no_clients_data ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#d43a26" height={50} width={50} />
          ) : no_clients_data ? (
            <EmptyContainer />
          ) : (
            <>
              <ClientListView
                clients={clients}
                client_selected={client_selected}
                handleClientClick={this.handleClientClick}
              />
              <div style={style.ListViewContainer}>
                <ClientDetailView
                  edit={edit}
                  projects={projects}
                  project_id={project_id}
                  client={client_selected}
                  client_name={client_name}
                  client_address={client_address}
                  show_edit_button={show_edit_button}
                  handleEditClient={this.handleEditClient}
                  client_project_pdf_name={client_project_pdf_name}
                  handleAddClientChanges={this.handleAddClientChanges}
                  handleShowEditClientView={this.handleShowEditClientView}
                  handleHideEditClientView={this.handleHideEditClientView}
                />
              </div>
            </>
          )}
          <CreateDialogBox
            projects={projects}
            project_id={project_id}
            client_name={client_name}
            client_address={client_address}
            show_add_client={show_add_client}
            handleAddClient={this.handleAddClient}
            handleHideAddClient={this.handleHideAddClient}
            handleShowAddClient={this.handleShowAddClient}
            disabled_create_button={disabled_create_button}
            client_project_pdf_name={client_project_pdf_name}
            handleAddClientChanges={this.handleAddClientChanges}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
    loading: state.LoaderReducer.loading,
    clients: state.ClientReducer.clients,
    projects: state.ProjectReducer.projects,
    no_clients_data: state.ClientReducer.no_clients_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  saveClient: (data) => dispatch(ActionsCreator.saveClient(data)),
  getAllClients: () => dispatch(ActionsCreator.getAllClients()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  updateClient: (data) => dispatch(ActionsCreator.updateClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Client));
