import React from 'react';

import Radium from 'radium';

import { style } from './style';
import { List, ListItem } from '@mui/material';

const MyComponent = (props) => (
  <>
    <List style={style.ListView}>
      <ListItem
        button
        onClick={() => props.handleTabName('summary')}
        style={
          props.selected_tab === 'summary' ? style.SelectedProjectItem : style.UnselectedProjectItem
        }>
        <span
          style={
            props.selected_tab === 'summary'
              ? style.SelectedProjectText
              : style.UnselectedProjectText
          }>
          Summary
        </span>
      </ListItem>

      <ListItem
        button
        onClick={() => props.handleTabName('payments')}
        style={
          props.selected_tab === 'payments'
            ? style.SelectedProjectItem
            : style.UnselectedProjectItem
        }>
        <span
          style={
            props.selected_tab === 'payments'
              ? style.SelectedProjectText
              : style.UnselectedProjectText
          }>
          Payments
        </span>
      </ListItem>

      <ListItem
        button
        onClick={() => props.handleTabName('invoices')}
        style={
          props.selected_tab === 'invoices'
            ? style.SelectedProjectItem
            : style.UnselectedProjectItem
        }>
        <span
          style={
            props.selected_tab === 'invoices'
              ? style.SelectedProjectText
              : style.UnselectedProjectText
          }>
          Invoices
        </span>
      </ListItem>
    </List>
  </>
);

export const LedgerMenu = Radium(MyComponent);
