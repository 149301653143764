import React, { Component } from 'react';

import HoursManagementCustom from '../../components/HoursManagementCustom';
import './HoursManagementPage.css';

class HoursManagementPage extends Component {
  render() {
    return (
      <div className="hoursmanagementpage-main">
        <HoursManagementCustom />
      </div>
    );
  }
}

export default HoursManagementPage;
