import React from 'react';

import Radium from 'radium';

import { style } from './style';
import { InvoiceState } from '../../constants/invoicestate';

export const MyComponent = (props) => (
  <div style={style.SearchFieldsContainer}>
    <div style={style.Row}>
      <div style={style.RowItems1}>
        <select
          key={2}
          name="Status"
          value={props.selected_status}
          style={style.ProjectSelect}
          onChange={props.handleChangeSelectedStatus}>
          <option value="">All Status</option>
          {Object.keys(InvoiceState).map((item, index) => {
            return <option key={index}>{item}</option>;
          })}
        </select>
      </div>
      <div style={style.RowItems1}>
        <select
          key={4}
          name="Project"
          value={props.selected_project}
          style={style.ProjectSelect}
          onChange={props.handleChangeSelectedProject}>
          <option value="">All Projects</option>
          {props.projects.map((item, index) => {
            return <option key={index}>{item}</option>;
          })}
        </select>
      </div>
      <div style={style.RowItems1}>
        <input
          key={3}
          type="text"
          value={props.selected_invoice_id}
          style={style.DateInput}
          placeholder="Invoice ID"
          onChange={props.handleChangeSearchInvoiceID}
        />
      </div>
    </div>
    <div style={style.Row}>
      <div style={style.RowItems1}>
        <input
          key={0}
          type="date"
          style={style.DateInput}
          value={props.selected_from_date}
          placeholder="From"
          onChange={props.handleChangeFromDate}
        />
      </div>
      <div style={style.RowItems1}>
        <input
          key={1}
          type="date"
          value={props.selected_to_date}
          style={style.DateInput}
          placeholder="To"
          onChange={props.handleChangeToDate}
        />
      </div>
      <div style={style.RowItems1}>
        <input
          key={5}
          type="date"
          style={style.DateInput}
          value={props.selected_issued_from_date}
          placeholder="Issued From"
          onChange={props.handleChangeIssuedFromDate}
        />
      </div>
      <div style={style.RowItems1}>
        <input
          key={6}
          type="date"
          value={props.selected_issued_to_date}
          style={style.DateInput}
          placeholder="Issued To"
          onChange={props.handleChangeIssuedToDate}
        />
      </div>

      <div style={style.RowItems1}>
        <button style={style.BorderedButton} onClick={props.handleClearFilters}>
          Clear
        </button>
      </div>
    </div>
  </div>
);

export const InvoicesSearchFields = Radium(MyComponent);
