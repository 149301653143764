import * as HoursManagementActions from './HoursManagementActions';
import * as GenerateEmailActions from './GenerateEmailActions';
import * as ResourceActions from './ResourceActions';
import * as ReceiverActions from './ReceiverActions';
import * as ProjectActions from './ProjectActions';
import * as InvoiceActions from './InvoiceActions';
import * as BillingActions from './BillingActions';
import * as LoaderActions from './LoaderActions';
import * as ClientActions from './ClientActions';
import * as LoginActions from './LoginActions';
import * as TeamActions from './TeamActions';
import * as RoleActions from './RoleActions';
import * as PaymentActions from './PaymentActions';
import * as LedgerActions from './LedgerActions';
import * as HoursActions from './HoursActions';
import * as SettingActions from './SettingActions';
import * as BookingActions from './BookingActions';
import * as ReportActions from './ReportActions';

export const ActionsCreator = Object.assign(
  {},
  HoursManagementActions,
  GenerateEmailActions,
  ResourceActions,
  ReceiverActions,
  ProjectActions,
  InvoiceActions,
  BillingActions,
  LoaderActions,
  ClientActions,
  LoginActions,
  TeamActions,
  RoleActions,
  PaymentActions,
  LedgerActions,
  HoursActions,
  SettingActions,
  BookingActions,
  ReportActions,
);
