import React, { Component } from 'react';
import { connect } from 'react-redux';
import { style } from '../style';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AvTimer from '@mui/icons-material/AvTimer';
import { ActionsCreator } from '../../../Redux/Actions/index';
import InvoiceReport from '../InvoiceReport';
import ProjectReport from '../HourLogging';
import { withSnackbar } from 'notistack';
import Radium from 'radium';

class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_view: null,
    };
  }
  componentDidMount() {
    this.props.stopLoader();
  }

  setSelectedView = (value) => {
    this.setState({
      selected_view: value,
    });
  };
  goBack = () => {
    this.setState({
      selected_view: null,
    });
  };

  renderReportType = () => {
    switch (this.state.selected_view) {
      case 'hour_logging':
        return <ProjectReport goBack={this.goBack} />;
      case 'invoice':
        return <InvoiceReport goBack={this.goBack} />;
      default:
        return <div>No Default Report</div>;
    }
  };

  render() {
    if (this.state.selected_view) {
      return this.renderReportType();
    }
    return (
      <div style={style.ReportsListContainer}>
        <div key="reportsListBody-key" style={style.ReportsListBox}>
          <div
            key="hourlogging-key"
            onClick={() => this.setSelectedView('hour_logging')}
            style={style.ReportsListItem}>
            <ReceiptIcon style={style.ItemIcon} /> <h3>Hours Logging</h3>
          </div>
          <div
            key="avtimer-key"
            onClick={() => this.setSelectedView('invoice')}
            style={style.ReportsListItem}>
            <AvTimer style={style.ItemIcon} /> <h3>Invoice</h3>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Radium(ReportsList)));
