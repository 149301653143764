import React from 'react';
import Select from 'react-select';

import Radium from 'radium';

import { style } from './style';
import { Delete } from '@mui/icons-material';

const MyComponent = (props) => {
  const handleChange = (selectedOption) => {
    props.handleChange(
      [...selectedOption.map((item) => ({ email: item.value, name: item.label }))],
      'team_manager',
    );
  };

  return (
    <div style={style.Container}>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Team ID</label>
          <p style={style.Value}>{props.team.docId}</p>
        </div>

        <div style={style.LabelValueCol}>
          <label style={style.Label}>Team</label>
          <p style={style.Value}>{props.team.name}</p>
        </div>

        <div style={style.LabelValueCol}>
          <label style={style.Label}>Display Name</label>
          {props.edit ? (
            <>
              <input
                style={style.Value}
                key={1}
                name="edit_display_name"
                type="text"
                defaultValue={props.edit_display_name}
                onChange={props.handleTeamDisplayNameInput}
              />
            </>
          ) : (
            <>
              <p style={style.Value}>{props.team.display_name}</p>
            </>
          )}
          {props.edit && props.disable_edit_button ? (
            <>
              <p style={style.InputErrorLabel}>use space and special characters between words</p>
            </>
          ) : null}
        </div>

        <div style={style.LabelValueCol}>
          <label style={style.Label}>Status</label>
          {props.edit ? (
            <select
              key={1}
              name="team_status"
              value={props.selected_team_status}
              style={style.TeamSelect}
              onChange={(event) => props.handleChange(event, 'team_status')}>
              <option value={''} disabled>
                Select Team Status
              </option>
              <option value={true}>Active</option>
              <option value={false}>InActive</option>
            </select>
          ) : (
            <p style={style.Value}>{props.team.active ? 'Active' : 'InActive'}</p>
          )}
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Team Manager</label>
          {props.edit ? (
            <Select
              value={
                props.selected_team_manager
                  ? [
                      ...props.selected_team_manager.map((item) => ({
                        value: item.email,
                        label: item.name,
                      })),
                    ]
                  : []
              }
              onChange={handleChange}
              options={[
                ...props.resources
                  .filter((resource) => resource.active == true)
                  .map((item) => {
                    return { value: item.email, label: item.name };
                  }),
              ]}
              isMulti
            />
          ) : (
            <p style={style.Value}>
              {props.team.team_managers
                ? [...props.team.team_managers.map((item) => item.name)].join(', ')
                : 'NOT ASSIGNED'}
            </p>
          )}
        </div>
        <div style={style.LabelValueCol}></div>
        <div style={style.LabelValueCol}></div>
      </div>

      <div style={style.Row}>
        {!props.edit && props.show_edit_button ? (
          <div style={style.ButtonContainer}>
            <button style={style.BorderedButton} onClick={props.handleShowEditTeamView}>
              Edit Team
            </button>
          </div>
        ) : null}
        {props.edit && props.show_edit_button ? (
          <div style={style.ButtonContainer}>
            <button
              style={props.disable_edit_button ? style.GreyBorderedButton : style.BorderedButton}
              onClick={() => props.handleEditTeam(props.team)}
              disabled={props.disable_edit_button}>
              Save Edit
            </button>
            <button style={style.PinkBorderedButton} onClick={props.handleHideEditTeamView}>
              Cancel Edit
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const TeamDetailView = Radium(MyComponent);
