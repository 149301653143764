import React, { Component } from 'react';

import Invoice from '../../components/Invoice';
import './InvoicesPage.css';

class InvoicesPage extends Component {
  render() {
    return (
      <div className="invoicepage-main">
        <Invoice />
      </div>
    );
  }
}

export default InvoicesPage;
