import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const setParsedData = (file, total_hours) => {
  return (dispatch) =>
    new Promise((resolve) => {
      let data = {
        total_hours: total_hours,
        csvdata: file,
      };

      dispatch({
        type: Types.SET_PARSED_CSV_DATA_REQUEST,
      });

      API.uploadFileRequest('post', EndPoints.SetCSVFileData, data)
        .then((res) => {
          resolve(true);
          dispatch({
            payload: res.data,
            type: Types.SET_PARSED_CSV_DATA_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            payload: err.response ? err.response.data : '',
            type: Types.SET_PARSED_CSV_DATA_FAILURE,
          });
        });
    });
};

export const getParsedData = (data) => {
  let month = '';
  if (data !== undefined) month = '&month=' + data;
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.PARSED_CSV_DATA_REQUEST,
      });
      API.request('get', EndPoints.GetCSVParsedData + '?parsed=true' + month)
        .then((res) => {
          if (res.data && res.data.start_date) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.PARSED_CSV_DATA_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.PARSED_CSV_DATA_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.PARSED_CSV_DATA_FAILURE,
          });
        });
    });
};

export const setProjectInHeader = (project) => {
  return {
    payload: {
      project: project,
      show_project_invoice: true,
    },
    type: Types.SET_PROJECT_IN_HEADER_SUCCESS,
  };
};

export const showDetailInvoice = (project) => {
  return {
    payload: {
      project: project,
      show_project_invoice: true,
    },
    type: Types.SET_SHOW_DETAIL_INVOICE_PROJECT,
  };
};

export const resetShowDetailInvoice = () => {
  return {
    type: Types.SET_SHOW_DETAIL_INVOICE_PROJECT_NULL,
  };
};

export const resetParsedCSV = () => {
  return {
    type: Types.SET_PARSED_CSV_DATA_NULL,
  };
};

export const resetRates = () => {
  return {
    type: Types.SET_RATES_NULL,
  };
};

export const saveHours = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.SAVE_HOURS_REQUEST,
      });

      API.request('post', EndPoints.SaveHours)
        .then((res) => {
          resolve(res.data);
          dispatch({
            payload: res.data,
            type: Types.SAVE_HOURS_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SAVE_HOURS_FAILURE,
          });
        });
    });
};

export const getHoursRange = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_HOURS_RANGE_REQUEST,
      });

      API.request('get', EndPoints.HoursRange)
        .then((res) => {
          resolve(true);
          dispatch({
            payload: res.data,
            type: Types.GET_HOURS_RANGE_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_HOURS_RANGE_FAILURE,
          });
        });
    });
};

export const getEntriesHours = (start, end) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_TIME_ENTRIES_HOURS_REQUEST,
      });
      API.request('get', EndPoints.FetchCustomTimeEntries + '?start=' + start + '&end=' + end)
        .then((res) => {
          if (res.data) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.GET_TIME_ENTRIES_HOURS_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.GET_TIME_ENTRIES_HOURS_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_TIME_ENTRIES_HOURS_FAILURE,
          });
        });
    });
};

export const getLastLocked = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_LAST_LOCKED_REQUEST,
      });
      API.request('get', EndPoints.GetLastLockedDate)
        .then((res) => {
          if (res.data) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.GET_LAST_LOCKED_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.GET_LAST_LOCKED_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_LAST_LOCKED_FAILURE,
          });
        });
    });
};

export const setLastLocked = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.SET_LAST_LOCKED_REQUEST,
      });
      API.request('post', EndPoints.SetLastLockedDate, data)
        .then((res) => {
          if (res.data) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.SET_LAST_LOCKED_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.SET_LAST_LOCKED_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SET_LAST_LOCKED_FAILURE,
          });
        });
    });
};
