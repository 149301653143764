export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-light-pink)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  FlexCol: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  BodyWithFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 204px)',
  },
  BodyWithoutFilters: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 154px)',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 184px)',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCellP: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0px',
  },
  TableCell1: {
    display: 'flex',
    flex: 3,
    alignItems: 'center',
    padding: '0px',
  },
  TextWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  TableCell2: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  TableCell3: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0px',
    paddingLeft: '5px',
  },
  TableCellH1: {
    display: 'flex',
    flex: 3,
    alignItems: 'center',
    padding: '0px',
    paddingLeft: '5px',
  },
  TableContainer: {
    flex: 1,
    width: '100%',
    padding: '0px 20px',
  },
  SearchFieldsContainer: {
    height: '50px',
  },
  ShowFiltersButtonContainer: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  ShowFiltersButton: {
    padding: '5px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-grey)',
    textDecoration: 'underline',
  },
  DetailPageContainer: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
  },
};
