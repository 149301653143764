export const PARSED_CSV_DATA_REQUEST = 'PARSED_CSV_DATA_REQUEST';
export const PARSED_CSV_DATA_SUCCESS = 'PARSED_CSV_DATA_SUCCESS';
export const PARSED_CSV_DATA_FAILURE = 'PARSED_CSV_DATA_FAILURE';

export const PARSED_CSV_DATA_BETWEEN_DATES_REQUEST = 'PARSED_CSV_DATA_BETWEEN_DATES_REQUEST';
export const PARSED_CSV_DATA_BETWEEN_DATES_SUCCESS = 'PARSED_CSV_DATA_BETWEEN_DATES_SUCCESS';
export const PARSED_CSV_DATA_BETWEEN_DATES_FAILURE = 'PARSED_CSV_DATA_BETWEEN_DATES_FAILURE';

export const HOURS_BETWEEN_DATES_REQUEST = 'HOURS_BETWEEN_DATES_REQUEST';
export const HOURS_BETWEEN_DATES_SUCCESS = 'HOURS_BETWEEN_DATES_SUCCESS';
export const HOURS_BETWEEN_DATES_FAILURE = 'HOURS_BETWEEN_DATES_FAILURE';

export const SET_PARSED_CSV_DATA_REQUEST = 'SET_PARSED_CSV_DATA_REQUEST';
export const SET_PARSED_CSV_DATA_SUCCESS = 'SET_PARSED_CSV_DATA_SUCCESS';
export const SET_PARSED_CSV_DATA_FAILURE = 'SET_PARSED_CSV_DATA_FAILURE';

export const SAVE_HOURS_REQUEST = 'SAVE_HOURS_REQUEST';
export const SAVE_HOURS_SUCCESS = 'SAVE_HOURS_SUCCESS';
export const SAVE_HOURS_FAILURE = 'SAVE_HOURS_FAILURE';

export const SET_BILLABLE_PROJECT_INVOICES_REQUEST = 'SET_BILLABLE_PROJECT_INVOICES_REQUEST';
export const SET_BILLABLE_PROJECT_INVOICES_SUCCESS = 'SET_BILLABLE_PROJECT_INVOICES_SUCCESS';
export const SET_BILLABLE_PROJECT_INVOICES_FAILURE = 'SET_BILLABLE_PROJECT_INVOICES_FAILURE';

export const GET_EMPLOYEE_RATES_REQUEST = 'GET_EMPLOYEE_RATES_REQUEST';
export const GET_EMPLOYEE_RATES_SUCCESS = 'GET_EMPLOYEE_RATES_SUCCESS';
export const GET_EMPLOYEE_RATES_FAILURE = 'GET_EMPLOYEE_RATES_FAILURE';

export const LOGIN_CODE_EXCHANGE_REQUEST = 'LOGIN_CODE_EXCHANGE_REQUEST';
export const LOGIN_CODE_EXCHANGE_SUCCESS = 'LOGIN_CODE_EXCHANGE_SUCCESS';
export const LOGIN_CODE_EXCHANGE_FAILURE = 'LOGIN_CODE_EXCHANGE_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const LOGGEDIN_CHECK_REQUEST = 'LOGGEDIN_CHECK_REQUEST';
export const LOGGEDIN_CHECK_SUCCESS = 'LOGGEDIN_CHECK_SUCCESS';
export const LOGGEDIN_CHECK_FAILURE = 'LOGGEDIN_CHECK_FAILURE';

export const GENERATE_REMINDER_EMAIL_REQUEST = 'GENERATE_REMINDER_EMAIL_REQUEST';
export const GENERATE_REMINDER_EMAIL_SUCCESS = 'GENERATE_REMINDER_EMAIL_SUCCESS';
export const GENERATE_REMINDER_EMAIL_FAILURE = 'GENERATE_REMINDER_EMAIL_FAILURE';

export const GENERATE_INVOICES_REMINDER_EMAIL_REQUEST = 'GENERATE_INVOICES_REMINDER_EMAIL_REQUEST';
export const GENERATE_INVOICES_REMINDER_EMAIL_SUCCESS = 'GENERATE_INVOICES_REMINDER_EMAIL_SUCCESS';
export const GENERATE_INVOICES_REMINDER_EMAIL_FAILURE = 'GENERATE_INVOICES_REMINDER_EMAIL_FAILURE';

export const PREPARE_INVOICE_REQUEST = 'PREPARE_INVOICE_REQUEST';
export const PREPARE_INVOICE_SUCCESS = 'PREPARE_INVOICE_SUCCESS';
export const PREPARE_INVOICE_ERROR = 'PREPARE_INVOICE_ERROR';
export const PREPARE_INVOICE_FAILURE = 'PREPARE_INVOICE_FAILURE';

export const SAVE_INVOICE_REQUEST = 'SAVE_INVOICE_REQUEST';
export const SAVE_INVOICE_SUCCESS = 'SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_FAILURE = 'SAVE_INVOICE_FAILURE';

export const DELETE_INVOICE_REQUEST = 'DELETE_RECEIVER_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_RECEIVER_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_RECEIVER_FAILURE';

export const DOWNLOAD_INVOICE_REQUEST = 'DOWNLOAD_INVOICE_REQUEST';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAILURE = 'DOWNLOAD_INVOICE_FAILURE';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const GET_SINGLE_INVOICE_REQUEST = 'GET_SINGLE_INVOICE_REQUEST';
export const GET_SINGLE_INVOICE_SUCCESS = 'GET_SINGLE_INVOICE_SUCCESS';
export const GET_SINGLE_INVOICE_FAILURE = 'GET_SINGLE_INVOICE_FAILURE';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';

export const UPDATE_INVOICE_DATES_REQUEST = 'UPDATE_INVOICE_DATES_REQUEST';
export const UPDATE_INVOICE_DATES_SUCCESS = 'UPDATE_INVOICE_DATES_SUCCESS';
export const UPDATE_INVOICE_DATES_FAILURE = 'UPDATE_INVOICE_DATES_FAILURE';

export const GET_RATES_REQUEST = 'GET_RATES_REQUEST';
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS';
export const GET_RATES_FAILURE = 'GET_RATES_FAILURE';
export const GET_NEW_RATES_REQUEST = 'GET_NEW_RATES_REQUEST';
export const GET_NEW_RATES_SUCCESS = 'GET_NEW_RATES_SUCCESS';
export const GET_NEW_RATES_FAILURE = 'GET_NEW_RATES_FAILURE';

export const DELETE_RATE_REQUEST = 'DELETE_RATE_REQUEST';
export const DELETE_RATE_SUCCESS = 'DELETE_RATE_SUCCESS';
export const DELETE_RATE_FAILURE = 'DELETE_RATE_FAILURE';

export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const UPDATE_PROJECT_CONFIG_REQUEST = 'UPDATE_PROJECT_CONFIG_REQUEST';
export const UPDATE_PROJECT_CONFIG_SUCCESS = 'UPDATE_PROJECT_CONFIG_SUCCESS';
export const UPDATE_PROJECT_CONFIG_FAILURE = 'UPDATE_PROJECT_CONFIG_FAILURE';

export const UPDATE_RESOURCE_CONFIG_REQUEST = 'UPDATE_RESOURCE_CONFIG_REQUEST';
export const UPDATE_RESOURCE_CONFIG_SUCCESS = 'UPDATE_RESOURCE_CONFIG_SUCCESS';
export const UPDATE_RESOURCE_CONFIG_FAILURE = 'UPDATE_RESOURCE_CONFIG_FAILURE';

export const ADD_RESOURCE_REQUEST = 'ADD_RESOURCE_REQUEST';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const ADD_RESOURCE_FAILURE = 'ADD_RESOURCE_FAILURE';

export const GET_PROJECT_HOURS_REQUEST = 'GET_PROJECT_HOURS_REQUEST';
export const GET_PROJECT_HOURS_SUCCESS = 'GET_PROJECT_HOURS_SUCCESS';
export const GET_PROJECT_HOURS_FAILURE = 'GET_PROJECT_HOURS_FAILURE';

export const GET_UNBILLABLE_PROJECTS_REQUEST = 'GET_UNBILLABLE_PROJECTS_REQUEST';
export const GET_UNBILLABLE_PROJECTS_SUCCESS = 'GET_UNBILLABLE_PROJECTS_SUCCESS';
export const GET_UNBILLABLE_PROJECTS_FAILURE = 'GET_UNBILLABLE_PROJECTS_FAILURE';

export const SET_RESOURCE_RATE_REQUEST = 'SET_RESOURCE_RATE_REQUEST';
export const SET_RESOURCE_RATE_SUCCESS = 'SET_RESOURCE_RATE_SUCCESS';
export const SET_RESOURCE_RATE_FAILURE = 'SET_RESOURCE_RATE_FAILURE';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';

export const SAVE_CLIENT_REQUEST = 'SAVE_CLIENT_REQUEST';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAILURE = 'SAVE_CLIENT_FAILURE';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const SAVE_RECEIVER_REQUEST = 'SAVE_RECEIVER_REQUEST';
export const SAVE_RECEIVER_SUCCESS = 'SAVE_RECEIVER_SUCCESS';
export const SAVE_RECEIVER_FAILURE = 'SAVE_RECEIVER_FAILURE';

export const GET_RECEIVERS_REQUEST = 'GET_RECEIVERS_REQUEST';
export const GET_RECEIVERS_SUCCESS = 'GET_RECEIVERS_SUCCESS';
export const GET_RECEIVERS_FAILURE = 'GET_RECEIVERS_FAILURE';

export const UPDATE_RECEIVER_REQUEST = 'UPDATE_RECEIVER_REQUEST';
export const UPDATE_RECEIVER_SUCCESS = 'UPDATE_RECEIVER_SUCCESS';
export const UPDATE_RECEIVER_FAILURE = 'UPDATE_RECEIVER_FAILURE';

export const DELETE_RECEIVER_REQUEST = 'DELETE_RECEIVER_REQUEST';
export const DELETE_RECEIVER_SUCCESS = 'DELETE_RECEIVER_SUCCESS';
export const DELETE_RECEIVER_FAILURE = 'DELETE_RECEIVER_FAILURE';

export const GET_HOURS_RANGE_REQUEST = 'GET_HOURS_RANGE_REQUEST';
export const GET_HOURS_RANGE_SUCCESS = 'GET_HOURS_RANGE_SUCCESS';
export const GET_HOURS_RANGE_FAILURE = 'GET_HOURS_RANGE_FAILURE';

export const SET_SHOW_DETAIL_INVOICE_PROJECT = 'SET_SHOW_DETAIL_INVOICE_PROJECT';
export const SET_SHOW_DETAIL_INVOICE_PROJECT_NULL = 'SET_SHOW_DETAIL_INVOICE_PROJECT_NULL';

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const FORCE_STOP_LOADER = 'FORCE_STOP_LOADER';

export const SET_PROJECT_IN_HEADER_SUCCESS = 'SET_PROJECT_IN_HEADER_SUCCESS';

export const SET_PARSED_CSV_DATA_NULL = 'SET_PARSED_CSV_DATA_NULL';

export const SET_SHOW_INVOICE = 'SET_SHOW_INVOICE';
export const RESET_SHOW_INVOICE = 'RESET_SHOW_INVOICE';
export const RESET_SHOW_INVOICE_DATA = 'RESET_SHOW_INVOICE_DATA';
export const CHANGE_INVOICE_DATA = 'CHANGE_INVOICE_DATA';

export const SET_INVOICE_DETAIL_DATA = 'SET_INVOICE_DETAIL_DATA';

export const RESET_PREPARE_INVOICE = 'RESET_PREPARE_INVOICE';

export const SET_RATES_NULL = 'SET_RATES_NULL';

export const FETCH_BILLING_REQUEST = 'FETCH_BILLING_REQUEST';
export const FETCH_BILLING_SUCCESS = 'FETCH_BILLING_SUCCESS';
export const FETCH_BILLING_FAILURE = 'FETCH_BILLING_FAILURE';

export const FETCH_ROLE_REQUEST = 'FETCH_ROLE_REQUEST';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_FAILURE = 'FETCH_ROLE_FAILURE';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const EDIT_ROLE_REQUEST = 'EDIT_ROLE_REQUEST';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';

export const SET_DASHBOARD_PROJECT_REQUEST = 'SET_DASHBOARD_PROJECT_REQUEST';
export const SET_DASHBOARD_PROJECT_SUCCESS = 'SET_DASHBOARD_PROJECT_SUCCESS';
export const SET_DASHBOARD_PROJECT_FAILURE = 'SET_DASHBOARD_PROJECT_FAILURE';

export const SAVE_PAYMENT_REQUEST = 'SAVE_PAYMENT_REQUEST';
export const SAVE_PAYMENT_SUCCESS = 'SAVE_PAYMENT_SUCCESS';
export const SAVE_PAYMENT_FAILURE = 'SAVE_PAYMENT_FAILURE';

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';

export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';

export const GET_LEDGERS_REQUEST = 'GET_LEDGERS_REQUEST';
export const GET_LEDGERS_SUCCESS = 'GET_LEDGERS_SUCCESS';
export const GET_LEDGERS_FAILURE = 'GET_LEDGERS_FAILURE';

export const GET_HOURS_WEEK_REQUEST = 'GET_HOURS_WEEK_REQUEST';
export const GET_HOURS_WEEK_SUCCESS = 'GET_HOURS_WEEK_SUCCESS';
export const GET_HOURS_WEEK_FAILURE = 'GET_HOURS_WEEK_FAILURE';

export const ADD_CUSTOM_TIME_ENTRY_REQUEST = 'ADD_CUSTOM_TIME_ENTRY_REQUEST';
export const ADD_CUSTOM_TIME_ENTRY_SUCCESS = 'ADD_CUSTOM_TIME_ENTRY_SUCCESS';
export const ADD_CUSTOM_TIME_ENTRY_FAILURE = 'ADD_CUSTOM_TIME_ENTRY_FAILURE';

export const GET_TIME_ENTRIES_HOURS_REQUEST = 'GET_TIME_ENTRIES_HOURS_REQUEST';
export const GET_TIME_ENTRIES_HOURS_SUCCESS = 'GET_TIME_ENTRIES_HOURS_SUCCESS';
export const GET_TIME_ENTRIES_HOURS_FAILURE = 'GET_TIME_ENTRIES_HOURS_FAILURE';

export const DELETE_CUSTOM_TIME_ENTRY_REQUEST = 'DELETE_CUSTOM_TIME_ENTRY_REQUEST';
export const DELETE_CUSTOM_TIME_ENTRY_SUCCESS = 'DELETE_CUSTOM_TIME_ENTRY_SUCCESS';
export const DELETE_CUSTOM_TIME_ENTRY_FAILURE = 'DELETE_CUSTOM_TIME_ENTRY_FAILURE';

export const UPDATE_CUSTOM_TIME_ENTRY_REQUEST = 'UPDATE_CUSTOM_TIME_ENTRY_REQUEST';
export const UPDATE_CUSTOM_TIME_ENTRY_SUCCESS = 'UPDATE_CUSTOM_TIME_ENTRY_SUCCESS';
export const UPDATE_CUSTOM_TIME_ENTRY_FAILURE = 'UPDATE_CUSTOM_TIME_ENTRY_FAILURE';

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';

export const ADD_BOOKING_REQUEST = 'ADD_BOOKING_REQUEST';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';

export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILURE = 'DELETE_BOOKING_FAILURE';

export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILURE = 'UPDATE_BOOKING_FAILURE';

export const GET_LAST_LOCKED_REQUEST = 'GET_LAST_LOCKED_REQUEST';
export const GET_LAST_LOCKED_SUCCESS = 'GET_LAST_LOCKED_SUCCESS';
export const GET_LAST_LOCKED_FAILURE = 'GET_LAST_LOCKED_FAILURE';

export const SET_LAST_LOCKED_REQUEST = 'SET_LAST_LOCKED_REQUEST';
export const SET_LAST_LOCKED_SUCCESS = 'SET_LAST_LOCKED_SUCCESS';
export const SET_LAST_LOCKED_FAILURE = 'SET_LAST_LOCKED_FAILURE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE';

export const ADD_HOLIDAYS_REQUEST = 'ADD_HOLIDAYS_REQUEST';
export const ADD_HOLIDAYS_SUCCESS = 'ADD_HOLIDAYS_SUCCESS';
export const ADD_HOLIDAYS_FAILURE = 'ADD_HOLIDAYS_FAILURE';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';

export const UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE';

export const FETCH_REPORTENTRIES_REQUEST = 'FETCH_REPORTENTRIES_REQUEST';
export const FETCH_REPORTENTRIES_SUCCESS = 'FETCH_REPORTENTRIES_SUCCESS';
export const FETCH_REPORTENTRIES_FAILURE = 'FETCH_REPORTENTRIES_FAILURE';

export const UPDATE_PMS_REQUEST = 'UPDATE_PMS_REQUEST';
export const UPDATE_PMS_SUCCESS = 'UPDATE_PMS_SUCCESS';
export const UPDATE_PMS_FAILURE = 'UPDATE_PMS_FAILURE';

export const SET_AUTO_FILL_DATA = 'SET_AUTO_FILL_DATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_RESOURCE_DETAILS = 'SET_RESOURCE_DETAILS';
