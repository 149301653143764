import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';
import { List, Toolbar, ListItem } from '@mui/material';

import { style } from './style';
import { DialogBoxes } from './DialogBoxes';
import { hasPermission } from '../../constants/ability';
import { TeamDetailView } from './TeamDetailView';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      show_team: '',
      team_name: '',
      display_name: '',
      edit_display_name: '',
      team_status: true, // means active
      show_add_team: false,
      selected_team_status: '',
      selected_team_manager: null,
      show_confirmation_popup: false,
      disable_edit_button: false,
    };
  }

  componentDidMount() {
    this.loadTeamSectionData();
  }

  loadTeamSectionData = async () => {
    const { stopLoader, resources, getResources, getTeams } = this.props;

    await getResources('?role=Team Manager');
    getTeams().then((response) => {
      stopLoader();
    });
  };

  handleTeamClick = (team) => {
    if (this.state.edit) {
      this.setState({
        team_name: team,
        show_confirmation_popup: true,
      });
    } else {
      this.setState({
        show_team: team,
        edit: false,
        selected_team_status: '',
        selected_team_manager: null,
        team_status: true,
        display_name: '',
        edit_display_name: '',
      });
    }
  };

  handleTeamEditing = (team) => {
    this.setState({
      show_team: team,
      edit: false,
      selected_team_status: '',
      selected_team_manager: null,
      team_status: true,
      display_name: '',
      edit_display_name: '',
    });
  };
  onContinue = () => {
    this.setState({ show_confirmation_popup: false });
  };

  onCancel = (team) => {
    this.setState({
      show_confirmation_popup: false,
    });
    this.handleTeamEditing(team);
  };

  validateTeamInput = () => {
    let { team_name, display_name } = this.state;
    const { enqueueSnackbar, teams } = this.props;
    // team name required
    if (!team_name || !display_name) {
      enqueueSnackbar('Team name not added', { variant: 'error' });
      return false; //
    }

    if (teams) {
      // check for unique team name
      if (teams.some((team) => team.name.toLowerCase() === team_name.toLowerCase())) {
        enqueueSnackbar('Team name must be unique', { variant: 'error' });
        return false;
      }

      // check for unique display team name
      if (teams.some((team) => team.display_name.toLowerCase() === display_name.toLowerCase())) {
        enqueueSnackbar('Display team name must be unique', { variant: 'error' });
        return false;
      }
    }
    return true;
  };

  validateTeamEdit = () => {
    let { edit_display_name } = this.state;

    const { enqueueSnackbar, teams } = this.props;

    if (!edit_display_name) {
      //return true incase display name isn't changed
      return true;
    }
    if (teams) {
      // check for unique display team name
      if (
        teams.some((team) => team.display_name.toLowerCase() === edit_display_name.toLowerCase())
      ) {
        enqueueSnackbar('Display team name must be unique', { variant: 'error' });
        return false;
      }
    }
    return true;
  };

  handleAddTeam = () => {
    let { team_name, team_status, display_name } = this.state;
    const { startLoader, stopLoader, enqueueSnackbar, getTeams, addTeam, teams } = this.props;

    if (team_name && display_name && this.validateTeamInput()) {
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            return addTeam({
              name: team_name,
              display_name: display_name,
              active: team_status,
              team_managers: [],
            });
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Team Added!', { variant: 'success' });
            return getTeams();
          } else {
            enqueueSnackbar("Team wasn't added!", { variant: 'error' });
            stopLoader();
          }
        })
        .then((res) => {
          this.handleHideAddTeam();
          stopLoader();
        });
    } else {
      enqueueSnackbar("Team wasn't added!", { variant: 'error' });
    }
  };

  handleTeamInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleTeamDisplayNameInput = (e) => {
    const pattern = /^[a-zA-Z0-9]+([ \t\-_&]*[a-zA-Z0-9]+)*$/;
    this.setState({
      [e.target.name]: e.target.value,
      disable_edit_button: pattern.test(e.target.value) && e.target.value !== '' ? false : true,
    });
  };

  handleShowAddTeam = () => {
    this.setState({
      show_add_team: true,
    });
  };

  handleHideAddTeam = () => {
    this.setState({
      show_add_team: false,
      team_name: '',
      team_status: true,
    });
  };

  handleHideEditTeamView = () => {
    this.setState({
      edit: false,
      selected_team_status: '',
      selected_team_manager: null,
      disable_edit_button: false,
    });
  };

  handleChange = (e, type) => {
    if (type === 'team_manager') {
      this.setState({
        selected_team_manager: e, // whole array is coming not event obj in this case
      });
    } else if (type === 'team_status') {
      this.setState({
        selected_team_status: e.target.value === 'true',
      });
    }
  };

  handleEditTeam = (team) => {
    const { startLoader, stopLoader, enqueueSnackbar, updateTeam, getTeams } = this.props;
    let { selected_team_status, selected_team_manager } = this.state;

    if (this.validateTeamEdit()) {
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            return updateTeam(
              {
                name: team.name,
                display_name: this.state.edit_display_name
                  ? this.state.edit_display_name
                  : team.display_name,
                active: selected_team_status === '' ? team.active : selected_team_status,
                team_managers: selected_team_manager
                  ? [...selected_team_manager.map((item) => item.email)]
                  : team.team_managers
                  ? [...team.team_managers.map((item) => item.email)]
                  : [],
              },
              team.docId,
            );
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Team Updated!', { variant: 'success' });
            this.setState({
              edit_display_name: null,
            });
            return getTeams();
          } else {
            enqueueSnackbar("Team wasn't updated!", { variant: 'error' });
            stopLoader();
          }
          this.handleHideEditTeamView();
        })
        .then((res) => {
          stopLoader();
          this.handleHideEditTeamView();
        });
    }
  };

  handleShowEditTeamView = () => {
    this.setState({
      edit: true,
    });
  };

  render() {
    let { roles, resources, receivers, teams, no_teams_data } = this.props;
    let {
      show_team,
      show_add_team,
      team_name,
      edit,
      selected_team_manager,
      selected_team_status,
      display_name,
      disable_edit_button,
    } = this.state;
    let selected_team_managers = [];
    let selected_team_display_name = '';
    let team_selected = {};
    const pattern = /^[a-zA-Z0-9]+([ \t\-_&]*[a-zA-Z0-9]+)*$/;
    let disabled_create_button =
      team_name === '' ||
      display_name === '' ||
      !pattern.test(team_name) ||
      !pattern.test(display_name);
    let show_edit_button = hasPermission(roles, 'team', 'can_edit');
    if (show_team === '' && !no_teams_data && teams && teams.length) {
      team_selected = teams[0];
    } else if (show_team !== '' && !no_teams_data && teams) {
      team_selected = teams.filter((team) => team.name === show_team)[0];
    }
    if (team_selected !== {} && selected_team_status === '') {
      selected_team_status = team_selected.active;
    }

    if (
      team_selected !== {} &&
      team_selected.team_managers !== '' &&
      selected_team_manager === ''
    ) {
      selected_team_manager = team_selected.team_managers;
    }

    if (team_selected.name) {
      selected_team_managers = selected_team_manager
        ? selected_team_manager
        : team_selected.team_managers;
    }
    if (team_selected.display_name) {
      selected_team_display_name = team_selected.display_name;
    }

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <p className="breadcrumb-link" style={style.HeaderText}>
            Teams
            <br />
          </p>
          {hasPermission(roles, 'team', 'can_create') ? (
            <button style={style.FilledButton} onClick={this.handleShowAddTeam}>
              + Add Team
            </button>
          ) : null}
        </Toolbar>
        <div style={!teams && !no_teams_data ? style.LoadingContainer : style.MainContainer}>
          {!teams ? (
            <Bars color="#d43a26" height={50} width={50} />
          ) : no_teams_data ? (
            <EmptyContainer />
          ) : (
            <>
              <List style={style.ListView}>
                {teams.map((team, index) => {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => this.handleTeamClick(team.name)}
                      style={
                        team_selected.name === team.name
                          ? style.SelectedTeamItem
                          : style.UnselectedTeamItem
                      }>
                      <span
                        style={
                          team_selected.name === team.name
                            ? style.SelectedTeamText
                            : style.UnselectedTeamText
                        }>
                        {team.name}
                      </span>
                    </ListItem>
                  );
                })}
              </List>
              <div style={style.ListViewContainer}>
                {team_selected !== {} ? (
                  <TeamDetailView
                    edit={edit}
                    resources={resources}
                    team={team_selected}
                    edit_display_name={selected_team_display_name}
                    handleChange={this.handleChange}
                    show_edit_button={show_edit_button}
                    handleEditTeam={this.handleEditTeam}
                    handleTeamDisplayNameInput={this.handleTeamDisplayNameInput}
                    selected_team_status={selected_team_status}
                    selected_team_manager={
                      typeof selected_team_managers === 'string'
                        ? [selected_team_managers]
                        : selected_team_managers
                    } // After integrating api make pass only array & remove this condition
                    handleShowEditTeamView={this.handleShowEditTeamView}
                    handleHideEditTeamView={this.handleHideEditTeamView}
                    disable_edit_button={disable_edit_button}
                  />
                ) : null}
              </div>
              <DialogBoxes
                team_name={team_name}
                display_name={display_name}
                show_add_team={show_add_team}
                handleAddTeam={this.handleAddTeam}
                handleTeamInput={this.handleTeamInput}
                handleTeamDisplayNameInput={this.handleTeamDisplayNameInput}
                disabled_create_button={disabled_create_button}
                handleHideAddTeam={this.handleHideAddTeam}
                show_confirmation_popup={this.state.show_confirmation_popup}
                onContinue={this.onContinue}
                onCancel={this.onCancel}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
    no_resource_data: state.ResourceReducer.no_data,
    resources: state.ResourceReducer.resources,
    loadingResources: state.ResourceReducer.loadingResources,
    teams: state.TeamReducer.teams,
    no_teams_data: state.TeamReducer.teams ? state.TeamReducer.teams.no_teams_data : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTeam: (team) => dispatch(ActionsCreator.addTeam(team)),
  updateTeam: (team, id) => dispatch(ActionsCreator.updateTeam(team, id)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getResources: (query) => dispatch(ActionsCreator.getResources(query)),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  getTeams: () => dispatch(ActionsCreator.getTeams()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Team));
