import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const saveClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_CLIENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.SaveClient, data)
        .then((res) => {
          dispatch({
            type: Types.SAVE_CLIENT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SAVE_CLIENT_FAILURE,
          });
        });
    });
  };
};

export const saveClientMultiple = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_CLIENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.SaveClientMultiple, data)
        .then((res) => {
          dispatch({
            type: Types.SAVE_CLIENT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.SAVE_CLIENT_FAILURE,
          });
        });
    });
  };
};

export const updateClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_CLIENT_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.UpdateClient, data)
        .then((res) => {
          dispatch({
            type: Types.UPDATE_CLIENT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_CLIENT_FAILURE,
          });
        });
    });
  };
};

export const getAllClients = () => {
  return (dispatch) => {
    dispatch({
      type: Types.GET_CLIENTS_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('get', EndPoints.GetClients)
        .then((res) => {
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.GET_CLIENTS_SUCCESS,
            });
            resolve(true);
          } else {
            dispatch({
              payload: null,
              type: Types.GET_CLIENTS_FAILURE,
            });
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            payload: null,
            type: Types.GET_CLIENTS_FAILURE,
          });
        });
    });
  };
};
