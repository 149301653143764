import React from 'react';
import { Route } from 'react-router-dom';

import { Pages } from './Pages';

export const AuthenticatedRoutes = (props) => (
  <>
    {Pages.map((page, index) => {
      return props.accessList.includes(page.name) ? (
        <Route exact key={index} path={`${props.baseURL}${page.path}`} component={page.component} />
      ) : null;
    })}
  </>
);
