import React from 'react';

import Moment from 'moment';
import { Toolbar } from '@mui/material';

import { style } from './style';
import { formatFloat } from '../../Selectors/index';
import { CustomDatePicker } from '../Utility/CustomDatePicker/index';

export const HourManagementHeader = (props) => (
  <Toolbar style={style.MainHeading}>
    <div style={style.HeaderText}>
      <b>Hours Management</b>
      <br />
      {props.start_date ? (
        <>
          {Moment(props.start_date).format('Do-MMM, YY') ===
          Moment(props.end_date).format('Do-MMM, YY') ? (
            <div>up to date</div>
          ) : (
            <div style={{ marginTop: '5px' }}>
              <span style={style.ActivePeriodText}>
                <b>Active Period:</b>{' '}
                {Moment(props.start_date).clone().add(1, 'days').format('Do-MMM, YY')} to
                <CustomDatePicker
                  datePickerValue={{
                    year: Moment(props.end_date).year(),
                    month: Moment(props.end_date).month() + 1,
                    day: Moment(props.end_date).date(),
                  }}
                  handleChangeDate={props.handleChangeLastLocked}
                  formattedMinimumDate={props.formattedMinimumDate}
                  style={style.DateStyle}></CustomDatePicker>
              </span>

              <div style={style.ActivePeriodText}>
                <span>
                  <b>Total Hours:</b>&nbsp;
                </span>
                <input
                  type="number"
                  value={props.total_hours}
                  minLength={1}
                  style={style.TotalHoursInput}
                  onChange={(e) => props.handleChangeTotalHours(e)}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div>N/A</div>
      )}
    </div>
    <div style={style.HoursCount}>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Worked Hours</label>
        <p style={style.Value}>{formatFloat(props.total_worked_hours)}</p>
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Completed Hours</label>
        <p style={style.Value}>{formatFloat(props.total_completed_hours)}</p>
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Leave Hours</label>
        <p style={style.Value}>{formatFloat(props.total_leave_hours)}</p>
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Overtime Hours</label>
        <p style={style.Value}>{formatFloat(props.total_overtime_hours)}</p>
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Missed Hours</label>
        <p style={style.Value}>{formatFloat(props.total_missed_hours)}</p>
      </div>
    </div>
    <button style={style.FilledButton} onClick={props.handleShowLockDataConfirmPrompt}>
      Lock Data
    </button>
  </Toolbar>
);
