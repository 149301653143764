import React from 'react';

import Chart from 'chart.js';
import { Typography } from '@mui/material';
import { style } from '../StreamlineView/style';
import ReactChartkick, { AreaChart } from 'react-chartkick';

ReactChartkick.addAdapter(Chart);

export const InvoicesHistoryChart = (props) => (
  <div style={style.TableContainer}>
    <Typography style={style.CardHeadings}>Invoices History</Typography>
    <div style={style.ChartContainer}>
      <AreaChart
        colors={['#0aec9d']}
        style={style.CentralizedRow}
        data={props.invoices_chart_data}
        library={style.AreaChart}
        curve={true}
        messages={{ empty: 'No data to make Chart!' }}
      />
    </div>
  </div>
);
