export const style = {
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ConfirmBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  CancelBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
};
