import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';

import { style } from './style';

export const BillableProjectInvoices = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCellP}>Client</TableCell>
        <TableCell style={style.TableCell}>Amount</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.invoice_data.map((item, index) => {
        if (item.isBillable >= 0) {
          return (
            <TableRow
              key={index}
              style={style.TableRow}
              className="hours-management-clickable-tablerow"
              onClick={() => props.handleShowProjectInvoice(item.project)}>
              <TableCell style={style.TableCellP}>
                <div style={{ flex: 1, minWidth: '20px' }}>
                  {item.can_be_finilized ? (
                    <span style={style.GreenCheckBadge}>
                      <DoneIcon fontSize="inherit" />
                    </span>
                  ) : (
                    <span style={style.ErrorBadge}>!</span>
                  )}
                </div>
                <Typography style={{ flex: 3 }}>{item.project}</Typography>
              </TableCell>
              <TableCell style={style.TableCell}>${item.total_amount}</TableCell>
            </TableRow>
          );
        } else {
          return null;
        }
      })}
    </TableBody>
  </Table>
);
