import React, { Component } from 'react';
import { connect } from 'react-redux';

import { DialogBoxes } from './DialogBoxes';
import Moment, { isMoment } from 'moment';
import { Lock } from '@mui/icons-material';
import { Tooltip as MuiToolTip } from '@mui/material';
import { withSnackbar } from 'notistack';
import { ActionsCreator } from '../../Redux/Actions/index';

import Bookings from './Booking/Bookings';
import { Ability, hasPermission } from '../../constants/ability';
import { HoursListView } from './HoursListView';
import { HoursToolbar } from './HoursToolbar';
import { style } from './style';
import hoursaddentryicon from '../../assets/images/hours-add-btn-grey.svg';
import hoursprevweekicon from '../../assets/images/hours-prevweek-icon.svg';
import hoursnextweekicon from '../../assets/images/hours-nextweek-icon.svg';
import hourssunlogogrey from '../../assets/images/hours-sun-grey.svg';
import hourssunlogo from '../../assets/images/hours-sun.svg';
import EmptyTeamsIcon from '../../assets/images/empty-teams-icon.svg';
import EmptyProjectsIcon from '../../assets/images/empty-projects-icon.svg';
import EmptyResourcesIcon from '../../assets/images/empty-resources-icon.svg';
import hourstimesheetscyanicon from '../../assets/images/hours-timesheets-tin-cyan.svg';

import { Grid } from 'react-loader-spinner';
import ReactLoaderSpinner from 'react-loader-spinner';
import { TIME_ENTRY_REGEX } from '../../constants/utility';
import { HoverMenu } from './HoverMenu';
class HoursLogging extends Component {
  constructor(props) {
    super(props);
    this.scrollAddEntryRef = React.createRef();
    this.state = {
      selected_view: 'private',
      selected_resource: null,
      selected_project: null,
      show_time_entry_modal: false,
      show_edit_delete_time_entry_modal: false,
      current_raw_date: Moment(),
      week_start: Moment().startOf('isoweek'),
      entry_selected_resource: null,
      entry_selected_project: null,
      entry_start_date: Moment().format('YYYY-MM-DD'),
      entry_end_date: Moment().format('YYYY-MM-DD'),
      entry_minutes: 480, //initializing time per day to 8h
      entry_minutes_error: '',
      entry_description: null,
      entry_submission_state: false,
      loggedin_user_id: null,
      clicked_entry: null,
      project_loggers: [],
      team_members: [],
      selected_team: null,
      selected_team_members: null,
      selected_team_member: null,
      selected_project_logger: null,
      entry_add_hover: false,
      entry_hover_date: null,
      toggleDeleteBtn: false,
      loggedInUserDetail: null,
      loader: false,
      createdBy: null,
      edittedBy: null,
      can_create_entries: true,
      holiday_entry: false,
      showTooltipForHoliday: true,
      search_value: '',
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { current_raw_date } = this.state;
    const {
      holidays,
      projects,
      resources,
      getHolidays,
      getProjects,
      getResources,
      stopLoader,
      getLastLocked,
      last_locked,
      getTeams,
      enqueueSnackbar,
    } = this.props;

    const result = await this.props.checkLoggedIn();
    const loggedInUserDetail = result.name;
    this.setState({
      week_start: current_raw_date.clone().startOf('isoweek'),
      loggedInUserDetail,
      loader: true,
    });

    !last_locked &&
      (await getLastLocked().then((res) => {
        if (!res) {
          enqueueSnackbar('Unable to fetch last locked date!', { variant: 'error' });
          stopLoader();
        }
      }));

    await getProjects(); // fetching all the projects when landing on private tab
    await getResources();
    !holidays && (await getHolidays());

    await stopLoader();
  };

  componentDidUpdate = () => {
    this.fetchResourceEntries();
  };

  fetchResourceEntries = async () => {
    const { current_raw_date, loggedin_user_id } = this.state;
    const { email, resources, getWeekHours, getWeekBookings, setCurrentUser } = this.props;

    if (resources && resources.length > 0 && !loggedin_user_id) {
      let user = resources.filter((resource) => {
        if (resource.email === email) {
          return resource;
        }
        return null;
      })[0];

      if (user) {
        this.setState({
          loggedin_user_id: user && user.id,
        });
        setCurrentUser(user.id);
        await Promise.all([
          getWeekHours(user.id, -1, current_raw_date.format('YYYY-MM-DD')),
          getWeekBookings(user.id, -1, current_raw_date.format('YYYY-MM-DD')),
        ]);
        this.setState({ loader: false });
      }
    }
  };

  fetchDataOnViewChange = async (view) => {
    const { getProjects, getTeams } = this.props;

    if (view === 'teams') {
      if (this.props.roles.includes('View Admin') || this.props.roles.includes('Super Admin')) {
        await getTeams(); //show all teams if super admin or view admin role
      } else {
        const query = `?tm_email=${this.props.email}`;
        await getTeams(query);
      }
    } else if (view === 'private') {
      await getProjects(); // fetching all projects again for hour logging and booking
      this.setState({
        can_create_entries: true,
      });
    }
  };

  handleViewSelect = async (view_type) => {
    const { current_raw_date, loggedin_user_id, selected_resource, selected_view } = this.state;
    const { getWeekHours, getWeekBookings, getResources, setCurrentUser } = this.props;

    this.setState({
      selected_view: view_type,
      resource_fetching: view_type === 'resources' ? true : false,
      selected_resource: null,
      search_value: '',
      selected_project: null,
      loader: true,
    });
    setCurrentUser(null);
    if (view_type === 'resources') {
      await getResources();
      this.setState({
        resource_fetching: false,
      });
    } else if (view_type === 'private') {
      setCurrentUser(loggedin_user_id);
      await this.fetchDataOnViewChange('private');
      await Promise.all([
        getWeekBookings(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD')),
        getWeekHours(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD')),
      ]);
    } else if (view_type === 'teams') {
      this.setState({
        team_members: [],
        selected_team: null,
        selected_team_members: null,
        selected_team_member: null,
        selected_project_logger: null,
      });
      await this.fetchDataOnViewChange('teams');
    } else {
      // reset projects state
      this.setState({
        can_create_entries: false,
        selected_project_logger: null,
        project_loggers: [],
        project_selected: null,
      });
    }
    this.setState({
      current_raw_date: Moment(),
      week_start: Moment().startOf('isoweek'),
      loader: false,
    });
  };

  handleResourceClick = async (resource_name) => {
    const { current_raw_date } = this.state;
    const { getWeekHours, resources, getWeekBookings, setCurrentUser } = this.props;

    let clicked_resource = resources.filter((resource) => resource.name === resource_name)[0];

    this.setState({
      selected_resource: clicked_resource,
      loader: true,
      can_create_entries: true,
    });
    setCurrentUser(clicked_resource.id),
      await Promise.all([
        getWeekBookings(clicked_resource.id, -1, current_raw_date.format('YYYY-MM-DD')),
        getWeekHours(clicked_resource.id, -1, current_raw_date.format('YYYY-MM-DD')),
      ]);
    this.setState({ loader: false });
  };

  handleProjectClick = async (project_name) => {
    const { current_raw_date } = this.state;
    const { enqueueSnackbar, projects, getProjectLoggers } = this.props;

    let clicked_project = projects.filter((project) => project.name === project_name)[0];

    this.setState({
      selected_project: clicked_project,
      resource_fetching: true, // show loader
      project_loggers: [], // to empty resource list
      selected_project_logger: null,
      can_create_entries: false,
      search_value: '',
    });
    let project_loggers = await getProjectLoggers(
      clicked_project.id,
      current_raw_date.format('YYYY-MM-DD'),
    );
    if (!project_loggers || project_loggers.length === 0) {
      enqueueSnackbar('No hours logged on this project recently', { variant: 'error' });
    } else {
      project_loggers = project_loggers.filter((proj_logger) => proj_logger.active == true);
    }
    this.setState({
      selected_project_logger: null,
      project_loggers: project_loggers ? project_loggers : [],
      project_selected: clicked_project,
      resource_fetching: false, // hide loader
    });
  };

  handleTeamClick = async (team_name) => {
    const { enqueueSnackbar, teams } = this.props;

    let clicked_team = teams.filter((team) => team.display_name === team_name)[0];

    this.setState({
      selected_team: clicked_team,
      resource_fetching: true, // show loader
      team_members: [], // to empty resource list
      selected_project_logger: null,
      can_create_entries: true,
      search_value: '',
    });
    await this.props.getResources(`?team=${clicked_team.name}`).then((response) => {
      if (!this.props.resources) {
        // null resources for this team
        enqueueSnackbar('No Resources Assigned To This Team', { variant: 'error' });
      }
      this.setState({
        selected_team_members: this.props.resources ? this.props.resources : [],
        resource_fetching: false, // hide loader
      });
    });
  };

  handleProjectLoggerClick = async (project_id, resource_id) => {
    const { current_raw_date } = this.state;
    const { getWeekHours, getWeekBookings, setCurrentUser } = this.props;

    this.setState({
      selected_project_logger: resource_id,
      loader: true,
      can_create_entries: true,
    });
    setCurrentUser(resource_id),
      // change: pull all entries of this resource, not only this project's
      await Promise.all([
        getWeekBookings(resource_id, -1, current_raw_date.format('YYYY-MM-DD')),
        getWeekHours(resource_id, -1, current_raw_date.format('YYYY-MM-DD')),
      ]);
    this.setState({ loader: false });
  };

  handleTeamMemberClick = async (resource_id) => {
    const { current_raw_date } = this.state;
    const { getWeekHours, getWeekBookings, setCurrentUser } = this.props;

    this.setState({ loader: true });
    setCurrentUser(resource_id);
    // change: pull all entries of this resource, not only this project's
    await Promise.all([
      getWeekBookings(resource_id, -1, current_raw_date.format('YYYY-MM-DD')),
      getWeekHours(resource_id, -1, current_raw_date.format('YYYY-MM-DD')),
    ]);
    this.setState({
      selected_project_logger: resource_id,
      loader: false,
      can_create_entries: true,
    });
  };

  setSelectedResource = async () => {
    const { resources, setCurrentUser } = this.props;
    let resource = resources[0];

    this.setState({
      selected_resource: resource,
    });
    setCurrentUser(resource.id);
  };

  setSelectedProject = () => {
    const { projects } = this.props;

    this.setState({
      selected_project: projects[0],
    });
  };

  handleBackToProjects = () => {
    this.setState({
      selected_project_logger: null,
      project_loggers: [],
    });
  };

  handleChangeDate = async (type) => {
    const {
      current_raw_date,
      loggedin_user_id,
      selected_resource,
      selected_view,
      selected_project,
      selected_project_logger,
    } = this.state;
    const { getWeekHours, getWeekBookings, enqueueSnackbar, getProjectLoggers } = this.props;
    let new_date = null,
      temp_date = current_raw_date.clone();

    switch (type) {
      case 'prev':
        new_date = current_raw_date.subtract(7, 'days');
        break;
      case 'next':
        new_date = current_raw_date.add(7, 'days');
        break;
      case 'month-start':
        new_date = current_raw_date.startOf('month');
        break;
      case 'prev-month':
        new_date = current_raw_date.subtract(1, 'months').startOf('month');
        break;
      case 'next-month':
        new_date = current_raw_date.add(1, 'months').startOf('month');
        break;
      default:
        break;
    }

    // only fetch entries if month has changed as backend only returns entries for current month
    if (!new_date.isSame(temp_date, 'month')) {
      if (selected_project) {
        this.setState({
          loader: true,
          resource_fetching: true,
        });

        let project_loggers = await getProjectLoggers(
          selected_project.id,
          current_raw_date.format('YYYY-MM-DD'),
        );
        if (!project_loggers || project_loggers.length === 0) {
          enqueueSnackbar('No hours logged on this project recently', { variant: 'error' });
        } else {
          project_loggers = project_loggers.filter((proj_logger) => proj_logger.active == true);
        }
        this.setState({
          project_loggers: project_loggers ? project_loggers : [],
          resource_fetching: false,
        });
        let project_logger_exist = project_loggers
          ? project_loggers.find((logger) => logger.id === selected_project_logger)
          : [];

        if (!project_logger_exist || project_logger_exist.length === 0) {
          //to not show entries of any resource if it not longer exists in the unique project loggers
          this.setState({
            selected_project_logger: null,
            can_create_entries: false,
          });
        }

        if (selected_project_logger) {
          await Promise.all([
            getWeekHours(selected_project_logger, -1, current_raw_date.format('YYYY-MM-DD')),
            getWeekBookings(selected_project_logger, -1, current_raw_date.format('YYYY-MM-DD')),
          ]);
        }
      } else {
        this.setState({ loader: true });
        if (selected_view === 'resources') {
          await Promise.all([
            getWeekHours(selected_resource.id, -1, current_raw_date.format('YYYY-MM-DD')),
            getWeekBookings(selected_resource.id, -1, current_raw_date.format('YYYY-MM-DD')),
          ]);
        } else if (selected_view === 'private') {
          await Promise.all([
            getWeekHours(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD')),
            getWeekBookings(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD')),
          ]);
        } else {
          if (selected_project_logger) {
            await Promise.all([
              getWeekHours(selected_project_logger, -1, current_raw_date.format('YYYY-MM-DD')),
              getWeekBookings(selected_project_logger, -1, current_raw_date.format('YYYY-MM-DD')),
            ]);
          } else {
            await Promise.all([
              getWeekHours(-1, selected_project.id, current_raw_date.format('YYYY-MM-DD')),
              getWeekBookings(-1, selected_project.id, current_raw_date.format('YYYY-MM-DD')),
            ]);
          }
        }
      }
    }

    this.setState({
      current_raw_date: new_date,
      week_start: new_date.clone().startOf('isoweek'),
      loader: false,
    });
  };

  handleSearch = (event) => {
    this.setState({
      search_value: event.target.value,
    });
  };

  handleToggleTimeEntryModal = (day) => {
    const { show_time_entry_modal } = this.state;

    // if a Moment instance is passed, assign it
    // else just set today's date to it
    if (!isMoment(day)) {
      day = Moment().format('YYYY-MM-DD');
    } else {
      day = day.format('YYYY-MM-DD');
    }

    if (show_time_entry_modal) {
      this.setState({
        toggleDeleteBtn: false,
        show_time_entry_modal: false,
        entry_description: null,
        entry_submission_state: false,
        entry_start_date: Moment().format('YYYY-MM-DD'),
        entry_end_date: Moment().format('YYYY-MM-DD'),
        entry_minutes: 480,
        entry_minutes_error: '',
        entry_selected_project: null,
      });
    } else {
      this.setState(
        {
          show_time_entry_modal: true,
          entry_description: null,
          entry_submission_state: false,
          entry_start_date: day,
          entry_end_date: day,
          entry_minutes_error: '',
        },
        () => {
          this.handleChangeMinutes({ target: { value: '8h' } }); // to show limit message if any
        },
      );
    }
  };
  handleCloseDialogueBox = (time_entry) => {
    const { show_edit_delete_time_entry_modal } = this.state;
    this.setState({
      clicked_entry: null,
    });

    if (show_edit_delete_time_entry_modal) {
      this.setState({
        entry_selected_project: null,
        entry_start_date: Moment().format('YYYY-MM-DD'),
        entry_end_date: Moment().format('YYYY-MM-DD'),
        entry_minutes: 480,
        entry_minutes_error: '',
        entry_description: null,
        entry_submission_state: false,
        show_edit_delete_time_entry_modal: false,
      });
    } else {
      this.setState({
        entry_selected_project: time_entry.projectId,
        entry_start_date: time_entry.start_date,
        entry_end_date: time_entry.end_date,
        entry_minutes: time_entry.minutes,
        entry_description: time_entry.description,
        entry_submission_state: false,
        show_edit_delete_time_entry_modal: true,
        entry_minutes_error: '',
      });
    }
  };
  handleToggleEditTimeEntryModal = (time_entry) => {
    const { show_edit_delete_time_entry_modal } = this.state;
    let created_event,
      editted_event = null;
    if (time_entry && 'events' in time_entry && time_entry.events !== null) {
      let created_events = time_entry.events.filter((event) => event.type === 'Created');
      created_event = created_events[0];
      let editted_events = time_entry.events.filter((event) => event.type === 'Modified');
      editted_event = editted_events[editted_events.length - 1];
    }
    this.setState({
      clicked_entry: time_entry,
    });
    if (show_edit_delete_time_entry_modal) {
      this.setState({
        entry_selected_project: null,
        entry_start_date: Moment().format('YYYY-MM-DD'),
        entry_end_date: Moment().format('YYYY-MM-DD'),
        entry_minutes: 480,
        entry_minutes_error: '',
        entry_description: null,
        entry_submission_state: false,
        show_edit_delete_time_entry_modal: false,
        createdBy: created_event,
        edittedBy: editted_event,
      });
    } else {
      this.setState({
        entry_selected_project: time_entry.projectId,
        entry_start_date: time_entry.start_date,
        entry_end_date: time_entry.end_date,
        entry_minutes: time_entry.minutes,
        entry_description: time_entry.description,
        entry_submission_state: false,
        show_edit_delete_time_entry_modal: true,
        entry_minutes_error:
          this.getMinutesInHMFormat(time_entry.minutes).search(TIME_ENTRY_REGEX) < 0
            ? 'Invalid time'
            : '',
        createdBy: created_event,
        edittedBy: editted_event,
      });
    }
  };

  checkTimeEntryExists = (cdate) => {
    const { time_entries, current_user } = this.props;
    let found_status = false;
    const user_time_entries = time_entries[current_user];
    for (let tindex in user_time_entries) {
      let te = user_time_entries[tindex].start_date;
      if (te === cdate.format('YYYY-MM-DD')) {
        found_status = true;
        break;
      }
    }
    return found_status;
  };

  handleResourceSelection = (e) => {
    this.setState({
      entry_selected_resource: e.target.value,
    });
  };

  handleProjectSelection = (e) => {
    this.setState({
      entry_selected_project: e.target.value,
    });
  };

  handleChangeStartDate = (e) => {
    const { entry_end_date, entry_start_date, show_edit_delete_time_entry_modal, clicked_entry } =
      this.state;
    const convertDate = { month: e.month - 1, day: e.day, year: e.year };
    this.setState(
      {
        entry_start_date: Moment(convertDate).format('YYYY-MM-DD'),
      },
      () => {
        this.handleChangeMinutes({
          target: {
            value: this.state.entry_minutes
              ? this.getMinutesInHMFormat(this.state.entry_minutes)
              : this.state.entry_minutes_error
              ? ''
              : '8h',
          },
        });
      },
    );

    // if start_date is weekend, end_date should be same (only 1 entry allowed on weekend/holiday)
    if (
      this.isWeekendDay(Moment(convertDate)) ||
      this.isHoliday(Moment(convertDate)) ||
      !entry_end_date
    ) {
      this.setState({
        entry_end_date: Moment(convertDate).format('YYYY-MM-DD'),
      });
    } else {
      // if end_date is before start_date or if start_date is weekday, make end_date = start_date
      let new_start_date = Moment(convertDate);
      if (
        Moment(entry_end_date).diff(new_start_date, 'days') < 0 ||
        this.isWeekendDay(Moment(entry_end_date)) ||
        this.isHoliday(Moment(entry_end_date))
      ) {
        this.setState({
          entry_end_date: Moment(convertDate).format('YYYY-MM-DD'),
        });
      }
    }

    // if edit case, end date should be same as start date
    if (show_edit_delete_time_entry_modal) {
      this.setState({
        entry_end_date: Moment(convertDate).format('YYYY-MM-DD'),
      });
    }
  };

  handleChangeEndDate = (e) => {
    const { entry_start_date } = this.state;
    const convertDate = { month: e.month - 1, day: e.day, year: e.year };
    this.setState(
      {
        entry_end_date: Moment(convertDate).format('YYYY-MM-DD'),
      },
      () => {
        this.handleChangeMinutes({
          target: {
            value: this.state.entry_minutes
              ? this.getMinutesInHMFormat(this.state.entry_minutes)
              : this.state.entry_minutes_error
              ? ''
              : '8h',
          },
        });
      },
    );

    // if end_date is weekend or if end_date is weekday, start_date should be same (only 1 entry allowed on weekend)
    if (this.isWeekendDay(Moment(convertDate)) || this.isWeekendDay(Moment(entry_start_date))) {
      this.setState({
        entry_start_date: Moment(convertDate).format('YYYY-MM-DD'),
      });
    } else if (this.isHoliday(Moment(convertDate)) || this.isHoliday(Moment(entry_start_date))) {
      this.setState({
        entry_start_date: Moment(convertDate).format('YYYY-MM-DD'),
      });
    }
  };

  formatInputStartDateText = () => {
    const { entry_start_date } = this.state;

    if (entry_start_date) {
      const month =
        Moment(entry_start_date).month() + 1 < 10
          ? `0${Moment(entry_start_date).month() + 1}`
          : Moment(entry_start_date).month() + 1;
      const date =
        Moment(entry_start_date).date() < 10
          ? `0${Moment(entry_start_date).date()}`
          : Moment(entry_start_date).date();
      return `${month}/${date}/${Moment(entry_start_date).year()}`;
    }
    return '';
  };

  formatInputEndDateText = () => {
    const { entry_end_date } = this.state;

    if (entry_end_date) {
      const month =
        Moment(entry_end_date).month() + 1 < 10
          ? `0${Moment(entry_end_date).month() + 1}`
          : Moment(entry_end_date).month() + 1;
      const date =
        Moment(entry_end_date).date() < 10
          ? `0${Moment(entry_end_date).date()}`
          : Moment(entry_end_date).date();
      return `${month}/${date}/${Moment(entry_end_date).year()}`;
    }
    return '';
  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    var currDate = Moment(startDate).startOf('day');
    var lastDate = Moment(endDate).startOf('day');
    dates.push(currDate.format('YYYY-MM-DD'));
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.format('YYYY-MM-DD'));
    }

    return dates;
  };

  getLoggedMinutesForDate = (dates) => {
    const { time_entries, current_user } = this.props;
    // console.log('enteries ',time_entries, 'dates ',dates);
    const filtered_entries = [];
    let total_minutes_logged = 0;
    let entry_minutes = {};
    time_entries[current_user] &&
      time_entries[current_user].forEach((timeEntry) => {
        if (dates.includes(timeEntry.start_date)) {
          total_minutes_logged += timeEntry.minutes;
          filtered_entries.push(timeEntry);

          // adding to dict, later we will get max minutes
          const date = timeEntry.start_date;
          if (entry_minutes[date]) {
            // adding minutes to existing entry
            entry_minutes[date] = entry_minutes[date] + timeEntry.minutes;
          } else {
            // making a new entry
            entry_minutes[date] = timeEntry.minutes;
          }
        }
      });
    // finding day with max minutes
    const max_minutes_logged_key = total_minutes_logged
      ? Object.entries(entry_minutes).reduce((a, b) => (a[1] > b[1] ? a : b))[0]
      : 0;
    return { total_minutes_logged, max_minutes_logged: entry_minutes[max_minutes_logged_key] || 0 };
  };

  handleChangeMinutes = (e) => {
    const { entry_start_date, entry_end_date, clicked_entry } = this.state;
    const dates = this.enumerateDaysBetweenDates(entry_start_date, entry_end_date);
    const max_minutes = 1440; // a day has 1440 minutes
    const { total_minutes_logged, max_minutes_logged } = this.getLoggedMinutesForDate(dates);

    // max allowed minutes to enter
    let editEntryMinutes = clicked_entry && clicked_entry.minutes ? clicked_entry.minutes : 0;
    if (clicked_entry && clicked_entry.start_date !== entry_end_date) {
      editEntryMinutes = 0;
    }

    const minutes_left = max_minutes - (max_minutes_logged - editEntryMinutes);
    let entry_minutes = '';
    let minutesInput = e.target.value;
    minutesInput = minutesInput.replace(/\s+/g, '');
    // only allow input of format 4h7m, 4h, or 7m
    if (minutesInput.search(TIME_ENTRY_REGEX) < 0) {
      this.setState({
        entry_minutes_error: 'Invalid time',
        entry_minutes,
      });
      return;
    }
    if (minutesInput.indexOf('h') > -1 && minutesInput.indexOf('m') > -1) {
      // 7h4m
      let hIndex = minutesInput.indexOf('h');
      let mIndex = minutesInput.indexOf('m');
      let hours = minutesInput.substring(0, hIndex);
      let minutes = minutesInput.substring(hIndex + 1, mIndex);
      entry_minutes = parseInt(hours) * 60 + parseInt(minutes);
    } else if (minutesInput.indexOf('h') > -1) {
      // 8h
      let hours = minutesInput.substring(0, minutesInput.length - 1);
      entry_minutes = parseInt(hours) * 60;
    } else if (minutesInput.indexOf('m') > -1) {
      // 30m
      let minutes = minutesInput.substring(0, minutesInput.length - 1);
      entry_minutes = parseInt(minutes);
    }

    if (entry_minutes + (max_minutes_logged - editEntryMinutes) > max_minutes) {
      // dont allow to enter time exceeding 24h
      this.setState({
        entry_minutes_error: `Max ${this.getMinutesInHMFormat(minutes_left)} left`,
        entry_minutes,
      });
    } else {
      // valid entry
      this.setState({
        entry_minutes_error: '',
        entry_minutes,
      });
    }
  };

  handleChangeDescription = (e) => {
    this.setState({
      entry_description: e.target.value,
    });
  };

  generateTimeEntriesArray = () => {
    const {
      selected_resource,
      entry_start_date,
      entry_end_date,
      entry_selected_resource,
      entry_selected_project,
      entry_minutes,
      entry_description,
      selected_view,
      loggedin_user_id,
      selected_project_logger,
    } = this.state;
    const { enqueueSnackbar } = this.props;

    let entries = [];
    let user_id = loggedin_user_id;
    let week_start = Moment(entry_start_date).clone().startOf('isoweek');

    if (selected_view === 'resources') {
      // if a resource has been selected while adding time entry, assign user_id to that
      // else just get the default selected resource
      user_id = entry_selected_resource ? entry_selected_resource : selected_resource.id;
    }
    if (selected_view === 'projects') {
      if (selected_project_logger) {
        // if hours are being added for an hour logger of a particular project
        user_id = selected_project_logger;
      } else {
        // or if hours are being added for a resource being selected from dropdown
        if (entry_selected_resource) {
          user_id = entry_selected_resource;
        } else {
          enqueueSnackbar('No Resource ID found!', { variant: 'error' });
        }
      }
    }
    if (selected_view === 'teams') {
      if (selected_project_logger) {
        user_id = selected_project_logger;
      }
    }

    // ask user if they want to add entry on weekend if they selected start date of weekend
    let weekendStartStatus = this.isWeekendDay(Moment(entry_start_date));
    let weekendConfirm = undefined;
    if (weekendStartStatus) {
      weekendConfirm = window.confirm(
        'Start date is a weekend, are you sure you want to add weekend entries?\n\nPress Ok to include weekend entries, press Cancel to ignore them.',
      );
    }

    let entriesLength = Moment(entry_end_date).diff(Moment(entry_start_date), 'days');

    for (let m = Moment(entry_start_date); m.isSameOrBefore(entry_end_date); m.add(1, 'days')) {
      // 1. User adds multiple entries from one weekday to another (Friday - Monday)
      // 1. Ignore weekends in that case automatically
      // 2. User selects start date of a weekend, in that case take decision based on the above prompt
      // 3. Skip if the day is a holiday, only can add one entry for holiday

      // 1.
      if (!weekendStartStatus && this.isWeekendDay(m)) {
        continue;
      }
      // 2.
      else if (weekendConfirm !== undefined && !weekendConfirm && this.isWeekendDay(m)) {
        continue;
      }
      // 3.
      else if (this.isHoliday(m) && entriesLength > 0) {
        continue;
      }

      entries.push({
        resourceId: parseInt(user_id),
        projectId: parseInt(entry_selected_project),
        start_date: m.format('YYYY-MM-DD'),
        end_date: m.format('YYYY-MM-DD'),
        week_start: week_start.format('YYYY-MM-DD'),
        minutes: parseInt(entry_minutes),
        description: entry_description || '',
      });
    }

    return entries;
  };

  handleAddTimeEntry = () => {
    const {
      current_raw_date,
      loggedin_user_id,
      selected_resource,
      entry_selected_resource,
      entry_start_date,
      entry_end_date,
      selected_view,
      selected_project_logger,
      selected_project,
      clicked_entry,
    } = this.state;

    const { addCustomTimeEntry, enqueueSnackbar, getWeekHours, last_locked } = this.props;

    if (this.areEntriesEmpty()) {
      enqueueSnackbar('Fields cannot be empty!', { variant: 'error' });
    } else if (this.areEntriesBeforeLocked()) {
      let new_ll = Moment(last_locked).format('DD/MM/YYYY');
      enqueueSnackbar(
        'Invalid Entry! Data has been locked till ' +
          new_ll +
          ' hence you can only add entries after it.\n' +
          'Please select a start date after ' +
          new_ll,
        { variant: 'error' },
      );
    } else if (this.checkEntryDatesOverlap()) {
      enqueueSnackbar(
        'Start Date (' + entry_start_date + ') cannot be after end date (' + entry_end_date + ')',
        { variant: 'error' },
      );
    } else if (!this.checkMinutesFormat()) {
      enqueueSnackbar('Could not save minutes, are they in 4h5m or 4h or 5m form?', {
        variant: 'error',
      });
    } else {
      this.setState({
        entry_submission_state: true,
        clicked_entry: null,
      });
      let time_entries = this.generateTimeEntriesArray();

      if (time_entries.length === 0) {
        enqueueSnackbar('No Time Entries to Add!', { variant: 'warning' });
        this.handleToggleTimeEntryModal(null);
        return;
      }

      addCustomTimeEntry(time_entries).then((res) => {
        if (res) {
          enqueueSnackbar('Time Entries Added!', { variant: 'success' });
          this.handleToggleTimeEntryModal(null);

          let user_id = loggedin_user_id;
          this.setState({
            week_start: Moment(time_entries[0].start_date).clone().startOf('isoweek'),
            current_raw_date: Moment(time_entries[0].start_date).clone(),
          });
          if (selected_view === 'resources') {
            user_id = entry_selected_resource ? entry_selected_resource : selected_resource.id;
          }
          if (selected_view === 'projects') {
            if (selected_project_logger) {
              user_id = selected_project_logger;
              return getWeekHours(user_id, -1, current_raw_date.format('YYYY-MM-DD'));
            } else {
              return getWeekHours(-1, selected_project.id, current_raw_date.format('YYYY-MM-DD'));
            }
          }
          if (selected_view === 'teams') {
            if (selected_project_logger) {
              user_id = selected_project_logger;
              return getWeekHours(user_id, -1, current_raw_date.format('YYYY-MM-DD'));
            } else {
              return getWeekHours(-1, selected_project.id, current_raw_date.format('YYYY-MM-DD'));
            }
          }
          return getWeekHours(user_id, -1, current_raw_date.format('YYYY-MM-DD'));
        } else {
          enqueueSnackbar("Can't add time entry!", { variant: 'error' });
        }
      });
    }
  };

  handleDeleteTimeEntry = () => {
    const { deleteCustomTimeEntry, enqueueSnackbar, getWeekHours } = this.props;
    const {
      clicked_entry,
      selected_view,
      selected_resource,
      current_raw_date,
      loggedin_user_id,
      selected_project_logger,
    } = this.state;

    var answer = window.confirm('Are you sure you want to delete this time entry?');
    if (answer) {
      this.setState({ toggleDeleteBtn: true });
      deleteCustomTimeEntry(clicked_entry.docId)
        .then((res) => {
          if (res) {
            enqueueSnackbar('Time Entry Deleted!', { variant: 'success' });
            if (selected_view === 'resources') {
              return getWeekHours(selected_resource.id, -1, current_raw_date.format('YYYY-MM-DD'));
            } else if (selected_view === 'private') {
              return getWeekHours(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD'));
            } else if (selected_view === 'projects' || selected_view === 'teams') {
              return getWeekHours(
                selected_project_logger,
                -1,
                current_raw_date.format('YYYY-MM-DD'),
              );
            }
          } else {
            enqueueSnackbar("Time Entry wasn't deleted!", { variant: 'error' });
            this.handleToggleEditTimeEntryModal(clicked_entry);
          }
        })
        .then((res) => {
          this.setState({ toggleDeleteBtn: false });
          this.handleToggleEditTimeEntryModal(clicked_entry);
        });
    }
  };

  handleEditTimeEntry = () => {
    const {
      loggedin_user_id,
      selected_resource,
      entry_selected_resource,
      entry_selected_project,
      entry_start_date,
      entry_end_date,
      entry_minutes,
      entry_description,
      selected_view,
      current_raw_date,
      clicked_entry,
      selected_project_logger,
    } = this.state;

    const { updateCustomTimeEntry, enqueueSnackbar, getWeekHours } = this.props;

    let user_id = loggedin_user_id;
    if (selected_view === 'resources') {
      user_id = entry_selected_resource ? entry_selected_resource : selected_resource.id;
    }
    if (selected_view === 'projects' || selected_view === 'teams') {
      user_id = selected_project_logger;
    }

    if (this.areEntriesEmpty()) {
      enqueueSnackbar('Fields cannot be empty!', { variant: 'error' });
    } else {
      this.setState({
        entry_submission_state: true,
      });
    }

    updateCustomTimeEntry({
      docId: clicked_entry.docId,
      resourceId: parseInt(user_id),
      projectId: parseInt(entry_selected_project),
      start_date: Moment(entry_start_date).format('YYYY-MM-DD'),
      end_date: Moment(entry_end_date).format('YYYY-MM-DD'),
      minutes: parseInt(entry_minutes),
      description: entry_description || '',
    }).then((res) => {
      if (res) {
        enqueueSnackbar('Time Entry updated!', { variant: 'success' });
        this.handleToggleEditTimeEntryModal(null);
        return getWeekHours(user_id, -1, current_raw_date.format('YYYY-MM-DD'));
      } else {
        enqueueSnackbar("Can't update time entry!", { variant: 'error' });
      }
    });
  };

  areEntriesEmpty = () => {
    let { entry_selected_project, entry_start_date, entry_end_date, entry_minutes } = this.state;

    if (
      entry_selected_project === null ||
      entry_selected_project === '' ||
      entry_start_date === null ||
      entry_start_date === '' ||
      entry_end_date === null ||
      entry_end_date === '' ||
      entry_minutes === null ||
      entry_minutes === ''
    ) {
      return true;
    }
    return false;
  };

  areEntriesBeforeLocked = () => {
    let { entry_start_date } = this.state;
    const { last_locked } = this.props;

    return Moment(entry_start_date).isSameOrBefore(last_locked);
  };

  checkEntryDatesOverlap = () => {
    let { entry_start_date, entry_end_date } = this.state;

    return Moment(entry_start_date).isAfter(entry_end_date);
  };

  checkMinutesFormat = () => {
    const { entry_minutes } = this.state;

    if (entry_minutes) {
      return true;
    }
    return false;
  };

  getProjectColor = (project_id) => {
    let { projects } = this.props;
    let default_color = '#01706c';

    if (projects && projects.length > 0) {
      let project = projects.filter((project) => {
        if (project.id === project_id) {
          return project;
        }
        return null;
      })[0];
      if (project) {
        if (!project.color) {
          return default_color;
        } else {
          let project_color = project.color.toLowerCase();
          var color_regex = /^#([0-9a-f]{3}){1,2}$/i;
          if (color_regex.test(project_color)) {
            return project.color;
          } else {
            return default_color;
          }
        }
      }
    }
    return default_color;
  };

  getProjectById = (project_id) => {
    const { enqueueSnackbar, projects } = this.props;

    if (projects && projects.length > 0) {
      for (var i = 0; i < projects.length; i++) {
        if (parseInt(projects[i].id) === parseInt(project_id)) {
          return projects[i];
        }
      }

      let error_str = 'Could not find project with id: ' + project_id;
      enqueueSnackbar(error_str, { variant: 'error' });
    }
  };

  getMinutesInHMFormat = (minutesInput) => {
    let hours = Math.floor(minutesInput / 60);
    let minutes = minutesInput % 60;

    if (hours === 0) {
      return minutes + 'm';
    } else if (minutes === 0) {
      return hours + 'h';
    }
    return hours + 'h' + minutes + 'm';
  };

  getUniqueProjectLoggers = (time_entries, project_selected) => {
    let project_logger_ids = [];
    let project_loggers = [];

    for (let time_entry_id in time_entries) {
      let time_entry = time_entries[time_entry_id];

      if (time_entry.projectId === project_selected.id) {
        if (!project_logger_ids.includes(time_entry.resourceId)) {
          project_logger_ids.push(time_entry.resourceId);
          const resource = this.props.resources.filter(
            (resource) => time_entry.resourceId === resource.id,
          )[0];
          if (resource.active)
            project_loggers.push({
              resourceId: time_entry.resourceId,
              resourcename: time_entry.resourcename,
            });
        }
      }
    }

    return project_loggers;
  };

  isHoliday = (day) => {
    const { holidays } = this.props;

    if (holidays) {
      let holiday = holidays.find((obj) => {
        return obj.start_date === day.format('YYYY-MM-DD');
      });

      return holiday ? true : false;
    }
    return false;
  };

  getHoliday = (day) => {
    const { holidays } = this.props;

    if (holidays) {
      let holiday = holidays.find((obj) => {
        return obj.start_date === day.format('YYYY-MM-DD');
      });
      return holiday;
    }
    return null;
  };

  isWeekendDay = (day) => {
    return day.day() % 6 === 0;
  };

  isStartOfMonth = (day) => {
    return day.format('YYYY-MM-DD') === day.clone().startOf('month').format('YYYY-MM-DD');
  };

  isEndOfMonth = (day) => {
    return day.format('YYYY-MM-DD') === day.clone().endOf('month').format('YYYY-MM-DD');
  };

  toggleAddEntryButton = (day, val) => {
    this.setState({
      entry_hover_date: day.format('YYYY-MM-DD'),
      entry_add_hover: val,
      holiday_entry: this.isHoliday(day),
    });
  };

  showHolidayTooltip = (val) => {
    this.setState({
      showTooltipForHoliday: val,
    });
  };

  changeWeekStart = (day) => {
    this.setState({
      week_start: Moment(day).clone().startOf('isoweek'),
      current_raw_date: Moment(day).clone(),
    });
  };

  showEmptyScreen = (view) => {
    if (view === 'teams') {
      const { selected_team, selected_project_logger } = this.state;
      if (!selected_team || !selected_project_logger) return true;
    } else if (view === 'projects') {
      const { selected_project_logger, project_selected } = this.state;
      if (!project_selected) return true;
    } else if (view === 'resources') {
      const { selected_resource } = this.state;
      if (!selected_resource) return true;
    }

    return false;
  };

  renderEmptyScreenImg = (view) => {
    if (view === 'teams') return <img src={EmptyTeamsIcon} alt="EmptyTeamsIcon" />;
    else if (view === 'projects') return <img src={EmptyProjectsIcon} alt="EmptyProjectsIcon" />;
    else if (view === 'resources') return <img src={EmptyResourcesIcon} alt="EmptyResourcesIcon" />;
  };

  renderCalenderLoader = () => {
    return (
      <div style={style.HoursContainer}>
        <div style={style.EmptyViewContainer}>
          <Grid
            height="80"
            width="80"
            radius="12.5"
            color="#4fa94d"
            ariaLabel="grid-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
    );
  };

  getHoursfromMinutes = (minutes) => {
    let result = '';
    const duration = Moment.duration(minutes, 'minutes');
    const hours = duration.hours();
    const remainingMinutes = duration.minutes();
    hours === 1 ? (result = `${hours} hour`) : (result = `${hours} hours`);
    if (remainingMinutes > 0) {
      result += ` ${remainingMinutes} minutes`;
    }
    return result;
  };

  getNameInitials = (name) => {
    const nameArray = name.split(' '); // Split the name into an array of words
    const initials = nameArray.map((word) => word.charAt(0).toUpperCase()); // Get the first character of each word and convert it to uppercase
    return initials.join(''); // Join the initials together
  };

  handleDeleteTimeEntryFromPopup = (clicked_entry) => {
    const { deleteCustomTimeEntry, enqueueSnackbar, getWeekHours } = this.props;
    const {
      selected_view,
      selected_resource,
      current_raw_date,
      loggedin_user_id,
      selected_project_logger,
    } = this.state;

    var answer = window.confirm('Are you sure you want to delete this time entry?');
    if (answer) {
      deleteCustomTimeEntry(clicked_entry.docId).then((res) => {
        if (res) {
          enqueueSnackbar('Time Entry Deleted!', { variant: 'success' });
          if (selected_view === 'resources') {
            return getWeekHours(selected_resource.id, -1, current_raw_date.format('YYYY-MM-DD'));
          } else if (selected_view === 'private') {
            return getWeekHours(loggedin_user_id, -1, current_raw_date.format('YYYY-MM-DD'));
          } else if (selected_view === 'projects' || selected_view === 'teams') {
            return getWeekHours(selected_project_logger, -1, current_raw_date.format('YYYY-MM-DD'));
          }
        } else {
          enqueueSnackbar("Time Entry wasn't deleted!", { variant: 'error' });
        }
      });
    }
  };

  formatTimeAgo = (givenDateString) => {
    const givenDate = new Date(givenDateString);
    const currentDate = new Date();
    const timeDifference = currentDate - givenDate;

    if (timeDifference < 60000) {
      // Less than 1 minute (1 minute = 60,000 milliseconds)
      return `a few seconds ago by `;
    } else if (timeDifference < 3600000) {
      // Less than 1 hour (1 hour = 3,600,000 milliseconds)
      const minutes = Math.floor(timeDifference / 60000);
      if (minutes > 1) {
        return `${minutes} minutes ago by `;
      } else {
        return `${minutes} minute ago by `;
      }
    } else if (timeDifference < 86400000) {
      // Less than 24 hours (24 hours = 86,400,000 milliseconds)
      const hours = Math.floor(timeDifference / 3600000);
      return `${hours} hours ago by `;
    } else {
      // More than 24 hours
      const isoDateString = givenDate.toISOString().slice(0, 10);
      const isoDateParts = isoDateString.split('-');
      const month = isoDateParts[1];
      const day = isoDateParts[2];
      const dateString = `${day}/${month}`;
      return `at ${dateString} by `;
    }
  };

  render() {
    const {
      clicked_entry,
      current_raw_date,
      entry_selected_resource,
      entry_selected_project,
      entry_start_date,
      entry_end_date,
      entry_minutes,
      entry_minutes_error,
      entry_description,
      entry_submission_state,
      selected_project,
      selected_project_logger,
      selected_resource,
      selected_view,
      show_time_entry_modal,
      show_edit_delete_time_entry_modal,
      week_start,
      project_loggers,
      entry_add_hover,
      entry_hover_date,
      loggedin_user_id,
      selected_team,
      selected_team_members,
      selected_team_member,
      loggedInUserDetail,
      createdBy,
      edittedBy,
      can_create_entries,
      holiday_entry,
      showTooltipForHoliday,
      search_value,
    } = this.state;
    const { email, name, roles } = this.props;
    const {
      holidays,
      enqueueSnackbar,
      projects,
      resources,
      time_entries,
      last_locked,
      teams,
      checkLoggedIn,
      current_user,
    } = this.props;
    let resource_selected = {};
    let project_selected = {};
    const minimumDate = Moment(last_locked).clone().add(1, 'days');
    const formattedMinimumDate = {
      year: minimumDate.year(),
      month: minimumDate.month() + 1,
      day: minimumDate.date(),
    };
    // if(!this.props.roles.includes('View Admin'))
    // {
    // let active_teams = teams && teams.filter(team => team.active);
    // }
    if (!selected_resource && resources && resources.length > 0) {
      // this.setSelectedResource(); // not setting the first resource as default because not we have empty screen for it.
      // resource_selected = resources[0]; // not sending resource selected in component
    } else if (selected_resource) {
      resource_selected = selected_resource;
    }

    if (selected_project) {
      project_selected = selected_project;
    }
    let show_time_entries = time_entries[current_user];

    let days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(Moment(week_start).add(i, 'days'));
    }
    let week_end = week_start.clone().add(6, 'days');
    const showProjects =
      projects &&
      projects.filter(
        (project) =>
          (search_value !== '' &&
            project.name.toLowerCase().includes(search_value.toLowerCase())) ||
          search_value == '',
      );
    const showResources =
      resources &&
      resources.filter(
        (resource) =>
          (search_value !== '' &&
            resource.name.toLowerCase().includes(search_value.toLowerCase())) ||
          search_value == '',
      );
    const showTeams =
      teams &&
      teams.filter(
        (team) =>
          (search_value !== '' &&
            team.display_name.toLowerCase().includes(search_value.toLowerCase())) ||
          search_value == '',
      );
    return (
      <div style={style.ComponentMain}>
        <HoursToolbar
          selected_view={selected_view}
          roles={roles}
          handleViewSelect={this.handleViewSelect}
        />

        <div style={style.MainContainer}>
          <>
            <HoursListView
              loggedin_email={email}
              teams={teams}
              showTeams={showTeams}
              roles={roles}
              handleTeamClick={this.handleTeamClick}
              selected_team={selected_team}
              selected_team_members={selected_team_members}
              selected_team_member={selected_team_member}
              handleTeamMemberClick={this.handleTeamMemberClick}
              selected_view={selected_view}
              projects={projects}
              showProjects={showProjects}
              project_selected={project_selected}
              resource_fetching={this.state.resource_fetching}
              resources={resources}
              showResources={showResources} //to display search results
              resource_selected={resource_selected}
              project_loggers={project_loggers}
              selected_project_logger={selected_project_logger}
              handleProjectLoggerClick={this.handleProjectLoggerClick}
              handleBackToProjects={this.handleBackToProjects}
              handleProjectClick={this.handleProjectClick}
              handleResourceClick={this.handleResourceClick}
              getMinutesInHMFormat={this.getMinutesInHMFormat}
              loggedInUserDetail={loggedInUserDetail}
              search_value={search_value}
              handleSearch={this.handleSearch}
            />
            {this.showEmptyScreen(selected_view) || this.state.loader ? (
              this.state.loader ? (
                this.renderCalenderLoader()
              ) : (
                <div style={style.HoursContainer}>
                  <div style={style.EmptyViewContainer}>
                    {this.renderEmptyScreenImg(selected_view)}
                  </div>
                </div>
              )
            ) : (
              <div style={style.HoursContainer}>
                <div style={style.HoursHeader}>
                  <div
                    style={style.HoursInnerContainer}
                    onClick={() => this.handleChangeDate('prev-month')}>
                    <img style={style.HoursWeekChangeIcon} src={hoursprevweekicon} alt="<" />
                    <span style={{ cursor: 'pointer' }}>
                      {current_raw_date.clone().subtract(1, 'months').format('MMMM').toUpperCase()},{' '}
                      {current_raw_date.clone().subtract(1, 'months').year()}
                    </span>
                  </div>

                  <div style={style.HoursInnerContainer}>
                    <div onClick={() => this.handleChangeDate('prev')}>
                      <img style={style.HoursWeekChangeIcon} src={hoursprevweekicon} alt="<" />
                    </div>
                    {week_start.format('Do')} {week_start.format('MMM')}, {week_start.format('YY')}
                    &nbsp;-&nbsp;
                    {week_end.format('Do')} {week_end.format('MMM')}, {week_end.format('YY')}
                    <div onClick={() => this.handleChangeDate('next')}>
                      <img style={style.HoursWeekChangeIcon} src={hoursnextweekicon} alt=">" />
                    </div>
                  </div>

                  <div
                    style={style.HoursInnerContainer}
                    onClick={() => this.handleChangeDate('next-month')}>
                    <span style={{ cursor: 'pointer' }}>
                      {current_raw_date.clone().add(1, 'months').format('MMMM').toUpperCase()},{' '}
                      {current_raw_date.clone().add(1, 'months').year()}
                    </span>
                    <img style={style.HoursWeekChangeIcon} src={hoursnextweekicon} alt=">" />
                  </div>
                </div>

                <div style={style.HoursCurrentWeek}>
                  {days.map((day, index) => {
                    // todo: improve this code, just want to show grey BG for weekend
                    const isInFuture = Moment().format('YYYY-MM-DD') < day.format('YYYY-MM-DD');
                    return this.isWeekendDay(day) ? (
                      <div
                        key={index}
                        style={
                          Moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
                            ? style.HoursDayBoxActiveWeekend
                            : isInFuture
                            ? style.HoursDayBoxFutureWeekend
                            : style.HoursDayBoxWeekend
                        }>
                        {this.isEndOfMonth(day) ? (
                          <div style={style.HoursEndMonth}>
                            {day.format('MMM').toUpperCase()}, {day.year()}
                          </div>
                        ) : this.isStartOfMonth(day) ? (
                          <div style={style.HoursStartMonth}>
                            {day.format('MMM').toUpperCase()}, {day.year()}
                          </div>
                        ) : null}
                        {day.format('D')}
                        <br />
                        <br />
                        {day.format('ddd').toUpperCase()}
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={
                          this.isHoliday(day)
                            ? style.HoursDayBoxHoliday
                            : Moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
                            ? style.HoursDayBoxActive
                            : isInFuture
                            ? style.HoursDayBoxFuture
                            : style.HoursDayBox
                        }>
                        {this.isEndOfMonth(day) ? (
                          <div style={style.HoursEndMonth}>
                            {day.format('MMM').toUpperCase()}, {day.year()}
                          </div>
                        ) : this.isStartOfMonth(day) ? (
                          <div style={style.HoursStartMonth}>
                            {day.format('MMM').toUpperCase()}, {day.year()}
                          </div>
                        ) : null}
                        {day.format('D')}
                        <br />
                        <br />
                        {day.format('ddd').toUpperCase()}
                        {this.isHoliday(day) ? (
                          <div
                            style={
                              isInFuture ? style.EntryItemHolidayFuture : style.EntryItemHoliday
                            }>
                            <img
                              style={style.HoursHolidayIcon}
                              src={isInFuture ? hourssunlogogrey : hourssunlogo}
                              alt="holiday"
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {hasPermission(roles, 'hour_logging', 'can_view_bookings') && (
                  <Bookings
                    roles={roles}
                    days={days}
                    selected_view={selected_view}
                    isWeekendDay={this.isWeekendDay}
                    isHoliday={this.isHoliday}
                    getHoliday={this.getHoliday}
                    getProjectColor={this.getProjectColor}
                    loggedin_user_id={loggedin_user_id}
                    current_raw_date={current_raw_date}
                    selected_resource={selected_resource}
                    week_start={week_start}
                    selected_project_logger={selected_project_logger}
                    getMinutesInHMFormat={this.getMinutesInHMFormat}
                    last_locked={this.props.last_locked}
                    changeWeekStart={this.changeWeekStart}
                    enumerateDaysBetweenDates={this.enumerateDaysBetweenDates}
                    can_create_entries={can_create_entries}
                    current_user={current_user}
                    getNameInitials={this.getNameInitials}
                    formatTimeAgo={this.formatTimeAgo}
                    formattedMinimumDate={formattedMinimumDate}
                    getHoursfromMinutes={this.getHoursfromMinutes}
                  />
                )}

                <div style={style.EntryHeader}>
                  <div style={style.EntryHeaderLabel}>
                    <img src={hourstimesheetscyanicon} alt="" />
                    <div>Timesheets</div>
                  </div>
                </div>
                {/* </div > */}
                <div style={style.EntryCurrentWeek}>
                  {days.map((day, index) => {
                    const isInFuture = Moment().format('YYYY-MM-DD') < day.format('YYYY-MM-DD');
                    const showAddEntryIcon =
                      can_create_entries &&
                      Moment(day).isAfter(last_locked) &&
                      entry_add_hover &&
                      day.format('YYYY-MM-DD') === entry_hover_date;
                    const showHolidayTitle =
                      holiday_entry &&
                      day.format('YYYY-MM-DD') === entry_hover_date &&
                      showTooltipForHoliday;
                    return (
                      <React.Fragment key={index}>
                        {showHolidayTitle ? (
                          <MuiToolTip title={this.getHoliday(day).title} placement="top">
                            <div
                              key={index}
                              id="main-div"
                              style={
                                this.isWeekendDay(day)
                                  ? style.EntryDayBoxWeekend
                                  : this.isHoliday(day)
                                  ? style.EntryDayBoxHoliday
                                  : style.EntryDayBox
                              }
                              onMouseEnter={() => this.toggleAddEntryButton(day, true)}
                              onMouseLeave={() => this.toggleAddEntryButton(day, false)}>
                              {this.isWeekendDay(day) ? (
                                <div
                                  style={
                                    isInFuture
                                      ? style.EntryItemWeekendLabelFuture
                                      : style.EntryItemWeekendLabel
                                  }></div>
                              ) : this.isHoliday(day) ? (
                                <div
                                  style={
                                    isInFuture
                                      ? style.EntryItemHolidayFuture
                                      : style.EntryItemHoliday
                                  }></div>
                              ) : null}
                              {this.checkTimeEntryExists(day) ? (
                                (selected_view === 'projects' && selected_project_logger) ||
                                selected_view === 'private' ||
                                selected_view === 'resources' ||
                                selected_view === 'teams' ? (
                                  <>
                                    {show_time_entries &&
                                      show_time_entries
                                        .filter((te) => te.start_date === day.format('YYYY-MM-DD'))
                                        .sort((entry1, entry2) =>
                                          entry1.projectname.localeCompare(entry2.projectname),
                                        )
                                        .map((filteredTe, index2) => (
                                          <div key={index2} style={style.EntryItemContainer}>
                                            <div className="tooltip" style={style.EntryItem}>
                                              <div
                                                style={{
                                                  backgroundColor: this.getProjectColor(
                                                    filteredTe.projectId,
                                                  ),
                                                  padding: '5px',
                                                  borderRadius: '3px',
                                                  display: 'flex',
                                                  justifyContent: 'space-between',
                                                  alignItems: 'center',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  Moment(day).isSameOrBefore(last_locked)
                                                    ? enqueueSnackbar('Time entry is locked', {
                                                        variant: 'warning',
                                                      })
                                                    : this.handleToggleEditTimeEntryModal(
                                                        filteredTe,
                                                      )
                                                }
                                                onMouseEnter={() => this.showHolidayTooltip(false)}
                                                onMouseLeave={() => this.showHolidayTooltip(true)}>
                                                <HoverMenu
                                                  entry={filteredTe}
                                                  getProjectColor={this.getProjectColor}
                                                  getNameInitials={this.getNameInitials}
                                                  handleDeleteEntryFromPopup={
                                                    this.handleDeleteTimeEntryFromPopup
                                                  }
                                                  handleEditEntryFromPopup={
                                                    this.handleToggleEditTimeEntryModal
                                                  }
                                                  getHoursfromMinutes={this.getHoursfromMinutes}
                                                  formatTimeAgo={this.formatTimeAgo}
                                                  toDelete={filteredTe}
                                                  isLocked={Moment(
                                                    filteredTe.start_date,
                                                  ).isSameOrBefore(last_locked)}
                                                  can_edit={true}></HoverMenu>
                                                <div>
                                                  <b>{filteredTe.projectname}</b>
                                                  <br />
                                                  {this.getMinutesInHMFormat(filteredTe.minutes)}
                                                </div>
                                                {Moment(filteredTe.start_date).isSameOrBefore(
                                                  last_locked,
                                                ) ? (
                                                  <div style={{ textAlign: 'center' }}>
                                                    <Lock fontSize="small" />
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                  </>
                                ) : Moment(day).isSameOrBefore(last_locked) ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                    }}>
                                    <Lock fontSize="small" />
                                  </div>
                                ) : null
                              ) : Moment(day).isSameOrBefore(last_locked) ? (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}>
                                  <Lock fontSize="small" />
                                </div>
                              ) : null}
                              {showAddEntryIcon && (
                                <MuiToolTip title="Add Entry" placement="bottom">
                                  <div
                                    style={style.EntryItemAdd}
                                    onClick={() => this.handleToggleTimeEntryModal(day)}>
                                    <img src={hoursaddentryicon} alt="" />
                                  </div>
                                </MuiToolTip>
                              )}
                              {showAddEntryIcon &&
                                this.scrollAddEntryRef.current &&
                                this.scrollAddEntryRef.current.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'nearest',
                                })}
                            </div>
                          </MuiToolTip>
                        ) : (
                          <div
                            key={index}
                            id="main-div"
                            style={
                              this.isWeekendDay(day)
                                ? style.EntryDayBoxWeekend
                                : this.isHoliday(day)
                                ? style.EntryDayBoxHoliday
                                : style.EntryDayBox
                            }
                            onMouseEnter={() => this.toggleAddEntryButton(day, true)}
                            onMouseLeave={() => this.toggleAddEntryButton(day, false)}>
                            {this.isWeekendDay(day) ? (
                              <div
                                style={
                                  isInFuture
                                    ? style.EntryItemWeekendLabelFuture
                                    : style.EntryItemWeekendLabel
                                }></div>
                            ) : this.isHoliday(day) ? (
                              <div
                                style={
                                  isInFuture ? style.EntryItemHolidayFuture : style.EntryItemHoliday
                                }></div>
                            ) : null}
                            {this.checkTimeEntryExists(day) ? (
                              (selected_view === 'projects' && selected_project_logger) ||
                              selected_view === 'private' ||
                              selected_view === 'resources' ||
                              selected_view === 'teams' ? (
                                <>
                                  {show_time_entries &&
                                    show_time_entries
                                      .filter((te) => te.start_date === day.format('YYYY-MM-DD'))
                                      .sort((entry1, entry2) =>
                                        entry1.projectname.localeCompare(entry2.projectname),
                                      )
                                      .map((filteredTe, index2) => (
                                        <div key={index2} style={style.EntryItemContainer}>
                                          <div className="tooltip" style={style.EntryItem}>
                                            <div
                                              style={{
                                                backgroundColor: this.getProjectColor(
                                                  filteredTe.projectId,
                                                ),
                                                padding: '5px',
                                                borderRadius: '3px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                Moment(day).isSameOrBefore(last_locked)
                                                  ? enqueueSnackbar('Time entry is locked', {
                                                      variant: 'warning',
                                                    })
                                                  : this.handleToggleEditTimeEntryModal(filteredTe)
                                              }
                                              onMouseEnter={() => this.showHolidayTooltip(false)}
                                              onMouseLeave={() => this.showHolidayTooltip(true)}>
                                              <HoverMenu
                                                entry={filteredTe}
                                                getProjectColor={this.getProjectColor}
                                                getNameInitials={this.getNameInitials}
                                                handleDeleteEntryFromPopup={
                                                  this.handleDeleteTimeEntryFromPopup
                                                }
                                                handleEditEntryFromPopup={
                                                  this.handleToggleEditTimeEntryModal
                                                }
                                                getHoursfromMinutes={this.getHoursfromMinutes}
                                                formatTimeAgo={this.formatTimeAgo}
                                                toDelete={filteredTe}
                                                isLocked={Moment(
                                                  filteredTe.start_date,
                                                ).isSameOrBefore(last_locked)}
                                                can_edit={true}></HoverMenu>
                                              <div>
                                                <b>{filteredTe.projectname}</b>
                                                <br />
                                                {this.getMinutesInHMFormat(filteredTe.minutes)}
                                              </div>
                                              {Moment(filteredTe.start_date).isSameOrBefore(
                                                last_locked,
                                              ) ? (
                                                <div style={{ textAlign: 'center' }}>
                                                  <Lock fontSize="small" />
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              ) : Moment(day).isSameOrBefore(last_locked) ? (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}>
                                  <Lock fontSize="small" />
                                </div>
                              ) : null
                            ) : Moment(day).isSameOrBefore(last_locked) ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}>
                                <Lock fontSize="small" />
                              </div>
                            ) : null}
                            {showAddEntryIcon && (
                              <MuiToolTip title="Add Entry" placement="bottom">
                                <div
                                  style={style.EntryItemAdd}
                                  onClick={() => this.handleToggleTimeEntryModal(day)}>
                                  <img src={hoursaddentryicon} alt="" />
                                </div>
                              </MuiToolTip>
                            )}
                            {showAddEntryIcon &&
                              this.scrollAddEntryRef.current &&
                              this.scrollAddEntryRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest',
                              })}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                {/* empty div to use for ref when scrolling to show the add entry icon in large day boxes */}
                <div style={{ height: '40px' }} ref={this.scrollAddEntryRef} />
              </div>
            )}
          </>
        </div>
        <DialogBoxes
          show_time_entry_modal={show_time_entry_modal}
          show_edit_delete_time_entry_modal={show_edit_delete_time_entry_modal}
          selected_view={selected_view}
          handleToggleTimeEntryModal={this.handleToggleTimeEntryModal}
          handleToggleEditTimeEntryModal={this.handleToggleEditTimeEntryModal}
          handleCloseDialogueBox={this.handleCloseDialogueBox}
          loggedin_name={name}
          loggedin_email={email}
          current_raw_date={current_raw_date}
          resources={resources}
          projects={projects}
          roles={roles}
          clicked_entry={clicked_entry}
          last_locked={last_locked}
          selected_resource={selected_resource}
          selected_project_logger={selected_project_logger}
          entry_selected_resource={entry_selected_resource}
          entry_selected_project={entry_selected_project}
          entry_start_date={entry_start_date}
          entry_end_date={entry_end_date}
          entry_minutes={entry_minutes}
          entry_minutes_error={entry_minutes_error}
          entry_description={entry_description}
          entry_submission_state={entry_submission_state}
          areEntriesEmpty={this.areEntriesEmpty}
          handleResourceSelection={this.handleResourceSelection}
          handleProjectSelection={this.handleProjectSelection}
          handleChangeStartDate={this.handleChangeStartDate}
          handleChangeEndDate={this.handleChangeEndDate}
          handleChangeMinutes={this.handleChangeMinutes}
          handleChangeDescription={this.handleChangeDescription}
          handleAddTimeEntry={this.handleAddTimeEntry}
          handleEditTimeEntry={this.handleEditTimeEntry}
          handleDeleteTimeEntry={this.handleDeleteTimeEntry}
          getProjectById={this.getProjectById}
          getMinutesInHMFormat={this.getMinutesInHMFormat}
          isWeekendDay={this.isWeekendDay}
          isHoliday={this.isHoliday}
          toggleDeleteBtn={this.state.toggleDeleteBtn}
          createdBy={createdBy}
          edittedBy={edittedBy}
          formattedMinimumDate={formattedMinimumDate}
          formatInputStartDateText={this.formatInputStartDateText}
          formatInputEndDateText={this.formatInputEndDateText}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,

    name: state.LoginReducer.name,
    email: state.LoginReducer.email,
    roles: state.LoginReducer.roles,

    holidays: state.SettingReducer.holidays,
    projects: state.ProjectReducer.projects,
    resources: state.ResourceReducer.resources,

    time_entries: state.HoursReducer.time_entries,
    last_locked: state.HoursManagementReducer.last_locked,

    teams: state.TeamReducer.teams,
    booking_entries: state.BookingReducer.booking_entries,
    current_user: state.HoursReducer.current_user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),

  getHolidays: () => dispatch(ActionsCreator.getHolidays()),
  getProjects: (query) => dispatch(ActionsCreator.getProjects(query)),
  getResources: (query) => dispatch(ActionsCreator.getResources(query)),
  getWeekHours: (resid, projid, date) => dispatch(ActionsCreator.getWeekHours(resid, projid, date)),
  getLastLocked: () => dispatch(ActionsCreator.getLastLocked()),
  getWeekBookings: (resid, projid, date) =>
    dispatch(ActionsCreator.getWeekBookings(resid, projid, date)),

  addCustomTimeEntry: (data) => dispatch(ActionsCreator.addCustomTimeEntry(data)),
  deleteCustomTimeEntry: (id) => dispatch(ActionsCreator.deleteCustomTimeEntry(id)),
  updateCustomTimeEntry: (data) => dispatch(ActionsCreator.updateCustomTimeEntry(data)),

  getTeams: (query) => dispatch(ActionsCreator.getTeams(query)),
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
  setCurrentUser: (user) => dispatch(ActionsCreator.setCurrentUser(user)),
  getProjectLoggers: (projid, date) => dispatch(ActionsCreator.getProjectLoggers(projid, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(HoursLogging));
