export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 100px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 100px)',
  },
  Dialog: {
    padding: '20px',
  },
  FlexCol: {
    width: '100%',
    overflow: 'auto',
    backgroundColor: 'var(--tin-lightgrey)',
  },
  ChartRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px',
    height: '50px',
    alignItems: 'center',
  },
  Input: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    fontWeight: '400',
    width: '50px',
  },
  InputInvoicePage: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    fontWeight: '400',
    display: 'flex',
    marginLeft: 'auto',
  },
  CardsRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 20px',
    alignItems: 'center',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  Head: {
    display: 'block',
    width: '100%',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    maxHeight: '20vh',
    minHeight: '5vh',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  TableCellP: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '10px',
  },
  Discount: {
    color: 'red',
    display: 'flex',
    marginLeft: 'auto',
  },
  TableCellActivity: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '10px',
  },
  TableCell1: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    padding: '10px',
    minWidth: '20px',
  },
  TableCell3: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    padding: '10px 20px 10pxpx 10px',
    minWidth: '20px',
    whiteSpace: 'initial',
  },
  TextWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  TableCell2: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  TableContainer: {
    flex: 1,
    margin: '20px',
    boxShadow: '-1px 2px 15px -5px black',
    backgroundColor: 'white',
    borderRadius: '10px',
    minHeight: '45vh',
  },
  CentralizedRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TotalInvoiceLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0px',
    fontSize: '11px',
  },
  TotalInvoiceValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    margin: '0px',
  },
  ErrorBadge: {
    color: 'white',
    backgroundColor: 'var(--tin-red)',
    marginRight: '5px',
    height: '15px',
    width: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    padding: '2px',
  },
  GreenCheckBadge: {
    color: 'white',
    backgroundColor: 'var(--tin-green)',
    marginRight: '5px',
    height: '15px',
    width: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    borderRadius: '15px',
  },
  Cards: {
    height: '9vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    color: 'white',
    margin: '0px 20px',
    padding: '10px 20px',
    boxShadow: '-1px 2px 15px -5px black',
  },
  DashboardCard1: {
    backgroundColor: 'var(--tin-dashboardcard1)',
  },
  DashboardCard2: {
    backgroundColor: 'var(--tin-dashboardcard2)',
  },
  DashboardCard3: {
    backgroundColor: 'var(--tin-dashboardcard3)',
  },
  DashboardCard4: {
    backgroundColor: 'var(--tin-dashboardcard4)',
  },
  DashboardCard5: {
    backgroundColor: 'var(--tin-dashboardcard5)',
  },
  AdminCard1: {
    backgroundColor: 'var(--tin-admincards1)',
  },
  AdminCard2: {
    backgroundColor: 'var(--tin-admincards2)',
  },
  AdminCard3: {
    backgroundColor: 'var(--tin-admincards3)',
  },
  AdminCard4: {
    backgroundColor: 'var(--tin-admincards4)',
  },
  AdminCard5: {
    backgroundColor: 'var(--tin-admincards5)',
  },
  AdminCard6: {
    backgroundColor: 'var(--tin-admincards6)',
  },
  AdminCard7: {
    backgroundColor: 'var(--tin-admincards7)',
  },
  CardHeadings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    fontSize: '25px',
    height: '30px',
  },
  DonutChart: {
    legend: {
      display: true,
      position: 'right',
      fullWidth: false,
      labels: {
        boxWidth: 20,
        fontSize: 11,
        padding: 5,
        usePointStyle: true,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
    cutoutPercentage: 70,
  },
  PieChart: {
    legend: {
      display: true,
      position: 'right',
      fullWidth: false,
      labels: {
        boxWidth: 20,
        fontSize: 11,
        padding: 5,
        usePointStyle: true,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
  },
  TabColumns: {
    display: 'flex',
    flexDirection: 'row',
  },
  AreaChart: {
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic',
    },
  },
  ChartContainer: {
    padding: '25px 0px',
  },
  HeaderSelect: {
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    backgroundColor: 'var(--tin-blue)',
    boxShadow: '0px 2px 4px 0px black',
  },
  HeaderSelectOption: {
    color: 'white',
    backgroundColor: 'var(--tin-blue)',
    ':hover': {
      color: 'var(--tin-blue)',
      backgroundColor: 'white',
    },
  },
  ProjectDetailInvoiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '20px',
  },
  TotalLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flex: 3,
    fontSize: '12px',
  },
  TotalLabelFinal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flex: 3,
    fontSize: '12px',
    fontWeight: 'bold',
  },
  TotalLabelNew: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flex: 3,
    fontSize: '12px',
  },
  TotalValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flex: 2,
    fontSize: '11px',
    padding: '5px',
  },
  TotalContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
    alignItems: 'flex-end',
  },
  TotalContainerNew: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
    alignItems: 'flex-end',
    paddingRight: '7vh',
  },
  TotalHourContainer: {
    display: 'flex',
    flex: 8,
    flexDirection: 'column',
    padding: '5px',
    alignItems: 'flex-end',
  },
  NoDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--tin-grey)',
  },
  CardContainer: {
    height: 'calc(45vh - 30px)',
  },
  TableCellInvoiceDetailLong: {
    display: 'flex',
    flex: '2',
    alignItems: 'Left',
    justifyContent: 'start',
    padding: '10px',
    borderBottom: 'none',
    textAlign: 'center',
  },
  TableCellInvoiceDetailDefault: {
    display: 'flex',
    flex: '1',
    alignItems: 'start',
    justifyContent: 'start',
    padding: '10px',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    borderBottom: 'none',
  },
  TableCellMargin: {
    marginLeft: '10px',
  },
};
