import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { Toolbar } from '@mui/material';

import { style } from './style';
import { withSnackbar } from 'notistack';
import { hasPermission } from '../../constants/ability';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { ReceiverListView } from './ReceiverListView';
import { ReceiverDetailView } from './ReceiverDetailView';
import { DialogBoxes } from './DialogBoxes';
import * as ReactGA from 'react-ga';

const LOADER_COLOR = '#c9aa92';

class Receiver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      show_receiver: '',
      receiver_name: '',
      receiver_details: '',
      receiver_docId: '',
      receiver_selected: null,
      show_add_receiver: false,
      show_delete_popup: false,
      quill_ref: null,
      enable_affiliate: '',
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadReceiversData();
  }

  loadReceiversData = async () => {
    const { stopLoader, getAllReceivers } = this.props;

    getAllReceivers().then((res) => {
      stopLoader();
    });
  };

  toggleDeletePopup = (id) => {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
      receiver_docId: id,
    });
  };

  handleReceiverClick = (receiver) => {
    this.setState({
      edit: false,
      show_receiver: receiver,
      enable_affiliate: '',
    });
  };

  handleEditReceiver = (id) => {
    const {
      receivers,
      stopLoader,
      startLoader,
      updateReceiver,
      getAllReceivers,
      enqueueSnackbar,
      no_receivers_data,
    } = this.props;
    let { show_receiver, receiver_name, receiver_details, receiver_selected, enable_affiliate } =
      this.state;

    if (show_receiver === '' && !no_receivers_data && receivers && receivers.length > 0) {
      receiver_selected = receivers[0];
    } else if (show_receiver !== '' && !no_receivers_data && receivers) {
      receiver_selected = receivers.filter((receiver) => receiver.docId === id)[0];
    }

    if (receivers && receiver_selected) {
      console.log(receiver_selected);
      if (receiver_name === '') {
        receiver_name = receiver_selected.receiver_name;
      }
      if (receiver_details === '') {
        receiver_details = receiver_selected.receiver_details;
      }
      if (receiver_details === '') {
        enable_affiliate = receiver_selected.enable_affiliate;
      }
    }

    startLoader(LOADER_COLOR)
      .then((res) => {
        if (res) {
          return updateReceiver(id, {
            receiver_name: receiver_name,
            receiver_details: receiver_details,
            enable_affiliate: enable_affiliate,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Receivers',
            action: 'Edited a Receiver',
          });
          enqueueSnackbar('Receiver Updated!', { variant: 'success' });
          this.handleHideEditReceiverView();
          return getAllReceivers();
        } else {
          enqueueSnackbar("Receiver wasn't updated!", { variant: 'error' });
          stopLoader();
        }
      })
      .then((res) => {
        stopLoader();
      });
  };

  handleShowEditReceiverView = () => {
    this.setState({
      edit: true,
    });
  };

  handleHideEditReceiverView = () => {
    this.setState({
      edit: false,
      receiver_name: '',
      receiver_details: '',
      enable_affiliate: '',
    });
  };

  handleAddReceiver = () => {
    const { stopLoader, startLoader, saveReceiver, getAllReceivers, enqueueSnackbar } = this.props;
    let { receiver_name, receiver_details } = this.state;

    startLoader(LOADER_COLOR)
      .then((res) => {
        if (res) {
          return saveReceiver({
            receiver_name: receiver_name,
            receiver_details: receiver_details,
            enable_affiliate: false, // default value
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Receivers',
            action: 'Created a Receiver',
          });
          enqueueSnackbar('Receiver Saved!', { variant: 'success' });
          this.handleHideAddReceiver();
          return getAllReceivers();
        } else {
          enqueueSnackbar("Receiver wasn't saved!", { variant: 'error' });
          this.handleHideEditReceiverView();
          stopLoader();
        }
      })
      .then((res) => {
        this.handleHideEditReceiverView();
        stopLoader();
      });
  };

  handleShowAddReceiver = () => {
    this.setState({
      receiver_details: '',
      edit: false,
      show_add_receiver: true,
    });
  };

  handleHideAddReceiver = () => {
    this.setState({
      receiver_details: '',
      receiver_name: '',
      show_add_receiver: false,
    });
  };

  handleLineBreaks = (html) => {
    let i,
      count = 0;
    for (i = 0; i < html.length; i++) {
      if (count > 80) {
        count = 0;
        html = html.substr(0, i - 1) + '</p><p>' + html.substr(i - 1);
        i = i + 5;
        if (html.slice(i + 2, i + 6) === '</p>' && html[i + 6] !== undefined) {
          html = html.substr(0, i + 2) + html.substr(i + 9);
        }
      } else if (html.slice(i, i + 3) === '<p>') {
        i = i + 2;
        count = 0;
      } else if (html.slice(i, i + 4) === '</p>' || html.slice(i, i + 4) === '<br>') {
        i = i + 3;
        count = 0;
      } else count++;
    }
    return html;
  };

  handleQuillRef = (input) => {
    if (input !== null) {
      this.setState({
        quill_ref: input.getEditor(),
      });
    }
  };

  handleChangeDetails = async (html) => {
    let quill = this.state.quill_ref;
    let formatted_html = '';
    await (formatted_html = this.handleLineBreaks(html));
    await this.setState({
      receiver_details: formatted_html,
    });
    if (quill.getSelection() !== null) {
      let index = quill.getSelection().index;
      if (index !== null) {
        if (quill.getText(index + 1, 1) === '\n' && quill.getText(index + 2, 1) === '') {
          quill.setSelection(formatted_html.length, formatted_html.length);
        }
      }
    }
  };

  handleChangeName = (e) => {
    this.setState({
      receiver_name: e.target.value,
    });
  };

  handleDeleteReceiver = () => {
    let { stopLoader, startLoader, deleteReceiver, getAllReceivers, enqueueSnackbar } = this.props;
    let { receiver_docId } = this.state;

    startLoader(LOADER_COLOR)
      .then((res) => {
        if (res) {
          return deleteReceiver(receiver_docId);
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Receivers',
            action: 'Deleted a Receiver',
          });
          enqueueSnackbar('Receiver Deleted!', { variant: 'success' });
          this.toggleDeletePopup('');
          return getAllReceivers();
        } else {
          enqueueSnackbar("Receiver wasn't deleted!", { variant: 'error' });
          this.handleHideEditReceiverView();
          stopLoader();
        }
      })
      .then((res) => {
        this.handleHideEditReceiverView();
        stopLoader();
      });
  };

  handleAddReceiverChanges = (e, type, enable_affiliate_old = '') => {
    let { enable_affiliate } = this.state;
    console.log(enable_affiliate_old);
    console.log(enable_affiliate);
    ['receiver_name', 'receiver_details', 'receiver_selected', 'enable_affiliate'].forEach(
      (item) => {
        if (item === type) {
          this.setState({
            [item]: e.target.value,
          });
        } else if (item === type) {
          this.setState({
            [item]: JSON.stringify(e.target.value.split('\n')),
          });
        }
      },
    );
    if (type === 'enable_affiliate') {
      this.setState({
        enable_affiliate: enable_affiliate === '' ? !enable_affiliate_old : !enable_affiliate,
      });
    }
  };

  render() {
    let { roles, receivers, loading, no_receivers_data } = this.props;
    let {
      edit,
      show_receiver,
      receiver_name,
      receiver_details,
      receiver_selected,
      show_add_receiver,
      enable_affiliate,
    } = this.state;

    let show_edit_button = hasPermission(roles, 'receiver', 'can_edit');
    let disabled_create_button = receiver_name === '';

    if (show_receiver === '' && !no_receivers_data && receivers && receivers.length > 0) {
      receiver_selected = receivers[0];
    } else if (show_receiver !== '' && !no_receivers_data && receivers) {
      receiver_selected = receivers.filter((receiver) => receiver.docId === show_receiver)[0];
    }

    if (receiver_selected === undefined) {
      receiver_selected = receivers[0];
    }

    if (receivers && receiver_selected && edit) {
      if (receiver_name === '') {
        receiver_name = receiver_selected.receiver_name;
      }
      if (receiver_details === '' || receiver_details === '<p><br></p>') {
        receiver_details = receiver_selected.receiver_details;
      }
      if (enable_affiliate === '') {
        enable_affiliate = receiver_selected.enable_affiliate;
      }
    }
    console.log(enable_affiliate);

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div className="breadcrumb-link" style={style.HeaderText}>
            Receivers
          </div>
          {hasPermission(roles, 'receiver', 'can_create') ? (
            <button style={style.FilledButton} onClick={this.handleShowAddReceiver}>
              + Add Receiver
            </button>
          ) : null}
        </Toolbar>
        <div style={loading || no_receivers_data ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#d43a26" height={50} width={50} />
          ) : no_receivers_data ? (
            <EmptyContainer />
          ) : (
            <>
              <ReceiverListView
                receivers={receivers}
                receiver_selected={receiver_selected}
                handleReceiverClick={this.handleReceiverClick}
              />
              <div style={style.ListViewContainer}>
                <ReceiverDetailView
                  edit={edit}
                  receiver={receiver_selected}
                  receiver_name={receiver_name}
                  receiver_details={receiver_details}
                  enable_affiliate={enable_affiliate}
                  show_edit_button={show_edit_button}
                  handleEditReceiver={this.handleEditReceiver}
                  disabled_create_button={disabled_create_button}
                  handleAddReceiverChanges={this.handleAddReceiverChanges}
                  handleShowEditReceiverView={this.handleShowEditReceiverView}
                  handleHideEditReceiverView={this.handleHideEditReceiverView}
                  modules={Editor_modules}
                  handleChangeDetails={this.handleChangeDetails.bind(this)}
                  toggleDeletePopup={this.toggleDeletePopup.bind(this)}
                  handleQuillRef={this.handleQuillRef}
                  roles={roles}
                />
              </div>
            </>
          )}
          <DialogBoxes
            receiver_docIid={this.state.receiver_docId}
            receiver_name={receiver_name}
            receiver_details={receiver_details}
            show_add_receiver={show_add_receiver}
            handleAddReceiver={this.handleAddReceiver}
            handleHideAddReceiver={this.handleHideAddReceiver}
            handleShowAddReceiver={this.handleShowAddReceiver}
            disabled_create_button={disabled_create_button}
            handleAddReceiverChanges={this.handleAddReceiverChanges}
            modules={Editor_modules}
            handleChangeDetails={this.handleChangeDetails.bind(this)}
            handleChangeName={this.handleChangeName.bind(this)}
            toggleDeletePopup={this.toggleDeletePopup.bind(this)}
            show_delete_popup={this.state.show_delete_popup}
            deleteReceiver={this.handleDeleteReceiver}
            handleQuillRef={this.handleQuillRef}
          />
        </div>
      </div>
    );
  }
}

const Editor_modules = {
  toolbar: false,
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
    loading: state.LoaderReducer.loading,
    receivers: state.ReceiverReducer.receivers,
    no_receivers_data: state.ReceiverReducer.no_receivers_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  saveReceiver: (data) => dispatch(ActionsCreator.saveReceiver(data)),
  getAllReceivers: () => dispatch(ActionsCreator.getAllReceivers()),
  updateReceiver: (id, data) => dispatch(ActionsCreator.updateReceiver(id, data)),
  deleteReceiver: (id) => dispatch(ActionsCreator.deleteReceiver(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Receiver));
