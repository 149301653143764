export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ReminderButton: {
    marginRight: '8px',
    color: 'var(--tin-cyan)',
    backgroundColor: 'white',
    borderColor: 'var(--tin-cyan)',
    border: 'solid 1px',
    borderRadius: '20px',
    minHeight: '22px',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  WarningEmailContainer: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
  },
  MainHeading: {
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: 'var(--tin-cyan)',
    color: 'white',
    minHeight: 'inherit',
  },
  TotalHoursInput: {
    width: '45px',
    minWidth: '45px',
    border: '0',
    background: 'white',
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: '5px',
    fontSize: '12px',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'white',
  },
  NewLastLockedInput: {
    border: '0px',
    background: 'white',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '1px 1px',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
  },
  FilledButton: {
    backgroundColor: 'var(--tin-cyan)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cyan)',
    border: 'solid 1px var(--tin-cyan)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '12px',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  FileNameContainer: {
    margin: '10px 20px',
  },
  LabelRows1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  GenerateLabel: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
  },
  Rows: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  LabelRows: {
    display: 'flex',
    flexDirection: 'row',
  },
  EmployeeNameContainer: {
    flex: 1,
    minWidth: '20px',
    justifyContent: 'center',
    alignIems: 'center',
    display: 'flex',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  TableContainer: {
    flex: 1,
    padding: '0px 20px',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'calc(100vh - 164px)',
  },
  Body1: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: '30vh',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingLeft: '10px 15px',
  },
  TableCell1: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    paddingLeft: '10px 15px',
  },
  HoursDistributionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  HoursCount: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    paddingLeft: '30px',
    flexWrap: 'wrap',
  },
  LabelValueCol: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    margin: '0px',
    fontSize: '12px',
  },
  Value: {
    fontSize: '14px',
    margin: '0px',
    fontWeight: 'bold',
  },
  DateStyle: {
    textAlign: 'center',
    borderColor: 'rgba(0,0,0,0.1)',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    marginTop: '5px',
    outline: 'none',
    borderRadius: '5px',
  },
  ShowFiltersButtonContainer: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  ShowFiltersButton: {
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '5px',
    fontSize: '11px',
    cursor: 'pointer',
    color: 'var(--tin-dark-green)',
    marginRight: '20px',
  },
  RowItems1: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '22px',
  },
  FilterSelect: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-dark-green)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '30%',
    outline: 'none',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
};
