import * as Types from '../../constants/actionconstants';
const initialState = {
  no_billing_data: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_BILLING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.FETCH_BILLING_SUCCESS:
      return {
        ...state,
        billing_data: action.payload,
        loading: false,
        no_billing_data: false,
      };
    case Types.FETCH_BILLING_FAILURE:
      return {
        ...state,
        loading: false,
        billing_data: action.payload,
        no_billing_data: true,
      };
    default:
      return state;
  }
};
