import React, { Component } from 'react';

import Team from '../../components/Team';
import './TeamsPage.css';

class TeamsPage extends Component {
  render() {
    return (
      <div className="teampage-main">
        <Team />
      </div>
    );
  }
}

export default TeamsPage;
