import * as React from 'react';
import Select from 'react-select';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 20,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
    boxShadow: 'none',
  }),
  option: (styles, { data }) => {
    const color = data.color;
    return {
      ...styles,
      ...dot(color),
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), marginLeft: 3 }),
  valueContainer: (styles) => ({ ...styles, padding: 0 }),
  menu: (styles) => ({ ...styles, zIndex: 2 }),
};

function CustomSelect(props) {
  const getProjectById = (project_id) => {
    const { projects } = props;
    if (projects && projects.length > 0) {
      for (var i = 0; i < projects.length; i++) {
        if (parseInt(projects[i].id) === parseInt(project_id)) {
          return projects[i];
        }
      }
    }
  };

  const getColor = (project) => {
    let color;
    if (project.color) color = project.color;
    else color = '#01706c';
    return color;
  };

  const makeOptions = (options) => {
    if (options) {
      let projects = [];
      options.forEach((project) => {
        if (project.active && project.id !== props.entry_selected_project) {
          const color = getColor(project);
          projects.push({ value: project.id, label: project.name, color });
        }
      });

      return projects;
    }

    return []; // no options
  };

  const makeDefaultValue = (project) => {
    if (project) {
      const color = getColor(project);
      return { value: project.id, label: project.name, color };
    }
    return null; // no default value
  };

  const onChange = (values) => {
    let event = {};
    event.target = { value: values.value, name: 'projectId' };
    props.handleProjectSelection(event);
  };

  const project = getProjectById(props.entry_selected_project)
    ? getProjectById(props.entry_selected_project)
    : '';
  return (
    <div>
      <Select
        styles={style}
        options={makeOptions(props.projects)}
        onChange={onChange}
        defaultValue={makeDefaultValue(project)}
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default React.memo(CustomSelect);
