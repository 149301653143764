import React from 'react';

import Radium from 'radium';

import { style } from './style';
import { List, ListItem, NativeSelect } from '@mui/material';

import hoursprojectsdropdownicon from '../../assets/images/hours-projects-dropdown-dark-icon.svg';
import hoursteamsdropdownicon from '../../assets/images/hours-teams-dropdown-dark-icon.svg';
import { ThreeDots } from 'react-loader-spinner';
import Select from 'react-select';

const selectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
  }),
};

/*
  -- renders a List of items based on the view item selected in HoursToolbar (HoursToolbar.jsx)
  -- shows resources if resources selected else projects etc.
*/
const MyComponent = (props) => {
  const renderLoadingContainer = () => {
    return (
      <div style={style.LoaderContainer}>
        <ThreeDots
          height="10"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  };

  const renderEmptyMessage = (message) => {
    return <div style={{ textAlign: 'center' }}>{message}</div>;
  };

  const getUserBasedProjectList = (projects) => {
    let filtered_projects;

    if (props.roles.includes('Super Admin')) {
      filtered_projects = projects;
    } else {
      // filter projects of this project manager
      filtered_projects = projects.filter((project) => {
        let project_managers = project.project_managers;

        if (project_managers) {
          for (let project_manager in project_managers) {
            if (project_managers[project_manager].email === props.loggedin_email) {
              return project;
            }
          }
        }
      });
    }

    return filtered_projects;
  };

  const sortFunction = (list) => {
    // Sort projects based on activity status (active projects first)
    const result = list.sort((a, b) => {
      if (a.active && !b.active) {
        return -1; // a is active, b is inactive, a comes before b
      } else if (!a.active && b.active) {
        return 1; // a is inactive, b is active, b comes before a
      }
      return 0; // both projects have the same activity status, maintain the order
    });
    return result;
  };

  const renderProjectsList = () => {
    const { showProjects, project_selected, handleProjectClick } = props;

    if (showProjects.length === 0) {
      return renderEmptyMessage('No Projects Assigned');
    }

    const filteredProjects = getUserBasedProjectList(props.showProjects);

    const sortedProjects = sortFunction(filteredProjects);

    return sortedProjects.map((project, index) => {
      const isActive = project.active;
      const isSelected = project_selected && project_selected.name === project.name;
      let itemStyle;
      if (isActive) {
        itemStyle = isSelected ? style.SelectedProjectItem : style.UnselectedProjectItem;
      } else {
        itemStyle = isSelected ? style.SelectedProjectItem : style.UnselectedInactiveProjectItem;
      }

      return (
        <ListItem
          button
          key={index}
          onClick={() => handleProjectClick(project.name)}
          style={itemStyle}>
          <div>{project.name}</div>
        </ListItem>
      );
    });
  };

  const renderTeamsList = () => {
    const { showTeams, selected_team, handleTeamClick } = props;

    if (showTeams.length === 0) {
      return renderEmptyMessage('No Teams Assigned');
    }

    // Sort teams based on activity status (active teams first)
    const sortedTeams = sortFunction(showTeams);

    return sortedTeams.map((team, index) => {
      const isActive = team.active;
      const isSelected = selected_team && selected_team.name === team.name;
      let itemStyle;
      if (isActive) {
        itemStyle = isSelected ? style.SelectedProjectItem : style.UnselectedProjectItem;
      } else {
        itemStyle = isSelected ? style.SelectedProjectItem : style.UnselectedInactiveProjectItem;
      }

      return (
        <ListItem
          button
          key={index}
          onClick={() => handleTeamClick(team.display_name)}
          style={itemStyle}>
          <div>{team.display_name}</div>
        </ListItem>
      );
    });
  };

  const getInitials = function (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const Chip = ({ name, isSelected }) => {
    return (
      <div style={style.ChipContainer}>
        <div style={isSelected ? style.SelectedChip : style.UnselectedChip}>
          {getInitials(name)}
        </div>
      </div>
    );
  };

  const makeOptions = (options, key) => {
    if (options) {
      let values = [];
      options.forEach((option) => {
        values.push({ value: option[key], label: option[key] });
      });

      return values;
    }

    return []; // no options
  };

  const makeValueObject = (value) => {
    if (value) {
      return { value: value, label: value };
    }
    return {}; // no default value
  };

  const onProjectChange = (values) => {
    let event = {};
    event.target = { value: values.value, name: 'projectId' };
    props.handleProjectClick(values.value);
  };

  const onTeamChange = (values) => {
    props.handleTeamClick(values.value);
  };

  return (
    <>
      <List style={style.ListView}>
        {props.selected_view === 'resources' ? (
          <>
            {props.resources && props.resources.length > 10 ? (
              <input
                type="text"
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: 'solid 2px #3D9477',
                  margin: '5px',
                  color: 'var(--tin-grey)',
                  width: '95%',
                  outline: 'none',
                }}
                value={props.search_value}
                onChange={props.handleSearch}
                placeholder="Search Resources"
              />
            ) : null}

            {!props.resource_fetching && props.showResources ? (
              sortFunction(props.showResources).map((resource, index) => {
                const isSelected =
                  props.resource_selected && props.resource_selected.name === resource.name;
                const isLoggedinUser = props.loggedin_email === resource.email;
                let itemStyle;
                if (resource.active) {
                  itemStyle = isSelected
                    ? style.SelectedResourceItem
                    : style.UnselectedResourceItem;
                } else {
                  itemStyle = isSelected
                    ? style.SelectedResourceItem
                    : style.UnselectedInactiveResourceItem;
                }
                return (
                  <ListItem
                    button
                    key={index}
                    onClick={() => props.handleResourceClick(resource.name)}
                    style={itemStyle}>
                    <div>
                      <Chip name={resource.name} isSelected={isSelected} />
                      {resource.name} {isLoggedinUser && <span>(Me)</span>}
                    </div>
                  </ListItem>
                );
              })
            ) : (
              <div>{renderLoadingContainer()}</div>
            )}
          </>
        ) : null}
        {props.selected_view === 'projects' ? (
          <>
            {props.projects &&
            Object.keys(props.project_selected).length == 0 &&
            getUserBasedProjectList(props.projects).length > 10 ? (
              <input
                type="text"
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: 'solid 2px #3D9477',
                  margin: '5px',
                  color: 'var(--tin-grey)',
                  width: '95%',
                  outline: 'none',
                }}
                value={props.search_value}
                onChange={props.handleSearch}
                placeholder="Search Projects"
              />
            ) : null}

            {props.project_selected && props.project_selected.name ? (
              <div style={style.BackProjectsButton}>
                <img src={hoursprojectsdropdownicon} alt="" />
                <div style={{ width: 200 }}>
                  <Select
                    styles={selectStyle}
                    options={makeOptions(getUserBasedProjectList(props.showProjects), 'name')}
                    onChange={onProjectChange}
                    defaultValue={makeValueObject(props.project_selected.name)}
                  />
                </div>
              </div>
            ) : props.showProjects && props.selected_view === 'projects' ? (
              renderProjectsList()
            ) : (
              <div>{renderLoadingContainer()}</div>
            )}

            {/* During API call show loader, else show list of hour loggers */}
            {props.resource_fetching ? (
              <div style={style.LoaderContainer}>
                <ThreeDots
                  type="ThreeDots"
                  height="10"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              props.project_loggers.map((project_logger) => {
                const isSelected = props.selected_project_logger === project_logger.id;
                return (
                  <ListItem
                    button
                    key={project_logger.id}
                    onClick={() =>
                      props.handleProjectLoggerClick(props.project_selected.id, project_logger.id)
                    }
                    style={
                      props.selected_project_logger === project_logger.id
                        ? style.SelectedProjectItem
                        : style.UnselectedProjectItem
                    }>
                    {props.loggedin_email === project_logger.email ? (
                      <div>
                        <Chip name={project_logger.name} isSelected={isSelected} />
                        {project_logger.name} <span>(Me)</span>
                      </div>
                    ) : (
                      <div>
                        <Chip name={project_logger.name} isSelected={isSelected} />
                        {project_logger.name}
                      </div>
                    )}
                  </ListItem>
                );
              })
            )}
          </>
        ) : null}

        {props.selected_view === 'teams' ? (
          <>
            {props.teams && props.teams.length > 10 && !props.selected_team ? (
              <input
                type="text"
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: 'solid 2px #3D9477',
                  margin: '5px',
                  color: 'var(--tin-grey)',
                  width: '95%',
                  outline: 'none',
                }}
                value={props.search_value}
                onChange={props.handleSearch}
                placeholder="Search Teams"
              />
            ) : null}

            {props.selected_team && props.selected_team.display_name ? (
              <>
                <div style={style.BackProjectsButton}>
                  <img src={hoursteamsdropdownicon} alt="" />
                  <div style={{ width: 200 }}>
                    <Select
                      styles={selectStyle}
                      options={makeOptions(props.showTeams, 'display_name')}
                      onChange={onTeamChange}
                      defaultValue={makeValueObject(props.selected_team.display_name)}
                    />
                  </div>
                </div>
                {/* During API call show loader, else show list of hour loggers */}
                {props.resource_fetching ? (
                  <div style={style.LoaderContainer}>
                    <ThreeDots
                      type="ThreeDots"
                      height="10"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  props.selected_team_members.map((team_member) => {
                    const isSelected = props.selected_project_logger === team_member.id;
                    return (
                      <ListItem
                        button
                        key={team_member.id}
                        onClick={() => props.handleTeamMemberClick(team_member.id)}
                        style={
                          props.selected_project_logger === team_member.id
                            ? style.SelectedProjectItem
                            : style.UnselectedProjectItem
                        }>
                        {props.loggedin_email === team_member.email ? (
                          <div>
                            <Chip name={team_member.name} isSelected={isSelected} />
                            {team_member.name} <span>(Me)</span>
                          </div>
                        ) : (
                          <div>
                            <Chip name={team_member.name} isSelected={isSelected} />
                            {team_member.name}
                          </div>
                        )}
                      </ListItem>
                    );
                  })
                )}
              </>
            ) : props.showTeams ? (
              renderTeamsList()
            ) : (
              <div>{renderLoadingContainer()}</div>
            )}
          </>
        ) : null}
        {props.selected_view === 'private' ? (
          <ListItem button style={style.SelectedResourceItem}>
            <div>My View (Default)</div>
          </ListItem>
        ) : null}
      </List>
    </>
  );
};
export const HoursListView = Radium(MyComponent);
