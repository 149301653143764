import React from 'react';

import Radium from 'radium';
import { Dialog } from '@mui/material';

import { style } from './style';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_add_resource}
      onClose={props.handleHideAddResource}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Resource</h2>
        <p style={style.Cross} onClick={props.handleHideAddResource}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={0}
            name="resource_name"
            type="text"
            value={props.resource_name}
            style={style.Select}
            placeholder="Name"
            onChange={props.handleResourceName}
          />
        </div>
        <div style={style.RowItems}>
          <input
            key={1}
            name="resource_email"
            type="text"
            value={props.resource_email}
            style={style.Select}
            placeholder="Email"
            onChange={props.handleResourceEmail}
          />
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={2}
            name="resource_status"
            value={props.resource_status}
            style={style.Select}
            onChange={props.handleResourceStatus}>
            <option value={''} disabled>
              Select Resource Status
            </option>
            <option value={true}>Active</option>
            <option value={false}>InActive</option>
          </select>
        </div>
        <div style={style.RowItems}>
          <select
            key={3}
            name="resource_association"
            value={props.resource_association}
            style={style.Select}
            onChange={props.handleResourceAssociation}>
            <option value={''} disabled>
              Select Resource Association
            </option>
            <option value={true}>Part-Time</option>
            <option value={false}>Full-Time</option>
          </select>
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disabled_create_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleAddResource}
          disabled={props.disabled_create_button}>
          Create
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_delete_popup}
      onClose={() => props.toggleDeletePopupResources()}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h3 style={style.Heading}>Are you sure you want to delete this entry?</h3>
        <p style={style.Cross} onClick={() => props.toggleDeletePopupResources()}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={() => props.handleDelete()}>
          {'Yes'}
        </button>
        <button
          style={style.CancelBorderedButton}
          onClick={() => props.toggleDeletePopupResources()}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
    <Dialog open={props.show_confirmation_popup} fullWidth={true} maxWidth="sm">
      <div style={style.HeadingRow}>
        <h3 style={style.Heading}>Do you want to continue to edit this entry?</h3>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={() => props.onContinue()}>
          {'Yes'}
        </button>
        <button
          style={style.CancelBorderedButton}
          onClick={() => props.onCancel(props.resource_name)}>
          {'No'}
        </button>
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);
