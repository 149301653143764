import * as Types from '../../constants/actionconstants';
const initialState = {
  unbillable: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PROJECTS_REQUEST:
      return {
        ...state,
        projects: null,
      };
    case Types.GET_PROJECTS_SUCCESS:
      const projectsMap = new Map(
        action.payload.map((project) => {
          return [project.id, project];
        }),
      );
      return {
        ...state,
        projects: action.payload,
        projectsMap: projectsMap,
        loadingProjects: false,
        no_data: false,
      };
    case Types.GET_PROJECTS_FAILURE:
      return {
        ...state,
        loadingProjects: false,
        no_data: true,
        projects: [],
      };
    case Types.ADD_PROJECT_REQUEST:
      return {
        ...state,
      };
    case Types.ADD_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case Types.ADD_PROJECT_FAILURE:
      return {
        ...state,
      };
    case Types.GET_UNBILLABLE_PROJECTS_REQUEST:
      return {
        ...state,
      };
    case Types.GET_UNBILLABLE_PROJECTS_SUCCESS:
      return {
        ...state,
        unbillable: action.payload,
      };
    case Types.GET_UNBILLABLE_PROJECTS_FAILURE:
      return {
        ...state,
        unbillable: [],
      };
    case Types.UPDATE_PROJECT_CONFIG_REQUEST:
      return {
        ...state,
        loadingProjects: true,
      };
    case Types.UPDATE_PROJECT_CONFIG_SUCCESS:
      return {
        ...state,
        loadingProjects: true,
      };
    case Types.UPDATE_PROJECT_CONFIG_FAILURE:
      return {
        ...state,
        loadingProjects: false,
      };
    case Types.GET_PROJECT_HOURS_REQUEST:
      return {
        ...state,
        no_project_records: true,
      };
    case Types.GET_PROJECT_HOURS_SUCCESS:
      return {
        ...state,
        no_project_records: false,
        project_records: action.payload,
      };
    case Types.GET_PROJECT_HOURS_FAILURE:
      return {
        ...state,
        no_project_records: true,
      };
    default:
      return state;
  }
};
