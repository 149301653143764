import React from 'react';

import { Input, TableRow, TableCell } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { style } from '../StreamlineView/style';
//import { getEmployeeData, formatFloat } from '../../../Selectors/index';

export const ExpensesTable = (props) =>
  props.expenses.map((expense, index) => {
    const expenseAmountDisplayValue =
      'expense_amount_display_string' in expense
        ? expense.expense_amount_display_string
        : expense.expense_amount;
    return props.edit && props.showInvoiceData ? (
      <TableRow key={index} style={style.TableRow}>
        <TableCell style={style.TableCell3}>Expense ({expense.expense_label})</TableCell>
        <TableCell style={style.TableCell}>
          <Input
            style={style.InputInvoicePage}
            onChange={(event) => props.handleChange(event, 'expense', '', index)}
            type="number"
            value={expenseAmountDisplayValue}
          />
        </TableCell>
        <TableCell style={style.TableCell}>
          <Delete style={{ color: 'red' }} onClick={() => props.handleDeleteRowExpense(index)} />
        </TableCell>
      </TableRow>
    ) : expense.expense_amount ? (
      <TableRow key={index} style={style.TableRow}>
        <TableCell style={style.TableCell3}>Expense ({expense.expense_label})</TableCell>
        <TableCell style={style.TableCell}>
          <p style={{ display: 'flex', marginLeft: 'auto' }}>${expense.expense_amount}</p>
        </TableCell>
      </TableRow>
    ) : null;
  });
