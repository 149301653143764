import Moment from 'moment';
import Radium from 'radium';
import { style } from './style';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import calendarGrey from '../../assets/images/calendar-grey.svg';
import editIcon from '../../assets/images/Shape.svg';
import './hover-menu.css';
const MyComponent = (props) => {
  return (
    <div
      className="tooltiptext"
      onClick={(event) => {
        event.stopPropagation(); //to prevent the edit dialogue box from opening
      }}>
      <div
        className="HeaderMain"
        style={{
          backgroundColor: props.getProjectColor(props.entry.projectId),
        }}>
        <div className="PopupHeader">
          <p
            className="NameTag"
            style={{
              color: props.getProjectColor(props.entry.projectId),
            }}>
            {props.getNameInitials(props.entry.resourcename)}
          </p>
          {!props.isLocked && props.can_edit ? (
            <div className="EditOptions">
              <button
                className="DialogDeleteBtn"
                onClick={() => {
                  props.handleDeleteEntryFromPopup(props.toDelete);
                }}>
                <DeleteOutlineOutlined style={{ width: 23, height: 23 }} />
              </button>
              <button
                className="DialogDeleteBtn"
                onClick={() => {
                  props.handleEditEntryFromPopup(props.entry);
                }}>
                <img
                  src={editIcon}
                  style={{
                    height: 19.5,
                  }}></img>
              </button>
            </div>
          ) : null}
        </div>
        <div className="ProjectHeading">{props.entry.projectname}</div>
      </div>
      <div className="CalendarDiv">
        <img
          src={calendarGrey}
          alt=""
          style={{
            marginRight: 5,
            height: 20,
            marginTop: 16,
          }}
        />
        <div className="DateDiv">
          <div className="date">{Moment(props.entry.start_date).format('MMMM D, YYYY')}</div>
          <div className="hours">{props.getHoursfromMinutes(props.entry.minutes)}</div>
        </div>
      </div>
      <div className="FooterDiv">
        <div
          className="NameTag"
          style={{
            color: props.getProjectColor(props.entry.projectId),
            border: '1px solid',
          }}>
          {props.entry.events
            ? props.getNameInitials(props.entry.events[props.entry.events.length - 1].user_name)
            : props.getNameInitials(props.entry.resourcename)}
        </div>
        <div
          className="ActivityLog"
          style={{
            color: props.getProjectColor(props.entry.projectId),
          }}>
          {props.entry.events ? (
            <span>
              {props.entry.events[props.entry.events.length - 1].type}{' '}
              {props.formatTimeAgo(props.entry.events[props.entry.events.length - 1].event_at)}
              {props.entry.events[props.entry.events.length - 1].user_name}
            </span>
          ) : (
            `Created by ${props.entry.resourcename}`
          )}
        </div>
      </div>
    </div>
  );
};
export const HoverMenu = Radium(MyComponent);
