import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Moment from 'moment';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { style } from '../style';
import Radium from 'radium';
import { CustomDatePicker } from '../../Utility/CustomDatePicker/index';
import { withSnackbar } from 'notistack';
class ProjectReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandRow: -1,
      startDate: Moment().clone().startOf('month').format('YYYY-MM-DD'),
      endDate: Moment().clone().endOf('month').format('YYYY-MM-DD'),
      has_apply_button: true,
    };
  }

  componentDidMount() {
    this.props.startLoader('var(--tin-pink)');
    this.onLoad();
  }

  onLoad = async (isChanged) => {
    const { startDate, endDate } = this.state;
    let { range, getHoursRange, stopLoader, fetchReportTimeEntries, report_hours } = this.props;

    if (isChanged) {
      await fetchReportTimeEntries(startDate, endDate, 'json2');
      await getHoursRange();
    }

    !report_hours && (await fetchReportTimeEntries(startDate, endDate, 'json2'));
    !range && (await getHoursRange());

    await stopLoader();
  };

  setExpandRow = (index) => {
    this.setState({
      expandRow: this.state.expandRow === index ? -1 : index,
    });
  };

  formatHoursToHM = (hoursStr) => {
    // format would be in X.XXh
    let hours = hoursStr.split('h')[0];

    let hour = parseInt(hours.split('.')[0]); // H
    let minutes = parseFloat(hours.split('.')[1]) * 0.6; // MM

    return hour + 'h ' + Math.round(minutes) + 'm';
  };

  onDateChange = async (e) => {
    if (e.from) {
      const convertDate = { month: e.from.month - 1, day: e.from.day, year: e.from.year };
      this.setState({ startDate: Moment(convertDate).format('YYYY-MM-DD') });
    }
    if (e.to) {
      const convertDate = { month: e.to.month - 1, day: e.to.day, year: e.to.year };
      this.setState({ endDate: Moment(convertDate).format('YYYY-MM-DD') });
    } else {
      this.setState({ endDate: null });
    }
  };

  onDateSelect = () => {
    const { startDate, endDate } = this.state;
    const { enqueueSnackbar } = this.props;
    if (startDate && endDate) {
      this.onLoad(true);
    } else {
      enqueueSnackbar('Select proper date range', { variant: 'error' });
    }
  };

  render() {
    const { startDate, endDate, has_apply_button } = this.state;
    const { report_hours, goBack } = this.props;
    const maxDate = Moment().clone().endOf('month').format('YYYY-MM-DD');
    const dateFrom = {
      year: Moment(startDate).year(),
      month: Moment(startDate).month() + 1,
      day: Moment(startDate).date(),
    };
    const dateTo = endDate
      ? {
          year: Moment(endDate).year(),
          month: Moment(endDate).month() + 1,
          day: Moment(endDate).date(),
        }
      : null;

    const dateRange = {
      from: dateFrom,
      to: dateTo,
    };
    return (
      <>
        <div style={style.HourContainer}>
          <div style={style.HourLoggingTable}>
            <div style={style.TableRow}>
              <div style={style.HeaderTextWithBackIcon} onClick={goBack}>
                <ArrowBackIosRounded />
                Projects
              </div>
              <div style={style.TimeContainer}>
                <CustomDatePicker
                  datePickerValue={dateRange}
                  handleChangeDate={this.onDateChange}
                  formattedMinimumDate={null}
                  maximumDate={{
                    year: Moment(maxDate).year(),
                    month: Moment(maxDate).month() + 1,
                    day: Moment(maxDate).date(),
                  }}
                  has_apply_button={has_apply_button}
                  button_name={'Apply'}
                  onDateSelect={this.onDateSelect}
                  style={style.DateStyle}></CustomDatePicker>
              </div>
            </div>
            <div style={{ ...style.TableRow, ...style.RowHeader }}>
              <div style={style.RowBlock}>
                <p>Name</p>
              </div>
              <div style={style.RowBlock}>
                <p>Logged Hours</p>
              </div>
            </div>

            {report_hours &&
              report_hours.map((obj, index) => {
                return (
                  <div key={index}>
                    <div style={style.TableRow}>
                      <div
                        style={{ ...style.RowBlock, cursor: 'pointer' }}
                        onClick={() => {
                          this.setExpandRow(index);
                        }}>
                        {this.state.expandRow === index ? (
                          <ArrowDropDownIcon style={style.RowIcon} />
                        ) : (
                          <ArrowRightIcon style={style.RowIcon} />
                        )}
                        {obj.Project}
                      </div>

                      <div style={style.RowBlock}>
                        {parseFloat(obj.Hours) % 1
                          ? this.formatHoursToHM(obj.Hours)
                          : parseInt(obj.Hours) + 'h'}
                      </div>
                    </div>
                    {this.state.expandRow === index
                      ? obj.Resources.map((resource, i) => {
                          const name = Object.keys(resource)[0];
                          const splitName = name.split(' ');
                          const initials = splitName[0][0] + splitName[1][0];

                          return (
                            <div key={i} style={style.TableRow}>
                              <div style={{ ...style.RowBlock }}>
                                <div style={style.nameRowIcon}>{initials}</div>
                                {name}
                              </div>
                              <div style={style.RowBlock}>
                                {parseFloat(resource[name]) % 1
                                  ? this.formatHoursToHM(resource[name])
                                  : parseInt(resource[name]) + 'h'}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    range: state.HoursManagementReducer.range,
    report_hours: state.ReportReducer.report_hours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  fetchReportTimeEntries: (start, end, format) =>
    dispatch(ActionsCreator.fetchReportTimeEntries(start, end, format)),
  getHoursRange: () => dispatch(ActionsCreator.getHoursRange()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withSnackbar(ProjectReport),
  Radium(ProjectReport),
);
