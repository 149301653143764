import React, { Component } from 'react';
import { style } from './style';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { getTotalFromInvoiceData } from '../../Selectors/index';

export class LedgerTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalNumberOfInvoices: 0,
      totalInvoicesAmount: 0,
      totalNumberOfPayments: 0,
      totalPaymentsAmount: 0,
    };
  }
  componentDidMount() {
    this.setState({
      totalNumberOfInvoices: 0,
      totalInvoicesAmount: 0,
      totalNumberOfPayments: 0,
      totalPaymentsAmount: 0,
    });
  }
  render() {
    let { totalNumberOfInvoices, totalInvoicesAmount, totalNumberOfPayments, totalPaymentsAmount } =
      this.state;

    return (
      <div>
        <Table style={style.Table}>
          <TableHead style={style.Head}>
            <TableRow style={style.TableRow}>
              <TableCell style={style.TableCell}>Total Invoices #</TableCell>
              <TableCell style={style.TableCell}>Total Invoices $</TableCell>
              <TableCell style={style.TableCell}>Total Payments #</TableCell>
              <TableCell style={style.TableCell}>Total Payments $</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={style.Body}>
            {this.props.ledger !== undefined
              ? this.props.ledger.map((transaction, index) => {
                  if (index === 0) {
                    totalPaymentsAmount = 0;
                    totalNumberOfPayments = 0;
                  }
                  if (transaction.type === 'debit') {
                    totalPaymentsAmount =
                      parseFloat(totalPaymentsAmount) + parseFloat(transaction.amount);
                    totalNumberOfPayments++;
                  }
                  return null;
                })
              : null}
            {this.props.invoices !== undefined
              ? this.props.invoices.map((invoice, index) => {
                  if (this.props.project !== undefined) {
                    if (index === 0) {
                      totalInvoicesAmount = 0;
                      totalNumberOfInvoices = 1;
                      totalInvoicesAmount =
                        parseFloat(totalInvoicesAmount) +
                        getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data));
                    } else {
                      totalNumberOfInvoices++;
                      totalInvoicesAmount =
                        parseFloat(totalInvoicesAmount) +
                        getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data));
                    }
                  }
                  return null;
                })
              : null}

            <TableRow style={style.TableRow}>
              <TableCell style={style.TableCell}>
                {this.props.is_invoices_null ? 0 : totalNumberOfInvoices}
              </TableCell>

              <TableCell style={style.TableCell}>
                <div
                  style={{
                    color: 'black',
                  }}>
                  {this.props.is_invoices_null ? 0 : totalInvoicesAmount}
                </div>
              </TableCell>

              <TableCell style={style.TableCell}>
                <div style={{ color: 'black' }}>
                  {this.props.is_ledgers_null ? 0 : totalNumberOfPayments}
                </div>
              </TableCell>

              <TableCell style={style.TableCell}>
                <div style={{ color: 'black' }}>
                  ${this.props.is_ledgers_null ? 0 : totalPaymentsAmount}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {this.props.is_ledgers_null === false || this.props.is_invoices_null === false ? (
          <div style={style.TotalContainer}>
            <label style={style.TotalLabel}>Final Balance</label>
            <p
              style={
                totalInvoicesAmount - totalPaymentsAmount > 0
                  ? { color: 'red' }
                  : { color: 'green' }
              }>
              ${totalInvoicesAmount - totalPaymentsAmount}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LedgerTableView;
