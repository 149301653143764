import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { style } from './style';

export const MissingHoursDefaulters = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        {['Hours Defaulters', 'Missing Hours'].map((item, index) => {
          return (
            <TableCell key={index} style={style.TableCellP}>
              {item}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.defaulters.map((item, index) => {
        return (
          <TableRow key={index} style={style.TableRow}>
            <TableCell style={style.TableCellP}>{item[0]}</TableCell>
            <TableCell style={style.TableCellP}>{item[1]}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
