import React, { Component } from 'react';

import HoursLogging from '../../components/HoursLogging';
import './HoursLoggingPage.css';

class HoursLoggingPage extends Component {
  render() {
    return (
      <div className="hours-logging-page-main">
        <HoursLogging />
      </div>
    );
  }
}

export default HoursLoggingPage;
