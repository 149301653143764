import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const exchangeLoginCode = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.LOGIN_CODE_EXCHANGE_REQUEST,
      });

      API.request('post', EndPoints.Login, data)
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data,
            type: Types.LOGIN_CODE_EXCHANGE_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.LOGIN_CODE_EXCHANGE_FAILURE,
          });
        });
    });
};

export const checkLoggedIn = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.LOGGEDIN_CHECK_REQUEST,
      });

      API.request('get', EndPoints.LoggedIn)
        .then((response) => {
          if (response.data.authenticated) {
            dispatch({
              payload: response.data,
              type: Types.LOGGEDIN_CHECK_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.LOGGEDIN_CHECK_FAILURE,
            });
          }
          resolve({
            completed: true,
            ...response.data,
          });
        })
        .catch((err) => {
          resolve({
            completed: true,
            authenticated: false,
          });
          dispatch({
            type: Types.LOGGEDIN_CHECK_FAILURE,
          });
        });
    });
};

export const logout = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.LOGOUT_REQUEST,
      });

      API.request('post', EndPoints.Logout)
        .then((response) => {
          dispatch({
            payload: response.data,
            type: Types.LOGOUT_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.LOGOUT_FAILURE,
          });
        });
    });
};
