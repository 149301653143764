import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { Toolbar } from '@mui/material';

import { style } from './style';
import { withSnackbar } from 'notistack';
import { BillingTableView } from './BillingTableView';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { getMonthsFromBillingData } from '../../Selectors/index';

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_filters: false,
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadBillingData();
  }

  loadBillingData = async () => {
    let { stopLoader, fetchBillingData } = this.props;

    await fetchBillingData();

    await stopLoader();
  };

  handleChangeShowFilterStatus = (status) => {
    this.setState({
      show_filters: status,
    });
  };

  handleClearFilters = () => {};

  render() {
    const { loading, no_billing_data, billing_data } = this.props;
    let { show_filters } = this.state;
    let months;

    if (!no_billing_data && billing_data) {
      months = getMonthsFromBillingData(billing_data);
      months = months.slice(Math.max(months.length - 6, 1));
    }

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div className="breadcrumb-link" style={style.HeaderText}>
            Billing
          </div>
        </Toolbar>
        <div style={loading || no_billing_data ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#C992B8" height={50} width={50} />
          ) : no_billing_data ? (
            <EmptyContainer />
          ) : (
            <BillingTableView
              months={months}
              show_filters={show_filters}
              billing_data={billing_data}
              handleClearFilters={this.handleClearFilters}
              handleChangeShowFilterStatus={this.handleChangeShowFilterStatus}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,
    no_billing_data: state.BillingReducer.no_billing_data,
    billing_data: state.BillingReducer.billing_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  fetchBillingData: () => dispatch(ActionsCreator.fetchBillingData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Billing));
