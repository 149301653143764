import React from 'react';

import Moment from 'moment';

import { Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material';
import { style } from '../StreamlineView/style';

export const ActivityLog = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCellActivity}>Date</TableCell>
        <TableCell style={style.TableCellActivity}>Action</TableCell>
        <TableCell style={style.TableCellActivity}>Performed By</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.events
        ? props.events.map((event, index) => {
            //var date = new Date(event.event_at);
            var temp = Moment(event.event_at);
            return (
              <TableRow key={index} style={style.TableRow}>
                <TableCell style={style.TableCellActivity}>
                  <p>{temp.format('l')}</p>
                </TableCell>
                <TableCell style={style.TableCellActivity}>
                  <p>{event.event}</p>
                </TableCell>
                <TableCell style={style.TableCellActivity}>
                  <p>{event.user}</p>
                </TableCell>
              </TableRow>
            );
          })
        : null}
    </TableBody>
  </Table>
);
