import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getProjects = (query) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_PROJECTS_REQUEST,
      });

      API.request('post', EndPoints.GetProjects + (query ? query : ''), {
        teamdeck: false,
      })
        .then((response) => {
          resolve(response.data);
          dispatch({
            payload: response.data ? response.data : [],
            type: Types.GET_PROJECTS_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_PROJECTS_FAILURE,
          });
        });
    });
};

export const getUnbillableProjects = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_UNBILLABLE_PROJECTS_REQUEST,
      });

      API.request('post', EndPoints.UnbillableProjects, {
        teamdeck: false,
      })
        .then((response) => {
          resolve(response.data);
          dispatch({
            payload: response.data,
            type: Types.GET_UNBILLABLE_PROJECTS_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_UNBILLABLE_PROJECTS_FAILURE,
          });
        });
    });
};

export const updateProjectConfig = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.UPDATE_PROJECT_CONFIG_REQUEST,
      });

      API.request('post', EndPoints.UpdateProjectConfig, data)
        .then((response) => {
          resolve(true);
          dispatch({
            payload: response.data,
            type: Types.UPDATE_PROJECT_CONFIG_SUCCESS,
          });
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_PROJECT_CONFIG_FAILURE,
          });
        });
    });
};

export const getProjectHoursRecords = (project) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_PROJECT_HOURS_REQUEST,
      });

      API.request('post', EndPoints.GetProjectHours + `?project=${project}`)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.GET_PROJECT_HOURS_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.GET_PROJECT_HOURS_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.GET_PROJECT_HOURS_FAILURE,
          });
        });
    });
};

export const addProject = (project) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_PROJECT_REQUEST,
      });

      API.request('post', EndPoints.AddProject, project)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.ADD_PROJECT_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.ADD_PROJECT_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.ADD_PROJECT_FAILURE,
          });
        });
    });
};
