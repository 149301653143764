import * as Types from '../../constants/actionconstants';

export const startLoader = (color) => {
  return (dispatch) =>
    new Promise((resolve) => {
      resolve(true);
      dispatch({
        loading: true,
        loaderBackgroundColor: color,
        type: Types.START_LOADER,
      });
    });
};

export const stopLoader = () => {
  return {
    loading: false,
    type: Types.STOP_LOADER,
  };
};

export const unknownPageStopLoader = () => {
  return {
    forcestop_loading: true,
    type: Types.FORCE_STOP_LOADER,
  };
};

export const unknownPageStartLoader = () => {
  return {
    forcestop_loading: false,
    type: Types.FORCE_STOP_LOADER,
  };
};
