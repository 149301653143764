import React from 'react';

import Radium from 'radium';
import Chart from 'chart.js';
import { style } from '../style';
import ReactChartkick, { Timeline } from 'react-chartkick';

ReactChartkick.addAdapter(Chart);

const MyComponent = (props) => (
  <div>
    <div style={style.Headings}>
      <div style={style.CardHeadingText}>Project Invoice Timeline</div>
    </div>
    <div style={style.ChartContainer}>
      <Timeline
        colors={['#d0175d']}
        style={style.CentralizedRow}
        data={props.invoices_timeline_chart_data}
        library={style.TimelineChart}
        messages={{ empty: 'No data to make Chart!' }}
      />
    </div>
  </div>
);

export const ProjectTimelineChart = Radium(MyComponent);
