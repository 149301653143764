import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import FileSaver from 'file-saver';
import * as API from '../api.js';
//import { red } from '@material-ui/core/colors';

export const saveInvoice = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.SaveInvoice, data)
        .then((response) => {
          let payload = {
            saved: true,
            message: 'Invoice saved!',
          };
          dispatch({
            payload: payload,
            type: Types.SAVE_INVOICE_SUCCESS,
          });
          resolve(payload);
        })
        .catch((err) => {
          let payload = {
            saved: false,
            message: 'Invoice finalizing failed!',
          };
          dispatch({
            payload: payload,
            type: Types.SAVE_INVOICE_FAILURE,
          });
          resolve(payload);
        });
    });
  };
};

export const saveInvoiceAffiliates = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.SaveInvoiceAffiliates, data)
        .then((response) => {
          let payload = {
            saved: true,
            message: 'Invoices saved!',
          };
          dispatch({
            payload: payload,
            type: Types.SAVE_INVOICE_SUCCESS,
          });
          resolve(payload);
        })
        .catch((err) => {
          let payload = {
            saved: false,
            message: 'Invoice finalizing failed!',
          };
          dispatch({
            payload: payload,
            type: Types.SAVE_INVOICE_FAILURE,
          });
          resolve(payload);
        });
    });
  };
};

export const getParsedData = (data) => {
  let month = '';
  if (data !== undefined) month = '&month=' + data;
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.PARSED_CSV_DATA_REQUEST,
      });
      API.request('get', EndPoints.GetCSVParsedData + '?parsed=true' + month)
        .then((res) => {
          if (res.data && res.data.start_date) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.PARSED_CSV_DATA_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.PARSED_CSV_DATA_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.PARSED_CSV_DATA_FAILURE,
          });
        });
    });
};

export const getParsedDataBetweenDates = (start, end) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.PARSED_CSV_DATA_BETWEEN_DATES_REQUEST,
      });
      API.request(
        'get',
        EndPoints.GetCSVParsedData + '?parsed=true&start_date=' + start + '&end_date=' + end,
      )
        .then((res) => {
          if (res.data) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.PARSED_CSV_DATA_BETWEEN_DATES_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.PARSED_CSV_DATA_BETWEEN_DATES_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.PARSED_CSV_DATA_BETWEEN_DATES_FAILURE,
          });
        });
    });
};

export const getHoursBetweenDates = (start, end) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.HOURS_BETWEEN_DATES_REQUEST,
      });
      API.request(
        'get',
        EndPoints.GetHoursBetweenDates + '?start_date=' + start + '&end_date=' + end,
      )
        .then((res) => {
          if (res.data) {
            resolve(res.data);
            dispatch({
              payload: res.data,
              type: Types.HOURS_BETWEEN_DATES_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.HOURS_BETWEEN_DATES_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.HOURS_BETWEEN_DATES_FAILURE,
          });
        });
    });
};

export const getInvoices = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_INVOICES_REQUEST,
      });

      API.request('post', EndPoints.GetInvoices)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.GET_INVOICES_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.GET_INVOICES_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_INVOICES_FAILURE,
          });
        });
    });
};

export const deleteInvoice = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('delete', EndPoints.DeleteInvoice + id)
        .then((res) => {
          if (res.data) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.DELETE_INVOICE_SUCCESS,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.DELETE_INVOICE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const setShowInvoice = (project, invoice_data) => {
  return {
    payload: {
      project: project,
      invoice: JSON.parse(JSON.stringify(invoice_data)),
    },
    type: Types.SET_SHOW_INVOICE,
  };
};

export const resetShowInvoice = () => {
  return {
    type: Types.RESET_SHOW_INVOICE,
  };
};

export const resetShowInvoiceData = (invoice_data) => {
  return {
    payload: JSON.parse(JSON.stringify(invoice_data)),
    type: Types.RESET_SHOW_INVOICE_DATA,
  };
};

export const changeInvoiceData = (new_data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      resolve(true);
      dispatch({
        payload: JSON.parse(JSON.stringify(new_data)),
        type: Types.CHANGE_INVOICE_DATA,
      });
    });
};

export const setInvoiceDetailData = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        payload: JSON.parse(JSON.stringify(data)),
        type: Types.SET_INVOICE_DETAIL_DATA,
      });
      resolve(true);
    });
};

export const autoFillInvoice = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        payload: data,
        type: Types.SET_AUTO_FILL_DATA,
      });
      resolve(true);
    });
};

export const getSingleInvoice = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.GET_SINGLE_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.GetInvoiceByDocId + id, data)
        .then((res) => {
          dispatch({
            type: Types.GET_SINGLE_INVOICE_SUCCESS,
          });
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
          dispatch({
            type: Types.GET_SINGLE_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const updateInvoice = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.UpdateInvoiceByDocId + id, data)
        .then((res) => {
          dispatch({
            type: Types.UPDATE_INVOICE_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const setBillableProjectInvoices = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_BILLABLE_PROJECT_INVOICES_REQUEST,
    });

    return new Promise((resolve) => {
      dispatch({
        payload: data,
        type: Types.SET_BILLABLE_PROJECT_INVOICES_SUCCESS,
      });
      resolve(true);
    });
  };
};

export const prepareInvoice = (project, end_date, start_date, type) => {
  let prepaid = '';
  if (type === 'Prepaid') prepaid = '&prepaid=1';
  const project_name = encodeURIComponent(project); //to encode special characters
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.PREPARE_INVOICE_REQUEST,
      });

      API.request(
        'post',
        EndPoints.PrepareInvoice +
          `?projectname=${project_name}&end_date=${end_date}&start_date=${start_date}` +
          prepaid,
      )
        .then((res) => {
          if ((res.data && !res.data.error) || (prepaid === '&prepaid=1' && res.data === null)) {
            resolve(true);
            dispatch({
              payload: res.data,
              type: Types.PREPARE_INVOICE_SUCCESS,
            });
          } else if (res.data.error) {
            resolve(false);
            dispatch({
              type: Types.PREPARE_INVOICE_ERROR,
              error: res.data.error,
            });
          } else {
            resolve(false);
            dispatch({
              type: Types.PREPARE_INVOICE_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.PREPARE_INVOICE_FAILURE,
          });
        });
    });
};

export const resetPrepareInvoice = () => {
  return (dispatch) =>
    new Promise((resolve) => {
      resolve(true);
      dispatch({
        type: Types.RESET_PREPARE_INVOICE,
      });
    });
};

export const setPreviousResourceDetails = (data) => {
  console.log('data in action ', data);
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        payload: JSON.parse(JSON.stringify(data)),
        type: Types.SET_RESOURCE_DETAILS,
      });
      resolve(true);
    });
};

export const updateInvoiceDates = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_INVOICE_DATES_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.UpdateInvoiceDates + id, data)
        .then((res) => {
          dispatch({
            type: Types.UPDATE_INVOICE_DATES_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.UPDATE_INVOICE_DATES_FAILURE,
          });
        });
    });
  };
};

export const downloadInvoicePDF = (id, name, template) => {
  return (dispatch) => {
    dispatch({
      type: Types.DOWNLOAD_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.requestFile(
        'get',
        EndPoints.DownloadInvoicePDF + '?docId=' + id + '&templateType=' + template,
      )
        .then((res) => {
          const file = new Blob([res.data], {
            type: 'application/pdf',
          });
          FileSaver.saveAs(file, name);

          dispatch({
            type: Types.DOWNLOAD_INVOICE_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DOWNLOAD_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const downloadInvoiceCSV = (id, name) => {
  return (dispatch) => {
    dispatch({
      type: Types.DOWNLOAD_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.requestFile('get', EndPoints.DownloadInvoiceCSV + id)
        .then((res) => {
          const file = new Blob([res.data], {
            type: 'text/csv',
          });
          FileSaver.saveAs(file, name + '.csv');

          dispatch({
            type: Types.DOWNLOAD_INVOICE_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DOWNLOAD_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const downloadMultipleInvoicesCSV = (ids) => {
  return (dispatch) => {
    dispatch({
      type: Types.DOWNLOAD_INVOICE_REQUEST,
    });

    return new Promise((resolve) => {
      API.requestFile('post', EndPoints.DownloadMultipleInvoicesCSV, ids)
        .then((res) => {
          const file = new Blob([res.data], {
            type: 'text/csv',
          });
          FileSaver.saveAs(file, 'invoices_report.csv'); // file name can be made better

          dispatch({
            type: Types.DOWNLOAD_INVOICE_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DOWNLOAD_INVOICE_FAILURE,
          });
        });
    });
  };
};

export const setDashboardProject = (name) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_DASHBOARD_PROJECT_REQUEST,
    });

    return new Promise((resolve) => {
      if (name !== undefined && name !== null && name !== '') {
        dispatch({
          dashboard_project: name,
          type: Types.SET_DASHBOARD_PROJECT_SUCCESS,
        });
        resolve(true);
      } else {
        resolve(false);
        dispatch({
          type: Types.SET_DASHBOARD_PROJECT_FAILURE,
        });
      }
    });
  };
};
