import React from 'react';

import Radium from 'radium';
import { style } from './StreamlineView/style';

export const MyComponent = (props) => (
  <select
    name="Projects"
    value={props.project}
    style={style.HeaderSelect}
    className="header-select hours-management-button"
    onChange={props.handleProjectSelection}>
    <option value="" disabled>
      Select Project
    </option>
    {props.projects.map((project, index) => {
      return (
        <option key={index} style={style.HeaderSelectOption}>
          {project.name}
        </option>
      );
    })}
  </select>
);

export const SelectProject = Radium(MyComponent);
