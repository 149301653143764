import React from 'react';

import { Dialog } from '@mui/material';

import ResourceView from './ResourceView';

export const DialogBoxes = (props) => (
  <>
    <Dialog
      open={props.show_hours_distribution}
      onClose={props.handleHideHoursDistribution}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Hours Distribution</h2>
        <p style={style.Cross} onClick={props.handleHideHoursDistribution}>
          X
        </p>
      </div>
      <ResourceView
        employee={props.employee}
        sendEmail={props.sendEmail}
        total_hours={props.total_hours}
        hours_data={props.hours_data}
      />
    </Dialog>
  </>
);

const style = {
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
};
