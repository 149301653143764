import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ActionsCreator } from '../../Redux/Actions/index';
import errorimage from '../../assets/images/Error-404-(-tinvoice-).gif';

class UnknownPage extends Component {
  componentDidMount() {
    const { stopLoader, unknownPageStopLoader } = this.props;

    unknownPageStopLoader();
    stopLoader();
  }

  render() {
    return (
      <main style={style.Main}>
        <img style={style.MainIcon} src={errorimage} alt="" />
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  unknownPageStopLoader: () => dispatch(ActionsCreator.unknownPageStopLoader()),
});

export default connect(null, mapDispatchToProps)(UnknownPage);

const style = {
  MainIcon: {
    width: 'auto',
    height: '100vh',
  },
  Main: {
    height: '100vh',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#b5a8a3',
  },
  HeadingText: {
    fontSize: '50px',
    margin: '100px 100px 0px',
    fontWeight: 'bolder',
  },
  RouteText: {
    fontSize: '20px',
    margin: '10px 100px',
    fontWeight: 'bold',
  },
};
