import React from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { formatFloat } from '../../../Selectors/index';

export const AllResourcesHoursDistribution = (props) => (
  <div style={style.Container}>
    <div style={style.LabelRows}>
      <div style={{ ...style.BillableGreen, ...style.LabelValue }}>
        <label style={style.Label}>Billable Hours:</label>
        <p style={style.Value}>{props.hours_chart_data[0][1]}</p>
      </div>
      <div style={{ ...style.UnBillableBlue, ...style.LabelValue }}>
        <label style={style.Label}>Unbillable Hours:</label>
        <p style={style.Value}>{props.hours_chart_data[1][1]}</p>
      </div>
      <div style={style.LabelValue}>
        <label className="hours-management-employee-detail-label-all-view">Warning Email:</label>
        <div className="hours-management-generate-email-all-view">
          <Button
            className="hours-management-upload-button"
            onClick={() => {
              let data = [];
              props.resources_data.forEach((item) => {
                if (item.missed_hours > 0) {
                  data.push({
                    name: item.resource,
                    thours: item.completed_hours,
                    vhours: item.leave_hours,
                    mhours: parseInt(props.total_hours),
                  });
                }
              });
              data = JSON.stringify(data);
              props.sendEmail(data);
            }}>
            Generate
          </Button>
        </div>
      </div>
    </div>
    <div style={style.LabelRows}>
      <div style={{ ...style.LeaveYellow, ...style.LabelValue }}>
        <label style={style.Label}>Leave Hours:</label>
        <p style={style.Value}>{props.hours_chart_data[3][1]}</p>
      </div>
      <div style={{ ...style.OvertimeOrange, ...style.LabelValue }}>
        <label style={style.Label}>Overtime Hours:</label>
        <p style={style.Value}>{props.hours_chart_data[4][1]}</p>
      </div>
      <div style={{ ...style.MissedRed, ...style.LabelValue }}>
        <label style={style.Label}>Missed Hours:</label>
        <p style={style.Value}>{props.hours_chart_data[2][1]}</p>
      </div>
    </div>
    <Table style={style.Table}>
      <TableHead style={style.Head}>
        <TableRow style={style.TableRow}>
          {['Employees', 'Completed Hours', 'Leave Hours', 'Missed Hours'].map((item, index) => {
            return (
              <TableCell key={index} style={index === 0 ? style.TableCell : style.TableCell1}>
                {item}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={style.Body}>
        {props.resources_data.map((item, index) => {
          return (
            <TableRow
              key={index}
              style={style.TableRow}
              className="hours-management-clickable-tablerow"
              onClick={() => props.showDetailView(item.resource)}>
              <TableCell style={style.TableCell}>{item.resource}</TableCell>
              <TableCell style={style.TableCell1}>{formatFloat(item.completed_hours)}</TableCell>
              <TableCell style={style.TableCell1}>{formatFloat(item.leave_hours)}</TableCell>
              <TableCell
                style={style.TableCell1}
                className={
                  item.missed_hours > 0
                    ? 'hours-management-red'
                    : '' + item.missed_hours < 0
                    ? 'hours-management-blue'
                    : ''
                }>
                {formatFloat(item.missed_hours)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
);

const style = {
  Container: {
    display: 'flex',
    flexDirection: 'column',
  },
  LabelRows: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: '0px 20px',
  },
  Label: {
    flex: 1,
    fontSize: '16px',
    alignItems: 'center',
  },
  Value: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  LabelValue: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCell1: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BillableGreen: {
    color: '#00b900',
  },
  UnBillableBlue: {
    color: '#009ce2',
  },
  OvertimeOrange: {
    color: '#f68100',
  },
  LeaveYellow: {
    color: '#f3c700',
  },
  MissedRed: {
    color: '#c82000',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: '30vh',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
};
