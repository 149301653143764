import React, { Component } from 'react';

import Project from '../../components/Project';
import './ProjectsPage.css';

class ProjectsPage extends Component {
  render() {
    return (
      <div className="projectpage-main">
        <Project />
      </div>
    );
  }
}

export default ProjectsPage;
