import React from 'react';

import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import '@reach/menu-button/styles.css';

export const FixedProjectHeader = (props) => {
  return (
    <div style={style.flexContainer}>
      <div style={style.buttonDiv}>
        <div style={style.Row}>
          <div style={style.LabelValueCol}>
            <label style={style.Label}>Project</label>
            <p style={style.Value}>{props.project}</p>
          </div>
          <div style={style.LabelValueCol}>
            <label style={style.Label}>Project Manager</label>
            <p style={style.Value}>{props.project_manager}</p>
          </div>
          <div style={style.LabelValueCol}>
            <label style={style.Label}>Start Date</label>
            <p style={style.Value}>{props.start_date}</p>
          </div>
          {props.editable ? (
            <div style={style.LabelValueCol}>
              <label style={style.Label}>End Date</label>
              <p style={style.Value}>{props.end_date}</p>
            </div>
          ) : null}
        </div>
        {props.editable ? (
          <div style={style.Row}>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Invoice Id</label>
              <p style={style.Value}>
                {props.invoice_id === '' ? 'NOT ASSIGNED' : props.invoice_id}
              </p>
            </div>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Invoice Type</label>
              <p style={style.Value}>{props.invoice_type}</p>
            </div>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Status</label>
              <p style={{ ...style.StatusValue, color: props.status.color }}>{props.status.name}</p>
            </div>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Receiver</label>
              <p style={style.Value}>{props.receiver}</p>
            </div>
          </div>
        ) : null}
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <div style={style.buttonDiv}>
          {props.edit_invoice_enteries && props.editable ? (
            <>
              {!props.edit && props.is_current_pm ? (
                <button
                  style={style.basicInvoiceButtonEdit}
                  onClick={props.handleOpenEditView}
                  disabled={props.edit}>
                  Edit Invoice
                </button>
              ) : null}
              {props.edit ? (
                <button style={style.basicInvoiceButtonEdit} onClick={props.handleCancelEditView}>
                  Cancel Edit
                </button>
              ) : null}
              {props.edit ? (
                <button
                  style={style.basicInvoiceButtonEdit}
                  onClick={() => props.handleClearEdit()}>
                  Clear Editing
                </button>
              ) : null}
              {props.edit ? (
                <button
                  style={style.basicInvoiceButtonSave}
                  onClick={props.handleSaveDraftInvoiceChanges}>
                  Save Invoice
                </button>
              ) : null}
              {props.edit && !props.is_affiliate_invoice ? (
                <Menu>
                  <MenuButton style={style.basicInvoiceButtonEdit}>
                    Add <span aria-hidden>▾</span>
                  </MenuButton>
                  <MenuList style={style.basicInvoiceDropDown}>
                    {props.show_reconciliation_data === false ? (
                      <MenuItem onSelect={props.handleReconciliation}>Reconciliation</MenuItem>
                    ) : null}
                    <MenuItem onSelect={props.handleShowAddResourceView}>Resource</MenuItem>
                    <MenuItem onSelect={props.handleToggleAddDiscountView}>Discount</MenuItem>
                    <MenuItem onSelect={props.handleToggleAddExpenseView}>Expense</MenuItem>
                  </MenuList>
                </Menu>
              ) : null}

              {props.can_delete === true &&
              (props.status.name === 'DRAFT' || props.status.name === 'READY') &&
              !props.edit ? (
                <button style={style.basicInvoiceButtonEdit} onClick={props.toggleDeletePopup}>
                  Delete Invoice
                </button>
              ) : null}
              {!props.edit ? (
                <button
                  style={style.basicInvoiceButtonSave}
                  onClick={props.handleSaveInvoiceChanges}>
                  Approve Invoice
                </button>
              ) : null}
            </>
          ) : null}
          {props.show_invoice_dates_button ? (
            <button
              style={style.basicInvoiceButtonEdit}
              onClick={props.handleShowEditInvoiceDatesView}>
              Edit Invoice Details
            </button>
          ) : null}
          {props.edit_invoice_status && props.editable ? (
            <>
              {props.can_delete === true &&
              (props.status.name === 'DRAFT' || props.status.name === 'READY') ? (
                <button style={style.basicInvoiceButtonEdit} onClick={props.toggleDeletePopup}>
                  Delete Invoice
                </button>
              ) : null}
              <button
                style={style.basicInvoiceButtonEdit}
                onClick={props.handleOpenChangeStatusView}>
                Change Status
              </button>
              <Menu>
                <MenuButton style={style.basicInvoiceButtonEdit}>
                  Download <span aria-hidden>▾</span>
                </MenuButton>
                <MenuList style={style.basicInvoiceDropDown}>
                  <MenuItem onSelect={props.handleShowChangeTemplateView}>PDF invoice</MenuItem>
                  <MenuItem onSelect={props.handleDownloadCSV}>InDinero format CSV</MenuItem>
                </MenuList>
              </Menu>
              {props.show_create_affiliate_invoice_button ? (
                <button
                  style={
                    !props.affiliate_exists
                      ? style.basicInvoiceButtonEdit
                      : style.basicInvoiceButtonEditDisabled
                  }
                  onClick={props.handleOpenCreateAffiliateInvoiceView}
                  disabled={!props.affiliate_exists ? false : true}>
                  Create Affiliate Invoices
                </button>
              ) : null}
            </>
          ) : null}
        </div>
        {props.from_invoice_creation ? (
          <>
            <button
              style={style.basicInvoiceButtonEdit}
              onClick={() =>
                props.handleAutoFillHours(props.start_date, props.end_date, props.resource_details)
              }>
              Auto Fill hours
            </button>
            {/* <button
          style={style.basicInvoiceButtonEdit}
          onClick={() => props.handleClearAutoFillHours(props.resource_details)}
        >
          Clear Auto Fill
        </button>*/}
          </>
        ) : null}
      </div>
    </div>
  );
};

const style = {
  Row: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },

  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  LabelValueCol: {
    display: 'flex',
    width: '200px',
    flexDirection: 'column',
    padding: '10px',
  },
  Label: {
    padding: '5px',
    fontSize: '16px',
  },
  Value: {
    fontSize: '14px',
    color: 'var(--tin-blue)',
    padding: '5px',
    margin: '0px',
  },
  StatusValue: {
    fontSize: '14px',
    padding: '5px',
    margin: '0px',
  },
  basicInvoiceButtonSaveOld: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    width: 'auto',
    height: 'auto',
    padding: '10px 20px',
    fontSize: '14px',
    cursor: 'pointer',
    margin: '5px',
    backgroundColor: 'var(--tin-blue)',
    color: 'white',
    border: 'solid 1px white',
  },
  basicInvoiceButtonSave: {
    backgroundColor: 'var(--tin-blue)',
    color: 'white',
    border: 'solid 1px var(--tin-blue)',
    borderRadius: '10px',
    height: 'auto',
    width: '25vh',
    cursor: 'pointer',
    padding: '2px 20px',
    margin: '5px',
    whiteSpace: 'nowrap',
  },
  basicInvoiceButtonEditOld: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    width: 'auto',
    height: 'auto',
    padding: '10px 20px',
    fontSize: '14px',
    cursor: 'pointer',
    margin: '5px',
    backgroundColor: 'white',
    color: 'var(--tin-blue)',
    border: 'solid 1px var(--tin-blue)',
  },
  basicInvoiceButtonEdit: {
    backgroundColor: 'white',
    color: 'var(--tin-blue)',
    border: 'solid 1px var(--tin-blue)',
    borderRadius: '10px',
    height: 'auto',
    width: '25vh',
    cursor: 'pointer',
    padding: '2px 20px',
    margin: '5px',
    whiteSpace: 'nowrap',
  },
  basicInvoiceButtonEditDisabled: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: 'auto',
    width: '25vh',
    cursor: 'pointer',
    padding: '2px 20px',
    margin: '5px',
    whiteSpace: 'nowrap',
  },
  basicInvoiceDropDown: {
    backgroundColor: 'white',
    color: 'var(--tin-blue)',
    border: 'solid 1px var(--tin-blue)',
    borderRadius: '10px',
    height: 'auto',
    width: '25vh',
    cursor: 'pointer',
    padding: '2px',
    margin: '5px',
    whiteSpace: 'nowrap',
  },
  hoursManagementHeaderSaveInvoicing: {
    backgroundColor: 'var(--tin-blue)',
    color: 'white',
    border: 'solid 1px white',
  },
  hoursManagementHeaderEditInvoicing: {
    backgroundColor: 'white',
    color: 'var(--tin-blue)',
    border: 'solid 1px var(--tin-blue)',
  },
};
