import React, { Component } from 'react';

import './ClientPage.css';
import Client from '../../components/Client';

class ClientPage extends Component {
  render() {
    return (
      <div className="clientpage-main">
        <Client />
      </div>
    );
  }
}

export default ClientPage;
