export const style = {
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ListView: {
    flex: 2,
    overflowY: 'scroll',
    boxShadow: 'var(--tin-grey) 4px 1px 20px -5px',
  },
  UnSelectedSetting: {
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
  },
  SelectedSetting: {
    backgroundColor: 'var(--tin-dark-green)',
    color: 'white',
  },
  bodyContainer: {
    flex: 8,
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  HeadingContainer: {
    padding: '16px 40px',
    width: '52vw',
  },
  MainHeading: {
    fontSize: '1.7rem',
    fontWeight: '600',
  },
  SubHeading: {
    fontSize: '1rem',
  },
  DateHolidayContainer: {
    height: '64vh',
    width: '100%',
    position: 'absolute',
    overflowY: 'scroll',
  },
  MapperContainer: {
    position: 'relative',
    margin: '-24px 60px',
  },
  AddHolidayButton: {
    position: 'absolute',
    right: '64px',
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    marginTop: '-12px',
    cursor: 'pointer',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  TableCellDate: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
  },
  TableCellTitle: {
    display: 'flex',
    flex: 4,
    alignItems: 'center',
    width: '540',
  },
};
