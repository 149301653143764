import React from 'react';
import Select from 'react-select';

import Radium from 'radium';

export const MyComponent = (props) => {
  const handleChange = (selectedOption) => {
    props.handleChange([...selectedOption.map((item) => item.value)], 'team_name');
  };

  return (
    <div style={style.Container}>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Resource</label>
          <p style={style.Value}>{props.resource.name}</p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Email</label>
          <p style={style.Value}>
            {props.resource.email === '' ? 'NOT ASSIGNED' : props.resource.email}
          </p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Status</label>
          {props.edit_view ? (
            <select
              key={0}
              name="active_status"
              value={props.selected_active_status}
              style={style.Select}
              onChange={(event) => props.handleChange(event, 'active')}>
              <option value={''} disabled>
                Select Resource Status
              </option>
              <option value={true}>Active</option>
              <option value={false}>InActive</option>
            </select>
          ) : (
            <p style={style.Value}>{props.resource.active ? 'Active' : 'InActive'}</p>
          )}
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={{ ...style.Label, flex: 0 }}>Resource ID</label>
          <p style={style.Value}>{props.resource.id}</p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Teams</label>
          {props.edit_view && props.teams ? (
            <Select
              value={
                props.selected_team_name && props.selected_team_name.length
                  ? [...props.selected_team_name.map((item) => ({ value: item, label: item }))]
                  : []
              }
              onChange={handleChange}
              options={[...props.teams.map((item) => ({ value: item.name, label: item.name }))]}
              isMulti
            />
          ) : (
            <p style={style.Value}>
              {/* {props.resource.team_name === '' ? 'NOT ASSIGNED' : props.resource.team_name} */}
              {props.resource.teams ? props.resource.teams.join(', ') : 'NOT ASSIGNED'}
            </p>
          )}
        </div>
        <div style={style.LabelValueCol}>
          <label style={{ ...style.Label, flex: 0 }}>Resource Association</label>
          {props.edit_view ? (
            <select
              key={1}
              name="part_time_status"
              value={props.selected_part_time_status}
              style={style.Select}
              onChange={(event) => props.handleChange(event, 'part_time')}>
              <option value={''} disabled>
                Select Resource Association
              </option>
              <option value={true}>Part-Time</option>
              <option value={false}>Full-Time</option>
            </select>
          ) : (
            <p style={style.Value}>{props.resource.is_part_time ? 'Part-Time' : 'Full-Time'}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export const ResourceDetailView = Radium(MyComponent);

const style = {
  Container: {
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  LabelValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    flex: 2,
    padding: '5px',
    fontSize: '16px',
    color: 'var(--tin-grey)',
  },
  Value: {
    flex: 3,
    fontSize: '14px',
    color: 'var(--tin-yellow)',
    padding: '5px',
    margin: '0px',
    fontWeight: 'bold',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-yellow)',
    color: 'var(--tin-grey)',
    width: '90%',
    ':focus': {
      outline: 'none',
    },
  },
};
