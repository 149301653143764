import React from 'react';

import { List, ListItem } from '@mui/material';

import { style } from './style';

export const ReceiverListView = (props) => (
  <List style={style.ListView}>
    {props.receivers &&
      props.receivers.map((receiver, index) => {
        return (
          <ListItem
            button
            key={index}
            onClick={() => props.handleReceiverClick(receiver.docId)}
            style={
              props.receiver_selected.receiver_name === receiver.receiver_name
                ? style.SelectedReceiverItem
                : style.UnselectedReceiverItem
            }>
            <span
              style={
                props.receiver_selected.receiver_name === receiver.receiver_name
                  ? style.SelectedReceiverText
                  : style.UnselectedReceiverText
              }>
              {receiver.receiver_name}
            </span>
          </ListItem>
        );
      })}
  </List>
);
