import React from 'react';
import Radium from 'radium';
import { style } from './style';

export const MyComponent = (props) => (
  <div style={style.SearchFieldsContainer}>
    <div style={style.Row}>
      <div style={style.RowItems1}>
        <button style={style.BorderedButton} onClick={props.handleClearFilters}>
          Clear
        </button>
      </div>
    </div>
  </div>
);

export const BillingSearchFields = Radium(MyComponent);
