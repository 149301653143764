import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_TEAMS_REQUEST:
      return {
        ...state,
      };
    case Types.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        no_teams_data: false,
      };
    case Types.GET_TEAMS_FAILURE:
      return {
        ...state,
        teams: [],
        no_teams_data: true,
      };
    case Types.ADD_TEAM_REQUEST:
      return {
        ...state,
        add_team_request: true,
        add_team_success: false,
        add_team_failure: false,
      };
    case Types.ADD_TEAM_SUCCESS:
      return {
        ...state,
        add_team_request: false,
        add_team_success: true,
      };
    case Types.ADD_TEAM_FAILURE:
      return {
        ...state,
        add_team_request: false,
        add_team_failure: true,
      };
    case Types.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        update_team_request: true,
        update_team_success: false,
        update_team_failure: false,
      };
    case Types.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        update_team_request: false,
        update_team_success: true,
      };
    case Types.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        update_team_request: false,
        update_team_failure: true,
      };
    default:
      return state;
  }
};
