import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Redirect, withRouter, Switch } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import Loader from 'react-loaders';

import './App.css';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import UnknownPage from './pages/UnknownPage';
import { ActionsCreator } from './Redux/Actions/index';
import 'loaders.css/src/animations/pacman.scss';

import { createBrowserHistory } from 'history';
import * as ReactGA from 'react-ga';
//import { trackerId } from '../src/tracking';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

const history = createBrowserHistory();
//ReactGA.initialize(trackerId.id);
// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export const style = {
  loadingDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10000',
    position: 'absolute',
    left: '0px',
    top: '0px',
    height: '100vh',
    width: '100vw',
  },
  loadingHide: {
    display: 'none',
  },
};

const theme = createTheme();

const PREFIX = 'Tinvoice';
const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: style,
}));

class App extends Component {
  componentDidMount = () => {
    const { history, checkLoggedIn, startLoader, stopLoader } = this.props;

    startLoader('#01b9fe')
      .then((res) => {
        if (res) {
          return checkLoggedIn();
        }
      })
      .then((res) => {
        if (res.authenticated === false) {
          history.push('/');
          stopLoader();
        }
      });
  };

  static defaultProps = {
    logged_in: false,
    loading: true,
  };

  render() {
    let { logged_in, loading, forcestop_loading, loader_background_color } = this.props;

    return (
      <GoogleOAuthProvider clientId="1020609105960-ehqie4emllh85d3cp8jkgutt0mv373rk.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <Root className={classes.root}>
            <SnackbarProvider maxSnack={3}>
              <Router history={history}>
                <div className="app-container">
                  <div
                    style={
                      loading && !forcestop_loading
                        ? { ...style.loadingDisplay, backgroundColor: loader_background_color }
                        : style.loadingHide
                    }>
                    <Loader type="pacman" style={{ transform: 'scale(1.5)' }} active />
                  </div>
                  {logged_in ? (
                    <Switch>
                      <Route exact path="/" component={() => <Redirect to="/main" />} />
                      <Route path="/main" component={MainPage} />
                      <Route path="/not-found" component={UnknownPage} />
                      <Route path="*" component={() => <Redirect to={`/not-found`} />} />
                    </Switch>
                  ) : (
                    <Switch>
                      <Route path="/" component={LoginPage} />
                    </Switch>
                  )}
                </div>
              </Router>
            </SnackbarProvider>
          </Root>
        </ThemeProvider>
      </GoogleOAuthProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
    loading: state.LoaderReducer.loading,
    logged_in: state.LoginReducer.logged_in,
    forcestop_loading: state.LoaderReducer.forcestop_loading,
    loader_background_color: state.LoaderReducer.loader_background_color,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
