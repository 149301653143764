import React, { Component } from 'react';
import { connect } from 'react-redux';

import Chart from 'chart.js';
import { Bars } from 'react-loader-spinner';
import { Typography } from '@mui/material';
import ReactChartkick, { PieChart } from 'react-chartkick';

import { style } from '../StreamlineView/style';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { EmptyContainer } from '../../Utility/EmptyContainer';
import { getHoursPieChartData } from '../../../Selectors/index';

ReactChartkick.addAdapter(Chart);

const HoursDistributionChart = (props) => (
  <div style={style.ChartRow}>
    <div style={style.TableContainer}>
      <Typography style={style.CardHeadings}>Hours Distribution</Typography>
      <div style={style.ChartContainer}>
        <PieChart
          colors={['#00b900', '#009ce2', '#c82000', '#f3c700', '#f68100']}
          style={style.CentralizedRow}
          data={props.hours_chart_data}
          legend="right"
          library={style.PieChart}
          thousands=","
        />
      </div>
    </div>
    <div style={{ flex: 1 }} />
  </div>
);

class HourCzarDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours_chart_data: [],
      projects_chart_data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_month !== this.props.selected_month) this.loadDashboardData();
  }

  async loadDashboardData() {
    const { getParsedData, stopLoader, selected_month } = this.props;

    let parsed_csv_data = await getParsedData(selected_month);
    if (!parsed_csv_data.start_date && !parsed_csv_data) parsed_csv_data = undefined;

    if (parsed_csv_data && parsed_csv_data.records.length > 0) {
      let data = await getHoursPieChartData(
        parsed_csv_data,
        parseInt(parsed_csv_data.upload.total_hours),
      );
      this.setState(
        {
          hours_chart_data: data.hours_chart_data,
          loading: false,
        },
        () => {
          stopLoader();
        },
      );
    } else {
      this.setState(
        {
          loading: false,
        },
        () => {
          stopLoader();
        },
      );
    }
  }

  render() {
    let { no_parsed_data } = this.props;
    let { hours_chart_data, loading } = this.state;

    return (
      <div style={loading || no_parsed_data ? style.LoadingContainer : style.MainContainer}>
        {loading ? (
          <Bars color="#01b9fe" height={50} width={50} />
        ) : no_parsed_data ? (
          <EmptyContainer />
        ) : (
          <div style={style.FlexCol}>
            <HoursDistributionChart hours_chart_data={hours_chart_data} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parsed_csv_data: state.HoursManagementReducer.parsed_csv_data,
    no_parsed_data: state.HoursManagementReducer.no_parsed_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getParsedData: (data) => dispatch(ActionsCreator.getParsedData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HourCzarDashboard);
