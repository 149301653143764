import React from 'react';
import Moment from 'moment';
import Radium from 'radium';
import { Dialog } from '@mui/material';
import { CustomDatePicker } from '../Utility/CustomDatePicker/index';

const MyComponent = (props) => (
  <>
    {console.log('date', props.new_holiday_start_date)}
    <Dialog
      open={props.show_edit_popup || props.show_add_holiday}
      onClose={props.handleHideAddHoliday}
      fullWidth={true}
      PaperProps={{
        style: {
          overflow: 'visible',
          maxHeight: '100vh',
        },
      }}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>{props.show_edit_popup ? 'Edit Date' : 'Add Holiday'}</h2>
        <p
          style={style.Cross}
          onClick={props.show_edit_popup ? props.toggleEditPopup : props.handleHideAddHoliday}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div
          style={{
            ...style.RowItems,
            marginLeft: '20px',
            position: 'relative',
            marginTop: '17px',
          }}>
          {props.show_edit_popup ? (
            <CustomDatePicker
              datePickerValue={
                props.new_selected_holiday_start_date
                  ? {
                      year: Moment(props.new_selected_holiday_start_date).year(),
                      month: Moment(props.new_selected_holiday_start_date).month() + 1,
                      day: Moment(props.new_selected_holiday_start_date).date(),
                    }
                  : {
                      year: Moment(props.selected_holiday_start_date).year(),
                      month: Moment(props.selected_holiday_start_date).month() + 1,
                      day: Moment(props.selected_holiday_start_date).date(),
                    }
              }
              handleChangeDate={props.handleHolidayStartDate}
              formattedMinimumDate={null}
              style={style.HolidayDate}></CustomDatePicker>
          ) : (
            <CustomDatePicker
              date={
                props.new_holiday_start_date ? props.new_holiday_start_date : props.current_date
              }
              datePickerValue={
                props.new_holiday_start_date
                  ? {
                      year: Moment(props.new_holiday_start_date).year(),
                      month: Moment(props.new_holiday_start_date).month() + 1,
                      day: Moment(props.new_holiday_start_date).date(),
                    }
                  : {
                      year: Moment(props.current_date).year(),
                      month: Moment(props.current_date).month() + 1,
                      day: Moment(props.current_date).date(),
                    }
              }
              handleChangeDate={props.handleHolidayStartDate}
              formattedMinimumDate={null}
              style={style.HolidayDate}></CustomDatePicker>
          )}
        </div>
        <div style={style.RowItems}>
          {props.show_edit_popup ? (
            <input
              key={5}
              name="holiday_title"
              type="text"
              value={props.selected_holiday_title || ''}
              style={style.Select}
              placeholder="Title"
              onChange={props.handleHolidayTitle}
            />
          ) : (
            <input
              key={5}
              name="holiday_title"
              type="text"
              value={props.new_holiday_title || ''}
              style={style.Select}
              placeholder="Title"
              onChange={props.handleHolidayTitle}
            />
          )}
        </div>
      </div>
      <div>
        {(props.new_holiday_start_date &&
          props.holidayAlreadyExists(props.new_holiday_start_date)) ||
        (props.new_selected_holiday_start_date &&
          props.holidayAlreadyExists(props.new_selected_holiday_start_date)) ? (
          <div style={style.HolidayErrorLabel}>Holiday already exists for this date</div>
        ) : null}
      </div>
      <div style={style.ButtonContainer}>
        {!props.show_edit_popup ? (
          <button
            style={
              props.new_holiday_start_date &&
              props.new_holiday_title &&
              !props.holidayAlreadyExists(props.new_holiday_start_date)
                ? style.BorderedButton
                : style.GreyBorderedButton
            }
            onClick={() =>
              props.new_holiday_start_date &&
              props.new_holiday_title &&
              !props.holidayAlreadyExists(props.new_holiday_start_date) &&
              props.handleAddNewHoliday()
            }
            disabled={
              (!props.new_holiday_start_date && !props.new_holiday_title) ||
              props.holidayAlreadyExists(props.new_holiday_start_date)
            }>
            {'Add Holiday'}
          </button>
        ) : (
          <button
            style={
              (props.new_selected_holiday_start_date || props.selected_holiday_title) &&
              !props.holidayAlreadyExists(props.new_selected_holiday_start_date)
                ? style.BorderedButton
                : style.GreyBorderedButton
            }
            onClick={() =>
              (props.new_selected_holiday_start_date || props.selected_holiday_title) &&
              !props.holidayAlreadyExists(props.new_selected_holiday_start_date) &&
              props.handleEditHoliday()
            }>
            {'Update Holiday'}
          </button>
        )}
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-dark-green)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-dark-green)',
    border: 'solid 1px var(--tin-dark-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'not-allowed',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  HolidayErrorLabel: {
    margin: '0 20px',
    color: 'var(--tin-cancel-pink',
    fontSize: '13px',
  },
  HolidayDate: {
    textAlign: 'center',
    borderColor: 'var(--tin-dark-green)',
    borderStyle: 'solid',
    borderWidth: '0px 0px 2.5px 0px',
    marginTop: '5px',
    outline: 'none',
  },
};
