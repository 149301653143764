const BaseURL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://shipping-01.tintash.com'
    : 'https://staging-dot-shipping-01.uc.r.appspot.com';
//const BaseURL = 'http://localhost:8080';

export const EndPoints = {
  Logout: BaseURL + '/logout',
  Login: BaseURL + '/exchange',
  LoggedIn: BaseURL + '/loggedin',

  GetTeams: BaseURL + '/teams',
  AddTeams: BaseURL + '/teams',
  UpdateTeams: BaseURL + '/teams',

  GetRates: BaseURL + '/t/rates',
  Rates: BaseURL + '/rates',
  SetResourceRate: BaseURL + '/dashboard/set-resource-rate',

  SaveHours: BaseURL + '/save-hours',
  HoursRange: BaseURL + '/t/hours-range',

  SetCSVFileData: BaseURL + '/csv-report',
  GetCSVParsedData: BaseURL + '/csv-report',
  GetHoursBetweenDates: BaseURL + '/t/hours-bw-dates',

  InvoicesEmailReminder: BaseURL + '/t/email-pms',
  EmailReminder: BaseURL + '/email-reminder',
  GetProjectHours: BaseURL + '/project-hours/',

  SaveClient: BaseURL + '/t/save-client',
  SaveClientMultiple: BaseURL + '/t/save-client-multiple',
  GetClients: BaseURL + '/t/fetch-clients',
  UpdateClient: BaseURL + '/t/update-client',

  Receivers: BaseURL + '/receiver/',

  GetInvoices: BaseURL + '/t/invoices',
  SaveInvoice: BaseURL + '/t/save-invoice',
  SaveInvoiceAffiliates: BaseURL + '/t/save-invoice-affiliates',
  DeleteInvoice: BaseURL + '/delete-invoice/',
  GetInvoiceByDocId: BaseURL + '/t/invoice/',
  PrepareInvoice: BaseURL + '/prepare-invoice',
  UpdateInvoiceByDocId: BaseURL + '/t/update-invoice/',
  UpdateInvoiceDates: BaseURL + '/t/invoice-dangerous/',

  DownloadInvoicePDF: BaseURL + '/t/invoicepdf/',
  DownloadInvoiceCSV: BaseURL + '/t/invoicecsv/',
  DownloadMultipleInvoicesCSV: BaseURL + '/t/multiple-invoicecsv/',

  UnbillableProjects: BaseURL + '/t/unbillable',
  UpdateProjectConfig: BaseURL + '/project-config',
  GetProjects: BaseURL + '/dashboard/update-projects',

  UpdateResourceConfig: BaseURL + '/resource-config',
  GetResources: BaseURL + '/dashboard/update-resources',

  FetchBillingData: BaseURL + '/api1',

  FetchRoleData: BaseURL + '/role',
  Role: BaseURL + '/role/',

  Payments: BaseURL + '/payments/',

  Ledgers: BaseURL + '/payments/ledgers/',

  GetTempWeekHours: BaseURL + '/dashboard/week-temp',

  AddCustomTimeEntry: BaseURL + '/time-entries/save-custom-tentries',
  FetchCustomTimeEntries: BaseURL + '/time-entries/fetch-custom-tentries',
  DeleteCustomTimeEntry: BaseURL + '/time-entries/delete-tentry/',
  UpdateCustomTimeEntry: BaseURL + '/time-entries/update-custom-tentry',

  GetCustomBookings: BaseURL + '/bookings/resource',
  BookingsBase: BaseURL + '/bookings',

  GetCustomBookings: BaseURL + '/bookings/resource',
  BookingsBase: BaseURL + '/bookings',

  GetLastLockedDate: BaseURL + '/time-entries/get-last-locked',
  SetLastLockedDate: BaseURL + '/time-entries/set-last-locked',

  AddProject: BaseURL + '/add-project',
  AddResource: BaseURL + '/add-resource',

  AddHolidays: BaseURL + '/holidays',
  GetHolidays: BaseURL + '/holidays',
  DeleteHoliday: BaseURL + '/holidays',
  UpdateHoliday: BaseURL + '/holidays',

  FetchReportTimeEntries: BaseURL + '/project-hours-3',

  updatePMs: BaseURL + '/migrate/multi-pm',
  GetProjectLoggers: BaseURL + '/resources/calendar-entries',
};
