import * as Types from '../../constants/actionconstants';
const initialState = {
  emails_sent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GENERATE_REMINDER_EMAIL_REQUEST:
      return {
        ...state,
      };
    case Types.GENERATE_REMINDER_EMAIL_SUCCESS:
      return {
        ...state,
        emails_sent: action.payload.sent,
        email_sent_message: action.payload.message,
      };
    case Types.GENERATE_REMINDER_EMAIL_FAILURE:
      return {
        ...state,
        emails_sent: false,
      };
    case Types.GENERATE_INVOICES_REMINDER_EMAIL_REQUEST:
      return {
        ...state,
      };
    case Types.GENERATE_INVOICES_REMINDER_EMAIL_SUCCESS:
      return {
        ...state,
        emails_sent: action.payload.sent,
        email_sent_message: action.payload.message,
      };
    case Types.GENERATE_INVOICES_REMINDER_EMAIL_FAILURE:
      return {
        ...state,
        emails_sent: false,
      };
    default:
      return state;
  }
};
