import React from 'react';

import Radium from 'radium';
import { Dialog } from '@mui/material';
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './Editor.css';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_add_receiver}
      onClose={props.handleHideAddReceiver}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Receiver</h2>
        <p style={style.Cross} onClick={props.handleHideAddReceiver}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <input
          type="text"
          style={style.Select}
          placeholder={'Enter the Receiver Name'}
          onChange={props.handleChangeName}
        />
      </div>
      <div>
        <ReactQuill
          style={style.Quill}
          theme={'snow'}
          value={props.receiver_details}
          modules={props.modules}
          placeholder={'Enter the details...'}
          onChange={props.handleChangeDetails}
          ref={props.handleQuillRef}
        />
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disabled_create_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleAddReceiver}
          disabled={props.disabled_create_button}>
          Create
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_delete_popup}
      onClose={props.toggleDeletePopup}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.DeleteHeadingRow}>
        <h2 style={style.Heading}>
          Are you sure you want to delete &ldquo;{props.receiver_name}&rdquo; ?
        </h2>
        <p style={style.Cross} onClick={props.toggleDeletePopup}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={props.deleteReceiver}>
          {'Yes'}
        </button>
        <button style={style.DeleteBorderedButton} onClick={props.toggleDeletePopup}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-hazelnut)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '50%',
    ':focus': {
      outline: 'none',
    },
  },
  FilledButton: {
    backgroundColor: 'var(--tin-hazelnut)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-hazelnut)',
    border: 'solid 1px var(--tin-hazelnut)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  Quill: {
    marginTop: '0px',
    margin: '20px',
    width: '96%',
    height: '300px',
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    whiteSpace: 'pre',
    ':focus': {
      outline: 'none',
    },
  },
  DeleteHeadingRow: {
    fontSize: '12px',
    flex: 1,
    display: 'flex',
    padding: '20px 100px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  DeleteBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-hazelnut)',
    border: 'solid 1px var(--tin-hazelnut)',
    borderRadius: '10px',
    height: '32px',
    width: '150px',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
};
