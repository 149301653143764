import React from 'react';

import Radium from 'radium';
import { style } from './style';
import { Dialog } from '@mui/material';
import { InvoiceDetailsTableView } from '../Dashboard/ProjectDetailInvoice/InvoiceDetailsTableView';
import { FixedProjectHeader } from '../Dashboard/ProjectDetailInvoice/FixedProjectHeader';
import { formatFloat } from '../../Selectors';
import { Add } from '@mui/icons-material';

import './Invoice.css';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_add_project_manager}
      onClose={props.toggleAddPopupProjectManager}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>{'Add a Project Manager'}</h2>
        <p style={style.Cross} onClick={props.toggleAddPopupProjectManager}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={7}
            name="Resource"
            value={props.selected_project_manager}
            style={style.ProjectSelect}
            onChange={props.handleProjectManagerSelection}>
            <option value="" disabled>
              Resource
            </option>
            {props.resource_names.map((name, index) => {
              return <option key={index}>{name}</option>;
            })}
          </select>
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disable_add_pm_button ? style.GreyBorderedButton : style.BorderedButton}
          disabled={props.disable_add_pm_button}
          onClick={props.addProjectManger}>
          Add
        </button>
      </div>
    </Dialog>
    <Dialog
      open={props.show_create_invoice}
      onClose={props.handleHideCreateInvoice}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>New {props.invoice_radio} Invoice</h2>
        <p style={style.Cross} onClick={props.handleHideCreateInvoice}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            name="Projects"
            value={props.project}
            style={style.ProjectSelect}
            onChange={props.handleProjectSelection}>
            <option value="" disabled>
              Project
            </option>
            {props.projects.map((project, index) => {
              return project.billable === true && project.active === true ? (
                <option key={index}>{project.name}</option>
              ) : null;
            })}
          </select>
        </div>
        <div style={style.RowItems}>
          {props.show_receiver ? (
            <select
              key={4}
              name="Receiver"
              value={props.selected_receiver}
              style={style.ProjectSelect}
              onChange={props.handleChangeReciever}>
              <option value="" disabled>
                Receiver
              </option>
              {props.receivers &&
                props.receivers.map((receiver, index) => {
                  return (
                    <option key={index} value={receiver.receiver_name}>
                      {receiver.receiver_name}
                    </option>
                  );
                })}
            </select>
          ) : null}
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={0}
            type="date"
            style={style.DateInput}
            placeholder="Start Date"
            onChange={props.handleChangeStartDate}
          />
        </div>
        <div style={style.RowItems}>
          <input
            key={5}
            type="date"
            style={style.DateInput}
            placeholder="End Date"
            onChange={props.handleChangeEndDate}
          />
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disable_prepare_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleGetInvoiceData}
          disabled={props.disable_prepare_button}>
          Prepare
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_draft_invoice}
      onClose={props.handleHideDraftInvoice}
      fullWidth={true}
      maxWidth="xl">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>New {props.invoice_radio} Invoice</h2>
        <p style={style.Cross} onClick={props.handleHideDraftInvoice}>
          X
        </p>
      </div>
      {props.prepaid ? (
        <div style={style.ProjectDetailInvoiceContainer}>
          <FixedProjectHeader
            status={props.status}
            project={props.project}
            editable={false}
            project_manager={props.project_manager}
            from_invoice_creation={
              props.invoice_radio && props.invoice_radio === 'Prepaid' ? true : false
            }
            resource_details={props.resource_details}
            handleAutoFillHours={props.handleAutoFillHours}
            handleClearAutoFillHours={props.handleClearAutoFillHours}
            end_date={props.end_date}
            start_date={props.start_date}
          />
          <InvoiceDetailsTableView
            edit={true}
            editable={true}
            newInvoiceData={undefined}
            handleChange={props.handleChange}
            showInvoiceData={props.resource_details}
            parsed_csv_data={undefined}
            resource_details={props.resource_details}
            handleDeleteRow={props.handleDeleteRow}
            showDetailHourDistribution={null}
            total_invoice={props.total_invoice}
            auto_fill_resource_details={props.auto_fill_resource_details}
            auto_fill={props.auto_fill}
          />
          <div style={style.FooterRow}>
            {props.prepaid ? (
              <div style={style.PlusContainer}>
                <button style={style.Add_button} onClick={props.toggleAddPopup}>
                  <Add />
                </button>
              </div>
            ) : null}
            <div style={style.TotalContainer}>
              <label style={style.TotalLabel}>TOTAL COST</label>
              <p style={style.TotalLabel}>${formatFloat(props.total_invoice)}</p>
            </div>
          </div>
        </div>
      ) : (
        <div style={style.ProjectDetailInvoiceContainer}>
          <FixedProjectHeader
            status={props.status}
            project={props.project}
            editable={false}
            project_manager={props.project_manager}
            from_invoice_creation={
              props.invoice_radio && props.invoice_radio === 'Prepaid' ? true : false
            }
            resource_details={props.resource_details}
            handleAutoFillHours={props.handleAutoFillHours}
            handleClearAutoFillHours={props.handleClearAutoFillHours}
            end_date={props.end_date}
            start_date={props.start_date}
          />
          <InvoiceDetailsTableView
            edit={false}
            editable={false}
            newInvoiceData={undefined}
            handleChange={props.handleChange}
            showInvoiceData={props.resource_details}
            parsed_csv_data={undefined}
            resource_details={props.resource_details}
            handleDeleteRow={props.handleDeleteRow}
            showDetailHourDistribution={null}
            total_invoice={props.total_invoice}
          />
          <div style={style.FooterRow}>
            {props.prepaid ? (
              <div style={style.PlusContainer}>
                <button style={style.Add_button} onClick={props.toggleAddPopup}>
                  <Add />
                </button>
              </div>
            ) : null}
            <div style={style.TotalContainer}>
              <label style={style.TotalLabel}>TOTAL COST</label>
              <p style={style.TotalLabel}>${formatFloat(props.total_invoice)}</p>
            </div>
          </div>
        </div>
      )}
      <div style={style.ButtonContainer}>
        <button style={style.BorderedButton} onClick={props.handleSaveDraftInvoice}>
          Create
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_invoice_option}
      onClose={props.handleHideInvoiceOptions}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Which invoice would you like to make?</h2>
        <p style={style.Cross} onClick={props.handleHideInvoiceOptions}>
          X
        </p>
      </div>
      <br />
      <br />
      <label style={style.RadioLabel}>
        <input
          style={style.RadioButton}
          type="radio"
          name="invoice"
          value="Prepaid"
          checked={props.invoice_radio === 'Prepaid'}
          onChange={props.handleInvoiceRadioChange}
        />
        Prepaid
      </label>
      <br />
      <label style={style.RadioLabel}>
        <input
          style={style.RadioButton}
          type="radio"
          name="invoice"
          value="Postpaid"
          checked={props.invoice_radio === 'Postpaid'}
          onChange={props.handleInvoiceRadioChange}
        />
        Postpaid
      </label>
      <br />
      <div style={style.ButtonContainer}>
        <button style={style.BorderedButton} onClick={props.handleShowCreateInvoice}>
          Continue
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_add_resource}
      onClose={props.toggleAddPopup}
      fullWidth={true}
      maxWidth="xs">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>{'Add a resource'}</h2>
        <p style={style.Cross} onClick={props.toggleAddPopup}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={6}
            name="Resource"
            value={props.selected_resource_name}
            style={style.ProjectSelect}
            onChange={props.handleResourceSelection}>
            <option value="" disabled>
              Resource
            </option>
            {props.resource_names.map((name, index) => {
              return <option key={index}>{name}</option>;
            })}
          </select>
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disable_add_button ? style.GreyBorderedButton : style.BorderedButton}
          disabled={props.disable_add_button}
          onClick={props.addResource}>
          Add
        </button>
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);
