import React from 'react';

import { Dialog } from '@mui/material';
import ProjectDetailInvoice from '../ProjectDetailInvoice';
import { TentativeProjectInvoices } from './TentativeProjectInvoices';
import { ResourceHoursDistribution } from '../StreamlineView/ResourceHoursDistribution';
import { ProjectResourceHoursDistribution } from './ProjectResourceHoursDistribution';

export const DialogBoxes = (props) => (
  <>
    <Dialog
      open={props.show_tentative_invoices}
      onClose={props.handleHideTentativeInvoices}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Tentaive Invoices</h2>
        <p style={style.Cross} onClick={props.handleHideTentativeInvoices}>
          X
        </p>
      </div>
      <TentativeProjectInvoices
        invoices_data={props.invoices_data}
        handleShowProjectInvoice={props.handleShowProjectInvoice}
      />
    </Dialog>
    <Dialog
      open={props.show_single_invoice}
      onClose={props.handleHideSingleInvoice}
      fullWidth={true}
      maxWidth="xl">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Detail Invoice</h2>
        <p style={style.Cross} onClick={props.handleHideSingleInvoice}>
          X
        </p>
      </div>
      <ProjectDetailInvoice
        showDetailHourDistribution={props.handleShowResourceHoursDistribution}
      />
    </Dialog>
    <Dialog
      open={props.show_hours_distribution}
      onClose={props.handleHideHoursDistribution}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Hours Distribution</h2>
        <p style={style.Cross} onClick={props.handleHideHoursDistribution}>
          X
        </p>
      </div>
      <ProjectResourceHoursDistribution
        resource_details={props.resource_details}
        showDetailHourDistribution={props.handleShowResourceHoursDistribution}
      />
    </Dialog>
    <Dialog
      open={props.show_resource_hours_distribution}
      onClick={props.handleHideResourceHoursDistribution}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Logged Hours</h2>
        <p style={style.Cross} onClick={props.handleHideResourceHoursDistribution}>
          X
        </p>
      </div>
      <ResourceHoursDistribution
        resource={props.show_resource}
        completed_hours={props.resource_data.completed_hours}
        missed_hours={props.resource_data.missed_hours}
        leave_hours={props.resource_data.leave_hours}
        total_hours={props.total_hours}
        resource_records={props.resource_records}
        sendEmail={props.handleGenerateEmailReminder}
      />
    </Dialog>
  </>
);

const style = {
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
};
