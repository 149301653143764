import React from 'react';

import Radium from 'radium';
import { Markup } from 'interweave';
import * as ReactQuill from 'react-quill';
import { Checkbox, FormControlLabel } from '@mui/material';

import { style } from './style';

import './Editor.css';
import { hasPermission } from '../../constants/ability';

const MyComponent = (props) => (
  <div style={style.Container}>
    <div style={style.Column}>
      {['receiver_name', 'receiver_details', 'enable_affiliate'].map((type, index) => {
        return (
          <div key={index} style={style.LabelValueCol}>
            {type !== 'enable_affiliate' ? (
              <label style={style.Label}>
                {type === 'receiver_details' ? 'Receiver Details' : 'Receiver Name'}
              </label>
            ) : null}
            {!props.edit ? (
              <>
                <div className="value-font" style={style.Value}>
                  {type === 'receiver_details' ? (
                    <Markup content={props.receiver[type]} />
                  ) : (
                    props.receiver[type]
                  )}
                </div>
                {type === 'enable_affiliate' ? (
                  <FormControlLabel
                    disabled
                    control={<Checkbox checked={props.receiver[type]} name="checkedE" />}
                    label="Enable Afiliate"
                  />
                ) : null}
              </>
            ) : type === 'receiver_details' ? (
              <ReactQuill
                style={style.Select_Quill}
                key={type}
                name={type}
                theme={'snow'}
                value={props.receiver_details}
                modules={props.modules}
                placeholder={'Enter the details...'}
                onChange={props.handleChangeDetails}
                ref={props.handleQuillRef}
              />
            ) : (
              <>
                {type === 'enable_affiliate' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          props.enable_affiliate !== ''
                            ? props.enable_affiliate
                            : props.receiver[type]
                        }
                        onChange={(e) =>
                          props.handleAddReceiverChanges(e, type, props.receiver[type])
                        }
                        name="checkedE"
                      />
                    }
                    label="Enable Afiliate"
                  />
                ) : (
                  <input
                    key={type}
                    name={type}
                    type="text"
                    defaultValue={props.receiver[type]}
                    style={style.Select}
                    placeholder={type}
                    onChange={(e) => props.handleAddReceiverChanges(e, type)}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
    <div style={style.Row}>
      {!props.edit && props.show_edit_button ? (
        <div style={style.ButtonContainer}>
          <button style={style.BorderedButton} onClick={props.handleShowEditReceiverView}>
            Edit Receiver
          </button>
        </div>
      ) : null}
      {hasPermission(props.roles, 'receiver', 'can_delete') ? (
        <div style={style.ButtonContainer}>
          <button
            style={style.BorderedButton}
            onClick={() => props.toggleDeletePopup(props.receiver.docId)}>
            Delete Receiver
          </button>
        </div>
      ) : null}

      {props.edit && props.show_edit_button ? (
        <div style={style.ButtonContainer}>
          <button
            style={style.BorderedButton}
            onClick={() => props.handleEditReceiver(props.receiver.docId)}>
            Save Edit
          </button>
          <button style={style.PinkBorderedButton} onClick={props.handleHideEditReceiverView}>
            Cancel Edit
          </button>
        </div>
      ) : null}
    </div>
  </div>
);

export const ReceiverDetailView = Radium(MyComponent);
