import React from 'react';

import Radium from 'radium';

import { style } from './style';

const MyComponent = (props) => (
  <div style={style.Container}>
    <div style={style.Row}>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Client Name</label>
        {!props.edit ? (
          <p style={style.Value}>{props.client.client_name}</p>
        ) : (
          <input
            key={0}
            name="client_name"
            type="text"
            defaultValue={props.client_name}
            style={style.Select}
            placeholder="Client Name"
            onChange={(e) => props.handleAddClientChanges(e, 'name')}
          />
        )}
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Client Address</label>
        {!props.edit ? (
          <p style={style.Value}>
            {props.client.client_address !== ''
              ? JSON.parse(props.client.client_address).join(' ')
              : props.client.client_address}
          </p>
        ) : (
          <textarea
            key={1}
            rows="3"
            name="client_address"
            defaultValue={
              props.client_address !== ''
                ? JSON.parse(props.client_address).join('\n')
                : props.client_address
            }
            style={style.Select}
            placeholder="Client Address"
            onChange={(e) => props.handleAddClientChanges(e, 'address')}
          />
        )}
      </div>
    </div>
    <div style={style.Row}>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Project Name</label>
        {!props.edit ? (
          <p style={style.Value}>{props.client.project_name}</p>
        ) : (
          <select
            key={2}
            name="project_id"
            defaultValue={props.project_id}
            style={style.Select}
            onChange={(e) => props.handleAddClientChanges(e, 'project_id')}>
            <option value={''} disabled>
              Select Project
            </option>
            {props.projects.map((project, index) => {
              return (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div style={style.LabelValueCol}>
        <label style={style.Label}>Project Name on Invoice</label>
        {!props.edit ? (
          <p style={style.Value}>{props.client.client_project_pdf_name}</p>
        ) : (
          <input
            key={3}
            name="client_project_pdf_name"
            type="text"
            defaultValue={props.client_project_pdf_name}
            style={style.Select}
            placeholder="Project Name on Invoice"
            onChange={(e) => props.handleAddClientChanges(e, 'project_name')}
          />
        )}
      </div>
    </div>
    <div style={style.Row}>
      {!props.edit && props.show_edit_button ? (
        <div style={style.ButtonContainer}>
          <button style={style.BorderedButton} onClick={props.handleShowEditClientView}>
            Edit Client
          </button>
        </div>
      ) : null}
      {props.edit && props.show_edit_button ? (
        <div style={style.ButtonContainer}>
          <button
            style={style.BorderedButton}
            onClick={() => props.handleEditClient(props.client.docId)}>
            Save Edit
          </button>
          <button style={style.PinkBorderedButton} onClick={props.handleHideEditClientView}>
            Cancel Edit
          </button>
        </div>
      ) : null}
    </div>
  </div>
);

export const ClientDetailView = Radium(MyComponent);
