import React from 'react';

import Chart from 'chart.js';
import { Typography } from '@mui/material';
import { style } from '../StreamlineView/style';
import ReactChartkick, { PieChart } from 'react-chartkick';

ReactChartkick.addAdapter(Chart);

export const HoursDistributionChart = (props) => (
  <div
    style={style.TableContainer}
    className="hours-management-button"
    onClick={props.handleShowHoursDistribution}>
    <Typography style={style.CardHeadings}>Resource Hours Distribution</Typography>
    <div style={style.ChartContainer}>
      <PieChart
        style={style.CentralizedRow}
        data={props.hours_chart_data}
        legend="right"
        library={style.PieChart}
        thousands=","
        messages={{ empty: 'No data to make Chart!' }}
      />
    </div>
  </div>
);
