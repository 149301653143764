import * as Types from '../../constants/actionconstants';
const initialState = {
  time_entries: {},
  current_user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CURRENT_USER:
      return {
        ...state,
        current_user: action.payload,
      };
    case Types.GET_HOURS_WEEK_REQUEST:
      return {
        ...state,
      };
    case Types.GET_HOURS_WEEK_SUCCESS:
      const { id, data } = action.payload;
      return {
        ...state,
        time_entries: { ...state.time_entries, [id]: data },
      };
    case Types.GET_HOURS_WEEK_FAILURE:
      return {
        ...state,
      };
    case Types.DELETE_CUSTOM_TIME_ENTRY_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_CUSTOM_TIME_ENTRY_SUCCESS:
      return {
        ...state,
      };
    case Types.DELETE_CUSTOM_TIME_ENTRY_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_CUSTOM_TIME_ENTRY_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_CUSTOM_TIME_ENTRY_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_CUSTOM_TIME_ENTRY_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
