import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Bars } from 'react-loader-spinner';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { EmptyContainer } from '../../Utility/EmptyContainer';
import { style } from '../StreamlineView/style';

class TeamManagerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { stopLoader } = this.props;

    this.setState(
      {
        loading: false,
      },
      () => {
        stopLoader();
      },
    );
  }

  render() {
    const { loading } = this.state;

    return (
      <div style={loading ? style.LoadingContainer : style.MainContainer}>
        {loading ? <Bars color="#01b9fe" height={50} width={50} /> : <EmptyContainer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.LoginReducer.name,
    email: state.LoginReducer.email,
    roles: state.LoginReducer.roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamManagerDashboard));
