import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const fetchBillingData = () => {
  return (dispatch) => {
    dispatch({
      type: Types.FETCH_BILLING_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('get', EndPoints.FetchBillingData)
        .then((res) => {
          dispatch({
            payload: res.data,
            type: Types.FETCH_BILLING_SUCCESS,
          });
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.FETCH_BILLING_FAILURE,
          });
        });
    });
  };
};
