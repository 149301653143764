import React, { Component } from 'react';

import Report from '../../components/Report';
import './ReportsPage.css';

class ReportsPage extends Component {
  render() {
    return (
      <div className="reportspage-main">
        <Report />
      </div>
    );
  }
}

export default ReportsPage;
