import * as Types from '../../constants/actionconstants';
const initialState = {
  booking_entries: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_BOOKING_REQUEST:
      return {
        ...state,
      };
    case Types.GET_BOOKING_SUCCESS:
      const { id, data } = action.payload;
      return {
        ...state,
        booking_entries: { ...state.booking_entries, [id]: data },
      };
    case Types.GET_BOOKING_FAILURE:
      return {
        ...state,
      };
    case Types.DELETE_BOOKING_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
      };
    case Types.DELETE_BOOKING_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_BOOKING_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_BOOKING_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
