import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        loading: false,
        no_payments_data: false,
      };
    case Types.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        no_payments_data: true,
      };
    case Types.SAVE_PAYMENT_REQUEST:
      return {
        ...state,
      };
    case Types.SAVE_PAYMENT_SUCCESS:
      return {
        ...state,
      };
    case Types.SAVE_PAYMENT_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_PAYMENT_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
