import React, { Component } from 'react';

import Billing from '../../components/Billing';
import './BillingPage.css';

class BillingPage extends Component {
  render() {
    return (
      <div className="billingpage-main">
        <Billing />
      </div>
    );
  }
}

export default BillingPage;
