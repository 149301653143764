import * as Types from '../../constants/actionconstants';

import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const generateEmailForResources = (data) => {
  return (dispatch) => {
    dispatch({
      type: Types.GENERATE_REMINDER_EMAIL_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.EmailReminder, data)
        .then((res) => {
          let payload = {};
          if (res.data.status !== 'failed') {
            payload = {
              sent: true,
              message: 'Reminder(s) sent successfully!',
            };
            dispatch({
              payload: payload,
              type: Types.GENERATE_REMINDER_EMAIL_SUCCESS,
            });
            resolve(payload);
          } else {
            payload = {
              sent: false,
              message: 'Reminder(s) failed to send!',
            };
            dispatch({
              payload: payload,
              type: Types.GENERATE_REMINDER_EMAIL_SUCCESS,
            });
            resolve(payload);
          }
        })
        .catch((err) => {
          let payload = {
            sent: false,
            message: 'Reminder(s) failed to send!',
          };
          resolve(payload);
          dispatch({
            type: Types.GENERATE_REMINDER_EMAIL_FAILURE,
          });
        });
    });
  };
};

export const generateEmailForInvoices = () => {
  return (dispatch) => {
    dispatch({
      type: Types.GENERATE_INVOICES_REMINDER_EMAIL_REQUEST,
    });

    return new Promise((resolve) => {
      API.request('post', EndPoints.InvoicesEmailReminder)
        .then((res) => {
          let payload = {};
          if (res.data.status !== 'failed') {
            payload = {
              sent: true,
              message: 'Reminder(s) sent successfully!',
            };
            dispatch({
              payload: payload,
              type: Types.GENERATE_INVOICES_REMINDER_EMAIL_SUCCESS,
            });
            resolve(payload);
          } else {
            payload = {
              sent: false,
              message: 'Reminder(s) failed to send!',
            };
            dispatch({
              payload: payload,
              type: Types.GENERATE_INVOICES_REMINDER_EMAIL_SUCCESS,
            });
            resolve(payload);
          }
        })
        .catch((err) => {
          let payload = {
            sent: false,
            message: 'Reminder(s) failed to send!',
          };
          resolve(payload);
          dispatch({
            type: Types.GENERATE_INVOICES_REMINDER_EMAIL_FAILURE,
          });
        });
    });
  };
};
