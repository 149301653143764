import React from 'react';

import Radium from 'radium';

import { style } from './style';
import { Toolbar } from '@mui/material';
import { Ability, hasPermission } from '../../constants/ability';

import hoursprivatelighticon from '../../assets/images/hours-private-light-icon.svg';
import hoursprivatedarkicon from '../../assets/images/hours-private-dark-icon.svg';
import hoursprojectslighticon from '../../assets/images/hours-projects-light-icon.svg';
import hoursprojectsdarkicon from '../../assets/images/hours-projects-dark-icon.svg';
import hoursresourceslighticon from '../../assets/images/hours-resources-light-icon.svg';
import hoursresourcesdarkicon from '../../assets/images/hours-resources-dark-icon.svg';
import hoursteamsdarkicon from '../../assets/images/hours-teams-dark-icon.svg';
import hoursteamslighticon from '../../assets/images/hours-teams-light-icon.svg';

/*
  -- renders the Toolbar from which user can select the view they want and if they can
  -- selecting either of these items affects the list view (HoursListView.jsx)
*/

const MyComponent = (props) => (
  <>
    <Toolbar style={style.MainHeading}>
      <div
        style={
          props.selected_view === 'private' ? style.SelectedHeaderItem : style.UnselectedHeaderItem
        }
        onClick={() => props.handleViewSelect('private')}>
        <img
          src={props.selected_view === 'private' ? hoursprivatedarkicon : hoursprivatelighticon}
          alt=""
        />
        &nbsp;&nbsp;Private
      </div>
      {hasPermission(props.roles, 'hour_logging', 'can_access_resources') ? (
        <div
          style={
            props.selected_view === 'resources'
              ? style.SelectedHeaderItem
              : style.UnselectedHeaderItem
          }
          onClick={() => props.handleViewSelect('resources')}>
          <img
            src={
              props.selected_view === 'resources' ? hoursresourcesdarkicon : hoursresourceslighticon
            }
            alt=""
          />
          &nbsp;&nbsp;People
        </div>
      ) : null}
      {hasPermission(props.roles, 'hour_logging', 'can_access_projects') ? (
        <div
          style={
            props.selected_view === 'projects'
              ? style.SelectedHeaderItem
              : style.UnselectedHeaderItem
          }
          onClick={() => props.handleViewSelect('projects')}>
          <img
            src={
              props.selected_view === 'projects' ? hoursprojectsdarkicon : hoursprojectslighticon
            }
            alt=""
          />{' '}
          &nbsp;&nbsp;Projects
        </div>
      ) : null}
      {hasPermission(props.roles, 'hour_logging', 'can_access_teams') ? (
        <div
          style={
            props.selected_view === 'teams' ? style.SelectedHeaderItem : style.UnselectedHeaderItem
          }
          onClick={() => props.handleViewSelect('teams')}>
          <img
            src={props.selected_view === 'teams' ? hoursteamsdarkicon : hoursteamslighticon}
            alt=""
          />
          &nbsp;&nbsp;Teams
        </div>
      ) : null}
    </Toolbar>
  </>
);

export const HoursToolbar = Radium(MyComponent);
