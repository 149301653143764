import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
        no_clients_data: false,
      };
    case Types.GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        clients: action.payload,
        no_clients_data: true,
      };
    case Types.SAVE_CLIENT_REQUEST:
      return {
        ...state,
      };
    case Types.SAVE_CLIENT_SUCCESS:
      return {
        ...state,
      };
    case Types.SAVE_CLIENT_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
      };
    case Types.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
      };
    case Types.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
