import React from 'react';

import Radium from 'radium';

import { style } from './style';
import { List, ListItem } from '@mui/material';

export const SETTINGS_KEY_HOLIDAYS = 'holidays';
export const SETTINGS_KEY_APP_INFO = 'app_info';

const MyComponent = (props) => (
  <>
    <List style={style.ListView}>
      <ListItem
        button
        key={SETTINGS_KEY_HOLIDAYS}
        onClick={() => props.handleSettingItemClicked(SETTINGS_KEY_HOLIDAYS)}
        style={
          props.selected_view === SETTINGS_KEY_HOLIDAYS
            ? style.SelectedSetting
            : style.UnselectedSetting
        }>
        <div>Holidays</div>
      </ListItem>
      <ListItem
        button
        key={SETTINGS_KEY_APP_INFO}
        onClick={() => props.handleSettingItemClicked(SETTINGS_KEY_APP_INFO)}
        style={
          props.selected_view === SETTINGS_KEY_APP_INFO
            ? style.SelectedSetting
            : style.UnselectedSetting
        }>
        <div>App Info</div>
      </ListItem>
    </List>
  </>
);

export const SettingsListView = Radium(MyComponent);
