import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';
import { List, Toolbar, ListItem } from '@mui/material';

import { style } from './style';
import { LedgerTableView } from './LedgerTableView';
import Payment from '../Payments';
import Invoice from '../Invoice';
import { LedgerMenu } from './LedgerMenu';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      show_project: '',
      show_project_id: null,
      project_name: '',
      project_status: '',
      project_manager: '',
      project_association: '',
      selected_project_status: '',
      selected_project_manager: '',
      selected_project_association: '',
      selected_tab: 'summary',
    };
  }

  static defaultProps = {
    loadingProjects: true,
    loadingResources: true,
  };

  componentDidMount() {
    this.loadProjectSectionData();
  }

  loadProjectSectionData = async () => {
    const { stopLoader, resources, getProjects, getResources, getLedgers, invoices, getInvoices } =
      this.props;

    !resources && (await getResources());
    !invoices && (await getInvoices());

    getLedgers();

    getProjects().then((response) => {
      stopLoader();
    });
  };

  handleProjectClick = (project_name, project_id) => {
    this.props.resetShowInvoice();
    this.setState({
      show_project: project_name,
      show_project_id: project_id,
    });
  };

  handleProjectName = (e) => {
    this.setState({
      project_name: e.target.value,
    });
  };

  handleTabName = (tab) => {
    this.setState({
      selected_tab: tab,
    });
  };

  handleProjectManager = (e) => {
    this.setState({
      project_manager: e.target.value,
    });
  };

  handleProjectStatus = (e) => {
    this.setState({
      project_status: e.target.value,
    });
  };

  handleProjectAssociation = (e) => {
    this.setState({
      project_association: e.target.value,
    });
  };

  handleLedgerAmount = (type, transaction) => {
    let amount = null;
    if (
      (type === 'debit' && type === transaction.type) ||
      (type === 'credit' && type === transaction.type)
    )
      amount = '$' + transaction.amount;
    return amount;
  };

  isArchived = (project) => {
    return project.billable === true && project.active === true;
  };

  render() {
    let {
      loadingProjects,
      projects,
      no_data,
      loadingResources,
      no_resource_data,
      resources,
      ledgers,
      no_ledgers_data,
      invoices,
    } = this.props;

    let { show_project, selected_tab, show_project_id } = this.state;
    let project_selected = {};

    if (projects) projects = projects.filter(this.isArchived);

    if (show_project === '' && !no_data && projects && projects.length > 0) {
      project_selected = projects[0];
    } else if (show_project !== '' && !no_data && projects) {
      project_selected = projects.filter((project) => project.name === show_project)[0];
    }

    let ledger = undefined;
    if (ledgers && no_ledgers_data === false) {
      ledgers.forEach((project) => {
        if (project.project === project_selected.project) ledger = project.ledger;
      });
    }
    let invoice = [];
    if (invoices) {
      invoices.forEach((project) => {
        if (project.project_name === project_selected.project) invoice.push(project);
      });
    }

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <p className="breadcrumb-link" style={style.HeaderText}>
            Ledgers
            <br />
          </p>
        </Toolbar>
        <div
          style={
            loadingProjects || no_data || loadingResources || no_resource_data
              ? style.LoadingContainer
              : style.MainContainer
          }>
          {loadingProjects || loadingResources ? (
            <Bars color="#d43a26" height={50} width={50} />
          ) : no_data || no_resource_data ? (
            <EmptyContainer />
          ) : (
            <>
              <List style={style.ListView}>
                {projects
                  ? projects.map((project, index) => {
                      return (
                        <ListItem
                          button
                          key={index}
                          onClick={() => this.handleProjectClick(project.name, project.id)}
                          style={
                            project_selected.name === project.name
                              ? style.SelectedProjectItem
                              : style.UnselectedProjectItem
                          }>
                          <span
                            style={
                              project_selected.name === project.name
                                ? style.SelectedProjectText
                                : style.UnselectedProjectText
                            }>
                            {project.name}
                          </span>
                        </ListItem>
                      );
                    })
                  : null}
              </List>
              <LedgerMenu selected_tab={selected_tab} handleTabName={this.handleTabName} />

              {project_selected !== {} ? (
                <div style={style.ListViewContainer}>
                  <div style={style.ListViewContainer}>
                    {selected_tab === 'summary' ? (
                      <LedgerTableView
                        ledger={ledger}
                        is_ledgers_null={ledger === undefined ? true : false}
                        handleLedgerAmount={this.handleLedgerAmount}
                        resources={resources}
                        project={project_selected}
                        id={show_project_id}
                        invoices={invoice}
                        is_invoices_null={invoice.length === 0 ? true : false}
                      />
                    ) : null}
                  </div>
                  <div style={style.ListViewContainer}>
                    {selected_tab === 'payments' ? (
                      <Payment selected_project={project_selected.name} />
                    ) : null}
                  </div>
                  <div style={style.ListViewContainer}>
                    {selected_tab === 'invoices' ? (
                      <Invoice selected_project={project_selected.name} />
                    ) : null}
                  </div>
                </div>
              ) : (
                <div>
                  {' '}
                  <br />
                  <br />
                  <br /> <EmptyContainer />{' '}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ledgers: state.LedgerReducer.ledgers,
    no_ledgers_data: state.LedgerReducer.no_ledgers_data,
    invoices: state.InvoiceReducer.invoices,
    roles: state.LoginReducer.roles,
    no_data: state.ProjectReducer.no_data,
    projects: state.ProjectReducer.projects,
    no_resource_data: state.ResourceReducer.no_data,
    resources: state.ResourceReducer.resources,
    loadingProjects: state.ProjectReducer.loadingProjects,
    loadingResources: state.ResourceReducer.loadingResources,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLedgers: () => dispatch(ActionsCreator.getLedgers()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getInvoices: () => dispatch(ActionsCreator.getInvoices()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getResources: () => dispatch(ActionsCreator.getResources()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  updateProjectConfig: (data) => dispatch(ActionsCreator.updateProjectConfig(data)),
  resetShowInvoice: () => dispatch(ActionsCreator.resetShowInvoice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Project));
