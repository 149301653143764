export const InvoiceState = {
  DRAFT: {
    name: 'DRAFT',
    color: '#d8d8d8',
  },
  READY: {
    name: 'READY',
    color: '#8ef4fd',
  },
  SENT: {
    name: 'SENT',
    color: '#00A9FD',
  },
  DUE: {
    name: 'DUE',
    color: '#f87978',
  },
  CLEARED: {
    name: 'CLEARED',
    color: '#61cc40',
  },
};
