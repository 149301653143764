import React from 'react';

import Radium from 'radium';
import { Dialog } from '@mui/material';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_add_client}
      onClose={props.handleHideAddClient}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Client</h2>
        <p style={style.Cross} onClick={props.handleHideAddClient}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={0}
            name="client_name"
            type="text"
            value={props.client_name}
            style={style.Select}
            placeholder="Client Name"
            onChange={(e) => props.handleAddClientChanges(e, 'name')}
          />
        </div>
        <div style={style.RowItems}>
          <select
            key={2}
            name="project_id"
            value={props.project_id}
            style={style.Select}
            onChange={(e) => props.handleAddClientChanges(e, 'project_id')}>
            <option value={''} disabled>
              Select Project
            </option>
            {props.projects
              ? props.projects.map((project, index) => {
                  return (
                    <option key={index} value={project.id}>
                      {project.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={3}
            name="client_project_pdf_name"
            type="text"
            value={props.client_project_pdf_name}
            style={style.Select}
            placeholder="Project Name on Invoice"
            onChange={(e) => props.handleAddClientChanges(e, 'project_name')}
          />
        </div>
        <div style={style.RowItems}>
          <textarea
            key={1}
            rows="3"
            name="client_address"
            defaultValue={
              props.client_address !== ''
                ? JSON.parse(props.client_address).join('\n')
                : props.client_address
            }
            style={style.Select}
            placeholder="Client Address"
            onChange={(e) => props.handleAddClientChanges(e, 'address')}
          />
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.disabled_create_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleAddClient}
          disabled={props.disabled_create_button}>
          Create
        </button>
      </div>
    </Dialog>
  </>
);

export const CreateDialogBox = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-darkblue)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  FilledButton: {
    backgroundColor: 'var(--tin-darkblue)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-darkblue)',
    border: 'solid 1px var(--tin-darkblue)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};
