import * as Types from '../../constants/actionconstants';
import { EndPoints } from '../endpoints';
import * as API from '../api.js';

export const getWeekHours = (resid, projid, date) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.GET_HOURS_WEEK_REQUEST,
      });

      let hoursUrl = EndPoints.GetTempWeekHours + '?date=' + date;
      if (resid !== -1) {
        hoursUrl = hoursUrl + '&resid=' + resid;
      }
      if (projid !== -1) {
        hoursUrl = hoursUrl + '&projid=' + projid;
      }

      API.request('get', hoursUrl)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: { id: resid, data: res.data },
              type: Types.GET_HOURS_WEEK_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.GET_HOURS_WEEK_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.GET_HOURS_WEEK_FAILURE,
          });
        });
    });
};

export const setCurrentUser = (user) => {
  return (dispatch) => {
    dispatch({
      payload: user,
      type: Types.SET_CURRENT_USER,
    });
  };
};

export const addCustomTimeEntry = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.ADD_CUSTOM_TIME_ENTRY_REQUEST,
      });

      API.request('post', EndPoints.AddCustomTimeEntry, data)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.ADD_CUSTOM_TIME_ENTRY_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.ADD_CUSTOM_TIME_ENTRY_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.ADD_CUSTOM_TIME_ENTRY_FAILURE,
          });
        });
    });
};

export const deleteCustomTimeEntry = (id) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.DELETE_CUSTOM_TIME_ENTRY_REQUEST,
      });

      API.request('delete', EndPoints.DeleteCustomTimeEntry + id)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.DELETE_CUSTOM_TIME_ENTRY_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.DELETE_CUSTOM_TIME_ENTRY_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(false);
          dispatch({
            type: Types.DELETE_CUSTOM_TIME_ENTRY_FAILURE,
          });
        });
    });
};

export const updateCustomTimeEntry = (data) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: Types.UPDATE_CUSTOM_TIME_ENTRY_REQUEST,
      });

      API.request('put', EndPoints.UpdateCustomTimeEntry, data)
        .then((res) => {
          resolve(res.data);
          if (res.data) {
            dispatch({
              payload: res.data,
              type: Types.UPDATE_CUSTOM_TIME_ENTRY_SUCCESS,
            });
          } else {
            dispatch({
              type: Types.UPDATE_CUSTOM_TIME_ENTRY_FAILURE,
            });
          }
        })
        .catch((err) => {
          resolve(true);
          dispatch({
            type: Types.UPDATE_CUSTOM_TIME_ENTRY_FAILURE,
          });
        });
    });
};
