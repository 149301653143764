import React, { useState } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { ActionsCreator } from '../../Redux/Actions/index';

import tintashLogo from './../../assets/images/tintash blue bubbles.png';
import tinvoicewhitelogo from '../../assets/images/Tinvoice Logo (White).png';
import googleIcon from './../../assets/images/google-icon.png';

import './LoginPage.css';

function LoginPage(props) {
  const [triggered, setTriggered] = useState(false);

  const handleSuccess = (codeResponse) => {
    const { checkLoggedIn, startLoader, exchangeLoginCode, stopLoader, enqueueSnackbar } = props;
    startLoader('#01b9fe')
      .then((res) => {
        if (res) {
          return exchangeLoginCode(JSON.stringify({ code: codeResponse.code }));
        }
      })
      .then((data) => {
        if (data) {
          return checkLoggedIn();
        }
      })
      .then((res) => {
        if (res.authenticated === false) {
          stopLoader();
          enqueueSnackbar('Unable to Login!', { variant: 'error' });
        }
      });
  };

  const handleFailure = () => {
    enqueueSnackbar('Unable to Login!', { variant: 'error' });
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onFailure: handleFailure,
    scopes: ['profile', 'email'],
    flow: 'auth-code',
  });

  return (
    <div className="login-page">
      <div className="login-form">
        <div className="logo-title">
          <img className="logo" src={tinvoicewhitelogo} alt="home-logo" />
        </div>
        <div className="company-logo">
          <div className="company-logo-background">
            <img className="logo" src={tintashLogo} alt="tintash-logo" />
          </div>
        </div>
        <div className="login-button-area">
          <Button className="login-button-main" onClick={() => login()}>
            <div className="login-button">
              <img src={googleIcon} alt="google-icon" />
              <span>Sign In with Google</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    login_success: state.LoginReducer.login_success,
  };
};

const mapDispatchToProps = (dispatch) => ({
  exchangeLoginCode: (data) => dispatch(ActionsCreator.exchangeLoginCode(data)),
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(LoginPage));
