import React from 'react';

import Radium from 'radium';
import {
  Dialog,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Input,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import { style } from '../../Invoice/style';
import { InvoiceState } from '../../../constants/invoicestate';
import WarningIcon from '@mui/icons-material/Warning';
import { hasPermission } from '../../../constants/ability';
import { WarningDialog } from '../../Utility/Dialogs/WarningDialog';
const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_delete_popup}
      onClose={props.toggleDeletePopup}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h3 style={style.Heading}>Are you sure you want to delete this invoice?</h3>
        <p style={style.Cross} onClick={props.toggleDeletePopup}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={props.deleteInvoice}>
          {'Yes'}
        </button>
        <button style={style.CancelBorderedButton} onClick={props.toggleDeletePopup}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
    <Dialog
      open={props.show_invoice_status_change_view}
      onClose={props.handleCloseChangeStatusView}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Change Status</h2>
        <p style={style.Cross} onClick={props.handleCloseChangeStatusView}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={6}
            name="Projects"
            value={props.selected_status}
            style={style.ProjectSelect}
            onChange={props.handleChangeSelectedStatus}>
            <option value="" disabled>
              Status
            </option>
            {props.statuses.map((status, index) => {
              return <option key={index}>{status}</option>;
            })}
          </select>
        </div>
        <div style={style.RowItems} />
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.selected_status === '' ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleChangeStatus}
          disabled={props.selected_status === ''}>
          SET
        </button>
      </div>
    </Dialog>
    <Dialog
      open={props.show_invoice_template_select_view}
      onClose={props.handleCloseChangeTemplateView}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Select Template</h2>
        <p style={style.Cross} onClick={props.handleCloseChangeTemplateView}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <select
            key={7}
            name="Template Types"
            value={props.selected_template}
            style={style.ProjectSelect}
            onChange={props.handleChangeSelectedTemplate}>
            <option value="" disabled>
              Template Types
            </option>
            {['template1', 'template2', 'template3', 'template4'].map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </option>
              );
            })}
          </select>
        </div>
        <div style={style.RowItems} />
      </div>
      <div style={style.Row}>
        {props.selected_template === 'template1' ? (
          <div style={tableStyle.PreviewContainer}>
            <Table style={tableStyle.Table}>
              <TableHead style={tableStyle.Head}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB2}>Project Name</TableCell>
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB2}>
                    Resources - Export of Software
                  </TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Area</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Hours</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Rate($)</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Amount($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={tableStyle.Body}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Resource 1</TableCell>
                  <TableCell style={tableStyle.TableCellB}>Role 1</TableCell>
                  <TableCell style={tableStyle.TableCellB}>100hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>20</TableCell>
                  <TableCell style={tableStyle.TableCellB}>2000</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Resource 2</TableCell>
                  <TableCell style={tableStyle.TableCellB}>Role 2</TableCell>
                  <TableCell style={tableStyle.TableCellB}>150hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>10</TableCell>
                  <TableCell style={tableStyle.TableCellB}>1500</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell4}>Total</TableCell>
                  <TableCell style={tableStyle.TableCellB}>3500</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : null}
        {props.selected_template === 'template2' ? (
          <div style={tableStyle.PreviewContainer}>
            <Table style={tableStyle.Table}>
              <TableHead style={tableStyle.Head}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB2}>
                    Description - Export of Software
                  </TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Hours</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Rate($)</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Amount($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={tableStyle.Body}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Role 1</TableCell>
                  <TableCell style={tableStyle.TableCellB}>100hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>20</TableCell>
                  <TableCell style={tableStyle.TableCellB}>2000</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Role 2</TableCell>
                  <TableCell style={tableStyle.TableCellB}>150hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>10</TableCell>
                  <TableCell style={tableStyle.TableCellB}>1500</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell3}>Total</TableCell>
                  <TableCell style={tableStyle.TableCellB}>3500</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : null}
        {props.selected_template === 'template3' ? (
          <div style={tableStyle.PreviewContainer}>
            <Table style={tableStyle.Table}>
              <TableHead style={tableStyle.Head}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB4}>Description</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Amount($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={tableStyle.Body}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell4}>
                    Software Development for Project Name
                  </TableCell>
                  <TableCell style={tableStyle.TableCellB}>3500</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div style={tableStyle.DistributionDetails}>
              <p>Role 1(Resource 1) = 150 hours * $10 = $1500</p>
              <p>Role 2(Resource 2) = 100 hours * $20 = $2000</p>
            </div>
          </div>
        ) : null}
        {props.selected_template === 'template4' ? (
          <div style={tableStyle.PreviewContainer}>
            <Table style={tableStyle.Table}>
              <TableHead style={tableStyle.Head}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB2}>Project Name</TableCell>
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCellB2}>
                    Resources - Export of Software
                  </TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Area</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Hours</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Rate($)</TableCell>
                  <TableCell style={tableStyle.TableCellB1}>Amount($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={tableStyle.Body}>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Resource 1</TableCell>
                  <TableCell style={tableStyle.TableCellB}>Role 1</TableCell>
                  <TableCell style={tableStyle.TableCellB}>100hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>20</TableCell>
                  <TableCell style={tableStyle.TableCellB}>2000</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2}>Resource 2</TableCell>
                  <TableCell style={tableStyle.TableCellB}>Role 2</TableCell>
                  <TableCell style={tableStyle.TableCellB}>150hr</TableCell>
                  <TableCell style={tableStyle.TableCellB}>10</TableCell>
                  <TableCell style={tableStyle.TableCellB}>1500</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Total</TableCell>
                  <TableCell style={tableStyle.TableCellB}>3500</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Discount(5%)</TableCell>
                  <TableCell style={tableStyle.TableCellB}>$50</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Expense(demo)</TableCell>
                  <TableCell style={tableStyle.TableCellB}>$50</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Reconciled Hours</TableCell>
                  <TableCell style={tableStyle.TableCellB}>10</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Reconciled Cost</TableCell>
                  <TableCell style={tableStyle.TableCellB}>$100</TableCell>
                </TableRow>
                <TableRow style={tableStyle.TableRow}>
                  <TableCell style={tableStyle.TableCell2} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCell} />
                  <TableCell style={tableStyle.TableCellB}>Final Cost</TableCell>
                  <TableCell style={tableStyle.TableCellB}>$5000</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : null}
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={props.selected_template === '' ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleDownloadInvoicePDF}
          disabled={props.selected_template === ''}>
          Download Invoice PDF
        </button>
      </div>
    </Dialog>
    <Dialog
      open={props.show_edit_invoice_dates_view}
      onClose={props.handleHideEditInvoiceDatesView}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Update Details</h2>
        <p style={style.Cross} onClick={props.handleHideEditInvoiceDatesView}>
          X
        </p>
      </div>
      <div style={style.Row}>
        {hasPermission(props.roles, 'invoices', 'can_edit_invoice_id') ? (
          <div style={style.RowItems}>
            <input
              key={1}
              type="string"
              placeholder="Invoice ID"
              value={props.invoice_id}
              style={style.DateInput}
              onChange={props.handleChangeInvoiceId}
            />
          </div>
        ) : null}
        <div style={style.RowItems}>
          {hasPermission(props.roles, 'invoices', 'can_edit_invoice_id') ? (
            <select
              key={0}
              name="Receiver"
              value={props.receiver}
              style={style.ProjectSelect}
              onChange={props.handleChangeReciever}>
              <option value="" disabled>
                Receiver
              </option>
              {props.receivers &&
                props.receivers.map((receiver, index) => {
                  return (
                    <option key={index} value={receiver.receiver_name}>
                      {receiver.receiver_name}
                    </option>
                  );
                })}
            </select>
          ) : null}
        </div>
      </div>

      {
        // todo : new code starts here
        props.show_add_associate_receiver ? (
          <>
            <div style={style.Row}>
              <div style={style.RowItems}>
                <label key={'Affiliate Receiver'} style={style.ProjectSelect2}>
                  Affiliate Receiver
                </label>
              </div>
              <div style={style.RowItems}>
                <select
                  key={'amount_type'}
                  name="amount_type"
                  style={style.ProjectSelect}
                  value={props.affiliate_receivers_ratio_type}
                  onChange={(event) => props.handleChangeRatioType(event)}>
                  <option value={'percentage'}>percentage</option>
                  <option value={'absolute'}>absolute</option>
                </select>
              </div>
              <div style={style.RowItems}></div>
            </div>
            {props.AssociatedReceivers.length !== 0
              ? props.AssociatedReceivers.map((associated_receiver, index) => {
                  return (
                    <div key={index} style={style.Row}>
                      <div style={style.RowItems}>
                        <select
                          key={'select_old' + 14 + index}
                          name="associated_receiver"
                          value={
                            associated_receiver.name !== 'dummy_value'
                              ? associated_receiver.name
                              : ''
                          }
                          style={style.ProjectSelect}
                          onChange={(event) => props.handleAssociateReceiver(event, index, 'name')}>
                          <option value={''} disabled>
                            Select Sub-Receiver
                          </option>
                          {props.receivers.map((receiver, index2) => {
                            return (
                              <option
                                key={receiver.receiver_name + 'old' + index2}
                                disabled={receiver.receiver_name === props.receiver}
                                value={receiver.receiver_name}>
                                {receiver.receiver_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div style={style.RowItems}>
                        <input
                          key={'input_old' + 15 + index}
                          name="receiver_percentage"
                          type="text"
                          value={
                            associated_receiver.percentage !== -1
                              ? associated_receiver.percentage
                              : ''
                          }
                          style={style.ProjectSelect}
                          placeholder={
                            associated_receiver.amount_type === 'percentage'
                              ? 'amount in %'
                              : 'absolute amount'
                          }
                          onChange={(event) =>
                            props.handleAssociateReceiver(event, index, 'percentage')
                          }
                        />
                      </div>
                      <div style={style.RowItems}>
                        <Delete
                          style={{ color: 'red' }}
                          onClick={(event) => props.handleRemoveAssociateReceiver(index)}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
            {props.show_percentage_error ? (
              <div style={style.Row}>
                <p key={'error_statement1'} style={style.ProjectSelect3}>
                  Error: Sum of Percentages must not exceed 100
                </p>
              </div>
            ) : null}
            <div style={style.ButtonContainer2}>
              <button style={style.BorderedButton2} onClick={props.handleAddSubReceiverRow}>
                +Add Affiliate Receiver
              </button>
            </div>
          </>
        ) : null
        // todo -- new code ends here
      }
      {hasPermission(props.roles, 'invoices', 'can_edit_dates') &&
      props.status.name === InvoiceState.CLEARED.name ? (
        <div style={style.Row}>
          <div style={style.RowItems}>
            <input
              key={2}
              type="date"
              placeholder="Submitted Date"
              value={props.submitted_date}
              style={style.DateInput}
              onChange={props.handleChangeSubmittedDate}
            />
          </div>
          <div style={style.RowItems}>
            <input
              key={3}
              type="date"
              placeholder="Issued Date"
              value={props.issued_date}
              style={style.DateInput}
              onChange={props.handleChangeIssuedDate}
            />
          </div>
        </div>
      ) : null}
      {hasPermission(props.roles, 'invoices', 'can_edit_dates') &&
      props.status.name === InvoiceState.CLEARED.name ? (
        <div style={style.Row}>
          <div style={style.RowItems}>
            <input
              key={4}
              type="date"
              placeholder="Received Date"
              value={props.received_date}
              style={style.DateInput}
              onChange={props.handleChangeReceivedDate}
            />
          </div>
          <div style={style.RowItems} />
        </div>
      ) : null}
      {props.show_add_associate_receiver ? (
        <div style={style.Row}>
          <div style={style.RowItemsNote}>
            <WarningIcon style={style.Warning} />
            <Typography style={style.ProjectSelectNote}>
              Any changes made here are only applicable to this invoice. Permanent details regarding
              receivers should be added in projects tab{' '}
            </Typography>
          </div>
        </div>
      ) : null}
      <div style={style.ButtonContainer}>
        <button
          style={props.disabled_update_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleUpdateInvoiceDetails}
          disabled={props.disabled_update_button}>
          UPDATE
        </button>
      </div>
    </Dialog>
    {
      // todo --- new dialog starts here
    }
    <Dialog
      open={props.show_affiliate_invoice_create_view}
      onClose={props.handleHideCreateAffiliateInvoiceView}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Create Affiliate Invoices</h2>
        <p style={style.Cross} onClick={props.handleHideCreateAffiliateInvoiceView}>
          X
        </p>
      </div>

      {props.show_add_associate_receiver ? (
        <>
          <div style={style.Row}>
            <div style={style.RowItems}>
              <label key={'Affiliate Receiver2'} style={style.ProjectSelect2}>
                Affiliate Receiver
              </label>
            </div>
            <div style={style.RowItems}>
              <select
                key={'amount_type2'}
                name="amount_type"
                style={style.ProjectSelect}
                value={props.affiliate_receivers_ratio_type}
                onChange={(event) => props.handleChangeRatioType(event)}>
                <option value={'percentage'}>percentage</option>
                <option value={'absolute'}>absolute</option>
              </select>
            </div>
            <div style={style.RowItems}></div>
          </div>
          {props.AssociatedReceivers.length !== 0
            ? props.AssociatedReceivers.map((associated_receiver, index) => {
                return (
                  <div key={index} style={style.Row}>
                    <div style={style.RowItems}>
                      <select
                        key={'select_new' + 16 + index}
                        name="associated_receiver"
                        value={
                          associated_receiver.name !== 'dummy_value' ? associated_receiver.name : ''
                        }
                        style={style.ProjectSelect}
                        onChange={(event) => props.handleAssociateReceiver(event, index, 'name')}>
                        <option value={''} disabled>
                          Select Sub-Receiver
                        </option>
                        {props.receivers.map((receiver, index2) => {
                          return (
                            <option
                              key={receiver.receiver_name + 'new' + index2}
                              disabled={receiver.receiver_name === props.receiver}
                              value={receiver.receiver_name}>
                              {receiver.receiver_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div style={style.RowItems}>
                      <input
                        key={'input_new' + 17 + index}
                        name="receiver_percentage"
                        type="text"
                        value={
                          associated_receiver.percentage !== -1
                            ? associated_receiver.percentage
                            : ''
                        }
                        style={style.ProjectSelect}
                        placeholder={
                          associated_receiver.amount_type === 'percentage'
                            ? 'amount in %'
                            : 'absolute amount'
                        }
                        onChange={(event) =>
                          props.handleAssociateReceiver(event, index, 'percentage')
                        }
                      />
                    </div>
                    <div style={style.RowItems}>
                      <Delete
                        style={{ color: 'red' }}
                        onClick={(event) => props.handleRemoveAssociateReceiver(index)}
                      />
                    </div>
                  </div>
                );
              })
            : null}
          {props.show_percentage_error ? (
            <div style={style.Row}>
              <p key={'error_statement2'} style={style.ProjectSelect3}>
                Error: Sum of Percentages must not exceed 100
              </p>
            </div>
          ) : null}
          <div style={style.ButtonContainer2}>
            <button style={style.BorderedButton2} onClick={props.handleAddSubReceiverRow}>
              +Add Affiliate Receiver
            </button>
          </div>
        </>
      ) : null}
      <div style={style.ButtonContainer}>
        <button
          style={props.show_percentage_error ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleGenerateAffiliateInvoices}
          disabled={props.show_percentage_error ? true : false}>
          GENERATE
        </button>
      </div>
    </Dialog>

    {
      // todo --- new dialog ends here
    }

    {props.resources ? (
      <Dialog
        open={props.show_add_resource_view}
        onClose={props.handleHideAddResourceView}
        fullWidth={true}
        maxWidth="sm">
        <div style={style.HeadingRow}>
          <h2 style={style.Heading}>Add Resource</h2>
          <p style={style.Cross} onClick={props.handleHideAddResourceView}>
            X
          </p>
        </div>
        <div style={style.Row}>
          <div style={style.RowItems}>
            <select
              key={5}
              name="Resources"
              value={props.selected_resource}
              style={style.ProjectSelect}
              onChange={props.handleChangeSelectedResource}>
              <option value="" disabled>
                Resources
              </option>
              {props.resources.map((resource, index) => {
                return <option key={index}>{resource.name}</option>;
              })}
            </select>
          </div>
          <div style={style.RowItems} />
        </div>
        <div style={style.ButtonContainer}>
          <button
            style={props.selected_resource === '' ? style.GreyBorderedButton : style.BorderedButton}
            onClick={props.handleAddResourceToInvoiceData}
            disabled={props.selected_resource === ''}>
            ADD
          </button>
        </div>
      </Dialog>
    ) : null}

    <Dialog
      open={props.show_add_discount_view}
      onClose={props.handleToggleAddDiscountView}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Discount</h2>
        <p style={style.Cross} onClick={props.handleToggleAddDiscountView}>
          X
        </p>
      </div>
      <br />
      <br />
      <div style={style.Row}>
        <div style={style.ColumnItems1}>
          <label style={style.RadioLabel}>
            <input
              style={style.RadioButton}
              key={10}
              type="radio"
              name="discount"
              value="Percentage"
              checked={props.discount_radio === 'Percentage'}
              onChange={props.handleDiscountRadioChange}
            />
            Percentage (%)
          </label>
          <br />
          <label style={style.RadioLabel}>
            <input
              style={style.RadioButton}
              key={11}
              type="radio"
              name="discount"
              value="Absolute"
              checked={props.discount_radio === 'Absolute'}
              onChange={props.handleDiscountRadioChange}
            />
            Absolute Value
          </label>
          <br />
        </div>
        <div style={style.ColumnItems2}>
          <Input
            key={12}
            type="number"
            style={style.ProjectSelect}
            onChange={props.handleDiscountChange}
            value={props.selected_discount_display_string}
            placeholder="0"
          />
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={
            props.selected_discount_display_string === ''
              ? style.GreyBorderedButton
              : style.BorderedButton
          }
          onClick={props.handleAddDiscountToInvoiceData}
          disabled={props.selected_discount_display_string === ''}>
          ADD
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_add_expense_view}
      onClose={props.handleToggleAddExpenseView}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Expense</h2>
        <p style={style.Cross} onClick={props.handleToggleAddExpenseView}>
          X
        </p>
      </div>
      <br />
      <br />
      <div style={style.Row}>
        <div style={style.RowItems}>
          <Input
            key={8}
            type="text"
            style={style.ProjectSelect}
            onChange={props.handleExpenseLabelChange}
            value={props.selected_expense_label}
            placeholder="Label"
          />
        </div>
        <div style={style.RowItems}>
          <Input
            key={9}
            type="number"
            style={style.ProjectSelect}
            onChange={props.handleExpenseChange}
            value={props.selected_expense_display_string}
            placeholder="0"
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={
            props.selected_expense_display_string === '' || props.selected_expense_label === ''
              ? style.GreyBorderedButton
              : style.BorderedButton
          }
          onClick={props.handleAddExpenseToInvoiceData}
          disabled={
            props.selected_expense_display_string === '' || props.selected_expense_label === ''
          }>
          ADD
        </button>
      </div>
    </Dialog>
    <WarningDialog
      open={props.show_edit_warning_popup}
      handleDiscard={props.confirmOpenEditView}
      headingText="Warning!"
      promptText="This invoice contains a previously calculated reconciliation cost. To modify the reconciliation cost (e.g if changing the rate for a resource) please calculate the reconciliation again by adding it through the Add button."
    />
  </>
);

export const DialogBoxes = Radium(MyComponent);

const tableStyle = {
  PreviewContainer: {
    padding: '20px',
    width: '100%',
  },
  DistributionDetails: {
    display: 'block',
    fontSize: '11px',
    color: 'var(--tin-grey)',
  },
  TablesRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  Table: {
    display: 'flex',
    flexDirection: 'column',
  },
  Head: {
    display: 'block',
    width: '100%',
  },
  Body: {
    overflow: 'auto',
    width: '100%',
    display: 'block',
    height: 'auto',
  },
  TableRow: {
    display: 'flex',
    flex: 1,
    padding: '0px',
  },
  TableCellB1: {
    flex: 1,
    padding: '0px 0px 0px 10px',
    color: 'black',
    display: 'flex',
    fontSize: '13px',
    fontWeight: 'bold',
    alignItems: 'center',
  },
  TableCellB2: {
    flex: 2,
    padding: '0px 0px 0px 10px',
    color: 'black',
    display: 'flex',
    fontSize: '13px',
    fontWeight: 'bold',
    alignItems: 'center',
  },
  TableCellB: {
    flex: 1,
    padding: '0px 0px 0px 10px',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    color: 'var(--tin-grey)',
  },
  TableCell: {
    display: 'flex',
    flex: 1,
    color: 'var(--tin-grey)',
    alignItems: 'center',
    padding: '0px 0px 0px 10px',
  },
  TableCellB4: {
    flex: 5,
    padding: '0px 0px 0px 10px',
    color: 'black',
    display: 'flex',
    fontSize: '13px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'right',
  },
  TableCell4: {
    flex: 5,
    color: 'var(--tin-grey)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: '0px 0px 0px 10px',
  },
  TableCell3: {
    flex: 4,
    color: 'var(--tin-grey)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: '0px 0px 0px 10px',
  },
  TableCell2: {
    display: 'flex',
    flex: 2,
    color: 'var(--tin-grey)',
    alignItems: 'center',
    padding: '0px 0px 0px 10px',
  },
};
