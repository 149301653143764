import React from 'react';
import Moment from 'moment';
import Radium from 'radium';

import { Avatar, Chip, Dialog, useMediaQuery } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';

import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { style } from '../style';
import CustomSelect from '../CustomSelect';
import { hasPermission } from '../../../constants/ability';
import timelapsegreyicon from '../../../assets/images/hours-timelapse-grey.svg';
import dollargreyicon from '../../../assets/images/hours-dollar-grey.svg';
import { CustomDatePicker } from '../../Utility/CustomDatePicker';

const MyComponent = (props) => {
  const {
    is_dialoguebox_open,
    handleCloseDialogueBox,
    resources,
    projects,
    selected_day,
    handleBookingChange,
    selected_booking,
    createBooking,
    selected_view,
    selected_resource,
    loggedin_user_name,
    selected_project_logger,
    handleChangeMinutes,
    is_edit_booking,
    editBooking,
    new_booking,
    changeMinutesFormat,
    loggedin_user_id,
    deleteBooking,
    is_loading,
    is_form_valid,
    getMinutesInHMFormat,
    roles,
    booking_rate_error,
    booking_minutes_error,
  } = props;

  return (
    <>
      {/* Creating Update booking Dialog box */}
      <Dialog
        open={is_dialoguebox_open && is_edit_booking ? true : false}
        PaperProps={{
          style: { minHeight: 'auto', maxHeight: '90%', width: '25%' },
        }}>
        <div style={{ position: 'absolute', right: 7, top: 7 }}>
          <CloseOutlined
            style={{ marginTop: 6, marginRight: 5, color: 'var(--tin-grey)', cursor: 'pointer' }}
            onClick={props.handleCloseDialogueBox}
          />
        </div>
        <div style={style.DialogContainer}>
          <div style={style.DialogContainerHeading}>Edit Booking Entry</div>

          <div style={style.DialogInputContainer}>
            <label style={style.DialogInputLabel}>Person / Resource *</label>
            {/* show selected resource if in resources or projects screen
              else just the current resource */}
            {selected_view === 'resources' ? (
              <div style={style.DialogInputDisabled}>
                {props.selected_resource && props.selected_resource.name && (
                  <Chip
                    style={style.DialogInputChipContainer}
                    avatar={
                      <Avatar style={style.DialogInputChip}>
                        {props.selected_resource.name[0]}
                      </Avatar>
                    }
                    label={props.selected_resource.name}
                  />
                )}
              </div>
            ) : null}
            {selected_view === 'private' ? (
              <div style={style.DialogInputDisabled}>
                <Chip
                  style={style.DialogInputChipContainer}
                  avatar={
                    <Avatar style={style.DialogInputChip}>{props.loggedin_user_name[0]}</Avatar>
                  }
                  label={loggedin_user_name}
                />
              </div>
            ) : null}
            {selected_view === 'projects'
              ? resources &&
                resources
                  .filter((resource) => resource.id === selected_project_logger)
                  .map((resource) => {
                    return (
                      <div
                        style={style.DialogInputDisabled}
                        key={`B.projects.edit.${resource.name[0]}`}>
                        <Chip
                          style={style.DialogInputChipContainer}
                          avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                          label={resource.name}
                        />
                      </div>
                    );
                  })
              : null}

            {selected_view === 'teams'
              ? resources &&
                resources
                  .filter((resource) => resource.id === selected_project_logger)
                  .map((resource) => {
                    return (
                      <div
                        style={style.DialogInputDisabled}
                        key={`B.teams.edit.${resource.name[0]}`}>
                        <Chip
                          style={style.DialogInputChipContainer}
                          avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                          label={resource.name}
                        />
                      </div>
                    );
                  })
              : null}
          </div>

          <div style={style.DialogInputContainer}>
            <label style={style.DialogInputLabel}>Project *</label>
            <div>
              <CustomSelect
                projects={projects}
                entry_selected_project={new_booking.projectId}
                handleProjectSelection={handleBookingChange}
              />
            </div>
          </div>

          <div style={style.DialogDateInputContainer}>
            <div>
              <div style={style.DialogInputLabel}>Date</div>
              <CustomDatePicker
                datePickerValue={
                  new_booking.start_date
                    ? {
                        year: Moment(new_booking.start_date).year(),
                        month: Moment(new_booking.start_date).month() + 1,
                        day: Moment(new_booking.start_date).date(),
                      }
                    : selected_day && {
                        year: Moment(selected_day).year(),
                        month: Moment(selected_day).month() + 1,
                        day: Moment(selected_day).date(),
                      }
                }
                handleChangeDate={props.handleBookingStartDateChange}
                formattedMinimumDate={props.formattedMinimumDate}
                style={style.DateStyle}></CustomDatePicker>
            </div>
          </div>
          {props.isWeekendDay(Moment(new_booking.start_date)) ||
          props.isWeekendDay(Moment(new_booking.end_date)) ? (
            <div style={style.DateWeekendNotice}>
              Entries for weekend can only be added one day at a time
            </div>
          ) : props.isHoliday(Moment(new_booking.start_date)) ||
            props.isHoliday(Moment(new_booking.end_date)) ? (
            <div style={style.DateWeekendNotice}>
              Entries for holiday can only be added one day at a time
            </div>
          ) : null}

          <div style={style.DialogTimeRateInputContainer}>
            <div>
              <span style={style.DialogInputLabel}>Time per Day *</span>
              <div style={style.DialogTimeRateInputField}>
                <img src={timelapsegreyicon} alt="" style={{ marginRight: 5, marginLeft: 3 }} />
                <input
                  type="text"
                  name="minutes"
                  placeholder="8h"
                  style={[style.DialogInput, style.DialogTimeRateInput]}
                  onChange={handleChangeMinutes}
                  defaultValue={getMinutesInHMFormat(props.new_booking.minutes)}
                />
              </div>
              {booking_minutes_error && (
                <>
                  <span style={{ fontSize: '10px', color: 'red' }}>{booking_minutes_error} </span>
                  <br />
                </>
              )}
            </div>
            <div>
              <span style={style.DialogInputLabel}>Hourly Rate *</span>
              <div style={style.DialogTimeRateInputField}>
                <img src={dollargreyicon} alt="" style={{ marginRight: 10, marginLeft: 5 }} />
                <input
                  name="hourly_rate"
                  type="number"
                  placeholder="0.00"
                  min="0.00"
                  defaultValue={selected_booking.hourly_rate}
                  style={[style.DialogInput, style.DialogTimeRateInput]}
                  onChange={handleBookingChange}
                />
              </div>
              {booking_rate_error && (
                <>
                  <span style={{ fontSize: '10px', color: 'red' }}>{booking_rate_error} </span>
                  <br />
                </>
              )}
            </div>
          </div>
          <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>
            Please input time like 4h7m, 4h, or 7m
          </span>
          <br />
          <div style={style.DialogTextAreaContainer}>
            <span style={style.DialogInputLabel}>
              Description (optional)
              <br />
            </span>
            <textarea
              style={style.DialogInputTextArea}
              key={3}
              name="description"
              rows="4"
              placeholder="Add any details for this booking"
              onChange={handleBookingChange}
              defaultValue={new_booking.description}
            />
          </div>

          <div style={style.DialogueEdittedByContainer}>
            <div>
              <span style={{ fontSize: '10px', color: 'var(--tin-grey)', alignItems: 'center' }}>
                Created By:{' '}
              </span>
              {props.createdBy ? (
                <span style={{ fontSize: '10px', alignItems: 'center' }}>
                  {' '}
                  {props.createdBy.user_name} at{' '}
                  {moment(props.createdBy.event_at).format('MM/DD/YYYY hh:mm a')}
                </span>
              ) : (
                <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>-</span>
              )}
            </div>

            <div>
              <span style={{ fontSize: '10px', color: 'var(--tin-grey)', alignItems: 'center' }}>
                Last Edited By:{' '}
              </span>
              {props.edittedBy ? (
                <span style={{ fontSize: '10px', marginLeft: '2px' }}>
                  {' '}
                  {props.edittedBy.user_name} at{' '}
                  {moment(props.edittedBy.event_at).format('MM/DD/YYYY hh:mm a')}
                </span>
              ) : (
                <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>-</span>
              )}
            </div>
          </div>

          {Moment(new_booking.start_date).isAfter(props.last_locked) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              {/* Shows loader if delete request is made else there is Delete button
              default props.toggleDeleteBtn's value is false
            */}
              {!is_loading ? (
                <>
                  {hasPermission(roles, 'hour_logging', 'can_delete_bookings') ? (
                    <button
                      style={style.DialogDeleteBtn}
                      onClick={() => {
                        deleteBooking(selected_booking.docId);
                      }}
                      key={9}
                      disabled={props.toggleDeleteBtn}>
                      <DeleteOutlineOutlined style={{ width: 25, height: 25 }} />
                    </button>
                  ) : null}
                </>
              ) : (
                <ThreeDots
                  height="10"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              )}
              {/* Hiding save button if delete request is in process
              default props.toggleDeleteBtn's value is false
            */}
              {!is_loading && (
                <div>
                  <button style={style.DialogDiscardBtn} onClick={handleCloseDialogueBox}>
                    DISCARD
                  </button>

                  <button
                    style={
                      !is_form_valid || booking_minutes_error || booking_rate_error
                        ? style.DialogDisabledBtn
                        : style.DialogSubmissionBtn
                    }
                    onClick={() => {
                      editBooking(selected_booking.docId);
                    }}
                    disabled={!is_form_valid || booking_minutes_error || booking_rate_error}
                    key="edit_save">
                    Save
                  </button>
                </div>
              )}
            </div>
          ) : (
            <i>This time entry is uneditable since this data has been locked</i>
          )}
        </div>
      </Dialog>

      {/* Creating New booking Dialog box */}
      <Dialog
        open={is_dialoguebox_open && !is_edit_booking ? true : false}
        PaperProps={
          useMediaQuery('(max-width:1585px)')
            ? {
                style: {
                  minHeight: 'auto',
                  maxHeight: '90%',
                  width: '27%',
                },
              }
            : {
                style: {
                  minHeight: 'auto',
                  maxHeight: '90%',
                  width: '25%',
                },
              }
        }>
        <div style={{ position: 'absolute', right: 7, top: 7 }}>
          <CloseOutlined
            style={{ marginTop: 6, marginRight: 5, color: 'var(--tin-grey)', cursor: 'pointer' }}
            onClick={props.handleCloseDialogueBox}
          />
        </div>
        <div style={style.DialogContainer}>
          <div style={style.DialogContainerHeading}>New Booking Entry</div>

          <div style={style.DialogInputContainer}>
            <label style={style.DialogInputLabel}>Person / Resource *</label>
            {/* show selected resource if in resources or projects screen
              else just the current resource */}
            {selected_view === 'resources' ? (
              <div style={style.DialogInputDisabled}>
                {props.selected_resource && props.selected_resource.name && (
                  <Chip
                    style={style.DialogInputChipContainer}
                    avatar={
                      <Avatar style={style.DialogInputChip}>
                        {props.selected_resource.name[0]}
                      </Avatar>
                    }
                    label={props.selected_resource.name}
                  />
                )}
              </div>
            ) : null}
            {selected_view === 'private' ? (
              <div style={style.DialogInputDisabled}>
                <Chip
                  style={style.DialogInputChipContainer}
                  avatar={
                    <Avatar style={style.DialogInputChip}>{props.loggedin_user_name[0]}</Avatar>
                  }
                  label={loggedin_user_name}
                />
              </div>
            ) : null}
            {selected_view === 'projects'
              ? resources &&
                resources
                  .filter((resource) => resource.id === selected_project_logger)
                  .map((resource) => {
                    return (
                      <div style={style.DialogInputDisabled} key={`B.projects.${resource.name[0]}`}>
                        <Chip
                          style={style.DialogInputChipContainer}
                          avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                          label={resource.name}
                        />
                      </div>
                    );
                  })
              : null}

            {selected_view === 'teams'
              ? resources &&
                resources
                  .filter((resource) => resource.id === selected_project_logger)
                  .map((resource) => {
                    return (
                      <div style={style.DialogInputDisabled} key={`B.teams.${resource.name[0]}`}>
                        <Chip
                          style={style.DialogInputChipContainer}
                          avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                          label={resource.name}
                        />
                      </div>
                    );
                  })
              : null}
          </div>

          <div style={style.DialogInputContainer}>
            <label style={style.DialogInputLabel}>Project *</label>
            <div>
              <CustomSelect
                projects={projects}
                entry_selected_project={new_booking.projectId}
                handleProjectSelection={props.handleBookingChange}
                placeholder="Select Project"
              />
            </div>
          </div>

          <div style={style.DialogDateInputContainer}>
            <div>
              <div style={style.DialogInputLabel}>Start Date</div>
              <CustomDatePicker
                datePickerValue={
                  new_booking.start_date
                    ? {
                        year: Moment(new_booking.start_date).year(),
                        month: Moment(new_booking.start_date).month() + 1,
                        day: Moment(new_booking.start_date).date(),
                      }
                    : selected_day && {
                        year: Moment(selected_day).year(),
                        month: Moment(selected_day).month() + 1,
                        day: Moment(selected_day).date(),
                      }
                }
                handleChangeDate={props.handleBookingStartDateChange}
                formattedMinimumDate={props.formattedMinimumDate}
                style={style.DateStyle}></CustomDatePicker>
            </div>
            <div>
              <div style={[style.DialogInputLabel, { width: '60%' }]}>End Date</div>
              <CustomDatePicker
                datePickerValue={
                  new_booking.end_date
                    ? {
                        year: Moment(new_booking.end_date).year(),
                        month: Moment(new_booking.end_date).month() + 1,
                        day: Moment(new_booking.end_date).date(),
                      }
                    : selected_day && {
                        year: Moment(selected_day).year(),
                        month: Moment(selected_day).month() + 1,
                        day: Moment(selected_day).date(),
                      }
                }
                handleChangeDate={props.handleBookingEndDateChange}
                formattedMinimumDate={{
                  month: Moment(new_booking.start_date).month() + 1,
                  day: Moment(new_booking.start_date).date(),
                  year: Moment(new_booking.start_date).year(),
                }}
                style={style.DateStyle}></CustomDatePicker>
            </div>
          </div>
          {props.isWeekendDay(Moment(new_booking.start_date)) ||
          props.isWeekendDay(Moment(new_booking.end_date)) ? (
            <div style={style.DateWeekendNotice}>
              Entries for weekend can only be added one day at a time
            </div>
          ) : props.isHoliday(Moment(new_booking.start_date)) ||
            props.isHoliday(Moment(new_booking.end_date)) ? (
            <div style={style.DateWeekendNotice}>
              Entries for holiday can only be added one day at a time
            </div>
          ) : null}

          <div style={style.DialogTimeRateInputContainer}>
            <div>
              <span style={style.DialogInputLabel}>Time per Day *</span>
              <div style={style.DialogTimeRateInputField}>
                <img src={timelapsegreyicon} alt="" style={{ marginRight: 5, marginLeft: 3 }} />
                <input
                  key={'minutes_new'}
                  type="text"
                  name="minutes"
                  placeholder="8h"
                  style={[style.DialogInput, style.DialogTimeRateInput]}
                  onChange={handleChangeMinutes}
                  defaultValue={getMinutesInHMFormat(480)}
                />
              </div>
              {booking_minutes_error && (
                <>
                  <span style={{ fontSize: '10px', color: 'red' }}>{booking_minutes_error}</span>
                  <br />
                </>
              )}
            </div>
            <div>
              <span style={style.DialogInputLabel}>Hourly Rate *</span>
              <div style={style.DialogTimeRateInputField}>
                <img src={dollargreyicon} alt="" style={{ marginRight: 10, marginLeft: 5 }} />
                <input
                  key={'hourly_rate_new'}
                  name="hourly_rate"
                  type="number"
                  placeholder="0.00"
                  min="0.00"
                  defaultValue={new_booking.hourly_rate}
                  style={[style.DialogInput, style.DialogTimeRateInput]}
                  onChange={handleBookingChange}
                />
              </div>
              {booking_rate_error && (
                <>
                  <span style={{ fontSize: '10px', color: 'red' }}>{booking_rate_error} </span>
                  <br />
                </>
              )}
            </div>
          </div>
          <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>
            Please input time like 4h7m, 4h, or 7m
          </span>
          <br />
          <div style={style.DialogTextAreaContainer}>
            <span style={style.DialogInputLabel}>
              Description (optional)
              <br />
            </span>
            <textarea
              style={style.DialogInputTextArea}
              key={'textarea_new'}
              name="description"
              rows="4"
              placeholder="Add any details for this booking"
              onChange={handleBookingChange}
            />
          </div>
          <div style={style.DialogSubmission}>
            {!is_loading && (
              <button
                key="discard_new"
                style={style.DialogDiscardBtn}
                onClick={handleCloseDialogueBox}>
                DISCARD
              </button>
            )}
            {is_loading ? ( //props.entry_submission_state
              <div style={style.DialogSubmissionMessage}>Saving entries, please be patient...</div>
            ) : null}
            {!is_loading && (
              <button
                style={
                  !is_form_valid || booking_minutes_error || booking_rate_error
                    ? style.DialogDisabledBtn
                    : style.DialogSubmissionBtn
                }
                onClick={createBooking}
                disabled={!is_form_valid || booking_minutes_error || booking_rate_error}
                key={'save_new'}>
                Save
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DialogueBoxes = Radium(MyComponent);
