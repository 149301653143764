import React from 'react';

import Moment from 'moment';
import { Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@mui/material';

import { style } from '../StreamlineView/style';
import { InvoiceState } from '../../../constants/invoicestate';
import { getTotalFromInvoiceData } from '../../../Selectors/index';

export const ProjectInvoices = (props) => (
  <div style={style.TableContainer}>
    <Typography style={style.CardHeadings}>Invoices History</Typography>
    {props.invoices.length > 0 ? (
      <div style={style.CardContainer}>
        <Table style={{ ...style.Table, height: '100%' }}>
          <TableHead style={style.Head}>
            <TableRow style={style.TableRow}>
              {['Status', 'Start Date', 'End Date', 'Amount'].map((item, index) => {
                return (
                  <TableCell key={index} style={style.TableCellP}>
                    {item}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody style={style.Body}>
            {props.invoices.map((invoice, index) => {
              return (
                <TableRow
                  key={index}
                  style={style.TableRow}
                  className="hours-management-clickable-tablerow"
                  onClick={() => props.handleShowHistoricalInvoice(invoice.docId)}>
                  <TableCell style={style.TableCellP}>
                    <div
                      style={{
                        display: 'flex',
                        color: 'white',
                        backgroundColor: InvoiceState[invoice.status].color,
                        padding: '5px 10px',
                        borderRadius: '20px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {invoice.status}
                    </div>
                  </TableCell>
                  <TableCell style={style.TableCellP}>
                    {Moment(invoice.start_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell style={style.TableCellP}>
                    {Moment(invoice.end_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell style={style.TableCellP}>
                    ${getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    ) : (
      <div style={style.NoDataContainer}>This project doesn&apos;t have any previous invoice.</div>
    )}
  </div>
);
