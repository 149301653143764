import * as Types from '../../constants/actionconstants';
const initialState = {
  parsed_csv_data: null,
  show_project_invoice: false,
  project: 'all',
  error_message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.PARSED_CSV_DATA_REQUEST:
      return {
        ...state,
        loadingDashboard: true,
      };
    case Types.PARSED_CSV_DATA_SUCCESS:
      return {
        ...state,
        parsed_csv_data: action.payload,
        loadingDashboard: false,
        no_parsed_data: false,
      };
    case Types.PARSED_CSV_DATA_FAILURE:
      return {
        ...state,
        parsed_csv_data: undefined,
        loadingDashboard: false,
        no_parsed_data: true,
      };
    case Types.SET_PARSED_CSV_DATA_REQUEST:
      return {
        ...state,
        loadingDashboard: true,
      };
    case Types.SET_PARSED_CSV_DATA_SUCCESS:
      return {
        ...state,
        loadingDashboard: false,
      };
    case Types.SET_PARSED_CSV_DATA_FAILURE:
      return {
        ...state,
        error_message: action.payload,
        loadingDashboard: false,
      };
    case Types.GET_EMPLOYEE_RATES_REQUEST:
      return {
        ...state,
        rates: null,
        detailed_rates: null,
        loading: true,
      };
    case Types.GET_EMPLOYEE_RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload.rates,
        detailed_rates: action.payload.detailed_rates,
        loading: false,
      };
    case Types.GET_EMPLOYEE_RATES_FAILURE:
      return {
        ...state,
        rates: null,
        detailed_rates: null,
        loading: false,
      };
    case Types.SET_PROJECT_IN_HEADER_SUCCESS:
      return {
        ...state,
        show_project_invoice: action.payload.show_project_invoice,
        project: action.payload.project,
      };
    case Types.SET_PARSED_CSV_DATA_NULL:
      return {
        ...state,
        parsed_csv_data: null,
      };
    case Types.SET_RATES_NULL:
      return {
        ...state,
        rates: null,
        detailed_rates: null,
        loading: true,
      };
    case Types.SET_SHOW_DETAIL_INVOICE_PROJECT:
      return {
        ...state,
        show_project_invoice: action.payload.show_project_invoice,
        project: action.payload.project,
      };
    case Types.SET_SHOW_DETAIL_INVOICE_PROJECT_NULL:
      return {
        ...state,
        show_project_invoice: false,
        project: 'all',
      };
    case Types.SAVE_HOURS_REQUEST:
      return {
        ...state,
      };
    case Types.SAVE_HOURS_SUCCESS:
      return {
        ...state,
      };
    case Types.SAVE_HOURS_FAILURE:
      return {
        ...state,
      };
    case Types.GET_HOURS_RANGE_REQUEST:
      return {
        ...state,
      };
    case Types.GET_HOURS_RANGE_SUCCESS:
      return {
        ...state,
        no_active_period: false,
        range: action.payload,
      };
    case Types.GET_HOURS_RANGE_FAILURE:
      return {
        ...state,
        no_active_period: true,
      };
    case Types.GET_TIME_ENTRIES_HOURS_REQUEST:
      return {
        ...state,
      };
    case Types.GET_TIME_ENTRIES_HOURS_SUCCESS:
      return {
        ...state,
        time_entries_hours: action.payload,
      };
    case Types.GET_TIME_ENTRIES_HOURS_FAILURE:
      return {
        ...state,
      };
    case Types.GET_LAST_LOCKED_REQUEST:
      return {
        ...state,
      };
    case Types.GET_LAST_LOCKED_SUCCESS:
      return {
        ...state,
        last_locked: action.payload,
      };
    case Types.GET_LAST_LOCKED_FAILURE:
      return {
        ...state,
      };
    case Types.SET_LAST_LOCKED_REQUEST:
      return {
        ...state,
      };
    case Types.SET_LAST_LOCKED_SUCCESS:
      return {
        ...state,
      };
    case Types.SET_LAST_LOCKED_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
