import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Moment from 'moment';
import { withSnackbar } from 'notistack';

import { ActionsCreator } from '../../Redux/Actions/index';
import { SETTINGS_KEY_HOLIDAYS, SETTINGS_KEY_APP_INFO, SettingsListView } from './SettingsListView';
import { style } from './style';
import { DialogBoxes } from './DialogBoxes';
import { Edit } from '@mui/icons-material';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_view: SETTINGS_KEY_HOLIDAYS,
      new_holiday_start_date: null,
      new_holiday_title: '',
      selected_holiday_start_date: null,
      new_selected_holiday_start_date: null,
      selected_holiday_title: '',
      show_add_holiday: false,
      show_edit_popup: false,
      holiday_id: '',
      current_date: Moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { getHolidays, stopLoader } = this.props;

    await getHolidays();
    await stopLoader();
  };

  toggleEditPopup = (date, title, id) => {
    console.log(
      `date: ${date} title ${title} popup: ${this.state.show_edit_popup} hol: ${this.state.show_add_holiday}`,
    );
    this.setState({
      show_edit_popup: !this.state.show_edit_popup,
      selected_holiday_start_date: date,
      new_selected_holiday_start_date: null,
      selected_holiday_title: title,
      holiday_id: id,
    });
  };

  handleShowAddHoliday = () => {
    this.setState({
      show_add_holiday: true,
    });
  };

  handleHideAddHoliday = () => {
    this.setState({
      show_add_holiday: false,
      new_holiday_start_date: null,
      new_holiday_title: '',
    });
  };

  handleHolidayStartDate = (e) => {
    const convertDate = { month: e.month - 1, day: e.day, year: e.year };
    this.state.show_edit_popup
      ? this.setState({
          new_selected_holiday_start_date: Moment(convertDate).format('YYYY-MM-DD'),
        })
      : this.setState({
          new_holiday_start_date: Moment(convertDate).format('YYYY-MM-DD'),
        });
  };

  handleHolidayTitle = (e) => {
    this.state.show_edit_popup
      ? this.setState({
          selected_holiday_title: e.target.value,
        })
      : this.setState({
          new_holiday_title: e.target.value,
        });
  };

  handleEditHoliday = () => {
    const {
      new_selected_holiday_start_date,
      selected_holiday_start_date,
      selected_holiday_title,
      holiday_id,
    } = this.state;

    const { editHoliday, startLoader, stopLoader, enqueueSnackbar, getHolidays } = this.props;

    let updated_holiday = {
      title: selected_holiday_title,
      start_date: new_selected_holiday_start_date
        ? Moment(new_selected_holiday_start_date).format('YYYY-MM-DD')
        : Moment(selected_holiday_start_date).format('YYYY-MM-DD'),
    };

    startLoader('#029e5f')
      .then((response) => {
        if (response) {
          return editHoliday(holiday_id, updated_holiday);
        }
      })
      .then((res) => {
        if (res) {
          let hstr = updated_holiday ? 'Holiday Updated!' : 'Holidays Updated!';
          enqueueSnackbar(hstr, { variant: 'success' });
          return getHolidays();
        } else {
          enqueueSnackbar('Unable to update holidays', { variant: 'error' });
          stopLoader();
        }
      })
      .then((res) => {
        this.toggleEditPopup();
        stopLoader();
      });
  };

  handleAddNewHoliday = () => {
    const { new_holiday_start_date, new_holiday_title } = this.state;

    const { addHolidays, startLoader, stopLoader, enqueueSnackbar, getHolidays } = this.props;

    let holidays_array = [
      {
        title: new_holiday_title,
        start_date: Moment(new_holiday_start_date).format('YYYY-MM-DD'),
      },
    ];

    startLoader('#029e5f')
      .then((response) => {
        if (response) {
          return addHolidays(holidays_array);
        }
      })
      .then((res) => {
        if (res) {
          let hstr = holidays_array.length === 1 ? 'Holiday Added!' : 'Holidays Added!';
          enqueueSnackbar(hstr, { variant: 'success' });
          return getHolidays();
        } else {
          enqueueSnackbar('Unable to add holidays', { variant: 'error' });
          stopLoader();
        }
      })
      .then((res) => {
        this.handleHideAddHoliday();
        stopLoader();
      });
  };

  handleDeleteHoliday = (id) => {
    const { deleteHoliday, enqueueSnackbar, getHolidays, holidays, startLoader, stopLoader } =
      this.props;

    if (!holidays || holidays.length <= 0) {
      enqueueSnackbar('Unable to delete holiday', { variant: 'error' });
    }

    let holiday = holidays.find((obj) => {
      return obj.docId === id;
    });

    var answer = window.confirm('Are you sure you want to delete this holiday?');
    if (answer) {
      startLoader('#029e5f')
        .then((response) => {
          if (response) {
            return deleteHoliday(holiday.docId);
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Holiday deleted', { variant: 'success' });
            return getHolidays();
          } else {
            enqueueSnackbar('Unable to delete holiday', { variant: 'error' });
            stopLoader();
          }
        })
        .then((res) => {
          stopLoader();
        });
    }
  };

  holidayAlreadyExists = (day) => {
    if (!day) {
      return false;
    }

    const { holidays } = this.props;

    let holiday = holidays.find((obj) => {
      if (obj.start_date !== this.state.selected_holiday_start_date) {
        return obj.start_date === day;
      }
    });
    return holiday ? true : false;
  };

  handleSettingItemClicked = (selectedSettingItem) => {
    this.setState({ selected_view: selectedSettingItem });
  };

  render() {
    const {
      selected_view,
      new_holiday_start_date,
      new_holiday_title,
      show_add_holiday,
      show_edit_popup,
      selected_holiday_start_date,
      selected_holiday_title,
      new_selected_holiday_start_date,
    } = this.state;

    const { holidays, updatePMs } = this.props;

    return (
      <div style={style.ComponentMain}>
        <div style={style.MainContainer}>
          <SettingsListView
            selected_view={selected_view}
            handleSettingItemClicked={this.handleSettingItemClicked}
          />

          {selected_view === SETTINGS_KEY_HOLIDAYS ? (
            <div style={style.bodyContainer}>
              {/* Heading */}
              <div style={style.HeadingContainer}>
                <h1 style={style.MainHeading}>Manage holidays</h1>
                {holidays ? (
                  <div>
                    <span style={style.SubHeading}>
                      This is a list of public holidays set by SuperAdmins, during which people are
                      not subject to logging hours (or being booked).
                    </span>
                    <br />
                    <button style={style.AddHolidayButton} onClick={this.handleShowAddHoliday}>
                      + Add Holiday
                    </button>
                  </div>
                ) : (
                  <div>
                    <span style={style.SubHeading}>No holidays added yet.</span>
                    <br />
                    <button style={style.AddHolidayButton} onClick={this.handleShowAddHoliday}>
                      + Add Holiday
                    </button>
                  </div>
                )}
              </div>
              <br />
              {holidays && (
                <div style={style.DateHolidayContainer}>
                  <div style={style.MapperContainer}>
                    <Table style={style.Table}>
                      <TableHead style={style.Head}>
                        <TableRow style={style.TableRow}>
                          <TableCell style={style.TableCell}></TableCell>
                          <TableCell style={style.TableCell}></TableCell>
                          <TableCell style={style.TableCellDate}>
                            <p style={{ fontWeight: 'bold' }}>Date</p>
                          </TableCell>
                          <TableCell style={style.TableCellTitle}>
                            <p style={{ fontWeight: 'bold' }}>Title</p>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {holidays.map((holiday) => (
                          <TableRow key={holiday.docId} style={style.TableRow}>
                            <TableCell style={style.TableCell}>
                              <IconButton onClick={() => this.handleDeleteHoliday(holiday.docId)}>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                            <TableCell style={style.TableCell}>
                              <IconButton
                                onClick={() =>
                                  this.toggleEditPopup(
                                    holiday.start_date,
                                    holiday.title,
                                    holiday.docId,
                                  )
                                }>
                                <Edit fontSize="small" />
                              </IconButton>
                            </TableCell>
                            <TableCell style={style.TableCellDate}>
                              <p>{holiday.start_date}</p>
                            </TableCell>
                            <TableCell style={style.TableCellTitle}>
                              <p>{holiday.title}</p>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              )}

              {(show_add_holiday || show_edit_popup) && (
                <DialogBoxes
                  new_holiday_start_date={new_holiday_start_date}
                  handleHolidayStartDate={this.handleHolidayStartDate}
                  new_holiday_title={new_holiday_title}
                  handleHolidayTitle={this.handleHolidayTitle}
                  show_add_holiday={show_add_holiday}
                  show_edit_popup={show_edit_popup}
                  toggleEditPopup={this.toggleEditPopup}
                  selected_holiday_start_date={selected_holiday_start_date}
                  new_selected_holiday_start_date={new_selected_holiday_start_date}
                  selected_holiday_title={selected_holiday_title}
                  handleShowAddHoliday={this.handleShowAddHoliday}
                  handleHideAddHoliday={this.handleHideAddHoliday}
                  handleAddNewHoliday={this.handleAddNewHoliday}
                  handleEditHoliday={this.handleEditHoliday}
                  holidayAlreadyExists={this.holidayAlreadyExists}
                  current_date={this.state.current_date}
                />
              )}
            </div>
          ) : selected_view === SETTINGS_KEY_APP_INFO ? (
            <div style={style.bodyContainer}>
              <div style={style.HeadingContainer}>
                <h1 style={style.MainHeading}>App Information</h1>
                <div style={style.SubHeading}>
                  Current App Version: {process.env.REACT_APP_VERSION}
                  <br />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,

    holidays: state.SettingReducer.holidays,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),

  addHolidays: (holidays) => dispatch(ActionsCreator.addHolidays(holidays)),
  getHolidays: () => dispatch(ActionsCreator.getHolidays()),
  deleteHoliday: (id) => dispatch(ActionsCreator.deleteHoliday(id)),
  editHoliday: (id, holidays) => dispatch(ActionsCreator.editHoliday(id, holidays)),
  updatePMs: () => dispatch(ActionsCreator.updatePMs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Settings));
