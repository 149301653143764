import React from 'react';

import Chart from 'chart.js';
import { Typography } from '@mui/material';
import ReactChartkick, { PieChart } from 'react-chartkick';
import { style } from './style';

ReactChartkick.addAdapter(Chart);

export const DialogCharts = (props) => (
  <div style={style.ChartRow}>
    <div
      style={style.TableContainer}
      className="hours-management-button"
      onClick={props.handleShowBillableInvoices}>
      <Typography style={style.CardHeadings}>Invoices Distribution</Typography>
      <div style={style.ChartContainer}>
        <PieChart
          style={style.CentralizedRow}
          data={props.projects_chart_data}
          donut={true}
          library={style.DonutChart}
          prefix="$"
          messages={{ empty: 'No data to make Chart!' }}
        />
      </div>
    </div>
    <div
      style={style.TableContainer}
      className="hours-management-button"
      onClick={props.handleShowHoursDistribution}>
      <Typography style={style.CardHeadings}>Hours Distribution</Typography>
      <div style={style.ChartContainer}>
        <PieChart
          colors={['#00b900', '#009ce2', '#c82000', '#f3c700', '#f68100']}
          style={style.CentralizedRow}
          data={props.hours_chart_data}
          legend="right"
          library={style.PieChart}
          thousands=","
          messages={{ empty: 'No data to make Chart!' }}
        />
      </div>
    </div>
  </div>
);
