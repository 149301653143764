import * as Types from '../../constants/actionconstants';
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_RATES_REQUEST:
      return {
        ...state,
      };
    case Types.GET_RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload,
        no_rates_data: false,
      };
    case Types.GET_RATES_FAILURE:
      return {
        ...state,
        rates: null,
        no_rates_data: true,
      };
    case Types.GET_NEW_RATES_REQUEST:
      return {
        ...state,
      };
    case Types.GET_NEW_RATES_SUCCESS:
      return {
        ...state,
        new_rates: action.payload,
        no_new_rates_data: false,
      };
    case Types.GET_NEW_RATES_FAILURE:
      return {
        ...state,
        new_rates: null,
        no_new_rates_data: true,
      };
    case Types.GET_RESOURCES_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_RATE_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_RATE_SUCCESS:
      return {
        ...state,
      };
    case Types.DELETE_RATE_FAILURE:
      return {
        ...state,
      };
    case Types.GET_RESOURCES_SUCCESS:
      const resourcesMap = new Map(
        action.payload.map((resource) => {
          return [resource.id, resource];
        }),
      );
      return {
        ...state,
        resources: action.payload,
        resourcesMap: resourcesMap,
        loadingResources: false,
        no_data: false,
      };
    case Types.GET_RESOURCES_FAILURE:
      return {
        ...state,
        no_data: true,
        loadingResources: false,
      };
    case Types.SET_RESOURCE_RATE_REQUEST:
      return {
        ...state,
      };
    case Types.SET_RESOURCE_RATE_SUCCESS:
      return {
        ...state,
      };
    case Types.SET_RESOURCE_RATE_FAILURE:
      return {
        ...state,
      };
    case Types.UPDATE_RESOURCE_CONFIG_REQUEST:
      return {
        ...state,
        loadingProjects: true,
      };
    case Types.UPDATE_RESOURCE_CONFIG_SUCCESS:
      return {
        ...state,
        loadingProjects: true,
      };
    case Types.UPDATE_RESOURCE_CONFIG_FAILURE:
      return {
        ...state,
        loadingProjects: false,
      };
    case Types.ADD_RESOURCE_REQUEST:
      return {
        ...state,
      };
    case Types.ADD_RESOURCE_SUCCESS:
      return {
        ...state,
      };
    case Types.ADD_RESOURCE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
