import React from 'react';
import Select from 'react-select';

import Radium from 'radium';

import { style } from './style';
import { Delete } from '@mui/icons-material';

const MyComponent = (props) => {
  const handleChange = (selectedOption) => {
    props.handleChange(
      [...selectedOption.map((item) => ({ email: item.value, name: item.label }))],
      'project_manager',
    );
  };

  return (
    <div style={style.Container}>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Project</label>
          <p style={style.Value}>{props.project.name}</p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Project Manager</label>
          {props.edit ? (
            <Select
              value={
                props.selected_project_manager
                  ? [
                      ...props.selected_project_manager.map((item) => ({
                        value: item.email,
                        label: item.name,
                      })),
                    ]
                  : []
              }
              onChange={handleChange}
              isDisabled={!props.selected_project_status}
              options={[
                ...props.resources
                  .filter(
                    (resource) =>
                      // todo: verify if it's the only plave where a null check is required
                      resource.active == true &&
                      resource.roles !== null &&
                      resource.roles.includes('Project Manager'),
                  )
                  .map((item) => {
                    return { value: item.email, label: item.name };
                  }),
              ]}
              isMulti
            />
          ) : (
            <p style={style.Value}>
              {props.project.project_managers
                ? [...props.project.project_managers.map((item) => item.name)].join(', ')
                : 'NOT ASSIGNED'}
            </p>
          )}
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Status</label>
          {props.edit ? (
            <select
              key={1}
              name="project_status"
              value={props.selected_project_status}
              style={style.ProjectSelect}
              onChange={(event) => props.handleChange(event, 'project_status')}>
              <option value={''} disabled>
                Select Project Status
              </option>
              <option value={true}>Active</option>
              <option value={false}>InActive</option>
            </select>
          ) : (
            <p style={style.Value}>{props.project.active ? 'Active' : 'InActive'}</p>
          )}
        </div>
      </div>
      <div style={style.Row}>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Project Receiver</label>
          {props.edit && props.receivers ? (
            <select
              key={3}
              name="project_receiver"
              value={props.selected_project_receiver}
              style={style.ProjectSelect}
              disabled={!props.selected_project_status}
              onChange={(event) => props.handleChange(event, 'project_receiver')}>
              {props.project.base_receiver && props.project.base_receiver.type === 'base' ? (
                <option value={''} disabled>
                  {props.project.base_receiver.name}
                </option>
              ) : (
                <option value={''} disabled>
                  Select Receiver
                </option>
              )}
              {props.receivers.map((receiver, index) => {
                return <option key={index}>{receiver.receiver_name}</option>;
              })}
            </select>
          ) : (
            <p style={style.Value}>
              {props.selected_project_receiver !== ''
                ? props.selected_project_receiver
                : props.project.base_receiver && props.project.base_receiver.type === 'base'
                ? props.project.base_receiver.name
                : 'Not Available'}
            </p>
          )}
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Project ID</label>
          <p style={style.Value}>{props.project.id}</p>
        </div>
        <div style={style.LabelValueCol}>
          <label style={style.Label}>Project Association</label>
          {props.edit ? (
            <select
              key={2}
              name="project_association"
              value={props.selected_project_association}
              style={style.ProjectSelect}
              disabled={!props.selected_project_status}
              onChange={(event) => props.handleChange(event, 'project_association')}>
              <option value={''} disabled>
                Select Project Association
              </option>
              <option value={true}>Billable</option>
              <option value={false}>UnBillable</option>
            </select>
          ) : (
            <p style={style.Value}>{props.project.billable ? 'Billable' : 'UnBillable'}</p>
          )}
        </div>
      </div>
      {props.show_add_associate_receiver ||
      (props.consider_prop && props.project.affiliate_receiver) ? (
        <>
          <div style={style.Row}>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Sub-Receiver</label>
            </div>
            <div style={style.LabelValueCol}>
              <label style={style.Label}>Percentage</label>
            </div>
            <div style={style.LabelValueCol}></div>
          </div>

          {props.edit && props.AssociatedReceivers && props.AssociatedReceivers.length !== 0
            ? props.AssociatedReceivers.map((associated_receiver, index) => {
                return (
                  <div key={index} style={style.Row}>
                    <div style={style.LabelValueCol}>
                      <select
                        key={associated_receiver.name + index + 7}
                        name="associated_receiver"
                        value={
                          associated_receiver.name !== 'dummy_value' ? associated_receiver.name : ''
                        }
                        style={style.ProjectSelect}
                        onChange={(event) => props.handleAssociateReceiver(event, index, 'name')}>
                        <option value={''} disabled>
                          Select Sub-Receiver
                        </option>
                        {props.receivers.map((receiver, index2) => {
                          return (
                            <option
                              key={associated_receiver.name + index2}
                              disabled={receiver.receiver_name === props.selected_project_receiver}
                              value={receiver.receiver_name}>
                              {receiver.receiver_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div style={style.LabelValueCol}>
                      <input
                        key={associated_receiver.name + 8 + index}
                        name="receiver_percentage"
                        type="text"
                        value={
                          associated_receiver.percentage !== -1
                            ? associated_receiver.percentage
                            : ''
                        }
                        style={style.ProjectSelect}
                        placeholder="%"
                        onChange={(event) =>
                          props.handleAssociateReceiver(event, index, 'percentage')
                        }
                      />
                    </div>
                    <div style={style.LabelValueCol}>
                      <Delete
                        style={{ color: 'red' }}
                        onClick={(event) => props.handleRemoveAssociateReceiver(index)}
                      />
                    </div>
                  </div>
                );
              })
            : null}

          {!props.edit && props.AssociatedReceivers && props.project.affiliate_receiver.length !== 0
            ? props.project.affiliate_receiver.map((associated_receiver, index) => {
                return (
                  <div key={index} style={style.Row}>
                    <div style={style.LabelValueCol}>
                      <p style={style.Value}>{associated_receiver.name}</p>
                    </div>
                    <div style={style.LabelValueCol}>
                      <p style={style.Value}>{associated_receiver.percentage}</p>
                    </div>
                    <div style={style.LabelValueCol}></div>
                  </div>
                );
              })
            : null}

          {props.show_percentage_error ? (
            <div style={style.Row}>
              <div style={style.LabelValueCol}>
                <p style={style.ProjectSelect2}>Error: Sum of Percentages must not exceed 100</p>
              </div>
              <div style={style.LabelValueCol}></div>
              <div style={style.LabelValueCol}></div>
            </div>
          ) : null}
          {props.edit ? (
            <div style={style.Row}>
              <div style={style.ButtonContainer}>
                <button
                  style={style.BorderedButton2}
                  disabled={!props.selected_project_status}
                  onClick={props.handleAddSubReceiverRow}>
                  +Add Sub-Receiver
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <div style={style.Row}>
        {!props.edit && props.show_edit_button ? (
          <div style={style.ButtonContainer}>
            <button style={style.BorderedButton} onClick={props.handleShowEditProjectView}>
              Edit Project
            </button>
          </div>
        ) : null}
        {props.edit && props.show_edit_button ? (
          <div style={style.ButtonContainer}>
            <button
              style={style.BorderedButton}
              onClick={() => props.handleEditProject(props.project)}>
              Save Edit
            </button>
            <button style={style.PinkBorderedButton} onClick={props.handleHideEditProjectView}>
              Cancel Edit
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const ProjectDetailView = Radium(MyComponent);
