import React, { Component } from 'react';

import Ledger from '../../components/Ledger';
import './LedgersPage.css';

class ProjectsPage extends Component {
  render() {
    return (
      <div className="ledgerpage-main">
        <Ledger />
      </div>
    );
  }
}

export default ProjectsPage;
