import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { List, Toolbar, ListItem } from '@mui/material';

import { style } from './style';
import { withSnackbar } from 'notistack';
import { TeamIcons } from './ResourceIcons';
import { DialogBoxes } from './DialogBoxes';
import { hasPermission } from '../../constants/ability';
import { RatesTableView } from '../Utility/RatesTableView';
import { ResourceDetailView } from './ResourceDetailView';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { validateEmail, getResourceRates } from '../../Selectors/index';
import * as ReactGA from 'react-ga';
import { default_role_type } from '../../constants/utility';

class Resource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: null,
      edit_view: false,
      show_resource: '',
      resource_name: '',
      resource_email: '',
      resource_status: '',
      selected_team_name: null,
      resource_association: '',
      show_add_resource: false,
      show_set_rate_view: false,
      selected_active_status: '',
      selected_part_time_status: '',
      show_delete_popup: false,
      show_confirmation_popup: false,
      search_resource: '',
      delete_doc: '',
    };
  }

  static defaultProps = {
    loadingResources: true,
  };

  componentDidMount() {
    this.loadResourcesSectionData();
  }

  loadResourcesSectionData = async () => {
    const { projects, stopLoader, getResources, getProjects, getNewRates, getTeams } = this.props;

    const promises = [getNewRates(), getTeams(), getResources()];
    !projects && promises.push(getProjects());
    await Promise.all(promises);
    stopLoader();
  };

  handleAddResource = () => {
    let { resource_name, resource_email, resource_association, resource_status } = this.state;
    const { addResource, resources, startLoader, stopLoader, enqueueSnackbar, getResources } =
      this.props;

    let resource_id = Math.floor(Math.random() * 1000000000);

    if (resources && this.validateResourceInput(resource_id)) {
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            return addResource({
              id: resource_id,
              name: resource_name,
              email: resource_email,
              active: resource_status === 'true', // backend needs bool
              is_part_time: resource_association === 'true', // backend needs bool
            });
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Resource Added!', { variant: 'success' });
            return getResources();
          } else {
            enqueueSnackbar("Resource wasn't added!", { variant: 'error' });
            stopLoader();
          }
        })
        .then((res) => {
          this.handleHideAddResource();
          stopLoader();
        });
    } else {
      enqueueSnackbar("Resource wasn't added!", { variant: 'error' });
    }
  };

  toggleDeletePopupResources = (docId) => {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
      delete_doc: docId,
    });
  };

  handleAddRate = (resource_id, project_id, role, role_type, rate) => {
    let { setRate, enqueueSnackbar, startLoader, stopLoader } = this.props;

    if (project_id && rate && rate >= 0) {
      startLoader('#f5bd00')
        .then((response) => {
          if (response) {
            return setRate({
              resource_id: resource_id,
              project_id: project_id,
              rate: rate,
              role: role,
              role_type: role_type,
            });
          }
        })
        .then(async (response) => {
          if (response) {
            ReactGA.event({
              category: 'Resources',
              action: 'Added a Resource rate',
            });
            enqueueSnackbar('Rate set successfully!', { variant: 'success' });
            await this.loadResourcesSectionData();
            await this.handleHideSetResourceRate();
          } else {
            enqueueSnackbar('Unable to set rate!', { variant: 'error' });
          }
          stopLoader();
        });
    } else {
      enqueueSnackbar('Select Correct fields', { variant: 'error' });
    }
  };

  handleEdit = (role, rate, project_id, id, role_type, docId) => {
    let { editRate, enqueueSnackbar, startLoader, stopLoader } = this.props;

    startLoader('#f5bd00')
      .then((response) => {
        if (response) {
          return editRate(
            {
              resource_id: parseInt(id),
              project_id: parseInt(project_id),
              rate: parseInt(rate),
              role: role,
              role_type: role_type,
            },
            docId,
          );
        }
      })
      .then(async (response) => {
        if (response) {
          ReactGA.event({
            category: 'Resources',
            action: 'Edited a Resource rate/role',
          });
          enqueueSnackbar('Rate/role changed successfully!', { variant: 'success' });
          await this.loadResourcesSectionData();
          await this.toggleEditPopup();
        } else {
          enqueueSnackbar('Unable to change rate!', { variant: 'error' });
        }
        stopLoader();
      });
  };

  handleDelete = () => {
    let { deleteRate, enqueueSnackbar, startLoader, stopLoader } = this.props;
    let { delete_doc } = this.state;

    startLoader('#f5bd00')
      .then((response) => {
        if (response) {
          return deleteRate(delete_doc);
        }
      })
      .then(async (response) => {
        if (response) {
          ReactGA.event({
            category: 'Resources',
            action: 'Deleted a Resource rate/role',
          });
          enqueueSnackbar('Rate/role deleted successfully!', { variant: 'success' });
          await this.loadResourcesSectionData();
          await this.toggleDeletePopupResources();
        } else {
          enqueueSnackbar('Unable to delete rate!', { variant: 'error' });
        }
        stopLoader();
      });
  };

  handleResourceClick = (resource_name) => {
    if (this.state.edit_view || this.state.show_set_rate_view) {
      this.setState({
        resource_name: resource_name,
        show_confirmation_popup: true,
      });
    } else {
      const data = this.props.resources.filter((resource) => resource.name === resource_name)[0];
      if (data) {
        this.setState({
          show_resource: resource_name,
          selected_team_name: data.teams ? data.teams : [],
          selected_part_time_status: data.is_part_time,
          selected_active_status: data.active,
          edit_view: false,
        });
      }
    }
  };

  handleResourceEditing = (resource_name) => {
    const data = this.props.resources.filter((resource) => resource.name === resource_name)[0];
    if (data) {
      this.setState({
        show_resource: resource_name,
        selected_team_name: data.teams ? data.teams : [],
        selected_part_time_status: data.is_part_time,
        selected_active_status: data.active,
        edit_view: false,
      });
    }
  };

  onContinue = () => {
    this.setState({ show_confirmation_popup: false });
  };

  onCancel = (resource_name) => {
    this.setState({
      show_set_rate_view: false,
      show_confirmation_popup: false,
    });
    this.handleResourceEditing(resource_name);
  };

  validateResourceInput = (resource_id) => {
    let { resource_name, resource_email } = this.state;
    const { enqueueSnackbar, resources } = this.props;

    if (resources) {
      // check for unique resource name
      if (resources.some((resource) => resource.name === resource_name)) {
        enqueueSnackbar('Resource name must be unique', { variant: 'error' });
        return false;
      }
      // check for unique resource email
      if (resources.some((resource) => resource.email === resource_email)) {
        enqueueSnackbar('Resource email must be unique', { variant: 'error' });
        return false;
      }
    }
    return true;
  };

  handleResourceName = (e) => {
    this.setState({
      resource_name: e.target.value,
    });
  };

  handleResourceEmail = (e) => {
    this.setState({
      resource_email: e.target.value,
    });
  };

  handleResourceStatus = (e) => {
    this.setState({
      resource_status: e.target.value,
    });
  };

  handleResourceAssociation = (e) => {
    this.setState({
      resource_association: e.target.value,
    });
  };

  handleShowAddResource = () => {
    this.setState({
      show_add_resource: true,
    });
  };

  handleHideAddResource = () => {
    this.setState({
      show_add_resource: false,
      resource_name: '',
      resource_email: '',
      resource_status: '',
      resource_association: '',
    });
  };

  handleShowSetResourceRate = () => {
    this.setState({
      show_set_rate_view: true,
    });
  };

  handleHideSetResourceRate = () => {
    this.setState({
      show_set_rate_view: false,
    });
  };

  handleShowEditView = () => {
    this.setState({
      edit_view: true,
    });
  };

  handleHideEditView = () => {
    this.setState({
      edit_view: false,
    });
  };

  handleChange = (e, type) => {
    if (type === 'active') {
      this.setState({
        selected_active_status: e.target.value,
      });
    } else if (type === 'part_time') {
      this.setState({
        selected_part_time_status: e.target.value,
      });
    } else if (type === 'team_name') {
      this.setState({
        selected_team_name: e, // react-select will send whole array in it
      });
    }
  };

  handleSaveEdit = (resource) => {
    const { startLoader, stopLoader, getResources, updateResourceConfig, enqueueSnackbar } =
      this.props;
    let { selected_team_name, selected_active_status, selected_part_time_status } = this.state;

    let active = String(selected_active_status);
    let part_time = String(selected_part_time_status);

    startLoader('#f5bd00')
      .then((response) => {
        if (response) {
          return updateResourceConfig({
            resource: resource.name,
            teams: selected_team_name.length ? selected_team_name : [],
            active: active === 'true' ? true : false,
            part_time: part_time === 'true' ? true : false,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Resources',
            action: 'Edited a Resource',
          });
          enqueueSnackbar('Resource Updated!', { variant: 'success' });
          return getResources();
        } else {
          enqueueSnackbar("Resource wasn't updated!", { variant: 'error' });
          stopLoader();
        }
        this.handleHideEditView();
      })
      .then((res) => {
        stopLoader();
        this.handleHideEditView();
      });
  };

  handleScroll = (event) => {
    this.setState({ scrollPosition: event.nativeEvent });
  };
  handleResourceSearch = (event) => {
    this.setState({
      search_resource: event.target.value,
    });
  };

  render() {
    let { loadingResources, resources, no_data, roles, new_rates, projects, teams, projectsMap } =
      this.props;

    let {
      edit_view,
      show_resource,
      resource_name,
      resource_email,
      resource_status,
      show_add_resource,
      selected_team_name,
      show_set_rate_view,
      resource_association,
      selected_active_status,
      selected_part_time_status,
      show_delete_popup,
      search_resource,
    } = this.state;

    let show_edit_button = hasPermission(roles, 'resource', 'can_edit');
    let can_set_rate = hasPermission(roles, 'resource', 'can_set_rate');
    let can_delete = hasPermission(roles, 'resource', 'can_delete_rate');
    let disabled_create_button =
      !validateEmail(resource_email) ||
      resource_name === '' ||
      resource_email === '' ||
      resource_status === '' ||
      resource_association === '';
    let resource_selected = {};
    let resources_rate_data = [];
    let selected_teams = [];

    let activeResources =
      resources &&
      resources.filter(
        (resource) =>
          resource.active === true &&
          ((search_resource !== '' &&
            resource.name.toLowerCase().includes(search_resource.toLowerCase())) ||
            search_resource == ''),
      );
    let inactiveResources =
      resources &&
      resources.filter(
        (resource) =>
          !resource.active &&
          ((search_resource !== '' &&
            resource.name.toLowerCase().includes(search_resource.toLowerCase())) ||
            search_resource == ''),
      );

    if (show_resource !== '') {
      resource_selected = resources.filter((resource) => resource.name === show_resource)[0];
    } else {
      if (!no_data && activeResources && activeResources.length > 0) {
        this.handleResourceClick(activeResources[0].name);
      } else if (!no_data && inactiveResources && inactiveResources.length > 0) {
        this.handleResourceClick(inactiveResources[0].name);
      }
    }
    if (new_rates && Object.keys(resource_selected).length !== 0) {
      resources_rate_data = getResourceRates(resource_selected, new_rates);
    }

    if (selected_team_name) {
      selected_teams = selected_team_name;
    }

    if (resources && resource_selected && edit_view && selected_active_status === '') {
      selected_active_status = resource_selected.active;
    }

    if (resources && resource_selected && edit_view && selected_part_time_status === '') {
      selected_part_time_status = resource_selected.is_part_time;
    }

    projectsMap &&
      resources_rate_data.map((projectRate) => {
        // handle orphan records
        const currentProject = projectsMap.get(projectRate.project_id);
        if (currentProject) {
          projectRate.project_active = currentProject.active;
        }
      });

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <p className="breadcrumb-link" style={style.HeaderText}>
            Resources
            <br />
            {resources ? (
              <span style={style.ActivePeriodText}>Total: {resources.length}</span>
            ) : null}
          </p>
          {hasPermission(roles, 'resource', 'can_create') ? (
            <button style={style.FilledButton} onClick={this.handleShowAddResource}>
              + Add Resource
            </button>
          ) : null}
        </Toolbar>
        <div style={loadingResources || no_data ? style.LoadingContainer : style.MainContainer}>
          {loadingResources === true ? (
            <Bars color="#f5bd00" height={50} width={50} />
          ) : no_data ? (
            <EmptyContainer />
          ) : (
            <>
              <List onScroll={this.handleScroll} style={style.ListView}>
                {resources && resources.length > 0 ? (
                  <input
                    type="text"
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: 'solid 2px var(--tin-yellow)',
                      margin: '5px',
                      color: 'var(--tin-grey)',
                      width: '97%',
                      outline: 'none',
                    }}
                    value={search_resource}
                    onChange={this.handleResourceSearch}
                    placeholder="Search Resource"></input>
                ) : null}

                {activeResources && activeResources.length > 0 ? (
                  <>
                    <h4 style={{ marginLeft: '10px' }}>Active Resources</h4>
                    {activeResources &&
                      activeResources.map((resource, index) => {
                        return (
                          <ListItem
                            button
                            key={index}
                            onClick={() => this.handleResourceClick(resource.name)}
                            style={
                              resource_selected.name === resource.name
                                ? style.SelectedResourceItem
                                : style.UnselectedResourceItem
                            }>
                            {/* <div style={style.ResourceIconContainer}>
                        {TeamIcons.filter(team => team.name === resource.team_name).length > 0 ? (
                          <img
                            alt="Team Logo"
                            style={style.ResourceIcon}
                            src={TeamIcons.filter(team => team.name === resource.team_name)[0].icon}
                          />
                        ) : (
                          <div style={style.ResourceIcon} />
                        )}
                      </div> */}
                            <div style={style.ResourceTextContainer}>
                              <span
                                style={
                                  resource_selected.name === resource.name
                                    ? style.SelectedResourceText
                                    : style.UnselectedResourceText
                                }>
                                {resource.name}
                              </span>
                            </div>
                          </ListItem>
                        );
                      })}
                  </>
                ) : null}

                {inactiveResources && inactiveResources.length > 0 ? (
                  <>
                    <h4 style={{ marginLeft: '10px' }}>Inactive Resources</h4>
                    {inactiveResources &&
                      inactiveResources.map((resource, index) => {
                        return (
                          <ListItem
                            button
                            key={index}
                            onClick={() => this.handleResourceClick(resource.name)}
                            style={
                              resource_selected.name === resource.name
                                ? style.SelectedResourceItem
                                : style.UnselectedResourceItem
                            }>
                            {/* <div style={style.ResourceIconContainer}>
                        {TeamIcons.filter(team => team.name === resource.team_name).length > 0 ? (
                          <img
                            alt="Team Logo"
                            style={style.ResourceIcon}
                            src={TeamIcons.filter(team => team.name === resource.team_name)[0].icon}
                          />
                        ) : (
                          <div style={style.ResourceIcon} />
                        )}
                      </div> */}
                            <div style={style.ResourceTextContainer}>
                              <span
                                style={
                                  resource_selected.name === resource.name
                                    ? style.SelectedResourceText
                                    : style.UnselectedResourceText
                                }>
                                {resource.name}
                              </span>
                            </div>
                          </ListItem>
                        );
                      })}
                  </>
                ) : null}
              </List>
              <div style={style.ListViewContainer}>
                {Object.keys(resource_selected).length !== 0 ? (
                  <>
                    <ResourceDetailView
                      teams={teams}
                      edit_view={edit_view}
                      resource={resource_selected}
                      handleChange={this.handleChange}
                      selected_team_name={selected_teams}
                      selected_active_status={selected_active_status}
                      selected_part_time_status={selected_part_time_status}
                    />

                    {show_edit_button && !edit_view ? (
                      <div style={style.EditContainer}>
                        <button
                          style={edit_view ? style.FilledButton : style.BorderedButton}
                          onClick={this.handleShowEditView}>
                          Edit Resource
                        </button>
                      </div>
                    ) : null}
                    {show_edit_button && edit_view ? (
                      <div style={style.EditContainer}>
                        <button
                          style={style.BorderedButton}
                          onClick={() => this.handleSaveEdit(resource_selected)}>
                          Save Edit
                        </button>
                        <button style={style.PinkBorderedButton} onClick={this.handleHideEditView}>
                          Cancel Edit
                        </button>
                      </div>
                    ) : null}

                    {can_set_rate || (new_rates && resources_rate_data.length > 0) ? (
                      <RatesTableView
                        resources_rate_data={resources_rate_data}
                        entity_type={'Project'}
                        entity_parent_id={resource_selected.id}
                        entity_parent_active={resource_selected.active}
                        active_entities={
                          projects ? projects.filter((project) => project.active == true) : []
                        }
                        roles={roles}
                        edit_mode_active={edit_view}
                        can_delete={can_delete}
                        can_set_rate={can_set_rate}
                        show_set_rate_button={can_set_rate}
                        toggleDeletePopup={this.toggleDeletePopupResources.bind(this)}
                        handleApplyButton={this.handleAddRate}
                        handleEdit={this.handleEdit}
                        select_style={style.Select}
                        button_style={style.BorderedButton}
                        filled_button_style={style.FilledButton}
                      />
                    ) : null}
                  </>
                ) : (
                  <EmptyContainer />
                )}
              </div>
              <DialogBoxes
                resource_name={resource_name}
                resource_email={resource_email}
                resource_status={resource_status}
                show_add_resource={show_add_resource}
                handleAddResource={this.handleAddResource}
                resource_association={resource_association}
                handleResourceName={this.handleResourceName}
                handleResourceEmail={this.handleResourceEmail}
                disabled_create_button={disabled_create_button}
                handleResourceStatus={this.handleResourceStatus}
                handleHideAddResource={this.handleHideAddResource}
                handleResourceAssociation={this.handleResourceAssociation}
                show_delete_popup={show_delete_popup}
                handleDelete={this.handleDelete}
                toggleDeletePopupResources={this.toggleDeletePopupResources.bind(this)}
                show_confirmation_popup={this.state.show_confirmation_popup}
                onContinue={this.onContinue}
                onCancel={this.onCancel}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teams: state.TeamReducer.teams,
    roles: state.LoginReducer.roles,
    new_rates: state.ResourceReducer.new_rates,
    no_data: state.ResourceReducer.no_data,
    projects: state.ProjectReducer.projects,
    projectsMap: state.ProjectReducer.projectsMap,
    resources: state.ResourceReducer.resources,
    no_projects_data: state.ResourceReducer.no_data,
    loadingResources: state.ResourceReducer.loadingResources,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addResource: (resource) => dispatch(ActionsCreator.addResource(resource)),
  getNewRates: () => dispatch(ActionsCreator.getNewRates()),
  setRate: (data) => dispatch(ActionsCreator.setRate(data)),
  editRate: (data, docId) => dispatch(ActionsCreator.editRate(data, docId)),
  deleteRate: (docId) => dispatch(ActionsCreator.deleteRate(docId)),
  getTeams: () => dispatch(ActionsCreator.getTeams()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getResources: () => dispatch(ActionsCreator.getResources()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  updateResourceConfig: (data) => dispatch(ActionsCreator.updateResourceConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Resource));
