import { TYPE_MUTLI_DATE, TYPE_RANGE, TYPE_SINGLE_DATE } from './constants';

const hasProperty = (object, propertyName) =>
  Object.prototype.hasOwnProperty.call(object || {}, propertyName);

export const getValueType = (value) => {
  if (Array.isArray(value)) return TYPE_MUTLI_DATE;
  if (hasProperty(value, 'from') && hasProperty(value, 'to')) return TYPE_RANGE;
  if (
    !value ||
    (hasProperty(value, 'year') && hasProperty(value, 'month') && hasProperty(value, 'day'))
  ) {
    return TYPE_SINGLE_DATE;
  }
  throw new TypeError(
    `The passed value is malformed! Please make sure you're using one of the valid value types for date picker.`,
  );
};
