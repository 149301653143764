import React from 'react';

import Radium from 'radium';
import { Dialog } from '@mui/material';

const MyComponent = (props) => (
  <>
    <Dialog open={props.show_confirmation_popup} fullWidth={true} maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Do you want to continue to edit this entry?</h2>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.BorderedButton2} onClick={() => props.onContinue()}>
          {'Yes'}
        </button>
        <button style={style.PinkBorderedButton} onClick={() => props.onCancel(props.team_name)}>
          {'No'}
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_add_team}
      onClose={props.handleHideAddTeam}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Add Team</h2>
        <p style={style.Cross} onClick={props.handleHideAddTeam}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          <input
            key={3}
            name="team_name"
            type="text"
            value={props.team_name}
            style={style.Select}
            placeholder="Team Name"
            onChange={props.handleTeamInput}
          />
        </div>
        <div style={style.RowItems}>
          <input
            key={4}
            name="display_name"
            type="text"
            value={props.display_name}
            style={style.Select}
            placeholder="Display Name"
            onChange={props.handleTeamDisplayNameInput}
          />
        </div>
        <div style={style.RowItems}>
          <select key={2} name="team_status" style={style.Select} onChange={props.handleTeamInput}>
            <option value={true}>Active</option>
            <option value={false}>InActive</option>
          </select>
        </div>
      </div>
      <div style={style.FlexContainer}>
        {props.disabled_create_button ? (
          <div style={style.RowItems}>
            <p style={style.InputErrorLabel}>use space and special characters between words</p>
          </div>
        ) : null}
        <div style={style.ButtonContainer}>
          <button
            style={props.disabled_create_button ? style.GreyBorderedButton : style.BorderedButton}
            onClick={props.handleAddTeam}
            disabled={props.disabled_create_button}>
            Create
          </button>
        </div>
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  RowItems2: {
    flex: 1.5,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-orange)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select2: {
    backgroundColor: 'white',
    padding: '10px',
    paddingBotton: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    marginLeft: '20px',
    marginBottom: '-10px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select3: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  FilledButton: {
    backgroundColor: 'var(--tin-orange)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  BorderedButtonClear: {
    backgroundColor: 'white',
    color: 'var(--tin-orange)',
    border: 'solid 1px var(--tin-orange)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    marginLeft: '0px 10px',
    cursor: 'pointer',
  },
  BorderedButton2: {
    backgroundColor: 'white',
    color: 'var(--tin-green)',
    border: 'solid 1px var(--tin-green)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ButtonContainer2: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  InputErrorLabel: {
    margin: '0px 20px',
    color: 'var(--tin-cancel-pink)',
    fontSize: '13px',
  },
  FlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
