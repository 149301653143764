import * as Types from '../../constants/actionconstants';
const initialState = {
  no_role_data: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        role_data: action.payload,
        loading: false,
        no_role_data: false,
      };
    case Types.FETCH_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        role_data: action.payload,
        no_role_data: true,
      };
    case Types.CREATE_ROLE_REQUEST:
      return {
        ...state,
      };
    case Types.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        created_role: action.payload,
      };
    case Types.CREATE_ROLE_FAILURE:
      return {
        ...state,
      };
    case Types.DELETE_ROLE_REQUEST:
      return {
        ...state,
      };
    case Types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };
    case Types.DELETE_ROLE_FAILURE:
      return {
        ...state,
      };
    case Types.EDIT_ROLE_REQUEST:
      return {
        ...state,
      };
    case Types.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };
    case Types.EDIT_ROLE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
