import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { style } from './style';

export const ProjectsList = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCellP}>Clients</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.projects.map((item, index) => {
        if (item.billable === props.show_billable) {
          return (
            <TableRow
              key={index}
              style={style.TableRow}
              className="hours-management-clickable-tablerow">
              <TableCell style={style.TableCellP}>{item.name}</TableCell>
            </TableRow>
          );
        } else {
          return null;
        }
      })}
    </TableBody>
  </Table>
);

export const InvoicedProjectsList = (props) => (
  <Table style={style.Table}>
    <TableHead style={style.Head}>
      <TableRow style={style.TableRow}>
        <TableCell style={style.TableCellP}>Clients</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={style.Body}>
      {props.projects.map((item, index) => {
        return (
          <TableRow
            key={index}
            style={style.TableRow}
            className="hours-management-clickable-tablerow">
            <TableCell style={style.TableCellP}>{item}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
