import Radium from 'radium';
import { Dialog } from '@mui/material';
import { style } from './style';

const MyComponent = (props) => (
  <Dialog open={props.open} onClose={props.handleDiscard} fullWidth={true} maxWidth="sm">
    <div style={style.HeadingRow}>
      <h3 style={style.Heading}>{props.headingText}</h3>
    </div>
    <div style={style.PromptText}>{props.promptText}</div>
    <div style={style.ButtonContainer}>
      <button style={style.ConfirmBorderedButton} onClick={props.handleDiscard}>
        {`OK`}
      </button>
    </div>
  </Dialog>
);

export const WarningDialog = Radium(MyComponent);
