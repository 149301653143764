export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'white',
  },
  MainHeading: {
    backgroundColor: 'var(--tin-hazelnut)',
    color: 'white',
  },
  ComponentMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  },
  HeaderText: {
    flex: 1,
    lineHeight: '20px',
    display: 'block',
  },
  TableContainer: {
    flex: 1,
    margin: '20px',
    boxShadow: '-1px 2px 15px -5px black',
    backgroundColor: 'white',
    borderRadius: '10px',
    minHeight: '45vh',
  },
  FlexCol: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'var(--tin-lightgrey)',
  },
  Headings: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
  },
  CardHeadingText: {
    flex: 3,
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
  ProjectsSelection: {
    flex: 1,
    display: 'flex',
    padding: '5px',
  },
  Select: {
    width: '20%',
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-hazelnut)',
    color: 'var(--tin-grey)',
    ':focus': {
      outline: 'none',
    },
  },
  Select_Quill: {
    overflowX: 'none',
    height: '100%',
    width: '700px',
    backgroundColor: 'white',
    padding: '10px',
    paddingLeft: '0px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    color: 'var(--tin-grey)',
    ':focus': {
      outline: 'none',
    },
    whiteSpace: 'pre',
  },
  CentralizedRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  FilledButton: {
    backgroundColor: 'var(--tin-hazelnut)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-hazelnut)',
    border: 'solid 1px var(--tin-hazelnut)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  ListView: {
    flex: 1.65,
    overflowY: 'scroll',
    boxShadow: 'var(--tin-grey) 4px 1px 20px -5px',
  },
  ListViewContainer: {
    flex: 7,
    overflowY: 'scroll',
  },
  SelectedReceiverItem: {
    backgroundColor: 'var(--tin-hazelnut)',
  },
  UnselectedReceiverItem: {
    color: 'var(--tin-grey)',
  },
  SelectedReceiverText: {
    color: 'white',
  },
  UnselectedReceiverText: {
    color: 'var(--tin-grey)',
  },
  Container: {
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
  },
  LabelValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  Label: {
    flex: 2,
    padding: '5px',
    fontSize: '16px',
    color: 'var(--tin-grey)',
  },
  Value: {
    fontSize: '14px',
    color: 'var(--tin-hazelnut)',
    padding: '5px',
    margin: '0px',
    marginRight: '40px',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    width: '700px',
    maxWidth: '1300px',
    overflowX: 'none',
    height: '100%',
  },
  ButtonContainer: {
    padding: '20px',
  },
};
