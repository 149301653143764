import React from 'react';

import Radium from 'radium';
import { Dialog, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_add_client}
      onClose={props.handleHideAddClient}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <WarningIcon style={style.Warning} />
        <Typography>Muliple Projects Are Missing Data Required For Export </Typography>
        <p style={style.Cross} onClick={props.handleHideAddClientWithX}>
          X
        </p>
      </div>
      {props.projects
        ? props.projects.map((project, index) => {
            return (
              <div style={style.Row} key={index}>
                <div style={style.RowItems}>
                  <p key={project.docId + 0} style={style.Select2}>
                    <b>Project:</b> {project.project_name}
                  </p>
                </div>
                <div style={style.RowItems}>
                  <input
                    key={project.docId + 1}
                    name="client_name"
                    type="text"
                    disabled={index !== 0 && !props.client_address[index - 1] ? true : false}
                    value={props.client_name[index] || ''}
                    style={style.Select}
                    placeholder="Client Name"
                    onChange={(e) =>
                      props.handleAddClientChanges(e, 'name', project.projectId, index)
                    }
                  />
                </div>

                <div style={style.RowItems}>
                  <input
                    key={project.docId + 2}
                    name="client_project_pdf_name"
                    type="text"
                    disabled={props.client_name[index] ? false : true}
                    value={props.client_project_pdf_name[index] || ''}
                    style={style.Select}
                    placeholder="Project Name on Invoice"
                    onChange={(e) => props.handleAddClientChanges(e, 'project_name', '', index)}
                  />
                </div>
                <div style={style.RowItems}>
                  <textarea
                    key={project.docId + 4}
                    rows="2"
                    name="client_address"
                    defaultValue={
                      props.client_address[index] && props.client_address[index] !== ''
                        ? JSON.parse(props.client_address[index]).join('\n')
                        : props.client_address[index]
                    }
                    style={style.Select}
                    placeholder="Client Address"
                    onChange={(e) => props.handleAddClientChanges(e, 'address', '', index)}
                  />
                </div>
              </div>
            );
          })
        : null}
      <div style={style.ButtonContainer}>
        <button
          style={props.disabled_create_button ? style.GreyBorderedButton : style.BorderedButton}
          onClick={props.handleAddClient}
          disabled={props.disabled_create_button}>
          Create
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_clients_confirmation_dialog}
      onClose={props.handleHideClientConfirmation}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <WarningIcon style={style.Warning} />
        <Typography>Please Confirm The Client Associations You Have Added </Typography>
        <p style={style.Cross} onClick={props.handleHideClientConfirmation}>
          X
        </p>
      </div>
      {props.allClientsInfo
        ? props.allClientsInfo.map((project, index) => {
            return (
              <div style={style.Row} key={index}>
                <div style={style.RowItems}>
                  <p key={project.project_id + 0} style={style.Select2}>
                    <b>Project:</b> {project.project_name}
                  </p>
                </div>
                <div style={style.RowItems}>
                  <input
                    key={project.project_id + 1}
                    name="client_name"
                    type="text"
                    value={project.client_name || ''}
                    style={style.Select}
                    placeholder="Client Name"
                    onChange={(e) => props.handleClientChangesFinal(e, 'name', index)}
                  />
                </div>

                <div style={style.RowItems}>
                  <input
                    key={project.project_id + 2}
                    name="client_project_pdf_name"
                    type="text"
                    value={project.client_project_pdf_name || ''}
                    style={style.Select}
                    placeholder="Project Name on Invoice"
                    onChange={(e) => props.handleClientChangesFinal(e, 'project_name', index)}
                  />
                </div>
                <div style={style.RowItems}>
                  <textarea
                    key={project.project_id + 4}
                    rows="2"
                    name="client_address"
                    defaultValue={
                      project.client_address !== ''
                        ? JSON.parse(project.client_address).join('\n')
                        : project.client_address
                    }
                    style={style.Select}
                    placeholder="Client Address"
                    onChange={(e) => props.handleAddClientChanges(e, 'address', '', index)}
                  />
                </div>
              </div>
            );
          })
        : null}
      <div style={style.ButtonContainer}>
        <button style={style.BorderedButtonCancel} onClick={props.handleHideClientConfirmation}>
          Cancel
        </button>
        <button style={style.BorderedButton} onClick={props.saveClientsAndGenerateReport}>
          Confirm
        </button>
      </div>
    </Dialog>
  </>
);

export const CreateDialogBox = Radium(MyComponent);

const style = {
  Row: {
    display: 'flex',
  },
  Warning: {
    fontSize: 30,
    color: '#ffcc00',
    padding: '10px',
  },
  RowItems: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 2px var(--tin-darkblue)',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  Select2: {
    backgroundColor: 'white',
    padding: '10px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    margin: '20px',
    color: 'var(--tin-grey)',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  FilledButton: {
    backgroundColor: 'var(--tin-darkblue)',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    cursor: 'pointer',
    padding: '0px 20px',
    margin: '0px 10px',
  },
  BorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-darkblue)',
    border: 'solid 1px var(--tin-darkblue)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  BorderedButtonCancel: {
    backgroundColor: 'white',
    color: 'var(--tin-red)',
    border: 'solid 1px var(--tin-red)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    padding: '0px 20px',
    margin: '0px 10px',
    cursor: 'pointer',
  },
  GreyBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-grey)',
    border: 'solid 1px var(--tin-grey)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  PinkBorderedButton: {
    backgroundColor: 'white',
    color: 'var(--tin-cancel-pink)',
    border: 'solid 1px var(--tin-cancel-pink)',
    borderRadius: '10px',
    height: '22px',
    width: 'auto',
    margin: '0px 10px',
    padding: '0px 20px',
    cursor: 'pointer',
  },
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
  PromptText: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonContainer: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};
