import React from 'react';

import { Dialog } from '@mui/material';
import { ProjectsList, InvoicedProjectsList } from './ProjectsList';
import ProjectDetailInvoice from '../ProjectDetailInvoice';
import { MissingRatesDefaulters } from './MissingRatesDefaulters';
import { MissingHoursDefaulters } from './MissingHoursDefaulters';
import { BillableProjectInvoices } from './BillableProjectInvoices';
import { ResourceHoursDistribution } from './ResourceHoursDistribution';
import { AllResourcesHoursDistribution } from './AllResourcesHoursDistribution';
import { ResourcesList } from './ResourcesList';

export const DialogBoxes = (props) => (
  <>
    <Dialog
      open={props.show_billable_invoices}
      onClose={props.handleHideBillableInvoices}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Project Invoices</h2>
        <p style={style.Cross} onClick={props.handleHideBillableInvoices}>
          X
        </p>
      </div>
      <BillableProjectInvoices
        invoice_data={props.invoice_data}
        handleShowProjectInvoice={props.handleShowProjectInvoice}
      />
    </Dialog>
    <Dialog
      open={props.show_single_invoice}
      onClose={props.handleHideSingleInvoice}
      fullWidth={true}
      maxWidth="xl">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Detail Invoice</h2>
        <p style={style.Cross} onClick={props.handleHideSingleInvoice}>
          X
        </p>
      </div>
      <ProjectDetailInvoice
        showDetailHourDistribution={props.handleShowResourceHoursDistribution}
      />
    </Dialog>
    <Dialog
      open={props.show_missing_hours_defaulters}
      onClose={props.handleHideMissingHoursDefaulters}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Employees Missing Hours</h2>
        <p style={style.Cross} onClick={props.handleHideMissingHoursDefaulters}>
          X
        </p>
      </div>
      <MissingHoursDefaulters defaulters={props.missed_hours_defaulters} />
    </Dialog>
    <Dialog
      open={props.show_missing_rates_defaulters}
      onClose={props.handleHideMissingRatesDefaulters}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Employees Missing Rates</h2>
        <p style={style.Cross} onClick={props.handleHideMissingRatesDefaulters}>
          X
        </p>
      </div>
      <MissingRatesDefaulters rate_defaulters={props.rate_defaulters} />
    </Dialog>
    <Dialog
      open={props.show_billable_projects}
      onClose={props.handleHideBillableProjects}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Billable Projects</h2>
        <p style={style.Cross} onClick={props.handleHideBillableProjects}>
          X
        </p>
      </div>
      <ProjectsList projects={props.projects} show_billable={true} />
    </Dialog>
    <Dialog
      open={props.show_unbillable_projects}
      onClose={props.handleHideUnBillableProjects}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>UnBillable Projects</h2>
        <p style={style.Cross} onClick={props.handleHideUnBillableProjects}>
          X
        </p>
      </div>
      <ProjectsList projects={props.projects} show_billable={false} />
    </Dialog>
    <Dialog
      open={props.show_invoiced_projects}
      onClose={props.handleHideInvoicedProjects}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Invoiced Projects</h2>
        <p style={style.Cross} onClick={props.handleHideInvoicedProjects}>
          X
        </p>
      </div>
      <InvoicedProjectsList projects={props.invoiced_projects_list} />
    </Dialog>
    <Dialog
      open={props.show_unbillable_resources}
      onClose={props.handleHideUnBillableResources}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>UnBillable Resources</h2>
        <p style={style.Cross} onClick={props.handleHideUnBillableResources}>
          X
        </p>
      </div>
      <ResourcesList resources={props.unbillable_resources} />
    </Dialog>
    <Dialog
      open={props.show_billable_resources}
      onClose={props.handleHideBillableResources}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Billable Resources</h2>
        <p style={style.Cross} onClick={props.handleHideBillableResources}>
          X
        </p>
      </div>
      <ResourcesList resources={props.billable_resources} />
    </Dialog>
    <Dialog
      open={props.show_hours_distribution}
      onClose={props.handleHideHoursDistribution}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Hours Distribution</h2>
        <p style={style.Cross} onClick={props.handleHideHoursDistribution}>
          X
        </p>
      </div>
      <AllResourcesHoursDistribution
        total_hours={props.total_hours}
        resources_data={props.hours_distribution}
        sendEmail={props.handleGenerateEmailReminder}
        showDetailView={props.handleShowResourceHoursDistribution}
        hours_chart_data={props.hours_chart_data}
      />
    </Dialog>
    <Dialog
      open={props.show_resource_hours_distribution}
      onClick={props.handleHideResourceHoursDistribution}
      fullWidth={true}
      maxWidth="md">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>Logged Hours</h2>
        <p style={style.Cross} onClick={props.handleHideResourceHoursDistribution}>
          X
        </p>
      </div>
      <ResourceHoursDistribution
        resource={props.show_resource}
        completed_hours={props.resource_data.completed_hours}
        missed_hours={props.resource_data.missed_hours}
        leave_hours={props.resource_data.leave_hours}
        total_hours={props.total_hours}
        resource_records={props.resource_records}
        sendEmail={props.handleGenerateEmailReminder}
      />
    </Dialog>
  </>
);

const style = {
  HeadingRow: {
    flex: 1,
    display: 'flex',
    padding: '20px 20px 0px 20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Heading: {
    flex: 1,
    margin: '0px',
  },
  Cross: {
    fontSize: '30px',
    fontWeight: 550,
    margin: '0px',
    position: 'absolute',
    right: '20px',
    top: '7px',
    cursor: 'pointer',
  },
};
