import React from 'react';
import Moment from 'moment';
import Radium from 'radium';

import { Avatar, Chip, Dialog, useMediaQuery } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';

import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { style } from './style';
import CustomSelect from './CustomSelect';
import timelapsegreyicon from '../../assets/images/hours-timelapse-grey.svg';
import { CustomDatePicker } from '../Utility/CustomDatePicker';
const MyComponent = (props) => (
  <>
    {/* This is edit/delete case */}
    <Dialog
      open={props.show_edit_delete_time_entry_modal}
      PaperProps={{
        style: {
          minHeight: 'auto',
          maxHeight: '90%',
          width: '25%',
        },
      }}>
      <div style={{ position: 'absolute', right: 7, top: 7 }}>
        <CloseOutlined
          style={{ marginTop: 6, marginRight: 5, color: 'var(--tin-grey)', cursor: 'pointer' }}
          onClick={props.handleCloseDialogueBox}
        />
      </div>
      <div style={style.DialogContainer}>
        <div style={style.DialogContainerHeading}>Edit Time Entry</div>
        <div style={style.DialogInputContainer}>
          <label style={style.DialogInputLabel}>Person / Resource *</label>
          <div style={style.DialogInputDisabled}>
            {props.selected_view === 'private' ? (
              <Chip
                style={style.DialogInputChipContainer}
                avatar={<Avatar style={style.DialogInputChip}>{props.loggedin_name[0]}</Avatar>}
                label={props.loggedin_name}
              />
            ) : null}
            {props.selected_view === 'resources' ? (
              <>
                {props.selected_resource && props.selected_resource.name && (
                  <Chip
                    style={style.DialogInputChipContainer}
                    avatar={
                      <Avatar style={style.DialogInputChip}>
                        {props.selected_resource.name[0]}
                      </Avatar>
                    }
                    label={props.selected_resource.name}
                  />
                )}
              </>
            ) : null}
            {props.selected_view === 'projects'
              ? props.resources &&
                props.resources
                  .filter((resource) => resource.id === props.selected_project_logger)
                  .map((resource) => {
                    return (
                      <Chip
                        key={resource.id}
                        style={style.DialogInputChipContainer}
                        avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                        label={resource.name}
                      />
                    );
                  })
              : null}
            {props.selected_view === 'teams'
              ? props.resources &&
                props.resources
                  .filter((resource) => resource.id === props.selected_project_logger)
                  .map((resource) => {
                    return (
                      <Chip
                        key={`H.teams.edit.${resource.name[0]}`}
                        style={style.DialogInputChipContainer}
                        avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                        label={resource.name}
                      />
                    );
                  })
              : null}
          </div>
        </div>

        <div style={style.DialogInputContainer}>
          <label style={style.DialogInputLabel}>Project *</label>
          <div>
            <CustomSelect
              projects={props.projects}
              entry_selected_project={props.entry_selected_project}
              handleProjectSelection={props.handleProjectSelection}
            />
          </div>
        </div>

        <div style={style.DialogDateInputContainer}>
          <div>
            <div style={style.DialogInputLabel}>Date</div>
            <CustomDatePicker
              datePickerValue={{
                year: Moment(props.entry_start_date).year(),
                month: Moment(props.entry_start_date).month() + 1,
                day: Moment(props.entry_start_date).date(),
              }}
              handleChangeDate={props.handleChangeStartDate}
              formattedMinimumDate={props.formattedMinimumDate}
              style={style.DateStyle}></CustomDatePicker>
          </div>
        </div>

        <div style={style.DialogTimeInputContainer}>
          <span style={style.DialogInputLabel}>
            Time per Day * <br />
          </span>
          <div style={style.DialogTimeRateInputField}>
            <img src={timelapsegreyicon} alt="" style={{ marginRight: 5, marginLeft: 3 }} />
            <input
              name="Minutes"
              type="text"
              placeholder="8h"
              style={[style.DialogInput, style.DialogTimeRateInput]}
              onChange={(e) => props.handleChangeMinutes(e)}
              defaultValue={props.getMinutesInHMFormat(props.entry_minutes)}
              // value={props.entry_minutes && props.getMinutesInHMFormat(props.entry_minutes)}
            />
          </div>
          {props.entry_minutes_error && (
            <>
              <span style={{ fontSize: '10px', color: 'red' }}>{props.entry_minutes_error} </span>
              <br />
            </>
          )}
        </div>
        <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>
          Please input time like 4h7m, 4h, or 7m
        </span>
        <br />
        <div style={style.DialogInputContainer}>
          <span style={style.DialogInputLabel}>
            Description (optional)
            <br />
          </span>
          <textarea
            style={style.DialogInputTextArea}
            key={3}
            name="Description"
            rows="4"
            placeholder=""
            onChange={(e) => props.handleChangeDescription(e)}
            defaultValue={props.entry_description && props.entry_description}></textarea>
        </div>

        <div style={style.DialogueEdittedByContainer}>
          <div>
            <span style={{ fontSize: '10px', color: 'var(--tin-grey)', alignItems: 'center' }}>
              Created By:{' '}
            </span>
            {props.createdBy ? (
              <span style={{ fontSize: '10px', alignItems: 'center' }}>
                {' '}
                {props.createdBy.user_name} at{' '}
                {moment(props.createdBy.event_at).format('MM/DD/YYYY hh:mm a')}
              </span>
            ) : (
              <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>-</span>
            )}
          </div>

          <div>
            <span style={{ fontSize: '10px', color: 'var(--tin-grey)', alignItems: 'center' }}>
              Last Edited By:{' '}
            </span>
            {props.edittedBy ? (
              <span style={{ fontSize: '10px' }}>
                {' '}
                {props.edittedBy.user_name} at{' '}
                {moment(props.edittedBy.event_at).format('MM/DD/YYYY hh:mm a')}
              </span>
            ) : (
              <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>-</span>
            )}
          </div>
        </div>

        {Moment(props.entry_start_date).isAfter(props.last_locked) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {/* Shows loader if delete request is made else there is Delete button
              default props.toggleDeleteBtn's value is false
            */}
            {!props.toggleDeleteBtn && !props.entry_submission_state ? (
              <button
                style={style.DialogDeleteBtn}
                onClick={props.handleDeleteTimeEntry}
                key={9}
                disabled={props.toggleDeleteBtn}>
                <DeleteOutlineOutlined style={{ width: 25, height: 25 }} />
              </button>
            ) : (
              <ThreeDots
                height="10"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            )}
            {/* Hiding save button if delete request is in process 
              default props.toggleDeleteBtn's value is false
            */}
            {!props.toggleDeleteBtn && !props.entry_submission_state && (
              <div>
                <button style={style.DialogDiscardBtn} onClick={props.handleCloseDialogueBox}>
                  DISCARD
                </button>

                <button
                  style={
                    props.entry_minutes_error ? style.DialogDisabledBtn : style.DialogSubmissionBtn
                  }
                  onClick={props.handleEditTimeEntry}
                  disabled={
                    props.entry_submission_state ||
                    props.toggleDeleteBtn ||
                    props.entry_minutes_error
                  }
                  key={10}>
                  Save
                </button>
              </div>
            )}
          </div>
        ) : (
          <i>This time entry is uneditable since this data has been locked</i>
        )}
      </div>
    </Dialog>

    {/* This is create case */}
    <Dialog
      open={props.show_time_entry_modal}
      PaperProps={
        useMediaQuery('(max-width:1585px)')
          ? {
              style: {
                minHeight: 'auto',
                maxHeight: '90%',
                width: '27%',
              },
            }
          : {
              style: {
                minHeight: 'auto',
                maxHeight: '90%',
                width: '25%',
              },
            }
      }>
      <div style={style.DialogContainer}>
        <div style={{ position: 'absolute', right: 7, top: 7 }}>
          <CloseOutlined
            style={{ marginTop: 6, marginRight: 5, color: 'var(--tin-grey)', cursor: 'pointer' }}
            onClick={props.handleToggleTimeEntryModal}
          />
        </div>
        <div style={style.DialogContainerHeading}>New Time Entry</div>

        <div style={style.DialogInputContainer}>
          <label style={style.DialogInputLabel}>Person / Resource *</label>
          {/* show selected resource if in resources or projects screen
              else just the current resource */}
          {props.selected_view === 'resources' ? (
            <div style={style.DialogInputDisabled}>
              {props.selected_resource && props.selected_resource.name && (
                <Chip
                  style={style.DialogInputChipContainer}
                  avatar={
                    <Avatar style={style.DialogInputChip}>{props.selected_resource.name[0]}</Avatar>
                  }
                  label={props.selected_resource.name}
                />
              )}
            </div>
          ) : null}
          {props.selected_view === 'private' ? (
            <div style={style.DialogInputDisabled}>
              <Chip
                style={style.DialogInputChipContainer}
                avatar={<Avatar style={style.DialogInputChip}>{props.loggedin_name[0]}</Avatar>}
                label={props.loggedin_name}
              />
            </div>
          ) : null}
          {props.selected_view === 'projects' ? (
            props.selected_project_logger ? (
              <div style={style.DialogInputDisabled}>
                {props.resources &&
                  props.resources
                    .filter((resource) => resource.id === props.selected_project_logger)
                    .map((resource) => {
                      return (
                        <Chip
                          key={resource.id}
                          style={style.DialogInputChipContainer}
                          avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                          label={resource.name}
                        />
                      );
                    })}
              </div>
            ) : (
              <div style={style.DialogInput}>
                <select
                  name="Resources"
                  style={style.DialogInputSelect}
                  placeholder="Select Resource"
                  onChange={props.handleResourceSelection}>
                  <option value="">Select Resource</option>
                  {props.resources &&
                    props.resources.map((resource, index) => {
                      return (
                        <option key={index} value={resource.id}>
                          {resource.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )
          ) : null}

          {props.selected_view === 'teams'
            ? props.resources &&
              props.resources
                .filter((resource) => resource.id === props.selected_project_logger)
                .map((resource) => {
                  return (
                    <div style={style.DialogInputDisabled} key={`H.teams.${resource.name[0]}`}>
                      <Chip
                        style={style.DialogInputChipContainer}
                        avatar={<Avatar style={style.DialogInputChip}>{resource.name[0]}</Avatar>}
                        label={resource.name}
                      />
                    </div>
                  );
                })
            : null}
        </div>

        <div style={style.DialogInputContainer}>
          <label style={style.DialogInputLabel}>Project *</label>
          <div>
            <CustomSelect
              projects={props.projects}
              entry_selected_project={props.entry_selected_project}
              handleProjectSelection={props.handleProjectSelection}
              placeholder="Select Project"
            />
          </div>
        </div>

        <div style={style.DialogDateInputContainer}>
          <div>
            <div style={style.DialogInputLabel}>Start Date</div>
            <CustomDatePicker
              datePickerValue={{
                year: Moment(props.entry_start_date).year(),
                month: Moment(props.entry_start_date).month() + 1,
                day: Moment(props.entry_start_date).date(),
              }}
              handleChangeDate={props.handleChangeStartDate}
              formattedMinimumDate={props.formattedMinimumDate}
              style={style.DateStyle}></CustomDatePicker>
          </div>
          <div>
            <div style={[style.DialogInputLabel, { width: '60%' }]}>End Date</div>
            <CustomDatePicker
              datePickerValue={{
                year: Moment(props.entry_end_date).year(),
                month: Moment(props.entry_end_date).month() + 1,
                day: Moment(props.entry_end_date).date(),
              }}
              handleChangeDate={props.handleChangeEndDate}
              formattedMinimumDate={{
                year: Moment(props.entry_start_date).year(),
                month: Moment(props.entry_start_date).month() + 1,
                day: Moment(props.entry_start_date).date(),
              }}
              style={style.DateStyle}></CustomDatePicker>
          </div>
        </div>
        {props.isWeekendDay(Moment(props.entry_start_date)) ||
        props.isWeekendDay(Moment(props.entry_end_date)) ? (
          <div style={style.DateWeekendNotice}>
            Entries for weekend can only be added one day at a time
          </div>
        ) : props.isHoliday(Moment(props.entry_start_date)) ||
          props.isHoliday(Moment(props.entry_end_date)) ? (
          <div style={style.DateWeekendNotice}>
            Entries for holiday can only be added one day at a time
          </div>
        ) : null}

        <div style={style.DialogTimeInputContainer}>
          <span style={style.DialogInputLabel}>
            Time per Day * <br />
          </span>
          <div style={style.DialogTimeRateInputField}>
            <img src={timelapsegreyicon} alt="" style={{ marginRight: 5, marginLeft: 3 }} />
            <input
              name="Minutes"
              type="text"
              placeholder="8h"
              style={[style.DialogInput, style.DialogTimeRateInput]}
              onChange={props.handleChangeMinutes}
              defaultValue={props.getMinutesInHMFormat(480)}
            />
          </div>
          {props.entry_minutes_error && (
            <>
              <span style={{ fontSize: '10px', color: 'red' }}>{props.entry_minutes_error} </span>
              <br />
            </>
          )}
        </div>
        <span style={{ fontSize: '10px', color: 'var(--tin-grey)' }}>
          Please input time like 4h7m, 4h, or 7m
        </span>
        <br />
        <div style={style.DialogTextAreaContainer}>
          <span style={style.DialogInputLabel}>
            Description (optional)
            <br />
          </span>
          <textarea
            style={style.DialogInputTextArea}
            key={3}
            name="Description"
            rows="4"
            placeholder="What did you do for this project?"
            onChange={props.handleChangeDescription}
          />
        </div>
        <div style={style.DialogSubmission}>
          {!props.entry_submission_state && (
            <button style={style.DialogDiscardBtn} onClick={props.handleToggleTimeEntryModal}>
              DISCARD
            </button>
          )}
          {props.entry_submission_state ? (
            <div style={style.DialogSubmissionMessage}>Saving entries, please be patient...</div>
          ) : null}
          {!props.entry_submission_state && (
            <button
              style={
                props.areEntriesEmpty() || props.entry_submission_state || props.entry_minutes_error
                  ? style.DialogDisabledBtn
                  : style.DialogSubmissionBtn
              }
              onClick={props.handleAddTimeEntry}
              disabled={
                props.areEntriesEmpty() || props.entry_submission_state || props.entry_minutes_error
              }>
              Save
            </button>
          )}
        </div>
      </div>
    </Dialog>
  </>
);

export const DialogBoxes = Radium(MyComponent);
