import React from 'react';

import Radium from 'radium';
import Chart from 'chart.js';
import { style } from '../style';
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import { ProjectTimelineChart } from './ProjectTimelineChart';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';

ReactChartkick.addAdapter(Chart);

const MyComponent = (props) => (
  <div style={style.TableContainer}>
    <div style={style.Headings}>
      <div style={style.CardHeadingText} onClick={props.goBack}>
        <ArrowBackIosRounded />
        Hourly Data &#9876; Invoices
      </div>
      {props.projects ? (
        <div style={style.ProjectsSelection}>
          <select
            name="Projects"
            value={props.project}
            style={style.ProjectSelect}
            className="hours-management-button"
            onChange={props.handleProjectSelection}>
            <option value="" disabled>
              Select Project
            </option>
            {props.projects.map((project, index) => {
              return <option key={index}>{project.name}</option>;
            })}
          </select>
        </div>
      ) : null}
    </div>
    <div style={style.ChartContainer}>
      <ColumnChart
        colors={['#79797970', '#d0175d']}
        style={style.CentralizedRow}
        data={props.invoices_chart_data}
        library={style.ColumnChart}
        curve={true}
        messages={{ empty: 'No data to make Chart!' }}
      />
    </div>
    <ProjectTimelineChart invoices_timeline_chart_data={props.invoices_timeline_chart_data} />
  </div>
);

export const ProjectComparisonChart = Radium(MyComponent);
