import StreamlineView from '../components/Dashboard/StreamlineView';
import HourCzarDashboard from '../components/Dashboard/HourCzarDashboard';
import ProjectManagerDashboard from '../components/Dashboard/ProjectManagerDashboard';
import HourLoggerDashboard from '../components/Dashboard/HourLoggerDashboard';
import TeamManagerDashboard from '../components/Dashboard/TeamManagerDashboard';

export const hasPermission = (roles, category, permission) => {
  // filter only those roles who have permission equal to true
  const filtered = roles.filter(
    (role) => Ability[role][category] && Ability[role][category][permission],
  );

  if (filtered.length) return true; // atleast one role has permission to do some action

  return false; // none of the roles have permission
};

export const Ability = {
  'Super Admin': {
    can_access: [
      'Dashboard',
      'Invoices',
      'Payments',
      'Projects',
      'Resources',
      'Receivers',
      'Hours Management',
      'Reports',
      'Billing',
      'Roles Management',
      'Ledgers',
      'Hour Logging',
      'Settings',
      'Teams',
    ],
    dashboard: {
      present: true,
      name: StreamlineView,
    },
    invoices: {
      can_delete_invoice_id: true,
      can_edit_dates: true,
      can_edit_invoice_id: true,
      can_create: true,
      can_enter_receiver: true,
      can_edit: true,
      type: 'all',
    },
    hours: {
      can_overwrite: true,
    },
    project: {
      can_create: true,
      can_edit: true,
      can_set_rate: true,
      can_delete_rate: true,
    },
    team: {
      can_create: true,
      can_edit: true,
    },
    resource: {
      can_create: true,
      can_edit: true,
      can_set_rate: true,
      can_delete_rate: true,
    },
    receiver: {
      can_create: true,
      can_edit: true,
    },
    payment: {
      can_create: true,
      can_edit: true,
      can_delete: true,
    },
    hour_logging: {
      can_access_projects: true,
      can_access_resources: true,
      can_access_teams: true,
      can_create_bookings: true,
      can_view_bookings: true,
      can_update_bookings: true,
      can_delete_bookings: true,
    },
    settings: {
      can_access: true,
      can_add_holidays: true,
    },
  },
  'View Admin': {
    can_access: [
      'Dashboard',
      'Invoices',
      'Projects',
      'Teams',
      'Resources',
      'Receivers',
      'Reports',
      'Billing',
      'Hour Logging',
    ],
    dashboard: {
      present: true,
      name: StreamlineView,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: false,
      can_edit_invoice_id: false,
      can_create: false,
      can_enter_receiver: false,
      can_edit: false,
      type: 'all',
    },
    hours: {
      can_overwrite: false,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    team: {
      can_create: false,
      can_edit: false,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    receiver: {
      can_create: false,
      can_edit: false,
      can_delete: false,
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: false,
      can_access_teams: false,
      can_create_bookings: false,
      can_view_bookings: true,
      can_update_bookings: false,
      can_delete_bookings: false,
    },
    settings: {
      can_access: true,
      can_add_holidays: true,
    },
  },
  'Project Manager': {
    can_access: ['Dashboard', 'Invoices', 'Ledgers', 'Hour Logging', 'Projects'],
    dashboard: {
      present: true,
      name: ProjectManagerDashboard,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: false,
      can_edit_invoice_id: false,
      can_create: true,
      can_enter_receiver: false,
      can_edit: true,
      type: 'project',
    },
    hours: {
      can_overwrite: false,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: true,
    },
    team: {
      can_create: false,
      can_edit: false,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: true,
    },
    client: {
      can_create: false,
      can_edit: false,
    },
    receiver: {
      can_create: false,
      can_edit: false,
    },
    hour_logging: {
      can_access_projects: true,
      can_access_resources: false,
      can_access_teams: false,
      can_create_bookings: true,
      can_view_bookings: true,
      can_update_bookings: true,
      can_delete_bookings: true,
    },
    settings: {
      can_access: false,
      can_add_holidays: false,
    },
  },
  'Team Manager': {
    can_access: ['Dashboard', 'Hour Logging'],
    dashboard: {
      present: true,
      name: TeamManagerDashboard,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: false,
      can_edit_invoice_id: false,
      can_create: false,
      can_enter_receiver: false,
      can_edit: false,
      type: 'status',
    },
    hours: {
      can_overwrite: false,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    team: {
      can_create: true,
      can_edit: true,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    client: {
      can_create: false,
      can_edit: false,
    },
    receiver: {
      can_create: false,
      can_edit: false,
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: false,
      can_access_teams: true,
      can_create_bookings: true,
      can_view_bookings: true,
      can_update_bookings: true,
      can_delete_bookings: true,
    },
    settings: {
      can_access: false,
      can_add_holidays: false,
    },
  },
  'Hour Czar': {
    can_access: ['Dashboard', 'Hours Management', 'Hour Logging'],
    dashboard: {
      present: true,
      name: HourCzarDashboard,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: false,
      can_edit_invoice_id: false,
      can_create: false,
      can_enter_receiver: false,
      can_edit: false,
      type: 'all',
    },
    hours: {
      can_overwrite: false,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    team: {
      can_create: false,
      can_edit: false,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    client: {
      can_create: false,
      can_edit: false,
    },
    receiver: {
      can_create: false,
      can_edit: false,
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: false,
      can_access_teams: false,
      can_create_bookings: false,
      can_view_bookings: true,
      can_update_bookings: false,
      can_delete_bookings: false,
    },
    settings: {
      can_access: true,
      can_add_holidays: true,
    },
  },
  'Hour Logger': {
    can_access: ['Dashboard', 'Hour Logging'],
    dashboard: {
      present: true,
      name: HourLoggerDashboard,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: false,
      can_edit_invoice_id: false,
      can_create: false,
      can_enter_receiver: false,
      can_edit: false,
      type: 'status',
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: false,
      can_create_bookings: false,
      can_view_bookings: true,
      can_update_bookings: false,
      can_delete_bookings: false,
    },
    settings: {
      can_access: false,
      can_add_holidays: false,
    },
  },
  'Financial Controller': {
    can_access: ['Dashboard', 'Invoices', 'Payments', 'Receivers', 'Ledgers'],
    dashboard: {
      present: true,
      name: HourCzarDashboard,
    },
    invoices: {
      can_delete_invoice_id: false,
      can_edit_dates: true,
      can_edit_invoice_id: true,
      can_create: false,
      can_enter_receiver: false,
      can_edit: true,
      type: 'status',
    },
    hours: {
      can_overwrite: false,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    team: {
      can_create: false,
      can_edit: false,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: false,
    },
    receiver: {
      can_create: true,
      can_edit: true,
    },
    payment: {
      can_create: true,
      can_edit: true,
      can_delete: true,
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: true,
      can_access_teams: false,
      can_create_bookings: false,
      can_view_bookings: true,
      can_update_bookings: false,
      can_delete_bookings: false,
    },
    settings: {
      can_access: false,
      can_add_holidays: false,
    },
  },
  'Operations Controller': {
    can_access: ['Dashboard', 'Projects', 'Resources', 'Hour Logging'],
    dashboard: {
      present: true,
      name: HourLoggerDashboard,
    },
    project: {
      can_create: false,
      can_edit: false,
      can_set_rate: true,
      can_delete_rate: true,
    },
    resource: {
      can_create: false,
      can_edit: false,
      can_set_rate: true,
      can_delete_rate: true,
    },
    hour_logging: {
      can_access_projects: false,
      can_access_resources: false,
      can_access_teams: false,
      can_create_bookings: false,
      can_view_bookings: true,
      can_update_bookings: false,
      can_delete_bookings: false,
    },
  },
};
