import React, { Component } from 'react';

import Settings from '../../components/Settings';
import './SettingsPage.css';

class SettingsPage extends Component {
  render() {
    return (
      <div className="settingspage-main">
        <Settings />
      </div>
    );
  }
}

export default SettingsPage;
