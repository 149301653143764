import React from 'react';

import Radium from 'radium';
import { style } from './style';
import { Dialog } from '@mui/material';

const MyComponent = (props) => (
  <>
    <Dialog
      open={props.show_edit_popup || props.show_create_popup}
      onClose={props.show_edit_popup ? props.toggleEditPopup : props.toggleCreatePopup}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.HeadingRow}>
        <h2 style={style.Heading}>
          {props.show_edit_popup ? 'Change Role for ' + props.selected_email : 'New Role'}
        </h2>
        <p
          style={style.Cross}
          onClick={props.show_edit_popup ? props.toggleEditPopup : props.toggleCreatePopup}>
          X
        </p>
      </div>
      <div style={style.Row}>
        <div style={style.RowItems}>
          {!props.show_edit_popup ? (
            <select name="Email" style={style.Select} onChange={props.handleEmailSelection}>
              <option value="" disabled>
                Email
              </option>
              {props.emails.map((email, index) => {
                return <option key={index}>{email}</option>;
              })}
            </select>
          ) : null}
        </div>
        <div style={style.RowItems}>
          <select
            key={4}
            name="Roles"
            style={style.Select}
            onChange={props.handleRoleSelection}
            value={props.selected_role}>
            <option value="" disabled>
              Role
            </option>
            {props.roles.map((role, index) => {
              return (
                <option key={index} value={role}>
                  {role}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div style={style.ButtonContainer}>
        <button
          style={style.BorderedButton}
          onClick={props.show_edit_popup ? props.editRole : props.createRole}>
          {props.show_edit_popup ? 'Save' : 'Create'}
        </button>
      </div>
    </Dialog>

    <Dialog
      open={props.show_delete_popup}
      onClose={props.toggleDeletePopup}
      fullWidth={true}
      maxWidth="sm">
      <div style={style.DeleteHeadingRow}>
        <h2 style={style.Heading}>
          Are you sure you want to delete &ldquo;{props.selected_email}&rdquo; role as &ldquo;
          {props.selected_role}&rdquo;?
        </h2>
        <p style={style.Cross} onClick={props.toggleDeletePopup}>
          X
        </p>
      </div>
      <div style={style.ButtonContainer}>
        <button style={style.DeleteBorderedButton} onClick={props.deleteRole}>
          {'Yes'}
        </button>
        <button style={style.DeleteBorderedButton} onClick={props.toggleDeletePopup}>
          {'Cancel'}
        </button>
      </div>
    </Dialog>
  </>
);

export const DialogBox = Radium(MyComponent);
