import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bars } from 'react-loader-spinner';
import { Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { style } from './style';
import { withSnackbar } from 'notistack';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import Moment from 'moment';

import { DialogBox } from './DialogBox';
import { PaymentsSearchFields } from '../Payments/PaymentsSearchFields';
import * as ReactGA from 'react-ga';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_project: '',
      selected_amount: 0,
      selected_date: '',
      show_create_popup: false,
      show_edit_popup: false,
      show_delete_popup: false,
      selected_payment_docId: '',
      show_filters: false,
      filtered_project: this.props.selected_project,
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadPaymentData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_project !== this.props.selected_project) {
      this.setState({
        filtered_project: this.props.selected_project,
      });
    }
  }

  toggleCreatePopup() {
    this.setState({
      show_create_popup: !this.state.show_create_popup,
      selected_project: '',
      selected_amount: 0,
      selected_date: '',
    });
  }

  toggleEditPopup(id, amount, date) {
    this.setState({
      show_edit_popup: !this.state.show_edit_popup,
      selected_payment_docId: id,
      selected_amount: parseInt(amount),
      selected_date: date,
    });
  }

  toggleDeletePopup(id) {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
      selected_payment_docId: id,
    });
  }

  handleProjectSelection = (e) => {
    this.setState({
      selected_project: e.target.value,
    });
  };

  handleDateSelection = (e) => {
    this.setState({
      selected_date: e.target.value,
    });
  };

  handleAmountSelection = (e) => {
    this.setState({
      selected_amount: e.target.value,
    });
  };

  handleProjectFilteredSelection = (e) => {
    this.props.projects.forEach((project) => {
      if (project.id.toString() === e.target.value) {
        this.setState({
          filtered_project: project.name,
        });
      }
    });
  };

  loadPaymentData = async () => {
    let { stopLoader, getAllPayments, projects, getProjects } = this.props;

    !projects && (await getProjects());

    getAllPayments().then((response) => {
      stopLoader();
    });
  };

  removePayment = async () => {
    let { stopLoader, startLoader, deletePayment, getAllPayments } = this.props;
    let { selected_payment_docId } = this.state;

    await this.toggleDeletePopup(selected_payment_docId);

    startLoader('#029E5F');

    ReactGA.event({
      category: 'Payments',
      action: 'Deleted a Payment',
    });

    await deletePayment(this.state.selected_payment_docId);

    await getAllPayments();

    await stopLoader();
  };

  generatePayment = async () => {
    let { stopLoader, startLoader, savePayment, getAllPayments, enqueueSnackbar } = this.props;
    let { selected_project, selected_date, selected_amount } = this.state;

    if (selected_amount <= 0) {
      enqueueSnackbar('The amount cannot be zero or less!', { variant: 'error' });
      return;
    }

    if (Moment(selected_date) <= Moment()) {
      await this.toggleCreatePopup();

      startLoader('#029E5F');

      let data = {
        project_id: selected_project,
        date: selected_date,
        amount: selected_amount.toString(),
      };

      ReactGA.event({
        category: 'Payments',
        action: 'Added a Payment',
      });

      await savePayment(data);

      await getAllPayments();

      await stopLoader();
    } else enqueueSnackbar("The date cannot exceed today's date!", { variant: 'error' });
  };

  changePayment = async () => {
    let { stopLoader, startLoader, updatePayment, getAllPayments, enqueueSnackbar } = this.props;
    let { selected_date, selected_amount } = this.state;

    if (selected_amount <= 0) {
      enqueueSnackbar('The amount cannot be zero or less!', { variant: 'error' });
      return;
    }

    if (Moment(selected_date) <= Moment()) {
      await this.toggleEditPopup(this.state.selected_payment_docId, 0, '');

      startLoader('#029E5F');

      let data = {
        date: selected_date,
        amount: selected_amount,
      };

      ReactGA.event({
        category: 'Payments',
        action: 'Edited a Payment',
      });

      await updatePayment(this.state.selected_payment_docId, data);

      await getAllPayments();

      await stopLoader();
    } else enqueueSnackbar("The date cannot exceed today's date!", { variant: 'error' });
  };

  handleClearFilters = () => {
    this.setState({
      filtered_project: '',
      filtered_client: '',
    });
  };

  handleChangeShowFilterStatus = (status) => {
    this.setState({
      show_filters: status,
    });
  };

  render() {
    const { loading, no_payments_data, payments, projects } = this.props;

    const {
      show_filters,
      selected_project,
      selected_date,
      selected_amount,
      filtered_project,
      show_create_popup,
      show_edit_popup,
      show_delete_popup,
    } = this.state;

    let project_name = '';

    let disable_create_button =
      selected_project === '' || !Moment(selected_date).isValid() || selected_amount <= 0;

    let disable_edit_button = !Moment(selected_date).isValid();

    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <div className="breadcrumb-link" style={style.HeaderText}>
            Payments
          </div>
          <button style={style.FilledButton} onClick={this.toggleCreatePopup.bind(this)}>
            + Create Payment
          </button>
          {projects ? (
            <DialogBox
              handleProjectSelection={this.handleProjectSelection}
              handleAmountSelection={this.handleAmountSelection}
              handleDateSelection={this.handleDateSelection}
              toggleCreatePopup={this.toggleCreatePopup.bind(this)}
              toggleEditPopup={this.toggleEditPopup.bind(this)}
              toggleDeletePopup={this.toggleDeletePopup.bind(this)}
              projects={projects}
              selected_project={selected_project}
              selected_date={selected_date}
              selected_amount={selected_amount}
              show_create_popup={show_create_popup}
              show_edit_popup={show_edit_popup}
              show_delete_popup={show_delete_popup}
              savePayment={this.generatePayment}
              updatePayment={this.changePayment}
              deletePayment={this.removePayment}
              disable_create_button={disable_create_button}
              disable_edit_button={disable_edit_button}
            />
          ) : null}
        </Toolbar>

        <div style={loading || no_payments_data ? style.LoadingContainer : style.MainContainer}>
          {loading === true ? (
            <Bars color="#C992B8" height={50} width={50} />
          ) : no_payments_data ? (
            <EmptyContainer />
          ) : (
            <div style={style.TableContainer}>
              {show_filters ? (
                <>
                  <div style={style.ShowFiltersButtonContainer}>
                    <div
                      style={style.ShowFiltersButton}
                      onClick={() => this.handleChangeShowFilterStatus(false)}>
                      Hide Filters
                    </div>
                  </div>
                  <PaymentsSearchFields
                    projects={projects}
                    selected_project={filtered_project}
                    handleClearFilters={this.handleClearFilters}
                    handleProjectSelection={this.handleProjectFilteredSelection}
                  />
                </>
              ) : (
                <div style={style.ShowFiltersButtonContainer}>
                  <div
                    style={style.ShowFiltersButton}
                    onClick={() => this.handleChangeShowFilterStatus(true)}>
                    Show Filters
                  </div>
                </div>
              )}
              <Table style={style.Table}>
                <TableHead style={style.Head}>
                  <TableRow style={style.TableRow}>
                    <TableCell style={style.TableCell1}>Project</TableCell>
                    <TableCell style={style.TableCell3}>Date</TableCell>
                    <TableCell style={style.TableCell3}>Amount</TableCell>
                    <TableCell style={style.TableCell4}>Edit</TableCell>
                    <TableCell style={style.TableCell5}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={show_filters ? style.BodyWithFilters : style.BodyWithoutFilters}>
                  {payments
                    ? payments.map((payment, index) => {
                        projects.forEach((project) => {
                          if (project.id.toString() === payment.project_id)
                            project_name = project.name;
                        });
                        return (filtered_project !== '' && project_name === filtered_project) ||
                          filtered_project === '' ? (
                          <TableRow style={style.TableRow} key={index}>
                            <TableCell style={style.TableCell1}>
                              <div
                                style={{
                                  color: 'black',
                                }}>
                                {project_name}
                              </div>
                            </TableCell>

                            <TableCell style={style.TableCell3}>
                              {Moment(payment.date).format('DD/MM/YYYY') === '01/01/0001' ? (
                                <span style={{ paddingLeft: '2.5em' }}>-</span>
                              ) : (
                                Moment(payment.date).format('DD/MM/YYYY')
                              )}
                            </TableCell>

                            <TableCell style={style.TableCell3}>
                              <div style={{ color: 'black' }}>${payment.amount}</div>
                            </TableCell>

                            <TableCell style={style.TableCell4}>
                              <button
                                style={style.edit_button}
                                onClick={() =>
                                  this.toggleEditPopup(payment.docId, payment.amount, payment.date)
                                }>
                                <Edit />
                              </button>
                            </TableCell>

                            <TableCell style={style.TableCell5}>
                              <button
                                style={style.delete_button}
                                onClick={() => this.toggleDeletePopup(payment.docId)}>
                                <Delete />
                              </button>
                            </TableCell>
                          </TableRow>
                        ) : null;
                      })
                    : null}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.LoaderReducer.loading,
    no_payments_data: state.PaymentReducer.no_payments_data,
    payments: state.PaymentReducer.payments,
    no_data: state.ProjectReducer.no_data,
    projects: state.ProjectReducer.projects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  getAllPayments: () => dispatch(ActionsCreator.getAllPayments()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  savePayment: (data) => dispatch(ActionsCreator.savePayment(data)),
  deletePayment: (id) => dispatch(ActionsCreator.deletePayment(id)),
  updatePayment: (id, data) => dispatch(ActionsCreator.updatePayment(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Payment));
